import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  GetAddressFromCoordinatesRequest,
  GetCoordinatesFromAddressRequest,
} from "../../api/generated/backend";
import { useApiMutation, useApiQuery } from "../../api/use-api";
import { ADDRESS, COORDINATES, COUNTRY_AND_CODE } from "../../types/query-keys";

const CENTER_OF_EUROPE_LAT_LON: [number, number] = [47.751569, 1.675063];

export const useMapLocation = (): {
  location: string;
  coordinates?: [number, number];
  setLocation: (location: string) => void;
  setCoordinates: (coords?: [number, number]) => void;
  updateCoordinatesBasedOnAddress: (address: string) => void;
} => {
  const [location, setLocation] = useState("");
  const [coordinates, setCoordinates] = useState<
    [number, number] | undefined
  >();

  const { mutate: getCoordinatesFromAddress } = useApiMutation(
    "backend",
    (api) => (request: GetCoordinatesFromAddressRequest) =>
      api.getCoordinatesFromAddress(request),
    COORDINATES,
    undefined,
    {
      onSuccess: ({ lat, lng }) => {
        setCoordinates([lat, lng]);
      },
    },
  );

  const updateCoordinatesBasedOnAddress = useCallback(
    async (address: string) => {
      getCoordinatesFromAddress({ addressDto: { address } });
    },
    [getCoordinatesFromAddress],
  );

  return {
    location,
    coordinates,
    setLocation,
    setCoordinates,
    updateCoordinatesBasedOnAddress,
  };
};

export const useMyMapLocation = (): {
  myLocation: string;
  myCoordinates?: [number, number];
  setMyLocation: (location: string) => void;
  setMyCoordinates: (coords: [number, number]) => void;
  updateMyCoordinatesBasedOnAddress: (address: string) => void;
} => {
  const {
    i18n: { language },
  } = useTranslation();

  const {
    location: myLocation,
    coordinates: myCoordinates,
    setLocation: setMyLocation,
    setCoordinates: setMyCoordinates,
    updateCoordinatesBasedOnAddress: updateMyCoordinatesBasedOnAddress,
  } = useMapLocation();

  const { mutate: getAddressFromCoordinates } = useApiMutation(
    "backend",
    (api) => (request: GetAddressFromCoordinatesRequest) =>
      api.getAddressFromCoordinates(request),
    ADDRESS,
    undefined,
    {
      onSuccess: ({ address }) => {
        setMyLocation(address);
      },
    },
  );

  const { mutate: getCoordinatesFromAddress } = useApiMutation(
    "backend",
    (api) => (request: GetCoordinatesFromAddressRequest) =>
      api.getCoordinatesFromAddress(request),
    COORDINATES,
    undefined,
    {
      onSuccess: ({ lat, lng }) => {
        if (lat && lng) {
          return onHasGeoLocation({
            coords: { latitude: lat, longitude: lng },
          });
        }
        onNoLocationAvailable();
      },
    },
  );

  const [getLocationBasedOnAddress, setGetLocationBasedOnAddress] =
    useState(false);

  useApiQuery(
    "backend",
    (api) => api.getUserCountryAndPostalCode(),
    COUNTRY_AND_CODE,
    undefined,
    {
      enabled: getLocationBasedOnAddress && !myCoordinates?.length,
      onSuccess: (userData) => {
        if (userData.country && userData.postalCode) {
          getCoordinatesFromAddress({
            addressDto: {
              address: `${userData.country} ${userData.postalCode}`,
            },
          });
        }
      },
    },
  );

  const onNoLocationAvailable = useCallback(async () => {
    setMyCoordinates(CENTER_OF_EUROPE_LAT_LON);
  }, [setMyCoordinates]);

  const onHasGeoLocation = useCallback(
    (position: { coords: { latitude: number; longitude: number } }) => {
      const latLng: [number, number] = [
        position.coords.latitude,
        position.coords.longitude,
      ];

      setMyCoordinates(latLng);
      getAddressFromCoordinates({
        coordinatesDto: { lat: latLng[0], lng: latLng[1], language },
      });
    },
    [getAddressFromCoordinates, language, setMyCoordinates],
  );

  const onDoesNotHaveGeoLocation = useCallback(async () => {
    setGetLocationBasedOnAddress(true);
  }, []);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        onHasGeoLocation,
        onDoesNotHaveGeoLocation,
        {
          enableHighAccuracy: true,
        },
      );
    } else {
      onDoesNotHaveGeoLocation();
    }
  }, [onDoesNotHaveGeoLocation, onHasGeoLocation]);

  return {
    myLocation,
    myCoordinates,
    setMyLocation,
    setMyCoordinates,
    updateMyCoordinatesBasedOnAddress,
  };
};
