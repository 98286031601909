import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Disease } from "shared/model/diseases";

export const ForwardEndoToEndometriosis: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const endo = "endo";
  const endometriosis = useMemo(() => Disease.ENDOMETRIOSIS.toLowerCase(), []);

  useEffect(() => {
    if (location.pathname.includes(`/${endo}/`)) {
      const endometriosisPath = location.pathname.replace(endo, endometriosis);
      navigate(endometriosisPath + location.search);
    }
  }, [endo, endometriosis, location, navigate]);

  return null;
};
