import { useMemo } from "react";
import { FrontendPageEnum } from "shared/model/pages";
import { TranslationFeKey } from "shared/types/translation-key";
import { twMerge } from "tailwind-merge";

import { chatIcon, doctorIcon, reportIcon } from "./constants";
import { SendReportViaEmailRequest } from "../api/generated/backend";
import { toTenantIdHeader, useApiMutation, useApiQuery } from "../api/use-api";
import { PrimaryButton, SuccessButton } from "../components/form/button";
import { Text } from "../components/text/text";
import { CONVERSATIONS_KEY } from "../types/query-keys";
import { useNavigateInsideTenant } from "../util/navigation-hooks";
import { useTenantId } from "../util/use-active-tenant-id";
import { useUser } from "../util/use-user";

const openMail = () => {
  window.location.href = "mailto:hand-eczema@mamahealth.com";
};

export const Report: React.FC = () => {
  return (
    <div className="flex min-h-full flex-col items-center justify-center overflow-auto bg-base-200	">
      <ReportPageHero />
      <ReportPageHowItWorks />
    </div>
  );
};

const ReportPageHero: React.FC = () => {
  const user = useUser();
  const tenantId = useTenantId();
  const navigate = useNavigateInsideTenant();

  const language = useMemo(
    () => user?.userAttributes?.language ?? "en-US",
    [user?.userAttributes?.language],
  );

  const { data: conversations } = useApiQuery(
    "multiagent",
    (api) => api.getConversations({ mamaDisease: tenantId.disease }),
    CONVERSATIONS_KEY(tenantId.disease),
    undefined,
  );

  const { mutate: sendReportViaEmail } = useApiMutation(
    "backend",
    (api) => (request: SendReportViaEmailRequest) =>
      api.sendReportViaEmail(request),
    undefined,
    {
      successMessage: {
        tx: "journey.timelineSent",
      },
    },
  );

  return (
    <div className="z-0 flex w-full flex-col items-center bg-white pb-32 pt-32">
      <div className="flex max-w-6xl flex-row content-center items-center justify-center gap-4 p-4 text-left">
        <div className="flex flex-row">
          <div>
            <Text
              className="text-5xl font-bold text-blue-950		"
              as="h1"
              tx={
                user?.userAttributes?.name
                  ? "report.header"
                  : "report.headerNoName"
              }
              txData={{ name: user?.userAttributes?.name ?? "" }}
            />
            <Text
              className="py-6 text-blue-950	"
              as="p"
              tx="report.getAccessToReport"
            />
            <div className="flex flex-col justify-start md:flex-row">
              <PrimaryButton
                className="my-2 text-white md:mr-2 md:min-w-[200px]"
                tx="report.updateYourHealthReport"
                onClick={() => {
                  navigate(FrontendPageEnum.CHAT);
                }}
              />
              <SuccessButton
                className="my-2 text-white md:mx-2	"
                tx="report.getTheReport"
                onClick={() => {
                  if (conversations?.[0]?.id) {
                    sendReportViaEmail({
                      ...toTenantIdHeader(tenantId),
                      reportDto: {
                        language,
                        conversationId: conversations?.[0]?.id,
                      },
                    });
                  }
                }}
              />
            </div>
          </div>
          <div className="relative hidden w-80 md:block">
            <img
              src={chatIcon}
              className="absolute left-0 top-16 z-20 w-64 object-cover"
            />
            <img
              src={doctorIcon}
              className="absolute left-16 top-0 z-10 w-72 object-cover "
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const ReportPageHowItWorks: React.FC = () => {
  const navigate = useNavigateInsideTenant();
  return (
    <div>
      <div className="flex w-full flex-col items-center pb-10">
        <div className="flex max-w-6xl flex-col justify-center bg-base-200">
          <div className="flex flex-col items-center justify-center p-16">
            <Text
              className=" text-3xl	font-bold text-blue-950	"
              as="h1"
              tx="report.howItWorks"
            />
            <Text
              className=" py-6	text-blue-950	"
              as="p"
              tx="report.howItWorksDescription"
            />
          </div>

          <div className="hidden grid-cols-5 gap-4 lg:grid ">
            <Text
              className=" place-content-center	text-center	text-blue-950"
              as="div"
              text="1"
            />
            <div />
            <Text
              className=" place-content-center	text-center	text-blue-950"
              as="div"
              text="2"
            />
            <div />
            <Text
              className=" place-content-center	text-center	text-blue-950"
              as="div"
              text="3"
            />
            <div>
              <img src={chatIcon} className="w-64" />
            </div>
            <DotDotDotDot className="flex flex-col" />

            <div>
              <img src={reportIcon} className="w-64" />
            </div>
            <DotDotDotDot className="flex flex-col" />

            <div>
              <img src={doctorIcon} className="w-64" />
            </div>
            <Text
              className="place-content-center	text-center	text-blue-950"
              as="div"
              tx="report.getTheReport"
            />
            <div />
            <Text
              className="place-content-center	text-center	text-blue-950"
              as="div"
              tx="report.haveYOurDataAtHand"
            />
            <div />
            <Text
              className="place-content-center	text-center	text-blue-950"
              as="div"
              tx="report.shareItWithYOurDoctors"
            />
          </div>

          <div className="flex flex-col gap-8 lg:hidden">
            <IconWithText
              topText="1"
              iconSrc={chatIcon}
              bottomText="report.getTheReport"
            />

            <DotDotDotDot className="flex flex-row" />

            <IconWithText
              topText="2"
              iconSrc={reportIcon}
              bottomText="report.haveYOurDataAtHand"
            />

            <DotDotDotDot className="flex flex-row" />

            <IconWithText
              topText="3"
              iconSrc={doctorIcon}
              bottomText="report.shareItWithYOurDoctors"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-start p-4 pb-32 md:hidden md:flex-row">
        <PrimaryButton
          className="my-2 text-white md:mr-2 md:min-w-[200px]"
          tx="report.updateYourHealthReport"
          onClick={() => {
            navigate(FrontendPageEnum.CHAT);
          }}
        />

        <SuccessButton
          className="my-2 text-white md:ml-2 md:min-w-[200px]"
          tx="report.getYourReport"
          onClick={openMail}
        />
      </div>
    </div>
  );
};

const DotDotDotDot: React.FC<{ className: string }> = ({ className }) => {
  return (
    <div
      className={twMerge(
        "items-center justify-center gap-4 md:flex-row",
        className,
      )}
    >
      <div className="h-8 w-8 rounded-full bg-base-300" />
      <div className="h-8 w-8 rounded-full bg-base-300" />
      <div className="h-8 w-8 rounded-full bg-base-300" />
      <div className="h-8 w-8 rounded-full bg-base-300" />
    </div>
  );
};

const IconWithText: React.FC<{
  topText: string;
  iconSrc: string;
  bottomText: TranslationFeKey;
}> = ({ topText, iconSrc, bottomText }) => {
  return (
    <div className="flex flex-col items-center justify-center gap-2">
      <Text
        className=" place-content-center	text-center	text-blue-950"
        as="div"
        text={topText}
      />
      <img src={iconSrc} className="w-64" />
      <Text
        className="place-content-center	text-center	text-blue-950"
        as="div"
        tx={bottomText}
      />
    </div>
  );
};
