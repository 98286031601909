import React from "react";
import { twMerge } from "tailwind-merge";

import { UserMediaRecord } from "../../../api/generated/multiagent";
import { MimeMainType } from "../../../types/mime-main-type";
import { Text } from "../../text";
import { ChatMessage, ChatRole } from "../types";

export type ChatMessageProps = {
  message: ChatMessage;
  userChatColors?: { bubbleColor?: string; textColor?: string };
} & JSX.IntrinsicElements["div"];

export const ChatMessageComponent: React.FC<ChatMessageProps> = ({
  message: { role, content, media },
  className,
  userChatColors,
  ...rest
}) => {
  return (
    <div
      className={twMerge(
        "chat",
        role === ChatRole.USER
          ? "chat-end"
          : role === ChatRole.ASSISTANT
          ? "chat-start"
          : "mb-2 flex justify-center",
        className,
      )}
      {...rest}
    >
      <Text
        as="div"
        style={
          role === ChatRole.USER && !!userChatColors
            ? {
                backgroundColor: userChatColors.bubbleColor,
                color: userChatColors.textColor,
              }
            : {}
        }
        className={twMerge(
          "whitespace-pre-line",
          role === ChatRole.USER
            ? "chat-bubble chat-bubble-primary"
            : role === ChatRole.ASSISTANT
            ? "chat-bubble bg-mama-gray-100"
            : "w-full max-w-[calc(100%-1.6rem)] rounded-xl bg-chat-bubble-assistant p-4 text-black",
        )}
        txComponents={{}}
        text={content.text || " "}
      >
        <MessageDocument media={media as UserMediaRecord[]} />
      </Text>
      <div />
    </div>
  );
};

const MessageDocument: React.FC<{
  media?: UserMediaRecord[];
}> = ({ media }) => {
  return media?.length ? (
    <div className="flex flex-wrap justify-center gap-2 py-2">
      {media?.map((m, idx) =>
        m.mainType === MimeMainType.IMAGE ? (
          <img
            className="h-full max-h-48 rounded-xl object-cover"
            key={`${m.path}-${idx}`}
            src={m.path}
          />
        ) : m.mainType === MimeMainType.AUDIO ? (
          <audio key={`${m.path}-${idx}`} controls src={m.path} />
        ) : null,
      )}
    </div>
  ) : null;
};
