import { SocialMediaPostCategory } from "../../api/generated/backend";
import { toTenantIdHeader, useApiQuery } from "../../api/use-api";
import { SOCIAL_MEDIA_POST_CATEGORIES_GLOBAL } from "../../types/query-keys";
import { useTenantId } from "../../util/use-active-tenant-id";

export const useSocialMediaCategories = (): {
  categories: SocialMediaPostCategory[] | undefined;
  isFetching: boolean;
  refetch: () => void;
} => {
  const tenantId = useTenantId();
  const { data, isFetching, refetch } = useApiQuery(
    "backend",
    (api) =>
      api.listSocialMediaPostCategories({
        ...toTenantIdHeader(tenantId),
      }),
    SOCIAL_MEDIA_POST_CATEGORIES_GLOBAL,
  );

  return { categories: data, isFetching: isFetching, refetch: refetch };
};
