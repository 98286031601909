"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.endometrioseVereinigung = void 0;
const cognito_1 = require("../../../../model/cognito");
const languages_1 = require("../../../../model/languages");
const lock_1 = require("../../../../model/lock/lock");
const organisations_1 = require("../../../../model/organisations");
const pages_1 = require("../../../../model/pages");
const deployment_environments_1 = require("../../../deployment-environments");
exports.endometrioseVereinigung = {
    organisation: organisations_1.Organisation.ENDOMETRIOSEVEREINIGUNG,
    organisationType: organisations_1.OrganisationType.PAG,
    homePage: pages_1.FrontendPageEnum.CHAT,
    theme: {
        daisyTheme: {
            primary: "#720734",
            "primary-focus": "#a30a4a",
            "primary-content": "#FFFFFF",
            secondary: "#022553",
            "secondary-focus": "#04377a",
            "secondary-content": "#FFFFFF",
            accent: "#3459A3",
            "accent-focus": "#2D4D8C",
            "accent-content": "#FFFFFF",
            neutral: "#424242",
            "neutral-focus": "#333333",
            "neutral-content": "#FFFFFF",
            "base-100": "#ffffff",
            "base-200": "#f0f0f0",
            "base-300": "#e0e0e0",
            "base-content": "#1f1f1f",
            info: "#25dbf1",
            "info-content": "#082f34",
            success: "#30ca6b",
            "success-content": "#14522a",
            warning: "#dec762",
            "warning-content": "#524b2b",
            error: "#ff4d4d",
            "error-content": "#ffffff",
            "--btn-text-case": "none",
        },
    },
    publicPages: [],
    enabledPages: [
        Object.assign({ route: "/home", txPageName: "home", groupsWhichCanSeeThePage: [
                cognito_1.SupportedGroupsEnum.ADMIN,
                cognito_1.SupportedGroupsEnum.USER,
                cognito_1.SupportedGroupsEnum.PAG,
            ] }, lock_1.defaultPageLock),
        Object.assign({ txPageName: "admin-signup", route: pages_1.FrontendPageEnum.ADMIN_SIGNUP, groupsWhichCanSeeThePage: [
                cognito_1.SupportedGroupsEnum.USER,
                cognito_1.SupportedGroupsEnum.ADMIN,
            ] }, lock_1.defaultPageLock),
        Object.assign({ txPageName: "admin-dashboard", route: pages_1.FrontendPageEnum.ADMIN_DASHBOARD, groupsWhichCanSeeThePage: [cognito_1.SupportedGroupsEnum.PAG], settings: {
                dashboards: {
                    [deployment_environments_1.DeploymentEnvironment.DEV]: {
                        index: 6,
                    },
                    [deployment_environments_1.DeploymentEnvironment.PROD]: {
                        index: 21,
                    },
                },
            } }, lock_1.defaultPageLock),
        Object.assign({ txPageName: "user-dashboard", route: pages_1.FrontendPageEnum.USER_DASHBOARD, groupsWhichCanSeeThePage: [cognito_1.SupportedGroupsEnum.PAG], settings: {
                dashboards: {
                    [deployment_environments_1.DeploymentEnvironment.DEV]: {
                        index: 7,
                    },
                    [deployment_environments_1.DeploymentEnvironment.PROD]: {
                        index: 36,
                    },
                },
            } }, lock_1.defaultPageLock),
    ],
    authLogo: "/resources/endometriosis/endometriosevereinigung/endometriosevereinigung-logo.png",
    tenantNameTx: "organisation.endometriosevereinigung.settings.name",
    autoConfirmUsers: false,
    groupSecretsArns: {
        [deployment_environments_1.DeploymentEnvironment.DEV]: "arn:aws:secretsmanager:eu-central-1:616427543840:secret:group-secret-endometriosevereinigung-mSvJdB",
        [deployment_environments_1.DeploymentEnvironment.PROD]: "arn:aws:secretsmanager:eu-central-1:025928572003:secret:group-secret-endometriosevereinigung-cLAfF7",
    },
    metaDescriptionTx: "organisation.endometriosevereinigung.settings.description",
    pagLanguages: [languages_1.Language.de_DE],
};
