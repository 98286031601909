"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lookupFrequencyInMinutes = exports.NotificationFrequency = void 0;
var NotificationFrequency;
(function (NotificationFrequency) {
    NotificationFrequency["EVERY_HALF_HOUR"] = "EVERY_HALF_HOUR";
    NotificationFrequency["EVERY_HOUR"] = "EVERY_HOUR";
    NotificationFrequency["EVERY_TWO_HOURS"] = "EVERY_TWO_HOURS";
    NotificationFrequency["EVERY_THREE_HOURS"] = "EVERY_THREE_HOURS";
    NotificationFrequency["EVERY_FOUR_HOURS"] = "EVERY_FOUR_HOURS";
    NotificationFrequency["EVERY_FIVE_HOURS"] = "EVERY_FIVE_HOURS";
    NotificationFrequency["EVERY_SIX_HOURS"] = "EVERY_SIX_HOURS";
    NotificationFrequency["EVERY_SEVEN_HOURS"] = "EVERY_SEVEN_HOURS";
    NotificationFrequency["EVERY_EIGHT_HOURS"] = "EVERY_EIGHT_HOURS";
    NotificationFrequency["EVERY_NINE_HOURS"] = "EVERY_NINE_HOURS";
    NotificationFrequency["EVERY_TEN_HOURS"] = "EVERY_TEN_HOURS";
    NotificationFrequency["EVERY_ELEVEN_HOURS"] = "EVERY_ELEVEN_HOURS";
    NotificationFrequency["EVERY_TWELVE_HOURS"] = "EVERY_TWELVE_HOURS";
    NotificationFrequency["EVERY_THIRTEEN_HOURS"] = "EVERY_THIRTEEN_HOURS";
    NotificationFrequency["EVERY_FOURTEEN_HOURS"] = "EVERY_FOURTEEN_HOURS";
    NotificationFrequency["EVERY_FIFTEEN_HOURS"] = "EVERY_FIFTEEN_HOURS";
    NotificationFrequency["EVERY_SIXTEEN_HOURS"] = "EVERY_SIXTEEN_HOURS";
    NotificationFrequency["EVERY_SEVENTEEN_HOURS"] = "EVERY_SEVENTEEN_HOURS";
    NotificationFrequency["EVERY_EIGHTEEN_HOURS"] = "EVERY_EIGHTEEN_HOURS";
    NotificationFrequency["EVERY_NINETEEN_HOURS"] = "EVERY_NINETEEN_HOURS";
    NotificationFrequency["EVERY_TWENTY_HOURS"] = "EVERY_TWENTY_HOURS";
    NotificationFrequency["EVERY_TWENTYONE_HOURS"] = "EVERY_TWENTYONE_HOURS";
    NotificationFrequency["EVERY_TWENTYTWO_HOURS"] = "EVERY_TWENTYTWO_HOURS";
    NotificationFrequency["EVERY_TWENTYTHREE_HOURS"] = "EVERY_TWENTYTHREE_HOURS";
    NotificationFrequency["EVERY_TWENTYFOUR_HOURS"] = "EVERY_TWENTYFOUR_HOURS";
    NotificationFrequency["EVERY_THIRTYSIX_HOURS"] = "EVERY_THIRTYSIX_HOURS";
    NotificationFrequency["EVERY_FOURTYEIGHT_HOURS"] = "EVERY_FOURTYEIGHT_HOURS";
    NotificationFrequency["EVERY_SEVENTYTWO_HOURS"] = "EVERY_SEVENTYTWO_HOURS";
})(NotificationFrequency || (exports.NotificationFrequency = NotificationFrequency = {}));
function lookupFrequencyInMinutes(frequency) {
    const lookupTable = {
        [NotificationFrequency.EVERY_HALF_HOUR]: 30,
        [NotificationFrequency.EVERY_HOUR]: 60,
        [NotificationFrequency.EVERY_TWO_HOURS]: 120,
        [NotificationFrequency.EVERY_THREE_HOURS]: 180,
        [NotificationFrequency.EVERY_FOUR_HOURS]: 240,
        [NotificationFrequency.EVERY_FIVE_HOURS]: 300,
        [NotificationFrequency.EVERY_SIX_HOURS]: 360,
        [NotificationFrequency.EVERY_SEVEN_HOURS]: 420,
        [NotificationFrequency.EVERY_EIGHT_HOURS]: 480,
        [NotificationFrequency.EVERY_NINE_HOURS]: 540,
        [NotificationFrequency.EVERY_TEN_HOURS]: 600,
        [NotificationFrequency.EVERY_ELEVEN_HOURS]: 660,
        [NotificationFrequency.EVERY_TWELVE_HOURS]: 720,
        [NotificationFrequency.EVERY_THIRTEEN_HOURS]: 780,
        [NotificationFrequency.EVERY_FOURTEEN_HOURS]: 840,
        [NotificationFrequency.EVERY_FIFTEEN_HOURS]: 900,
        [NotificationFrequency.EVERY_SIXTEEN_HOURS]: 960,
        [NotificationFrequency.EVERY_SEVENTEEN_HOURS]: 1020,
        [NotificationFrequency.EVERY_EIGHTEEN_HOURS]: 1080,
        [NotificationFrequency.EVERY_NINETEEN_HOURS]: 1140,
        [NotificationFrequency.EVERY_TWENTY_HOURS]: 1200,
        [NotificationFrequency.EVERY_TWENTYONE_HOURS]: 1260,
        [NotificationFrequency.EVERY_TWENTYTWO_HOURS]: 1320,
        [NotificationFrequency.EVERY_TWENTYTHREE_HOURS]: 1380,
        [NotificationFrequency.EVERY_TWENTYFOUR_HOURS]: 1440,
        [NotificationFrequency.EVERY_THIRTYSIX_HOURS]: 2160,
        [NotificationFrequency.EVERY_FOURTYEIGHT_HOURS]: 2880,
        [NotificationFrequency.EVERY_SEVENTYTWO_HOURS]: 4320,
    };
    return lookupTable[frequency];
}
exports.lookupFrequencyInMinutes = lookupFrequencyInMinutes;
