import React, { useMemo } from "react";
import { ChatType, FrontendPageEnum } from "shared/model/pages";

import { EventActionRow } from "./event-action-row";
import { EventModifier } from "./types";
import { useDiseaseEnumDropdownOptions } from "./use-disease-enum";
import { EventDetailRecord } from "../../api/generated/landbot";
import { usePageSettings } from "../../tenant-settings";
import { Dropdown } from "../form/dropdown";
import { TextInput } from "../form/text-input";

export const EditableEventDetails: React.FC<{
  detail: EventDetailRecord;
  editEvent: (modifier: EventModifier) => void;
}> = ({ detail, editEvent }) => {
  const dropDownOptions = useDiseaseEnumDropdownOptions();
  const chatSettings = usePageSettings(FrontendPageEnum.CHAT);

  const isUsingLandbotChat = useMemo(
    () => chatSettings?.chatType === ChatType.LANDBOT,
    [chatSettings?.chatType],
  );

  return (
    <>
      <div className="flex flex-col gap-5">
        <div>
          <EventActionRow
            deleteButtonConfig={{
              onDeleteButtonPress: () => {
                editEvent((editableEvent) =>
                  modifyRecursiveEventDetail(
                    detail.id,
                    undefined,
                    undefined,
                    editableEvent.eventDetails,
                  ),
                );
              },
              deleteButtonText: {
                tx: "journey.remove",
              },
              isHidden: isUsingLandbotChat,
            }}
            dropDownConfig={{
              dropDownOptions,
              enumType: "detail",
              noValueSelected: detail.type,
              dropDownValue: detail.type,
              isHidden: isUsingLandbotChat,
              onChangeDropDownValue: (newType) => {
                editEvent((editableEvent) =>
                  modifyRecursiveEventDetail(
                    detail.id,
                    newType,
                    "type",
                    editableEvent.eventDetails,
                  ),
                );
              },
            }}
          />
          <EventDetailInput detail={detail} editEvent={editEvent} />
        </div>
      </div>
      {detail.details.map((recursiveDetails) => (
        <EditableEventDetails
          key={recursiveDetails.id}
          detail={recursiveDetails}
          editEvent={editEvent}
        />
      ))}
    </>
  );
};

const EventDetailInput: React.FC<{
  detail: EventDetailRecord;
  editEvent: (event: EventModifier) => void;
}> = ({ detail, editEvent }) => {
  return (
    <div className="flex flex-col gap-3">
      {detail?.expectedDatatypes?.map((type, idx) => {
        switch (type) {
          case "TEXT":
          default:
            return (
              <TextInput
                key={detail.id + idx}
                as="textarea"
                className="max-h-40"
                value={detail.text}
                onChange={(el) => {
                  editEvent((editableEvent) => {
                    modifyRecursiveEventDetail(
                      detail.id,
                      el.target.value,
                      "text",
                      editableEvent.eventDetails,
                    );
                  });
                }}
              />
            );
          case "SELECT":
            return (
              <Dropdown
                key={detail.id + idx}
                options={[
                  { label: { text: "❌" }, value: false },
                  { label: { text: "✅" }, value: true },
                ]}
                noValueSelected={{ text: detail.select ? "✅" : "❌" }}
                onChange={(value) => {
                  editEvent((editableEvent) => {
                    modifyRecursiveEventDetail(
                      detail.id,
                      value,
                      "select",
                      editableEvent.eventDetails,
                    );
                  });
                }}
              />
            );
          case "NUMBER":
            return (
              <TextInput
                key={detail.id + idx}
                type="number"
                value={detail.number}
                onChange={(el) => {
                  editEvent((editableEvent) => {
                    modifyRecursiveEventDetail(
                      detail.id,
                      el.target.value,
                      "number",
                      editableEvent.eventDetails,
                    );
                  });
                }}
              />
            );
          case "UNIT":
            return (
              <TextInput
                key={detail.id + idx}
                value={detail.unit}
                onChange={(el) => {
                  editEvent((editableEvent) => {
                    modifyRecursiveEventDetail(
                      detail.id,
                      el.target.value,
                      "unit",
                      editableEvent.eventDetails,
                    );
                  });
                }}
              />
            );
        }
      })}
    </div>
  );
};

function modifyRecursiveEventDetail(
  id: string,
  value: string | number | boolean | undefined,
  field: "text" | "number" | "select" | "unit" | "type" | undefined,
  details: EventDetailRecord[],
): void {
  for (let i = 0; i < details.length; i++) {
    if (details[i].id === id) {
      if (field === undefined) {
        details.splice(i, 1);
        return;
      }

      switch (field) {
        case "text":
        case "unit":
        case "type":
          if (typeof value === "string") {
            details[i][field] = value;
          }
          break;
        case "number":
          if (typeof value === "string") {
            details[i][field] = Number(value);
          }
          break;
        case "select":
          if (typeof value === "boolean") {
            details[i][field] = value;
          }
          break;
      }
    }

    if (details[i].details) {
      modifyRecursiveEventDetail(id, value, field, details[i].details);
    }
  }
}
