/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NotificationType } from './NotificationType';
import {
    NotificationTypeFromJSON,
    NotificationTypeFromJSONTyped,
    NotificationTypeToJSON,
} from './NotificationType';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * Represents a Notification record
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    id: string;
    /**
     * 
     * @type {User}
     * @memberof Notification
     */
    user?: User;
    /**
     * 
     * @type {NotificationType}
     * @memberof Notification
     */
    type: NotificationType;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    userId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Notification
     */
    whatsAppMessageIds: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Notification
     */
    hasError: boolean;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    frequency: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    messageTitle: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    message: string;
    /**
     * 
     * @type {Date}
     * @memberof Notification
     */
    sendAt: Date;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    attemptsPerScheduleCycle: number;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    language: string;
}

/**
 * Check if a given object implements the Notification interface.
 */
export function instanceOfNotification(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "whatsAppMessageIds" in value;
    isInstance = isInstance && "hasError" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "frequency" in value;
    isInstance = isInstance && "messageTitle" in value;
    isInstance = isInstance && "message" in value;
    isInstance = isInstance && "sendAt" in value;
    isInstance = isInstance && "attemptsPerScheduleCycle" in value;
    isInstance = isInstance && "language" in value;

    return isInstance;
}

export function NotificationFromJSON(json: any): Notification {
    return NotificationFromJSONTyped(json, false);
}

export function NotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Notification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'type': NotificationTypeFromJSON(json['type']),
        'userId': json['userId'],
        'whatsAppMessageIds': json['whatsAppMessageIds'],
        'hasError': json['hasError'],
        'title': json['title'],
        'frequency': json['frequency'],
        'messageTitle': json['messageTitle'],
        'message': json['message'],
        'sendAt': (new Date(json['sendAt'])),
        'attemptsPerScheduleCycle': json['attemptsPerScheduleCycle'],
        'language': json['language'],
    };
}

export function NotificationToJSON(value?: Notification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'user': UserToJSON(value.user),
        'type': NotificationTypeToJSON(value.type),
        'userId': value.userId,
        'whatsAppMessageIds': value.whatsAppMessageIds,
        'hasError': value.hasError,
        'title': value.title,
        'frequency': value.frequency,
        'messageTitle': value.messageTitle,
        'message': value.message,
        'sendAt': (value.sendAt.toISOString()),
        'attemptsPerScheduleCycle': value.attemptsPerScheduleCycle,
        'language': value.language,
    };
}

