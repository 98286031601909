/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RenewSubscriptionDto
 */
export interface RenewSubscriptionDto {
    /**
     * 
     * @type {string}
     * @memberof RenewSubscriptionDto
     */
    priceId: string;
}

/**
 * Check if a given object implements the RenewSubscriptionDto interface.
 */
export function instanceOfRenewSubscriptionDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "priceId" in value;

    return isInstance;
}

export function RenewSubscriptionDtoFromJSON(json: any): RenewSubscriptionDto {
    return RenewSubscriptionDtoFromJSONTyped(json, false);
}

export function RenewSubscriptionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RenewSubscriptionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'priceId': json['priceId'],
    };
}

export function RenewSubscriptionDtoToJSON(value?: RenewSubscriptionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'priceId': value.priceId,
    };
}

