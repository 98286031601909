import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import { DataGuardRoutesType } from "./types";

export const DataGuardRouter: React.FC<{
  numberOfGuardsCompleted: number;
  dataGuardComponents: JSX.Element[];
  dataGuardRoutes: DataGuardRoutesType;
}> = ({ dataGuardComponents, dataGuardRoutes, numberOfGuardsCompleted }) => {
  return (
    <Routes>
      {dataGuardComponents?.map((component, index) => (
        <Route
          key={index}
          path={dataGuardRoutes[index].route}
          element={
            <RouteGuard
              currentGuardIndex={index}
              dataGuardRoutes={dataGuardRoutes}
              numberOfGuardsCompleted={numberOfGuardsCompleted}
            >
              {component}
            </RouteGuard>
          }
        />
      ))}
      <Route
        path="*"
        element={
          <NavigateToActiveGuard
            dataGuardRoutes={dataGuardRoutes}
            numberOfGuardsCompleted={numberOfGuardsCompleted}
          />
        }
      />
    </Routes>
  );
};

const RouteGuard: React.FC<{
  currentGuardIndex: number;
  children: React.ReactNode;
  numberOfGuardsCompleted: number;
  dataGuardRoutes: DataGuardRoutesType;
}> = ({
  children,
  dataGuardRoutes,
  currentGuardIndex,
  numberOfGuardsCompleted,
}) => {
  return currentGuardIndex > numberOfGuardsCompleted ? (
    <NavigateToActiveGuard
      dataGuardRoutes={dataGuardRoutes}
      numberOfGuardsCompleted={numberOfGuardsCompleted}
    />
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};

const NavigateToActiveGuard: React.FC<{
  numberOfGuardsCompleted: number;
  dataGuardRoutes: DataGuardRoutesType;
}> = ({ numberOfGuardsCompleted, dataGuardRoutes }) => {
  const navigate = useNavigate();
  const route = dataGuardRoutes[numberOfGuardsCompleted]?.route;

  useEffect(() => {
    if (route) {
      navigate(`../${route}`);
    }
  }, [navigate, route]);

  return null;
};
