import { useMemo } from "react";
import { FrontendPageEnum } from "shared/model/pages";

import { useApiQuery } from "../api/use-api";
import { LoadingScreen } from "../components/loading-screen";
import { usePageSettings } from "../tenant-settings";
import { SIGNED_IFRAMEURL_FOR_METABASE_DASHBOARD_KEY } from "../types/query-keys";
import { adminDashboardUrl, appEnv } from "../util/env-utils";
import { useTenantId } from "../util/use-active-tenant-id";

const DEFAULT_DASHBOARD_INDEX = 21;

type DashboardPage =
  | FrontendPageEnum.USER_DASHBOARD
  | FrontendPageEnum.ADMIN_DASHBOARD;

function createDashboardComponent<T extends DashboardPage>(page: T): React.FC {
  const component = () => {
    const tenantId = useTenantId();
    const dashboardSettings = usePageSettings(page);

    const dashboardIndex = useMemo(
      () =>
        dashboardSettings?.dashboards[appEnv].index || DEFAULT_DASHBOARD_INDEX,
      [dashboardSettings?.dashboards],
    );

    const { data: signedIframeUrlToken, isFetched } = useApiQuery(
      "backend",
      (api) =>
        api.getiframeUrlTokenForAdminDashboard({
          dashboardIndex,
          mamaDisease: tenantId.disease,
          mamaOrganisation: tenantId?.organisation,
        }),
      SIGNED_IFRAMEURL_FOR_METABASE_DASHBOARD_KEY(page)(tenantId),
      {},
      { refetchOnWindowFocus: false },
    );

    const iframeUrl = useMemo(
      () =>
        `${adminDashboardUrl}/embed/dashboard/${signedIframeUrlToken?.iframeUrlToken}#theme=transparent&bordered=false&titled=true`,
      [signedIframeUrlToken],
    );

    return isFetched ? (
      <iframe className="h-full w-full" src={iframeUrl} allowFullScreen />
    ) : (
      <LoadingScreen />
    );
  };

  return component;
}

export const UserDashboard = createDashboardComponent(
  FrontendPageEnum.USER_DASHBOARD,
);
export const AdminDashboard = createDashboardComponent(
  FrontendPageEnum.ADMIN_DASHBOARD,
);
