import {
  ChatOptionsType,
  OptionsConfigType,
} from "shared/model/data-collection/guard/chat-data-guard/chat-data-guard";

import { IconType, SvgIcon } from "../../../../../components/icons/svg-icon";
import { Text } from "../../../../../components/text";

export const DefaultCard: React.FC<{
  icon: IconType;
  selected: boolean;
  config: OptionsConfigType;

  setChatOption: (option: ChatOptionsType) => void;
}> = ({ config, icon, selected, setChatOption }) => {
  return (
    <div
      className="flex cursor-pointer items-center justify-center gap-5 p-5"
      onClick={() => setChatOption(config.type)}
    >
      <SvgIcon width={80} height={80} icon={icon} className="self-center" />
      <Text
        tx={config.description}
        className="flex-1 text-center text-2xl font-bold"
      />
      <input type="radio" className="h-8 w-8" checked={selected} readOnly />
    </div>
  );
};
