import { FormTextInput } from "../../../../../components/form/form-text-input";
import { useT } from "../../../../../i18n/use-t";
import { isOlderThan, isYoungerThan } from "../../../../../util/age-comparison";
import { FormDataCollectionInput } from "../types";

const minimumAge = 16;
const maximumAge = 100;
export const yearOfBirthInput: FormDataCollectionInput = {
  wasCompleted: (user) => !!user.userAttributes?.dateOfBirth,
  Component: ({ errors, register }) => {
    const t = useT();

    return (
      <FormTextInput
        type="number"
        labelClassName="font-bold"
        title={{ tx: "userData.dateOfBirth" }}
        error={{ txUnchecked: errors.dateOfBirth?.message }}
        {...register("dateOfBirth", {
          validate: (value) => {
            if (!value) {
              return t({ tx: "userData.dateOfBirthIsRequiredError" });
            }

            if (!isOlderThan({ year: Number(value), minimumAge })) {
              return t({
                tx: "userData.youMustBeOlderError",
                txData: { age: minimumAge },
              });
            }

            if (!isYoungerThan({ year: Number(value), maximumAge })) {
              return t({
                tx: "userData.youMustBeYoungerError",
                txData: { age: maximumAge },
              });
            }
          },
        })}
      />
    );
  },
};
