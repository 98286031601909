import { reaction } from "mobx";
import { createContext, useContext, useEffect, useState } from "react";

import { UserData } from "./implementations/generic/types";
import { IAuthStore } from "./implementations/generic/types";

export const AuthStoreContext = createContext<IAuthStore | null>(null);

export function useAuthStore(): IAuthStore {
  const context = useContext(AuthStoreContext);
  if (context === null) {
    throw new Error("useAuthStore must be used within an AuthStoreProvider");
  }
  return context;
}

export function useObservedUserData(): UserData | undefined {
  const authStore = useAuthStore();

  const [userData, setUserData] = useState<UserData | undefined>(
    authStore.userData,
  );

  useEffect(() => {
    const reactDisposer = reaction(
      () => authStore.userData,
      (data) => {
        setUserData(data);
      },
    );
    return () => reactDisposer();
  }, [authStore, authStore.userData]);

  return userData;
}

export function useObservedJwtToken(): string {
  const authStore = useAuthStore();
  if (!authStore.jwtToken) {
    throw new Error(
      "useObservedJwtToken must be used within an AuthStoreProvider",
    );
  }

  const [jwtToken, setJwtToken] = useState<string>(authStore.jwtToken);

  useEffect(() => {
    const reactDisposer = reaction(
      () => authStore.jwtToken,
      (token) => {
        if (!token) {
          console.log("empty jwt token, signing out");
          authStore.signOut();
          return "";
        }
        setJwtToken(token);
      },
    );
    return () => reactDisposer();
  }, [authStore, authStore.jwtToken]);

  return jwtToken;
}

export function useLatestJwtToken(): string | undefined {
  const authStore = useAuthStore();

  const [jwtToken, setJwtToken] = useState<string | undefined>(undefined);

  useEffect(() => {
    authStore
      .getValidJwtToken()
      .then((token) => {
        if (!token) {
          authStore.signOut();
          return;
        }
        setJwtToken(token);
      })
      .catch(() => {
        authStore.signOut();
      });
  }, [authStore]);

  return jwtToken;
}
