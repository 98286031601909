import { errorMessage, HTTPErrorDetails } from "./http-error-details";

export class SentryError extends Error {
  public readonly message: string;

  public constructor(
    public readonly name: string,
    public readonly details: HTTPErrorDetails,
  ) {
    super(name);

    this.message = errorMessage(details);
  }
}
