import { useEffect } from "react";

import { cognitoPaths } from "./paths";
import { useAuthNavigate } from "../../generic/use-auth-navigate";

export const CognitoSocialLogoutHandler: React.FC = () => {
  const navigate = useAuthNavigate();
  useEffect(() => {
    navigate({
      to: { type: "uri", uri: cognitoPaths.sessionCleanup },
    });
  }, [navigate]);
  return null;
};
