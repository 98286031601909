import { useEffect } from "react";

import { toTenantIdHeader, useApiQuery } from "../api/use-api";
import { ME_KEY } from "../types/query-keys";
import { identifyUserBasedOnId } from "../util/data-layer-actions";
import { useTenantId } from "../util/use-active-tenant-id";

export const IdentifyUserInGoogleAnalytics: React.FC = () => {
  const tenantId = useTenantId();
  const { data: user } = useApiQuery(
    "backend",
    (api) => api.getMe(toTenantIdHeader(tenantId)),
    ME_KEY(tenantId),
  );

  useEffect(() => {
    if (user?.id) {
      identifyUserBasedOnId(user.id);
    }
  }, [user?.id]);

  return null;
};
