export const sortByKey = <T>(array: T[], key: keyof T): T[] => {
  return [...array].sort((a, b) => {
    const valueA = a[key];
    const valueB = b[key];

    if (typeof valueA === "number" && typeof valueB === "number") {
      return valueA - valueB;
    }

    return `${valueA}`.localeCompare(`${valueB}`);
  });
};
