import { useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";

import { DropdownProps } from "../../form/dropdown";
import { DEFAULT_LANGUAGE_KEY } from "../../form/language/constants";
import { useAppLanguage } from "../../form/language/use-app-language";
import { MultiSelectDropdown } from "../../form/multi-select-dropdown";
import { useSocialMediaCategories } from "../use-social-media-categories";

export type CategoryMultiSelectDropdownType = React.FC<{
  className?: string;
  onChange: (categoryNames: string[]) => void;
  selectedCategories?: string[];
}>;

export const CategoryMultiSelectDropdown: CategoryMultiSelectDropdownType = ({
  className,
  onChange,
  selectedCategories,
}) => {
  const { appLanguageKey } = useAppLanguage();

  const rawCategoryData = useSocialMediaCategories();
  const categoryData = useMemo(
    () => [...(rawCategoryData.categories ?? [])],
    [rawCategoryData.categories],
  );

  const categories = useMemo<DropdownProps<string>["options"]>(
    () =>
      categoryData.map((cat) => ({
        value: cat.name,
        label: {
          text:
            appLanguageKey !== DEFAULT_LANGUAGE_KEY &&
            cat.translations &&
            JSON.parse(cat.translations)[appLanguageKey]
              ? JSON.parse(cat.translations)[appLanguageKey]
              : cat.name,
        },
      })),
    [appLanguageKey, categoryData],
  );

  const [categoryDropdown, setCategoryDropdown] = useState<string[]>(
    selectedCategories ?? [],
  );

  return rawCategoryData.categories && rawCategoryData.categories.length > 0 ? (
    <MultiSelectDropdown
      noValueSelected={{ tx: "filters.selectCategories" }}
      options={categories}
      className={twMerge("z-10", className)}
      values={categoryDropdown}
      onChange={(cat) => {
        setCategoryDropdown(cat);
        onChange(cat);
      }}
    />
  ) : null;
};
