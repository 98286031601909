import React, { PropsWithChildren, useEffect, useRef } from "react";
import { DataGuard } from "shared/model/data-collection/guard";
import { DataGuardType } from "shared/model/data-collection/guard/data-guard-type";
import { ChatType, FrontendPageEnum } from "shared/model/pages";
import { twMerge } from "tailwind-merge";

import { toTenantIdHeader, useApiQuery } from "../../api/use-api";
import { I18nProps, Text } from "../../components/text";
import { usePageSettings } from "../../tenant-settings";
import { CHAT_MESSAGE_COUNT_KEY } from "../../types/query-keys";
import { useDimensions } from "../../util/hooks";
import { useTenantId } from "../../util/use-active-tenant-id";
import { MULTIAGENT_MESSAGE_THRESHOLD } from "../guard/implementations/chat/chat-data-guard";

export const StepsLayout: React.FC<
  PropsWithChildren<{
    hideLastStep: boolean;
    titles: I18nProps[];
    activeIndex: number;
    dataGuards?: DataGuard[];
    setActiveStep?: (step: number) => void;
  }>
> = ({
  children,
  titles,
  activeIndex,
  setActiveStep,
  hideLastStep,
  dataGuards,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const containerDimensions = useDimensions(containerRef);
  const stepsRef = useRef<HTMLUListElement>(null);
  const stepsDimensions = useDimensions(stepsRef);

  useEffect(() => {
    if (titles.length > 1) {
      const scrollArea = stepsDimensions.width - containerDimensions.width;
      const scrollPercent = activeIndex / (titles.length - 1);
      if (containerRef.current) {
        containerRef.current.scrollLeft = scrollArea * scrollPercent;
      }
    }
  }, [activeIndex, containerDimensions, stepsDimensions.width, titles.length]);

  return (
    <div className="flex h-full w-full flex-col">
      {titles.length > 0 ? (
        <div
          ref={containerRef}
          className="fixed z-10 flex w-full flex-col items-center justify-center gap-4 bg-white py-0 sm:flex-row sm:py-4"
        >
          <div className="w-fit max-w-full overflow-x-auto scroll-smooth">
            <ul
              ref={stepsRef}
              className="steps overflow-hidden text-xs sm:text-base"
            >
              {titles.map((title, index, arr) =>
                arr.length - 1 > index ? (
                  dataGuards?.[index].type ===
                  DataGuardType.CHAT_COMPLETION_DATA_GUARD ? (
                    <ChatCompletenessStep
                      key={index}
                      title={title}
                      index={index}
                      activeIndex={activeIndex}
                      onClick={setActiveStep}
                    />
                  ) : (
                    <Text
                      as="li"
                      key={index}
                      onClick={() => setActiveStep?.(index)}
                      className={twMerge(
                        "step cursor-pointer",
                        index <= activeIndex ? "step-primary" : "",
                        "md:!min-w-[8rem]",
                      )}
                      {...title}
                    />
                  )
                ) : !hideLastStep ? (
                  <Text
                    as="li"
                    key={index}
                    data-content="✓"
                    className="step-success step cursor-pointer md:!min-w-[8rem]"
                    {...title}
                  />
                ) : null,
              )}
            </ul>
          </div>
        </div>
      ) : null}

      <div className="grow" style={{ paddingTop: containerDimensions.height }}>
        {children}
      </div>
    </div>
  );
};

const ChatCompletenessStep: React.FC<{
  index: number;
  title: I18nProps;
  activeIndex: number;
  onClick?: (index: number) => void;
}> = ({ onClick, index, activeIndex, title }) => {
  const type = usePageSettings(FrontendPageEnum.CHAT);

  const data =
    type?.chatType === ChatType.AI ? useAiChatCompleteness() : index + 1;

  return (
    <Text
      as="li"
      key={index}
      onClick={() => onClick?.(index)}
      className={twMerge(
        "step cursor-pointer",
        index <= activeIndex ? "step-primary" : "",
        "md:!min-w-[8rem]",
      )}
      {...title}
      data-content={activeIndex === index ? data : undefined}
    />
  );
};

const useAiChatCompleteness = (): string => {
  const tenantId = useTenantId();

  const { data } = useApiQuery(
    "multiagent",
    (api) => api.getMultiagentMessageCount(toTenantIdHeader(tenantId)),
    CHAT_MESSAGE_COUNT_KEY(tenantId.disease),
    undefined,
  );

  return `${data ?? "0"}/${MULTIAGENT_MESSAGE_THRESHOLD}`;
};
