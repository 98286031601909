import { useEffect } from "react";

import { FormInput } from "../../../../../components/form/form-input";
import { Link } from "../../../../../components/link";
import { Text } from "../../../../../components/text";
import { FormDataCollectionInput } from "../types";

export const privacyPolicy20231018Input: FormDataCollectionInput = {
  wasCompleted: (user) => !!user.userAttributes?.agreedToPrivacyPolicy20231018,
  Component: ({ errors, register }) => {
    useEffect(() => {
      register("agreedToPrivacyPolicy20231018", { value: true });
    }, [register]);

    return (
      <div className="form-control my-2 text-left">
        <FormInput
          error={{
            txUnchecked: errors.agreedToPrivacyPolicy20231018?.message,
          }}
        >
          <Text
            className="label-text"
            tx="signUp.customFields.mamaPrivacyPolicy.checkOutPrivacyPolicy"
            txComponents={{
              href: <Link target="_blank" className="underline" />,
            }}
          />
        </FormInput>
      </div>
    );
  },
};
