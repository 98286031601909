import { useCallback, useState } from "react";

import { getStripeConnectRedirectLink } from "../../util/get-stripe-connect-redirect-link";
import { LoadingButton } from "../form/loading-button";

export const StripeConnectAccountButton: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const redirectToConnectLink = useCallback(() => {
    setIsLoading(true);
    window.open(
      getStripeConnectRedirectLink(
        `https://${location.host}/admin/stripe/redirect`,
      ),
      "_self",
    );
  }, []);

  return (
    <LoadingButton
      isDisabled={isLoading}
      tx="stripe.linkExistingAccount"
      onClick={redirectToConnectLink}
      loading={isLoading}
    />
  );
};
