export const setCookie = ({
  name,
  value,
  domain,
}: {
  name: string;
  value: string;
  domain: string;
}): void => {
  const expiresDate = new Date();
  expiresDate.setDate(expiresDate.getDate() + 7);
  document.cookie = `${name}=${value};expires=${expiresDate};domain=${domain};path=/`;
};

export const deleteCookie = ({
  name,
  domain,
}: {
  name: string;
  domain: string;
}): void => {
  const expiresDate = new Date();
  expiresDate.setTime(expiresDate.getTime() + -1);
  document.cookie = `${name}=;expires=${expiresDate};domain=${domain};path=/`;
};
