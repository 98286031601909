import { HeadlineTextInputFilter } from "../../filter/filter-setters/headline-text-input-filter";
import { LanguageDropdownFilter } from "../../filter/filter-setters/language-dropdown-filter";
import { OrgMultiSelectDropdownFilter } from "../../filter/filter-setters/org-multi-select-filter";
import { PublishedAtSorter } from "../../filter/filter-setters/published-at-sorter";
import { UpdatedAtSorter } from "../../filter/filter-setters/updated-at-sorter";
import {
  FilterComponentsConfigType,
  FilterProperty,
  SortConfigProperty,
} from "../../filter/types";

export const NewsFilterComponentsConfig: FilterComponentsConfigType = {
  [SortConfigProperty.LIKES]: PublishedAtSorter,
  [SortConfigProperty.UPDATED_AT]: UpdatedAtSorter,
  [FilterProperty.ORGANISATION]: OrgMultiSelectDropdownFilter,
  [FilterProperty.LANGUAGE]: LanguageDropdownFilter,
  [FilterProperty.HEADLINE]: HeadlineTextInputFilter,
};
