import { useMemo } from "react";
import { organisationMap, organisationsPerDisease } from "shared/config";

import { FormMultiSelectDropdown } from "../../../../../components/form/form-multi-select-dropdown";
import { useTenantId } from "../../../../../util/use-active-tenant-id";
import { FormDataCollectionInput } from "../types";

export const memberOfAssociationDropdown: FormDataCollectionInput = {
  wasCompleted: () => true,
  Component: ({ errors, control }) => {
    const { disease } = useTenantId();

    const orgsOfDisease = useMemo(
      () => [...(organisationsPerDisease.get(disease) ?? [])],
      [disease],
    );

    const options = useMemo(() => {
      if (!orgsOfDisease.length) {
        return [];
      }
      const showableOrgs = orgsOfDisease.map((org) => ({
        label: {
          tx: organisationMap[org].tenantNameTx,
        },
        value: org,
      }));
      return showableOrgs;
    }, [orgsOfDisease]);

    return options.length ? (
      <FormMultiSelectDropdown
        name="pags"
        labelClassName="font-bold"
        className="input-bordered w-full rounded-lg border-[1.5px]"
        title={{
          tx: "userData.memberOfAssociation.selectAnAssociation",
        }}
        options={options}
        control={control}
        defaultValueClassName="font-normal"
        noValueSelected={{ tx: "userData.memberOfAssociation.title" }}
        error={{ txUnchecked: errors.acquisitionSource?.message }}
      />
    ) : null;
  },
};
