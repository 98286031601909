/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FeedbackDto
 */
export interface FeedbackDto {
    /**
     * 
     * @type {string}
     * @memberof FeedbackDto
     */
    feedbackId: string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackDto
     */
    type: FeedbackDtoTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof FeedbackDto
     */
    activityMinutes: number;
}


/**
 * @export
 */
export const FeedbackDtoTypeEnum = {
    Rating: 'RATING',
    Scale: 'SCALE',
    Satisfaction: 'SATISFACTION'
} as const;
export type FeedbackDtoTypeEnum = typeof FeedbackDtoTypeEnum[keyof typeof FeedbackDtoTypeEnum];


/**
 * Check if a given object implements the FeedbackDto interface.
 */
export function instanceOfFeedbackDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "feedbackId" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "activityMinutes" in value;

    return isInstance;
}

export function FeedbackDtoFromJSON(json: any): FeedbackDto {
    return FeedbackDtoFromJSONTyped(json, false);
}

export function FeedbackDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedbackDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'feedbackId': json['feedbackId'],
        'title': json['title'],
        'type': json['type'],
        'activityMinutes': json['activityMinutes'],
    };
}

export function FeedbackDtoToJSON(value?: FeedbackDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'feedbackId': value.feedbackId,
        'title': value.title,
        'type': value.type,
        'activityMinutes': value.activityMinutes,
    };
}

