/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DiseaseSettings } from './DiseaseSettings';
import {
    DiseaseSettingsFromJSON,
    DiseaseSettingsFromJSONTyped,
    DiseaseSettingsToJSON,
} from './DiseaseSettings';
import type { OrganisationSettings } from './OrganisationSettings';
import {
    OrganisationSettingsFromJSON,
    OrganisationSettingsFromJSONTyped,
    OrganisationSettingsToJSON,
} from './OrganisationSettings';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * Represents a StripeInfo record
 * @export
 * @interface StripeInfo
 */
export interface StripeInfo {
    /**
     * 
     * @type {string}
     * @memberof StripeInfo
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof StripeInfo
     */
    customerId?: string;
    /**
     * 
     * @type {User}
     * @memberof StripeInfo
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof StripeInfo
     */
    userId: string;
    /**
     * 
     * @type {DiseaseSettings}
     * @memberof StripeInfo
     */
    disease?: DiseaseSettings;
    /**
     * 
     * @type {string}
     * @memberof StripeInfo
     */
    diseaseId: string;
    /**
     * 
     * @type {OrganisationSettings}
     * @memberof StripeInfo
     */
    organisation?: OrganisationSettings;
    /**
     * 
     * @type {string}
     * @memberof StripeInfo
     */
    organisationId?: string;
}

/**
 * Check if a given object implements the StripeInfo interface.
 */
export function instanceOfStripeInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "diseaseId" in value;

    return isInstance;
}

export function StripeInfoFromJSON(json: any): StripeInfo {
    return StripeInfoFromJSONTyped(json, false);
}

export function StripeInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StripeInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'userId': json['userId'],
        'disease': !exists(json, 'disease') ? undefined : DiseaseSettingsFromJSON(json['disease']),
        'diseaseId': json['diseaseId'],
        'organisation': !exists(json, 'organisation') ? undefined : OrganisationSettingsFromJSON(json['organisation']),
        'organisationId': !exists(json, 'organisationId') ? undefined : json['organisationId'],
    };
}

export function StripeInfoToJSON(value?: StripeInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customerId': value.customerId,
        'user': UserToJSON(value.user),
        'userId': value.userId,
        'disease': DiseaseSettingsToJSON(value.disease),
        'diseaseId': value.diseaseId,
        'organisation': OrganisationSettingsToJSON(value.organisation),
        'organisationId': value.organisationId,
    };
}

