// https://stackoverflow.com/a/19807441

const lastStackFrameRegex = new RegExp(/.+\/(.*?):\d+(:\d+)*$/);
const currentStackFrameRegex = new RegExp(
  /getCurrentScriptFileNameOrDate \(.+\/(.*):\d+:\d+\)/,
);

export const getCurrentScriptFileNameOrDate = (): string => {
  const error: Error & { fileName?: string } = new Error();
  let source;

  if (
    (source = lastStackFrameRegex.exec(error.stack?.trim() ?? "")) &&
    source[1] != ""
  )
    return source[1];
  else if ((source = currentStackFrameRegex.exec(error.stack?.trim() ?? "")))
    return source[1];
  else if (error.fileName != undefined) return error.fileName;
  else {
    return Date.now().toString();
  }
};
