import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { TenantIdentifier } from "shared/config";

import { toTenantIdHeader, useApiMutation } from "../../api/use-api";
import { useModal } from "../../models/modal-provider";
import {
  USER_OWNED_ACTIVE_SUBSCRIPTIONS_KEY,
  USER_OWNED_SCHEDULED_SUBSCRIPTIONS_KEY,
} from "../../types/query-keys";
import { useTenantId } from "../../util/use-active-tenant-id";
import { SecondaryButton } from "../form/button";

export const RenewSubscriptionButton: React.FC<{
  priceId: string;
  language: string;
  currentSubscriptionEndsAt: number;
}> = ({ language, currentSubscriptionEndsAt, priceId }) => {
  const tenantId = useTenantId();
  const queryClient = useQueryClient();
  const {
    showModal: showSharedModal,
    hideModal: hideSharedModal,
    updateModal: updateSharedModal,
  } = useModal();

  const { mutate: renewSubscription } = useApiMutation(
    "backend",
    (api) => (tenantIdentifier: TenantIdentifier) => {
      updateSharedModal({
        loading: { isLoading: true, message: { tx: "general.loading" } },
        disabled: {
          isCloseButtonDisabled: true,
          isConfirmButtonDisabled: true,
        },
        description: {},
      });

      return api.renewCurrentSubscription({
        renewSubscriptionDto: { priceId },
        ...toTenantIdHeader(tenantIdentifier),
      });
    },
    undefined,
    { successMessage: { tx: "subscriptions.membershipUpdated" } },
    {
      onSuccess: (_, tenantIdentifier) => {
        queryClient.invalidateQueries(
          USER_OWNED_ACTIVE_SUBSCRIPTIONS_KEY(tenantIdentifier),
        );
        queryClient.invalidateQueries(
          USER_OWNED_SCHEDULED_SUBSCRIPTIONS_KEY(tenantIdentifier),
        );
      },
      onSettled: () => hideSharedModal(),
    },
  );

  const openRenewSubscriptionModal = useCallback(() => {
    showSharedModal({
      title: { tx: "subscriptions.renewMembership" },
      description: {
        tx: "subscriptions.renewMembershipExplanation",
        txData: {
          endDate: new Date(
            currentSubscriptionEndsAt * 1000,
          ).toLocaleDateString(language),
        },
      },
      onConfirm: () => renewSubscription(tenantId),
    });
  }, [
    currentSubscriptionEndsAt,
    language,
    renewSubscription,
    showSharedModal,
    tenantId,
  ]);

  return (
    <SecondaryButton
      onClick={openRenewSubscriptionModal}
      tx="subscriptions.renew"
    />
  );
};
