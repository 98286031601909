/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Invoice
 */
export interface Invoice {
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    prorationAmount: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    currency: string;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    periodEnd: number;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    isUpgrading: boolean;
}

/**
 * Check if a given object implements the Invoice interface.
 */
export function instanceOfInvoice(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "prorationAmount" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "periodEnd" in value;
    isInstance = isInstance && "isUpgrading" in value;

    return isInstance;
}

export function InvoiceFromJSON(json: any): Invoice {
    return InvoiceFromJSONTyped(json, false);
}

export function InvoiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Invoice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'prorationAmount': json['prorationAmount'],
        'currency': json['currency'],
        'periodEnd': json['periodEnd'],
        'isUpgrading': json['isUpgrading'],
    };
}

export function InvoiceToJSON(value?: Invoice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'prorationAmount': value.prorationAmount,
        'currency': value.currency,
        'periodEnd': value.periodEnd,
        'isUpgrading': value.isUpgrading,
    };
}

