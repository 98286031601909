/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const QuestionStatus = {
    Complete: 'COMPLETE',
    Open: 'OPEN',
    Partial: 'PARTIAL',
    Irrelevant: 'IRRELEVANT',
    LoopSkipped: 'LOOP_SKIPPED',
    Skipped: 'SKIPPED',
    Exploration: 'EXPLORATION'
} as const;
export type QuestionStatus = typeof QuestionStatus[keyof typeof QuestionStatus];


export function QuestionStatusFromJSON(json: any): QuestionStatus {
    return QuestionStatusFromJSONTyped(json, false);
}

export function QuestionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionStatus {
    return json as QuestionStatus;
}

export function QuestionStatusToJSON(value?: QuestionStatus | null): any {
    return value as any;
}

