import { CreditCard } from "../../api/generated/backend";
import { Checkbox } from "../form/checkbox";
import { IconType, SvgIcon } from "../icons/svg-icon";
import { Text } from "../text";

export const StripeCard: React.FC<{
  card: CreditCard;
  paymentMethodId: string;
  setPaymentMethodId: (value: string) => void;
}> = ({ card, paymentMethodId, setPaymentMethodId }) => (
  <div
    key={card.paymentMethodId}
    className="flex items-center justify-between rounded-lg bg-slate-100 px-5 py-5"
  >
    <div className="flex items-center gap-5">
      <SvgIcon
        width={35}
        height={35}
        icon={card.brand ? brandMap[card.brand] : "creditCard"}
      />
      <div className="flex flex-col">
        <Text
          text={
            card?.lastFourDigits
              ? padLastFourDigits(card.lastFourDigits)
              : undefined
          }
        />
        <Text className="-mt-2 text-xs" text={card.expiresAt} />
      </div>
    </div>
    <Checkbox
      value={card.paymentMethodId}
      checked={paymentMethodId === card.paymentMethodId}
      onChange={(e) => setPaymentMethodId(e.currentTarget.value)}
    />
  </div>
);

const brandMap: Record<string, IconType> = {
  visa: "visa",
  amex: "amex",
  mastercard: "masterCard",
  sepa: "sepa",
};

const padLastFourDigits = (lastFourDigits: string) =>
  lastFourDigits
    .padStart(16, "*")
    .replace(/(.{4})/g, "$1 ")
    .trim();
