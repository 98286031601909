import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { FrontendPageEnum } from "shared/model/pages";

import { toTenantIdHeader, useApiQuery } from "../../api/use-api";
import { DefaultContentContainer } from "../../layouts/default-content-container";
import { useStore } from "../../models/helpers";
import { CONNECT_ACCOUNT_KEY } from "../../types/query-keys";
import { useNavigateInsideTenant } from "../../util/navigation-hooks";
import { useTenantId } from "../../util/use-active-tenant-id";
import { PlainError } from "../events/plain-error";
import { Text } from "../text";

export const StripeOAuthRedirectPage: React.FC = () => {
  const tenantId = useTenantId();
  const store = useStore();
  const [searchParams, _] = useSearchParams();
  const code = searchParams.get("code");
  const navigate = useNavigateInsideTenant();

  useEffect(() => {
    if (!code) {
      store.addToastEvent(new PlainError({ text: "Missing OAuth code" }));
    }
  }, [code, store]);

  const { error } = useApiQuery(
    "backend",
    (api) =>
      api
        .connectAccount({
          connectAccountDto: {
            code: code as string,
          },
          ...toTenantIdHeader(tenantId),
        })
        .then(() => navigate(FrontendPageEnum.STRIPE)),
    CONNECT_ACCOUNT_KEY(tenantId),
    {
      trackErrors: true,
      successMessage: { tx: "stripe.accountLinkedSuccessfuly" },
    },
    { enabled: !!code, retry: false },
  );

  return (
    <DefaultContentContainer>
      <div className="flex items-center justify-center">
        {!!error ? (
          <Text
            className="max-w-xl text-center text-2xl"
            tx="stripe.accountLinkingFailed"
          />
        ) : (
          !code && (
            <Text
              className="max-w-xl text-center text-2xl"
              tx="stripe.invalidCodeErrorMessage"
            />
          )
        )}
      </div>
    </DefaultContentContainer>
  );
};
