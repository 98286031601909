import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getTenantSettings, TenantIdentifier } from "shared/config";
import { SupportedGroupsEnum } from "shared/model/cognito";
import { Language } from "shared/model/languages";
import { Organisation } from "shared/model/organisations";
import { EnabledPage, FrontendPageEnum } from "shared/model/pages";

import { TenantPage } from "./tenant-page";
import { useApiQuery } from "../api/use-api";
import { FOLLOWED_PAGS_KEY, HAS_EVENTS, ME_KEY } from "../types/query-keys";
import { useTenantId } from "../util/use-active-tenant-id";

export interface TenantPage extends BareTenantPage {
  page: EnabledPage;
}

export interface BareTenantPage {
  page: Omit<
    EnabledPage,
    "groupsWhichCanSeeThePage" | "supportedLanguages" | "pageLock"
  >;
  tenantId: TenantIdentifier;
}

export const useTenantPages = (): TenantPage[] | undefined => {
  const {
    i18n: { language },
  } = useTranslation();

  const { disease } = useTenantId();
  const { data: diseaseUser } = useApiQuery(
    "backend",
    (api) => api.getMe({ mamaDisease: disease }),
    ME_KEY({ disease }),
  );

  const { data: followedOrganisations } = useApiQuery(
    "backend",
    (api) => api.listFollowedOrganisations({ mamaDisease: disease }),
    FOLLOWED_PAGS_KEY(disease),
  );

  const { data: hasEvents } = useApiQuery(
    "landbot",
    (api) => api.hasEvents({ mamaDisease: disease }),
    HAS_EVENTS(disease),
  );

  const groupsPerOrganisation = useMemo(
    () =>
      new Map(
        followedOrganisations?.groupsAndSubscriptionsPerFollowedOrg.map(
          ({ organisation, groups }) => [organisation, groups],
        ),
      ),
    [followedOrganisations],
  );

  const userGroupsAllowUserToSee = useCallback(
    (lPage: EnabledPage, organisation?: Organisation) =>
      (organisation
        ? groupsPerOrganisation.get(organisation)
        : diseaseUser?.tenantGroups
      )?.some((group) =>
        lPage.groupsWhichCanSeeThePage.includes(group as SupportedGroupsEnum),
      ),
    [groupsPerOrganisation, diseaseUser?.tenantGroups],
  );

  const pageLanguageAllowUserToSee = useCallback(
    (lPgae: EnabledPage) => {
      return lPgae.supportedLanguages
        ? lPgae.supportedLanguages?.includes(language as Language)
        : true;
    },
    [language],
  );

  const getTenantPage = useCallback(
    (page: EnabledPage, organisation?: Organisation): TenantPage => ({
      page,
      tenantId: { disease, organisation },
    }),
    [disease],
  );

  const removeTimelineRouteIfEmpty = useCallback(
    (tenantPage: TenantPage[]) => {
      if (!hasEvents?.hasEvents) {
        return tenantPage?.filter(
          (route) => route.page?.route !== FrontendPageEnum.JOURNEY,
        );
      }

      return tenantPage;
    },
    [hasEvents],
  );

  const getPages = useCallback(
    (organisation?: Organisation): TenantPage[] => {
      const tenantSettings = getTenantSettings({ disease, organisation });

      if (!tenantSettings) {
        return [];
      }

      return removeTimelineRouteIfEmpty(
        tenantSettings.enabledPages
          .filter(
            (page) =>
              userGroupsAllowUserToSee(page, organisation) &&
              pageLanguageAllowUserToSee(page),
          )
          .map((page): TenantPage => getTenantPage(page, organisation)),
      );
    },
    [
      disease,
      getTenantPage,
      pageLanguageAllowUserToSee,
      removeTimelineRouteIfEmpty,
      userGroupsAllowUserToSee,
    ],
  );

  const diseasePages = useMemo(() => getPages(), [getPages]);

  const organisationPages = useMemo(
    () =>
      followedOrganisations?.groupsAndSubscriptionsPerFollowedOrg.flatMap(
        ({ organisation }) => getPages(organisation as Organisation),
      ),
    [followedOrganisations?.groupsAndSubscriptionsPerFollowedOrg, getPages],
  );

  return useMemo(
    () =>
      diseaseUser &&
      followedOrganisations &&
      organisationPages && [...diseasePages, ...organisationPages],
    [diseasePages, followedOrganisations, organisationPages, diseaseUser],
  );
};
