import { useMemo, useState } from "react";

import { DropdownProps } from "../../form/dropdown";
import { DEFAULT_LANGUAGE_KEY } from "../../form/language/constants";
import { useAppLanguage } from "../../form/language/use-app-language";
import { MultiSelectDropdown } from "../../form/multi-select-dropdown";
import { useSocialMediaCategories } from "../../social-media/use-social-media-categories";
import { useFilterElementContext } from "../filter-element";
import {
  FilterComponent,
  FilterProperty,
  FilterType,
  PropertyFilterRule,
} from "../types";

export const CategoryMultiSelectDropdownFilter: FilterComponent = () => {
  const { appLanguageKey } = useAppLanguage();
  const { filterConfig, onFilterChange } = useFilterElementContext();

  const isCategoryFilterSet = filterConfig?.some(
    (propFilter) => propFilter.property === FilterProperty.CATEGORIES,
  );

  const rawCategoryData = useSocialMediaCategories();

  const categoryData = useMemo(
    () => [...(rawCategoryData.categories ?? [])],
    [rawCategoryData.categories],
  );

  const categories = useMemo<DropdownProps<string>["options"]>(
    () =>
      categoryData.map((cat) => ({
        value: cat.name,
        label: {
          text:
            appLanguageKey !== DEFAULT_LANGUAGE_KEY &&
            cat.translations &&
            JSON.parse(cat.translations)[appLanguageKey]
              ? JSON.parse(cat.translations)[appLanguageKey]
              : cat.name,
        },
      })),
    [appLanguageKey, categoryData],
  );

  const [categoryDropdown, setCategoryDropdown] = useState<string[]>([]);

  return isCategoryFilterSet &&
    rawCategoryData.categories &&
    rawCategoryData.categories.length > 0 ? (
    <MultiSelectDropdown
      noValueSelected={{ tx: "filters.selectCategories" }}
      options={categories}
      className="z-10"
      values={categoryDropdown}
      onChange={(cat) => {
        setCategoryDropdown(cat);
        onFilterChange({
          type: FilterType.FILTER,
          rule: PropertyFilterRule.ARE_PART_OF,
          value: cat,
          property: FilterProperty.CATEGORIES,
        });
      }}
    />
  ) : null;
};
