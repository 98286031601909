/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AnamneseOutput
 */
export interface AnamneseOutput {
    /**
     * 
     * @type {string}
     * @memberof AnamneseOutput
     */
    occupation?: string;
    /**
     * 
     * @type {string}
     * @memberof AnamneseOutput
     */
    comorbidities?: string;
    /**
     * 
     * @type {string}
     * @memberof AnamneseOutput
     */
    allergies?: string;
    /**
     * 
     * @type {string}
     * @memberof AnamneseOutput
     */
    currentTreatments?: string;
    /**
     * 
     * @type {string}
     * @memberof AnamneseOutput
     */
    introduction?: string;
    /**
     * 
     * @type {string}
     * @memberof AnamneseOutput
     */
    symptomHistory?: string;
    /**
     * 
     * @type {string}
     * @memberof AnamneseOutput
     */
    impactOnLife?: string;
    /**
     * 
     * @type {string}
     * @memberof AnamneseOutput
     */
    medicalHistory?: string;
    /**
     * 
     * @type {string}
     * @memberof AnamneseOutput
     */
    treatmentHistory?: string;
    /**
     * 
     * @type {string}
     * @memberof AnamneseOutput
     */
    lifestyleHabits?: string;
    /**
     * 
     * @type {string}
     * @memberof AnamneseOutput
     */
    epicrisis?: string;
}

/**
 * Check if a given object implements the AnamneseOutput interface.
 */
export function instanceOfAnamneseOutput(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AnamneseOutputFromJSON(json: any): AnamneseOutput {
    return AnamneseOutputFromJSONTyped(json, false);
}

export function AnamneseOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnamneseOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'occupation': !exists(json, 'occupation') ? undefined : json['occupation'],
        'comorbidities': !exists(json, 'comorbidities') ? undefined : json['comorbidities'],
        'allergies': !exists(json, 'allergies') ? undefined : json['allergies'],
        'currentTreatments': !exists(json, 'current_treatments') ? undefined : json['current_treatments'],
        'introduction': !exists(json, 'introduction') ? undefined : json['introduction'],
        'symptomHistory': !exists(json, 'symptom_history') ? undefined : json['symptom_history'],
        'impactOnLife': !exists(json, 'impact_on_life') ? undefined : json['impact_on_life'],
        'medicalHistory': !exists(json, 'medical_history') ? undefined : json['medical_history'],
        'treatmentHistory': !exists(json, 'treatment_history') ? undefined : json['treatment_history'],
        'lifestyleHabits': !exists(json, 'lifestyle_habits') ? undefined : json['lifestyle_habits'],
        'epicrisis': !exists(json, 'epicrisis') ? undefined : json['epicrisis'],
    };
}

export function AnamneseOutputToJSON(value?: AnamneseOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'occupation': value.occupation,
        'comorbidities': value.comorbidities,
        'allergies': value.allergies,
        'current_treatments': value.currentTreatments,
        'introduction': value.introduction,
        'symptom_history': value.symptomHistory,
        'impact_on_life': value.impactOnLife,
        'medical_history': value.medicalHistory,
        'treatment_history': value.treatmentHistory,
        'lifestyle_habits': value.lifestyleHabits,
        'epicrisis': value.epicrisis,
    };
}

