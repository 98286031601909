/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Conversation } from './Conversation';
import {
    ConversationFromJSON,
    ConversationFromJSONTyped,
    ConversationToJSON,
} from './Conversation';
import type { QuestionVisit } from './QuestionVisit';
import {
    QuestionVisitFromJSON,
    QuestionVisitFromJSONTyped,
    QuestionVisitToJSON,
} from './QuestionVisit';

/**
 * Represents a QuestionnaireState record
 * @export
 * @interface QuestionnaireState
 */
export interface QuestionnaireState {
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireState
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof QuestionnaireState
     */
    createdAt: Date;
    /**
     * 
     * @type {Array<QuestionVisit>}
     * @memberof QuestionnaireState
     */
    visitedNodes?: Array<QuestionVisit>;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireState
     */
    currentNodeId: string;
    /**
     * 
     * @type {Conversation}
     * @memberof QuestionnaireState
     */
    conversation?: Conversation;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireState
     */
    conversationId: string;
}

/**
 * Check if a given object implements the QuestionnaireState interface.
 */
export function instanceOfQuestionnaireState(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "currentNodeId" in value;
    isInstance = isInstance && "conversationId" in value;

    return isInstance;
}

export function QuestionnaireStateFromJSON(json: any): QuestionnaireState {
    return QuestionnaireStateFromJSONTyped(json, false);
}

export function QuestionnaireStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionnaireState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'visitedNodes': !exists(json, 'visitedNodes') ? undefined : ((json['visitedNodes'] as Array<any>).map(QuestionVisitFromJSON)),
        'currentNodeId': json['currentNodeId'],
        'conversation': !exists(json, 'conversation') ? undefined : ConversationFromJSON(json['conversation']),
        'conversationId': json['conversationId'],
    };
}

export function QuestionnaireStateToJSON(value?: QuestionnaireState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'visitedNodes': value.visitedNodes === undefined ? undefined : ((value.visitedNodes as Array<any>).map(QuestionVisitToJSON)),
        'currentNodeId': value.currentNodeId,
        'conversation': ConversationToJSON(value.conversation),
        'conversationId': value.conversationId,
    };
}

