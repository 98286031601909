import { useMemo } from "react";
import { SupportedGroupsEnum } from "shared/model/cognito";
import { EnabledPage } from "shared/model/pages";

import {
  Subscription,
  UserTenantGroupsEnum,
} from "../../api/generated/backend";
import { useApiQuery } from "../../api/use-api";
import {
  FOLLOWED_PAGS_KEY,
  ME_KEY,
  USER_OWNED_ACTIVE_SUBSCRIPTIONS_KEY,
} from "../../types/query-keys";
import { useTenantId } from "../../util/use-active-tenant-id";

export const usePaywallLock = (page?: EnabledPage): boolean => {
  const tenantId = useTenantId();

  const { data: diseaseUser } = useApiQuery(
    "backend",
    (api) => api.getMe({ mamaDisease: tenantId.disease }),
    ME_KEY({ disease: tenantId.disease }),
  );
  const purchasedPagesForDisease = usePurchasedPagesForDisease();

  const { data: followedOrganisations } = useApiQuery(
    "backend",
    (api) => api.listFollowedOrganisations({ mamaDisease: tenantId.disease }),
    FOLLOWED_PAGS_KEY(tenantId.disease),
  );

  const groupsPerOrganisation = useMemo(
    () =>
      new Map(
        followedOrganisations?.groupsAndSubscriptionsPerFollowedOrg.map(
          ({ organisation, groups }) => [organisation, groups],
        ),
      ),
    [followedOrganisations],
  );

  const purchasedPagesPerOrganisation = useMemo(
    () =>
      new Map(
        followedOrganisations?.groupsAndSubscriptionsPerFollowedOrg.map(
          ({ organisation, activeSubscriptions }) => [
            organisation,
            getPurchasedPages(activeSubscriptions),
          ],
        ),
      ),
    [followedOrganisations],
  );

  return !!hasAccessToPage({
    page,
    groups: tenantId.organisation
      ? groupsPerOrganisation.get(tenantId.organisation)
      : diseaseUser?.tenantGroups,
    purchasedPages: tenantId.organisation
      ? purchasedPagesPerOrganisation.get(tenantId.organisation)
      : purchasedPagesForDisease,
  });
};

const hasAccessToPage = ({
  purchasedPages,
  groups,
  page,
}: {
  purchasedPages?: Set<string | undefined>;
  groups?: UserTenantGroupsEnum[];
  page?: EnabledPage;
}): boolean | undefined => {
  return (
    !page ||
    (groups &&
      purchasedPages &&
      (groups
        .filter((group) =>
          page.groupsWhichCanSeeThePage.includes(group as SupportedGroupsEnum),
        )
        .some(
          (group) =>
            !page.groupsWhichHavePaywall?.includes(
              group as SupportedGroupsEnum,
            ),
        ) ||
        purchasedPages.has(page.route)))
  );
};

const usePurchasedPagesForDisease = () => {
  const { disease } = useTenantId();
  const { data: ownedSubscriptionsForDisease } = useApiQuery(
    "backend",
    (api) => api.getUserOwnedSubscriptions({ mamaDisease: disease }),
    USER_OWNED_ACTIVE_SUBSCRIPTIONS_KEY({ disease }),
    undefined,
  );

  const purchasedPagesForDisease = useMemo(
    () =>
      ownedSubscriptionsForDisease &&
      getPurchasedPages(ownedSubscriptionsForDisease.activeSubscriptions),
    [ownedSubscriptionsForDisease],
  );
  return purchasedPagesForDisease;
};

export const getPurchasedPages = (
  subscriptions: Subscription[],
): Set<string | undefined> => {
  return new Set(
    subscriptions.flatMap((plan) => plan.product?.grantAccessToPages),
  );
};
