/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Comment } from './Comment';
import {
    CommentFromJSON,
    CommentFromJSONTyped,
    CommentToJSON,
} from './Comment';
import type { DiseaseSettings } from './DiseaseSettings';
import {
    DiseaseSettingsFromJSON,
    DiseaseSettingsFromJSONTyped,
    DiseaseSettingsToJSON,
} from './DiseaseSettings';
import type { Like } from './Like';
import {
    LikeFromJSON,
    LikeFromJSONTyped,
    LikeToJSON,
} from './Like';
import type { Media } from './Media';
import {
    MediaFromJSON,
    MediaFromJSONTyped,
    MediaToJSON,
} from './Media';
import type { OrganisationSettings } from './OrganisationSettings';
import {
    OrganisationSettingsFromJSON,
    OrganisationSettingsFromJSONTyped,
    OrganisationSettingsToJSON,
} from './OrganisationSettings';
import type { SocialMediaPostCategory } from './SocialMediaPostCategory';
import {
    SocialMediaPostCategoryFromJSON,
    SocialMediaPostCategoryFromJSONTyped,
    SocialMediaPostCategoryToJSON,
} from './SocialMediaPostCategory';

/**
 * Represents a SocialMediaPost record
 * @export
 * @interface SocialMediaPost
 */
export interface SocialMediaPost {
    /**
     * 
     * @type {string}
     * @memberof SocialMediaPost
     */
    id: string;
    /**
     * 
     * @type {Media}
     * @memberof SocialMediaPost
     */
    media?: Media;
    /**
     * 
     * @type {string}
     * @memberof SocialMediaPost
     */
    mediaId: string;
    /**
     * 
     * @type {string}
     * @memberof SocialMediaPost
     */
    contentBody: string;
    /**
     * 
     * @type {Array<Like>}
     * @memberof SocialMediaPost
     */
    likes?: Array<Like>;
    /**
     * 
     * @type {Array<Comment>}
     * @memberof SocialMediaPost
     */
    comments?: Array<Comment>;
    /**
     * 
     * @type {Date}
     * @memberof SocialMediaPost
     */
    createdAt: Date;
    /**
     * 
     * @type {DiseaseSettings}
     * @memberof SocialMediaPost
     */
    disease?: DiseaseSettings;
    /**
     * 
     * @type {string}
     * @memberof SocialMediaPost
     */
    diseaseId: string;
    /**
     * 
     * @type {OrganisationSettings}
     * @memberof SocialMediaPost
     */
    organisation?: OrganisationSettings;
    /**
     * 
     * @type {string}
     * @memberof SocialMediaPost
     */
    organisationId?: string;
    /**
     * 
     * @type {string}
     * @memberof SocialMediaPost
     */
    language: string;
    /**
     * 
     * @type {Array<SocialMediaPostCategory>}
     * @memberof SocialMediaPost
     */
    categories?: Array<SocialMediaPostCategory>;
}

/**
 * Check if a given object implements the SocialMediaPost interface.
 */
export function instanceOfSocialMediaPost(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "mediaId" in value;
    isInstance = isInstance && "contentBody" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "diseaseId" in value;
    isInstance = isInstance && "language" in value;

    return isInstance;
}

export function SocialMediaPostFromJSON(json: any): SocialMediaPost {
    return SocialMediaPostFromJSONTyped(json, false);
}

export function SocialMediaPostFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocialMediaPost {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'media': !exists(json, 'media') ? undefined : MediaFromJSON(json['media']),
        'mediaId': json['mediaId'],
        'contentBody': json['contentBody'],
        'likes': !exists(json, 'likes') ? undefined : ((json['likes'] as Array<any>).map(LikeFromJSON)),
        'comments': !exists(json, 'comments') ? undefined : ((json['comments'] as Array<any>).map(CommentFromJSON)),
        'createdAt': (new Date(json['createdAt'])),
        'disease': !exists(json, 'disease') ? undefined : DiseaseSettingsFromJSON(json['disease']),
        'diseaseId': json['diseaseId'],
        'organisation': !exists(json, 'organisation') ? undefined : OrganisationSettingsFromJSON(json['organisation']),
        'organisationId': !exists(json, 'organisationId') ? undefined : json['organisationId'],
        'language': json['language'],
        'categories': !exists(json, 'categories') ? undefined : ((json['categories'] as Array<any>).map(SocialMediaPostCategoryFromJSON)),
    };
}

export function SocialMediaPostToJSON(value?: SocialMediaPost | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'media': MediaToJSON(value.media),
        'mediaId': value.mediaId,
        'contentBody': value.contentBody,
        'likes': value.likes === undefined ? undefined : ((value.likes as Array<any>).map(LikeToJSON)),
        'comments': value.comments === undefined ? undefined : ((value.comments as Array<any>).map(CommentToJSON)),
        'createdAt': (value.createdAt.toISOString()),
        'disease': DiseaseSettingsToJSON(value.disease),
        'diseaseId': value.diseaseId,
        'organisation': OrganisationSettingsToJSON(value.organisation),
        'organisationId': value.organisationId,
        'language': value.language,
        'categories': value.categories === undefined ? undefined : ((value.categories as Array<any>).map(SocialMediaPostCategoryToJSON)),
    };
}

