/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EventDuration } from './EventDuration';
import {
    EventDurationFromJSON,
    EventDurationFromJSONTyped,
    EventDurationToJSON,
} from './EventDuration';
import type { EventTime } from './EventTime';
import {
    EventTimeFromJSON,
    EventTimeFromJSONTyped,
    EventTimeToJSON,
} from './EventTime';
import type { UpdateEventDetail } from './UpdateEventDetail';
import {
    UpdateEventDetailFromJSON,
    UpdateEventDetailFromJSONTyped,
    UpdateEventDetailToJSON,
} from './UpdateEventDetail';

/**
 * 
 * @export
 * @interface UpdateEvent
 */
export interface UpdateEvent {
    /**
     * 
     * @type {string}
     * @memberof UpdateEvent
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEvent
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEvent
     */
    type: string;
    /**
     * 
     * @type {EventTime}
     * @memberof UpdateEvent
     */
    time: EventTime;
    /**
     * 
     * @type {EventDuration}
     * @memberof UpdateEvent
     */
    duration: EventDuration;
    /**
     * 
     * @type {Array<UpdateEventDetail>}
     * @memberof UpdateEvent
     */
    eventDetails: Array<UpdateEventDetail>;
}

/**
 * Check if a given object implements the UpdateEvent interface.
 */
export function instanceOfUpdateEvent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "time" in value;
    isInstance = isInstance && "duration" in value;
    isInstance = isInstance && "eventDetails" in value;

    return isInstance;
}

export function UpdateEventFromJSON(json: any): UpdateEvent {
    return UpdateEventFromJSONTyped(json, false);
}

export function UpdateEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'type': json['type'],
        'time': EventTimeFromJSON(json['time']),
        'duration': EventDurationFromJSON(json['duration']),
        'eventDetails': ((json['eventDetails'] as Array<any>).map(UpdateEventDetailFromJSON)),
    };
}

export function UpdateEventToJSON(value?: UpdateEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'text': value.text,
        'type': value.type,
        'time': EventTimeToJSON(value.time),
        'duration': EventDurationToJSON(value.duration),
        'eventDetails': ((value.eventDetails as Array<any>).map(UpdateEventDetailToJSON)),
    };
}

