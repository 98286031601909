import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Language } from "shared/model/languages";

import { useTenantId } from "../../util/use-active-tenant-id";
import { DropdownProps } from "../form/dropdown";
import { TranslationFeKey } from "../text";

export const useDiseaseEnumDropdownOptions =
  (): DropdownProps<string>["options"] => {
    const { disease } = useTenantId();
    const diseaseEnumKeys = useDiseaseEnumKeys();
    return useMemo<DropdownProps<string>["options"]>(
      () =>
        diseaseEnumKeys.map((key) => ({
          value: key,
          label: {
            tx: `enums.${disease}.event.${key}.title` as TranslationFeKey,
          },
        })),
      [disease, diseaseEnumKeys],
    );
  };

const useDiseaseEnumKeys = (): string[] => {
  const { disease } = useTenantId();
  const {
    i18n: { getResourceBundle },
  } = useTranslation();
  return useMemo(
    () =>
      Object.keys(getResourceBundle(Language.en_US, "").enums[disease].event),
    [disease, getResourceBundle],
  );
};
