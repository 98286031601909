import React, { ElementType, useState } from "react";
import { twMerge } from "tailwind-merge";

import { useT } from "../../i18n/use-t";
import { Disableable } from "../../types/disableable";
import { SvgIcon } from "../icons/svg-icon";
import { PlainI18nProps } from "../text";
import { Tooltip } from "../tooltip";

export type TextInputProps = Disableable<
  Omit<JSX.IntrinsicElements["input"], "ref" | "title" | "placeholder">
> & {
  type?: "text" | "password" | "number" | "email";
  rows?: number;
  placeholder?: PlainI18nProps;
  as?: ElementType;
};

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      className,
      isDisabled,
      type,
      placeholder,
      as: Component = "input",
      rows,
      ...rest
    },
    ref,
  ) => {
    const t = useT();
    const [inputType, setInputType] = useState(type ?? "text");

    const togglePasswordVisibility = () => {
      setInputType((prev) => (prev === "password" ? "text" : "password"));
    };

    return (
      <div className="relative flex w-full">
        <Component
          ref={ref}
          {...rest}
          rows={rows ?? 1}
          type={inputType}
          disabled={isDisabled}
          placeholder={t(placeholder)}
          inputMode={type === "number" ? "numeric" : undefined}
          className={twMerge(
            type === "password" && "pr-10",
            Component === "textarea"
              ? "textarea-bordered textarea text-base"
              : "input-bordered input",
            "w-full",
            isDisabled ? "cursor-not-allowed text-gray-700" : "",
            className,
          )}
        />
        {type === "password" && (
          <Tooltip
            text={{
              tx:
                inputType === "password"
                  ? "general.showPassword"
                  : "general.hidePassword",
            }}
            className="absolute right-0 top-1 cursor-pointer"
            tooltipPosition={
              inputType === "password" ? "tooltip-left" : undefined
            }
          >
            <SvgIcon
              width={40}
              onClick={togglePasswordVisibility}
              icon={inputType === "password" ? "eyeOpen" : "eyeClosed"}
            />
          </Tooltip>
        )}
      </div>
    );
  },
);
TextInput.displayName = "TextInput";
