import { UserFeedback } from "../../../api/generated/backend";
import { Text, TranslationFeKey } from "../../text";

export const UserFeedbackWithTitleLayout: React.FC<{
  feedback: UserFeedback;
  children: React.ReactNode;
}> = ({ feedback, children }) => {
  return (
    <div className="flex w-full flex-col items-center gap-1 rounded-lg bg-slate-100 p-5">
      <Text
        className="text-center text-lg"
        tx={feedback.title as TranslationFeKey}
      />
      {children}
    </div>
  );
};
