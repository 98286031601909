import i18next from "i18next";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { TenantIdentifier, tenantIdEquals } from "shared/config";
import { Language } from "shared/model/languages";

import { UpdateUserAttributesRequest } from "../api/generated/backend";
import { toTenantIdHeader, useApiMutation, useApiQuery } from "../api/use-api";
import { queryClient } from "../query-client";
import { useTenantConfig } from "../tenant-settings";
import { ME_KEY } from "../types/query-keys";
import { useTenantId } from "../util/use-active-tenant-id";

export const SynchronizeLanguageWithBackend: React.FC = () => {
  const {
    i18n: { language: currentlySelectedLanguage },
  } = useTranslation();
  const tenantConfig = useTenantConfig();
  const { disease } = useTenantId();

  const { data: user, isLoading } = useApiQuery(
    "backend",
    (api) => api.getMe(toTenantIdHeader({ disease })),
    ME_KEY({ disease }),
  );

  const savedBackendLanguage = useRef<string>();
  const loadedBackendLanguage = useRef<TenantIdentifier | undefined>(undefined);

  // Applies the language saved in the backend once after loading a new tenant
  useEffect(() => {
    if (
      user &&
      !isLoading &&
      !tenantIdEquals(loadedBackendLanguage.current, { disease })
    ) {
      const userAttributes = user.userAttributes;
      savedBackendLanguage.current = userAttributes?.language;
      loadedBackendLanguage.current = { disease };

      const { supportedLanguages } = tenantConfig;

      const languagePreference =
        userAttributes?.language ?? currentlySelectedLanguage;
      const applyLanguage = supportedLanguages.includes(
        languagePreference as Language,
      )
        ? languagePreference
        : supportedLanguages[0];

      if (currentlySelectedLanguage !== applyLanguage) {
        i18next.changeLanguage(applyLanguage);
      }
    }
  }, [isLoading, currentlySelectedLanguage, tenantConfig, disease, user]);

  const lastRequest = useRef(Promise.resolve());

  const { mutate: updateUserAttributes } = useApiMutation(
    "backend",
    (api) => (request: UpdateUserAttributesRequest) =>
      api.updateUserAttributes(request),
    undefined,
    undefined,
    {
      onSuccess: (settings) => {
        savedBackendLanguage.current = settings.language;
        queryClient.invalidateQueries(ME_KEY({ disease }));
      },
    },
  );

  // Stores the language in the backend whenever the user changes it,
  // only takes effect after the language has been loaded from the backend
  useEffect(() => {
    if (
      user &&
      !isLoading &&
      savedBackendLanguage.current !== currentlySelectedLanguage &&
      tenantIdEquals(loadedBackendLanguage.current, { disease })
    ) {
      lastRequest.current = lastRequest.current.finally(() => {
        updateUserAttributes({
          updateUserAttributesDto: {
            language: currentlySelectedLanguage as Language,
          },
        });
      });
    }
  }, [
    isLoading,
    currentlySelectedLanguage,
    disease,
    updateUserAttributes,
    user,
  ]);

  return null;
};

export const EnsureLanguageIsSupported: React.FC = () => {
  const {
    i18n: { language: currentlySelectedLanguage },
  } = useTranslation();
  const tenantConfig = useTenantConfig();

  useEffect(() => {
    const { supportedLanguages } = tenantConfig;

    if (!supportedLanguages.includes(currentlySelectedLanguage as Language)) {
      i18next.changeLanguage(supportedLanguages[0]);
    }
  }, [currentlySelectedLanguage, tenantConfig]);

  return null;
};
