import { useMemo } from "react";
import { FrontendPageEnum } from "shared/model/pages";

import { toTenantIdHeader, useApiQuery } from "../../../api/use-api";
import { usePageSettings } from "../../../tenant-settings";
import { ME_KEY } from "../../../types/query-keys";
import { useTenantId } from "../../../util/use-active-tenant-id";

export const useActiveLandbotUrlTx = (): string | undefined => {
  const tenantId = useTenantId();
  const { data: user } = useApiQuery(
    "backend",
    (lApi) => lApi.getMe(toTenantIdHeader(tenantId)),
    ME_KEY(tenantId),
  );

  const chatSettings = usePageSettings(FrontendPageEnum.CHAT);

  const activeLandbotUrlTx = useMemo(
    () =>
      user && chatSettings
        ? user.settings?.landbotUrlTx ?? chatSettings.defaultLandbotUrlTx
        : undefined,
    [user, chatSettings],
  );
  return activeLandbotUrlTx;
};
