/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SubmitFeedback } from './SubmitFeedback';
import {
    SubmitFeedbackFromJSON,
    SubmitFeedbackFromJSONTyped,
    SubmitFeedbackToJSON,
} from './SubmitFeedback';

/**
 * 
 * @export
 * @interface SubmitFeedbackDto
 */
export interface SubmitFeedbackDto {
    /**
     * 
     * @type {Array<SubmitFeedback>}
     * @memberof SubmitFeedbackDto
     */
    submitFeedbacks: Array<SubmitFeedback>;
}

/**
 * Check if a given object implements the SubmitFeedbackDto interface.
 */
export function instanceOfSubmitFeedbackDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "submitFeedbacks" in value;

    return isInstance;
}

export function SubmitFeedbackDtoFromJSON(json: any): SubmitFeedbackDto {
    return SubmitFeedbackDtoFromJSONTyped(json, false);
}

export function SubmitFeedbackDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubmitFeedbackDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'submitFeedbacks': ((json['submitFeedbacks'] as Array<any>).map(SubmitFeedbackFromJSON)),
    };
}

export function SubmitFeedbackDtoToJSON(value?: SubmitFeedbackDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'submitFeedbacks': ((value.submitFeedbacks as Array<any>).map(SubmitFeedbackToJSON)),
    };
}

