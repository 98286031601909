/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DocumentExtraction } from './DocumentExtraction';
import {
    DocumentExtractionFromJSON,
    DocumentExtractionFromJSONTyped,
    DocumentExtractionToJSON,
} from './DocumentExtraction';
import type { Message } from './Message';
import {
    MessageFromJSON,
    MessageFromJSONTyped,
    MessageToJSON,
} from './Message';

/**
 * Represents a UserMedia record
 * @export
 * @interface UserMediaRecord
 */
export interface UserMediaRecord {
    /**
     * 
     * @type {string}
     * @memberof UserMediaRecord
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserMediaRecord
     */
    mainType: string;
    /**
     * 
     * @type {string}
     * @memberof UserMediaRecord
     */
    subtype: string;
    /**
     * 
     * @type {string}
     * @memberof UserMediaRecord
     */
    extractedText: string;
    /**
     * 
     * @type {string}
     * @memberof UserMediaRecord
     */
    path: string;
    /**
     * 
     * @type {string}
     * @memberof UserMediaRecord
     */
    messageId?: string;
    /**
     * 
     * @type {Message}
     * @memberof UserMediaRecord
     */
    message?: Message;
    /**
     * 
     * @type {Date}
     * @memberof UserMediaRecord
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof UserMediaRecord
     */
    documentExtractionId?: string;
    /**
     * 
     * @type {DocumentExtraction}
     * @memberof UserMediaRecord
     */
    documentExtraction?: DocumentExtraction;
    /**
     * 
     * @type {number}
     * @memberof UserMediaRecord
     */
    duration?: number;
}

/**
 * Check if a given object implements the UserMediaRecord interface.
 */
export function instanceOfUserMediaRecord(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "mainType" in value;
    isInstance = isInstance && "subtype" in value;
    isInstance = isInstance && "extractedText" in value;
    isInstance = isInstance && "path" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function UserMediaRecordFromJSON(json: any): UserMediaRecord {
    return UserMediaRecordFromJSONTyped(json, false);
}

export function UserMediaRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMediaRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'mainType': json['mainType'],
        'subtype': json['subtype'],
        'extractedText': json['extractedText'],
        'path': json['path'],
        'messageId': !exists(json, 'messageId') ? undefined : json['messageId'],
        'message': !exists(json, 'message') ? undefined : MessageFromJSON(json['message']),
        'createdAt': (new Date(json['createdAt'])),
        'documentExtractionId': !exists(json, 'documentExtractionId') ? undefined : json['documentExtractionId'],
        'documentExtraction': !exists(json, 'documentExtraction') ? undefined : DocumentExtractionFromJSON(json['documentExtraction']),
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
    };
}

export function UserMediaRecordToJSON(value?: UserMediaRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'mainType': value.mainType,
        'subtype': value.subtype,
        'extractedText': value.extractedText,
        'path': value.path,
        'messageId': value.messageId,
        'message': MessageToJSON(value.message),
        'createdAt': (value.createdAt.toISOString()),
        'documentExtractionId': value.documentExtractionId,
        'documentExtraction': DocumentExtractionToJSON(value.documentExtraction),
        'duration': value.duration,
    };
}

