import { PropsWithChildren } from "react";

import { Choices } from "./choices";
import { ChatOptionComponentProps } from "../../lookup";

export const ChoicesWithText: React.FC<
  PropsWithChildren<ChatOptionComponentProps>
> = ({ children, ...props }) => {
  return (
    <div className="flex flex-col items-center gap-2">
      <Choices {...props} />
      {children}
    </div>
  );
};
