/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MedicalProfessional } from './MedicalProfessional';
import {
    MedicalProfessionalFromJSON,
    MedicalProfessionalFromJSONTyped,
    MedicalProfessionalToJSON,
} from './MedicalProfessional';
import type { MedicalProfessionalsOnUsers } from './MedicalProfessionalsOnUsers';
import {
    MedicalProfessionalsOnUsersFromJSON,
    MedicalProfessionalsOnUsersFromJSONTyped,
    MedicalProfessionalsOnUsersToJSON,
} from './MedicalProfessionalsOnUsers';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * Represents a MedicalProfessionalRating record
 * @export
 * @interface MedicalProfessionalRating
 */
export interface MedicalProfessionalRating {
    /**
     * 
     * @type {string}
     * @memberof MedicalProfessionalRating
     */
    id: string;
    /**
     * 
     * @type {MedicalProfessional}
     * @memberof MedicalProfessionalRating
     */
    medicalProfessional?: MedicalProfessional;
    /**
     * 
     * @type {string}
     * @memberof MedicalProfessionalRating
     */
    medicalProfessionalId: string;
    /**
     * 
     * @type {number}
     * @memberof MedicalProfessionalRating
     */
    rating: number;
    /**
     * 
     * @type {User}
     * @memberof MedicalProfessionalRating
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof MedicalProfessionalRating
     */
    userId: string;
    /**
     * 
     * @type {Array<MedicalProfessionalsOnUsers>}
     * @memberof MedicalProfessionalRating
     */
    medicalProfessionalsOnUsers?: Array<MedicalProfessionalsOnUsers>;
}

/**
 * Check if a given object implements the MedicalProfessionalRating interface.
 */
export function instanceOfMedicalProfessionalRating(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "medicalProfessionalId" in value;
    isInstance = isInstance && "rating" in value;
    isInstance = isInstance && "userId" in value;

    return isInstance;
}

export function MedicalProfessionalRatingFromJSON(json: any): MedicalProfessionalRating {
    return MedicalProfessionalRatingFromJSONTyped(json, false);
}

export function MedicalProfessionalRatingFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalProfessionalRating {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'medicalProfessional': !exists(json, 'medicalProfessional') ? undefined : MedicalProfessionalFromJSON(json['medicalProfessional']),
        'medicalProfessionalId': json['medicalProfessionalId'],
        'rating': json['rating'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'userId': json['userId'],
        'medicalProfessionalsOnUsers': !exists(json, 'medicalProfessionalsOnUsers') ? undefined : ((json['medicalProfessionalsOnUsers'] as Array<any>).map(MedicalProfessionalsOnUsersFromJSON)),
    };
}

export function MedicalProfessionalRatingToJSON(value?: MedicalProfessionalRating | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'medicalProfessional': MedicalProfessionalToJSON(value.medicalProfessional),
        'medicalProfessionalId': value.medicalProfessionalId,
        'rating': value.rating,
        'user': UserToJSON(value.user),
        'userId': value.userId,
        'medicalProfessionalsOnUsers': value.medicalProfessionalsOnUsers === undefined ? undefined : ((value.medicalProfessionalsOnUsers as Array<any>).map(MedicalProfessionalsOnUsersToJSON)),
    };
}

