import { useCallback, useMemo, useState } from "react";
import { organisationsPerDisease } from "shared/config";
import { Organisation } from "shared/model/organisations";

import { DropdownProps } from "./dropdown";
import { MultiSelectDropdown } from "./multi-select-dropdown";
import { useTenantId } from "../../util/use-active-tenant-id";

export const OrganisationsPerDiseaseMultiSelect: React.FC<{
  className?: string;
  values?: Organisation[];
  onChange: (languages: Organisation[]) => void;
  searchResultsOnTop?: boolean;
}> = ({ className, values, onChange, searchResultsOnTop }) => {
  const tenantId = useTenantId();
  const orgsOfDisease = useMemo<Organisation[]>(
    () => [...(organisationsPerDisease.get(tenantId.disease) ?? [])],
    [tenantId.disease],
  );

  const handleChange = useCallback(
    (orgs: Organisation[]) => {
      onChange(orgs);
    },
    [onChange],
  );

  const organisations = useMemo<DropdownProps<Organisation>["options"]>(
    () =>
      [
        ...orgsOfDisease.map((org) => ({
          label: { text: org },
          value: org as Organisation,
        })),
      ] ?? [],
    [orgsOfDisease],
  );

  const [selectedOrgs, setSelectedOrgs] = useState<Organisation[]>([
    ...(values ?? []),
  ]);

  return (
    <MultiSelectDropdown
      searchResultsOnTop={searchResultsOnTop}
      noValueSelected={{ txUnchecked: "filters.selectOrganisation" }}
      options={organisations}
      className={className}
      values={selectedOrgs}
      onChange={(orgs) => {
        setSelectedOrgs(orgs);
        handleChange(orgs);
      }}
    />
  );
};
