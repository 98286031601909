/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MediaType } from './MediaType';
import {
    MediaTypeFromJSON,
    MediaTypeFromJSONTyped,
    MediaTypeToJSON,
} from './MediaType';
import type { SocialMediaPost } from './SocialMediaPost';
import {
    SocialMediaPostFromJSON,
    SocialMediaPostFromJSONTyped,
    SocialMediaPostToJSON,
} from './SocialMediaPost';

/**
 * Represents a Media record
 * @export
 * @interface Media
 */
export interface Media {
    /**
     * 
     * @type {string}
     * @memberof Media
     */
    id: string;
    /**
     * 
     * @type {MediaType}
     * @memberof Media
     */
    type: MediaType;
    /**
     * 
     * @type {Array<string>}
     * @memberof Media
     */
    mediaUrls: Array<string>;
    /**
     * 
     * @type {Array<SocialMediaPost>}
     * @memberof Media
     */
    socialMediaPost?: Array<SocialMediaPost>;
}

/**
 * Check if a given object implements the Media interface.
 */
export function instanceOfMedia(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "mediaUrls" in value;

    return isInstance;
}

export function MediaFromJSON(json: any): Media {
    return MediaFromJSONTyped(json, false);
}

export function MediaFromJSONTyped(json: any, ignoreDiscriminator: boolean): Media {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': MediaTypeFromJSON(json['type']),
        'mediaUrls': json['mediaUrls'],
        'socialMediaPost': !exists(json, 'socialMediaPost') ? undefined : ((json['socialMediaPost'] as Array<any>).map(SocialMediaPostFromJSON)),
    };
}

export function MediaToJSON(value?: Media | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': MediaTypeToJSON(value.type),
        'mediaUrls': value.mediaUrls,
        'socialMediaPost': value.socialMediaPost === undefined ? undefined : ((value.socialMediaPost as Array<any>).map(SocialMediaPostToJSON)),
    };
}

