import { produce } from "immer";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChatType, FrontendPageEnum } from "shared/model/pages";

import { EditableEventDetails } from "./editable-event-details";
import { EditableEventText } from "./editable-event-text";
import { EventActionRow } from "./event-action-row";
import { EventModifier } from "./types";
import { useDiseaseEnumDropdownOptions } from "./use-disease-enum";
import {
  DeleteEventRequest,
  EventRecord,
  UpdateEventRequest,
} from "../../api/generated/landbot";
import { useApiMutation } from "../../api/use-api";
import { ModalConfig, useModal } from "../../models/modal-provider";
import { queryClient } from "../../query-client";
import { usePageSettings } from "../../tenant-settings";
import {
  GET_EVENTS_KEY,
  GET_LOCALIZED_EVENTS_KEY,
} from "../../types/query-keys";
import { useTenantId } from "../../util/use-active-tenant-id";

export const EditableEvent: React.FC<{
  event: EventRecord;
  hideModal: () => void;
  updateModal: (config: ModalConfig) => void;
}> = ({ event: unmodifiedEvent, hideModal, updateModal }) => {
  const { disease } = useTenantId();
  const {
    i18n: { language },
  } = useTranslation();

  const chatSettings = usePageSettings(FrontendPageEnum.CHAT);
  const isUsingLandbotChat = useMemo(
    () => chatSettings?.chatType === ChatType.LANDBOT,
    [chatSettings?.chatType],
  );

  const [event, setEvent] = useState<EventRecord>(unmodifiedEvent);

  const editEvent = useCallback(
    (modifier: EventModifier) =>
      setEvent((current) =>
        produce(current, (editableEvent) => {
          modifier(editableEvent);
        }),
      ),
    [],
  );

  const { mutateAsync: updateEvents } = useApiMutation(
    "landbot",
    (api) => (request: UpdateEventRequest) => api.updateEvent(request),
    undefined,
    undefined,
    {
      onSuccess: () => {
        isUsingLandbotChat
          ? queryClient.invalidateQueries(GET_EVENTS_KEY(disease))
          : queryClient.invalidateQueries(GET_LOCALIZED_EVENTS_KEY(disease));
      },
    },
  );
  useEffect(() => {
    updateModal({
      onConfirm: () => {
        updateModal({ confirmLoading: true });
        updateEvents({
          updateEvent: event,
        })
          .then(hideModal)
          .finally(() => {
            updateModal({ confirmLoading: false });
          });
      },
    });
  }, [
    event,
    disease,
    language,
    isUsingLandbotChat,
    hideModal,
    updateModal,
    updateEvents,
  ]);

  const { mutateAsync: deleteEvent, isLoading: deleteLoading } = useApiMutation(
    "landbot",
    (api) => (request: DeleteEventRequest) => api.deleteEvent(request),
    undefined,
    undefined,
    {
      onSuccess: () => {
        isUsingLandbotChat
          ? queryClient.invalidateQueries(GET_EVENTS_KEY(disease))
          : queryClient.invalidateQueries(GET_LOCALIZED_EVENTS_KEY(disease));
      },
    },
  );

  const { showModal: showDeleteModal, hideModal: hideDeleteModal } = useModal();

  return (
    <>
      <EventActionRow
        dropDownConfig={{
          enumType: "event",
          dropDownStyles: "mt-1",
          dropDownValue: event.type,
          noValueSelected: event.type,
          isHidden: isUsingLandbotChat,
          dropDownOptions: useDiseaseEnumDropdownOptions(),
          onChangeDropDownValue: (type) =>
            editEvent((editableEvent) => (editableEvent.type = type)),
        }}
        deleteButtonConfig={{
          deleteButtonStyles: "my-1",
          deleteButtonText: { tx: "journey.removeEvent" },
          deleteButtonLoading: deleteLoading,
          onDeleteButtonPress: () => {
            showDeleteModal({
              title: { tx: "journey.removeEvent" },
              description: { tx: "journey.removeEventFromTimelineBody" },
              onConfirm: () => {
                hideDeleteModal();
                deleteEvent({ eventId: unmodifiedEvent.id }).then(() =>
                  hideModal(),
                );
              },
            });
          },
        }}
        datePickerConfig={{
          datePickerStyles: "my-1",
          defaultDate: event.time,
          onChangeDate: (time) =>
            editEvent((editableEvent) => (editableEvent.time = time)),
          hideDatePicker: false,
        }}
      />

      {event.eventDetails.length > 0 ? (
        event.eventDetails.map((ev) => (
          <EditableEventDetails key={ev.id} detail={ev} editEvent={editEvent} />
        ))
      ) : (
        <EditableEventText event={event} editEvent={editEvent} />
      )}
    </>
  );
};
