/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Subscription } from './Subscription';
import {
    SubscriptionFromJSON,
    SubscriptionFromJSONTyped,
    SubscriptionToJSON,
} from './Subscription';

/**
 * 
 * @export
 * @interface Subscriptions
 */
export interface Subscriptions {
    /**
     * 
     * @type {Array<Subscription>}
     * @memberof Subscriptions
     */
    scheduledSubscriptions: Array<Subscription>;
    /**
     * 
     * @type {Array<Subscription>}
     * @memberof Subscriptions
     */
    activeSubscriptions: Array<Subscription>;
}

/**
 * Check if a given object implements the Subscriptions interface.
 */
export function instanceOfSubscriptions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "scheduledSubscriptions" in value;
    isInstance = isInstance && "activeSubscriptions" in value;

    return isInstance;
}

export function SubscriptionsFromJSON(json: any): Subscriptions {
    return SubscriptionsFromJSONTyped(json, false);
}

export function SubscriptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Subscriptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'scheduledSubscriptions': ((json['scheduledSubscriptions'] as Array<any>).map(SubscriptionFromJSON)),
        'activeSubscriptions': ((json['activeSubscriptions'] as Array<any>).map(SubscriptionFromJSON)),
    };
}

export function SubscriptionsToJSON(value?: Subscriptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'scheduledSubscriptions': ((value.scheduledSubscriptions as Array<any>).map(SubscriptionToJSON)),
        'activeSubscriptions': ((value.activeSubscriptions as Array<any>).map(SubscriptionToJSON)),
    };
}

