import { cognitoPaths } from "./paths";
import { Link } from "../../../../components/link";
import { Text } from "../../../../components/text";
import { useAuthNavigate } from "../../generic/use-auth-navigate";

export const CognitoSignUpDeprecated: React.FC = () => {
  const navigate = useAuthNavigate();

  return (
    <Text
      as="p"
      className="text-center"
      tx="auth.signUp.signUpDeprecated"
      txComponents={{
        OnboardingLink: (
          <Link
            onClick={() =>
              navigate({ to: { type: "uri", uri: cognitoPaths.onboarding } })
            }
          />
        ),
      }}
    />
  );
};
