import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

export const DefaultContentContainer: React.FC<
  PropsWithChildren<JSX.IntrinsicElements["div"]>
> = ({ children, className, ...rest }) => {
  return (
    <div className="h-full w-full overflow-x-hidden overflow-y-scroll">
      <div className="mx-auto min-h-full py-12" {...rest}>
        <div
          className={twMerge(
            "mx-auto w-11/12 max-w-[1600px] md:w-9/12",
            className,
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
