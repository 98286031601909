import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  AddressComponent,
  AddressComponentEnum,
  DetailedAddress,
  OSMQueryParams,
  OSMUrl,
} from "./constants";
import { OSMAdressType, OSMSerchResultType } from "./types";
import { useDebounce } from "../../util/hooks";

export const useAddressSearch = (
  addressComponents?: AddressComponent[],
): {
  query: string;
  searchResult: { address: string; detailedAddress: DetailedAddress }[];
  setQuery: (query: string) => void;
} => {
  const {
    i18n: { language },
  } = useTranslation();
  const [query, setQuery] = useState("");
  const [searchResult, setSearchResult] = useState<
    { address: string; detailedAddress: DetailedAddress }[]
  >([]);

  const debouncedQuery = useDebounce<string>(query, 200);

  useEffect(() => {
    if (debouncedQuery) {
      searchMap(debouncedQuery, language).then((addresses: OSMAdressType[]) => {
        const locations = addresses.map((address) =>
          generateLocations(address, addressComponents),
        );
        const uniqueLocations = locations.filter(
          (location, index, self) =>
            index ===
            self.findIndex(
              (nextLocation) => nextLocation.address === location.address,
            ),
        );
        setSearchResult(uniqueLocations);
      });
    }
  }, [addressComponents, debouncedQuery, language]);

  return useMemo(
    () => ({ searchResult, setQuery, query }),
    [query, searchResult],
  );
};

const searchMap = async (
  query: string,
  language: string,
): Promise<OSMAdressType[]> => {
  const url = OSMUrl;
  url.search = OSMQueryParams;
  url.searchParams.append("q", query);
  url.searchParams.append("accept-language", language);

  const res = await fetch(url);
  const json = await res.json();
  const addresses = json.map((v: OSMSerchResultType) => v.address);
  return addresses;
};

const generateLocations = (
  OSMAddress: OSMAdressType,
  addressComponents?: AddressComponent[],
): { address: string; detailedAddress: DetailedAddress } => {
  const road =
    !addressComponents || addressComponents.includes(AddressComponentEnum.ROAD)
      ? OSMAddress.road ??
        OSMAddress.district ??
        OSMAddress.suburb ??
        OSMAddress.park ??
        undefined
      : undefined;
  const city =
    !addressComponents || addressComponents.includes(AddressComponentEnum.CITY)
      ? OSMAddress.city ??
        OSMAddress.town ??
        OSMAddress.village ??
        OSMAddress.county
      : undefined;
  const postalCode =
    !addressComponents ||
    addressComponents.includes(AddressComponentEnum.POSTAL_CODE)
      ? OSMAddress.postcode
      : undefined;
  const state =
    !addressComponents || addressComponents.includes(AddressComponentEnum.CITY)
      ? OSMAddress.state
      : undefined;
  const country =
    !addressComponents ||
    addressComponents.includes(AddressComponentEnum.COUNTRY)
      ? OSMAddress.country
      : undefined;

  const address = `${road ? road + ", " : ""}${city ? city + ", " : ""}${
    postalCode ? postalCode + ", " : ""
  }${state ? state + ", " : ""}${country ? country : ""}`;

  const detailedAddress: DetailedAddress = {
    [AddressComponentEnum.ROAD]: road,
    [AddressComponentEnum.CITY]: city,
    [AddressComponentEnum.POSTAL_CODE]: postalCode,
    [AddressComponentEnum.STATE]: state,
    [AddressComponentEnum.COUNTRY]: country,
  };

  return { address: address, detailedAddress: detailedAddress };
};
