"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updatedLegalPolicyDataGuard = void 0;
const data_guard_type_1 = require("../../data-guard-type");
const form_data_guard_1 = require("../form-data-guard");
exports.updatedLegalPolicyDataGuard = (0, data_guard_type_1.createDataGuard)({
    path: "updated-legal-policy",
    type: data_guard_type_1.DataGuardType.FORM_DATA_GUARD,
    title: "dataGuards.updatedLegalPolicy.title",
    inputs: [
        {
            type: form_data_guard_1.FormDataCollectionInputType.TERMS_AND_CONDITIONS_2023_08_30,
            reasonWhyShown: "dataGuards.updatedLegalPolicy.termsAndConditionsUpdated",
        },
        {
            type: form_data_guard_1.FormDataCollectionInputType.PRIVACY_POLICY_2023_10_18,
            reasonWhyShown: "dataGuards.updatedLegalPolicy.privacyPolicyUpdated",
        },
    ],
    layout: form_data_guard_1.FormDataGuardLayoutType.EXISTING_USER_LAYOUT,
});
