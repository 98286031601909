import { reaction } from "mobx";
import { createContext, useContext, useEffect, useState } from "react";

import { AuthStateStore } from "./auth-state-store";
import { AuthStateEnum } from "./implementations/generic/types";

export const AuthStateStoreContext = createContext<AuthStateStore | null>(null);

export function useAuthStateStore(): AuthStateStore {
  const context = useContext(AuthStateStoreContext);
  if (context === null) {
    throw new Error(
      "useAuthStatusStore must be used within an AuthStatusStoreProvider",
    );
  }
  return context;
}

export function useObservedAuthState(): AuthStateEnum {
  const authStore = useAuthStateStore();
  const [authState, setAuthState] = useState<AuthStateEnum>(
    authStore.state ?? AuthStateEnum.LOADING,
  );

  useEffect(() => {
    reaction(
      () => authStore.state,
      (state) => setAuthState(state),
    );
  }, [authStore.state]);

  return authState;
}
