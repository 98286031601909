import { useQueryClient } from "@tanstack/react-query";
import { DeploymentEnvironment } from "shared/config/deployment-environments";
import {
  ConversationWithMessages,
  WebSocketRequest,
  WebSocketResponseEventData,
} from "shared/model/websocket/schema";

import { Conversation } from "./conversation";
import { mamaAskChatDrawerComponents } from "./drawer/lookup";
import { useApiQuery } from "../../../../api/use-api";
import { useWebSocket } from "../../../../api/use-web-socket";
import {
  CHAT_COMPLETENESS_KEY,
  CONVERSATION_KEY,
} from "../../../../types/query-keys";
import { appEnv, isNotLocalhost } from "../../../../util/env-utils";
import { useTenantId } from "../../../../util/use-active-tenant-id";
import { useGetExtendedTheme } from "../../../../util/use-get-theme";
import { LoadingScreen } from "../../../loading-screen";

export const AiPrivateChat: React.FC<{
  conversationId: string;
}> = ({ conversationId }) => {
  const { colors } = useGetExtendedTheme();
  const queryClient = useQueryClient();

  const { disease } = useTenantId();
  const { data: initialConversation } = useApiQuery(
    "multiagent",
    (api) =>
      api.getConversationWithId({
        conversationId: conversationId,
      }),
    CONVERSATION_KEY(disease, conversationId),
    {},
    { refetchOnWindowFocus: false },
  );

  const useAgnosticWebSocket = (params: {
    query?: Record<string, string>;
    onResponse?: (
      event?: MessageEvent<WebSocketResponseEventData>,
    ) => Promise<void> | void;
  }) => {
    return useWebSocket<WebSocketRequest, WebSocketResponseEventData>({
      url: getWebSocketUrl(),
      query: params.query,
      onResponse: (response) => {
        params.onResponse?.(response);

        if (response?.type === "message") {
          queryClient.invalidateQueries(CHAT_COMPLETENESS_KEY(disease));
        }
      },
    });
  };

  return !initialConversation ? (
    <LoadingScreen message={{ tx: "chat.loadingInitialChat" }} />
  ) : (
    <Conversation
      chatDrawerComponents={mamaAskChatDrawerComponents}
      useAgnosticWebSocket={useAgnosticWebSocket}
      conversation={initialConversation as ConversationWithMessages}
      shouldShowChatCompletion={false}
      userChatColors={
        colors && {
          bubbleColor: colors["mama-green-100"],
        }
      }
    />
  );
};

const getWebSocketUrl = () => {
  return !isNotLocalhost
    ? "ws://localhost:8000/conversation/ws"
    : appEnv === DeploymentEnvironment.DEV
    ? "wss://ws.dev.mamahealth.com/conversation/ws"
    : "wss://ws.app.mamahealth.com/conversation/ws";
};
