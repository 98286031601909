import { Button } from "../../../../../components/form/button";
import { LoadingButton } from "../../../../../components/form/loading-button";
import { Tooltip } from "../../../../../components/tooltip";
import { FormDataCollectionContext, FormDataGuardLayout } from "../types";

export const existingUserLayout: FormDataGuardLayout = {
  Layout: ({ inputs, loading, currentGuard, goToGuard, ...rest }) => {
    const currentGuardIndex = currentGuard ?? 0;
    return (
      <div className="flex flex-col overflow-x-hidden">
        {inputs.map(({ input, reasonWhyShown, prefilledValue }, index) => {
          const component = (
            <input.Component
              key={index}
              prefilledValue={prefilledValue}
              context={FormDataCollectionContext.POP_UP}
              {...rest}
            />
          );
          return (
            <Tooltip
              key={index}
              className="tooltip-right w-full self-start"
              text={reasonWhyShown}
            >
              {component}
            </Tooltip>
          );
        })}
        <div className="grid grid-flow-col justify-stretch">
          <Button
            className={`mt-4 ${
              currentGuard !== 0 ? "opacity-100" : "cursor-default opacity-0"
            }`}
            onClick={(e) => {
              e.preventDefault();
              goToGuard?.(Math.max(0, currentGuardIndex - 1));
            }}
            tx="navigateBack"
          />

          <LoadingButton
            loading={loading}
            tx="general.confirm"
            className="mt-4"
          />
        </div>
      </div>
    );
  },
};
