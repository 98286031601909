import { v4 as uuid } from "uuid";

import { EventProps } from "./event-props";
import { ToastEvent } from "./toast-event";
import { SentryProps } from "../../util/open-sentry-popup";
import { I18nProps } from "../text/text";

type PlainErrorProps = Partial<SentryProps> & object;

export class PlainError implements ToastEvent {
  public constructor(
    public readonly message: I18nProps,
    public readonly stack: string | undefined = new Error().stack,
    public readonly extraInformation: PlainErrorProps = {},
    private readonly id = uuid(),
  ) {}

  public set sentryEventId(eventId: string) {
    this.extraInformation.eventId = eventId;
  }

  public shouldAutoClear(): boolean {
    return false;
  }

  public getEventProps(): EventProps {
    return {
      eventId: this.id,
      kind: "error",
      message: this.message,
      stack: this.stack,
      sentryAction: this.extraInformation.eventId
        ? {
            eventId: this.extraInformation.eventId,
            sentryButtonText: { tx: "error.contactCustomerSupportButton" },
          }
        : undefined,
    };
  }
}
