/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpsertSocialMediaPostCategoryDto
 */
export interface UpsertSocialMediaPostCategoryDto {
    /**
     * 
     * @type {string}
     * @memberof UpsertSocialMediaPostCategoryDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertSocialMediaPostCategoryDto
     */
    translations?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertSocialMediaPostCategoryDto
     */
    isDiseaseCategory?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpsertSocialMediaPostCategoryDto
     */
    organisations?: Array<UpsertSocialMediaPostCategoryDtoOrganisationsEnum>;
}


/**
 * @export
 */
export const UpsertSocialMediaPostCategoryDtoOrganisationsEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type UpsertSocialMediaPostCategoryDtoOrganisationsEnum = typeof UpsertSocialMediaPostCategoryDtoOrganisationsEnum[keyof typeof UpsertSocialMediaPostCategoryDtoOrganisationsEnum];


/**
 * Check if a given object implements the UpsertSocialMediaPostCategoryDto interface.
 */
export function instanceOfUpsertSocialMediaPostCategoryDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function UpsertSocialMediaPostCategoryDtoFromJSON(json: any): UpsertSocialMediaPostCategoryDto {
    return UpsertSocialMediaPostCategoryDtoFromJSONTyped(json, false);
}

export function UpsertSocialMediaPostCategoryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpsertSocialMediaPostCategoryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'translations': !exists(json, 'translations') ? undefined : json['translations'],
        'isDiseaseCategory': !exists(json, 'isDiseaseCategory') ? undefined : json['isDiseaseCategory'],
        'organisations': !exists(json, 'organisations') ? undefined : json['organisations'],
    };
}

export function UpsertSocialMediaPostCategoryDtoToJSON(value?: UpsertSocialMediaPostCategoryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'translations': value.translations,
        'isDiseaseCategory': value.isDiseaseCategory,
        'organisations': value.organisations,
    };
}

