import { Language } from "shared/model/languages";

import { Link } from "../../../../../components/link";
import { Text } from "../../../../../components/text";
import { useT } from "../../../../../i18n/use-t";
import { useTenantConfig } from "../../../../../tenant-settings";
import {
  openEmailProgram,
  supportEmail,
} from "../../../../../util/open-email-program";
import { useAuthNavigateState } from "../../../generic/use-auth-navigate-state";

export const CognitoNeedToMigrateAccountNotice: React.FC = () => {
  const config = useTenantConfig();
  const { prefillValues } = useAuthNavigateState();
  const t = useT();

  return (
    <Text
      as="p"
      className="text-center"
      tx="auth.migrate.needToMigrateAccountNotice"
      txData={{
        tenantName: t({ tx: config?.tenantNameTx }),
      }}
      txComponents={{
        RequestNewMigrationLink: (
          <Link
            onClick={() =>
              openEmailProgram({
                to: supportEmail,
                subject:
                  "[ResendAccountMigrationEmail] Please resend my account migration email",
                body: `Dear ${t(
                  {
                    tx: config?.tenantNameTx,
                  },
                  Language.en_US,
                )} team,

please resend my account migration email.


#### Do not modify the information below this line ####
Account: ${prefillValues?.email ?? "unknown"}
HREF: ${window.location.href}
`,
              })
            }
          />
        ),
      }}
    />
  );
};
