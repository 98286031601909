import { FormTextInput } from "../../../../../components/form/form-text-input";
import { FormDataCollectionInput } from "../types";

export const medicationsInput: FormDataCollectionInput = {
  wasCompleted: (user) => !!user.userDetails?.medications,
  Component: ({ errors, register }) => (
    <FormTextInput
      labelClassName="font-bold"
      title={{ tx: "profile.medications" }}
      {...register("medications")}
      error={{ txUnchecked: errors.medications?.message }}
    />
  ),
};
