/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { QuestionNode } from './QuestionNode';
import {
    QuestionNodeFromJSON,
    QuestionNodeFromJSONTyped,
    QuestionNodeToJSON,
} from './QuestionNode';
import type { QuestionnaireGraph } from './QuestionnaireGraph';
import {
    QuestionnaireGraphFromJSON,
    QuestionnaireGraphFromJSONTyped,
    QuestionnaireGraphToJSON,
} from './QuestionnaireGraph';

/**
 * Represents a Embedding record
 * @export
 * @interface Embedding
 */
export interface Embedding {
    /**
     * 
     * @type {string}
     * @memberof Embedding
     */
    id: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof Embedding
     */
    prior: Array<number>;
    /**
     * 
     * @type {QuestionNode}
     * @memberof Embedding
     */
    questionNode?: QuestionNode;
    /**
     * 
     * @type {string}
     * @memberof Embedding
     */
    questionNodeId: string;
    /**
     * 
     * @type {QuestionnaireGraph}
     * @memberof Embedding
     */
    questionnaireGraph?: QuestionnaireGraph;
    /**
     * 
     * @type {string}
     * @memberof Embedding
     */
    questionnaireGraphId: string;
}

/**
 * Check if a given object implements the Embedding interface.
 */
export function instanceOfEmbedding(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "prior" in value;
    isInstance = isInstance && "questionNodeId" in value;
    isInstance = isInstance && "questionnaireGraphId" in value;

    return isInstance;
}

export function EmbeddingFromJSON(json: any): Embedding {
    return EmbeddingFromJSONTyped(json, false);
}

export function EmbeddingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Embedding {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'prior': json['prior'],
        'questionNode': !exists(json, 'questionNode') ? undefined : QuestionNodeFromJSON(json['questionNode']),
        'questionNodeId': json['questionNodeId'],
        'questionnaireGraph': !exists(json, 'questionnaireGraph') ? undefined : QuestionnaireGraphFromJSON(json['questionnaireGraph']),
        'questionnaireGraphId': json['questionnaireGraphId'],
    };
}

export function EmbeddingToJSON(value?: Embedding | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'prior': value.prior,
        'questionNode': QuestionNodeToJSON(value.questionNode),
        'questionNodeId': value.questionNodeId,
        'questionnaireGraph': QuestionnaireGraphToJSON(value.questionnaireGraph),
        'questionnaireGraphId': value.questionnaireGraphId,
    };
}

