import { Text } from "../../../../components/text";

export const CognitoConfirmSignUp: React.FC = () => {
  return (
    <Text
      as="p"
      className="text-center"
      tx="auth.signUp.finishRegistrationProcessWithCustomLink"
    />
  );
};
