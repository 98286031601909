import React from "react";
import { twMerge } from "tailwind-merge";

import { EnumIconConfig } from "./icons/enum-icon";
import { SvgBubble } from "./icons/svg-bubble";
import { IconType } from "./icons/svg-icon";

export const TimelineBubble: React.FC<{
  color: string;
  icon: IconType | EnumIconConfig;
  isButton?: boolean;
}> = ({ color, icon, isButton }) => {
  return (
    <div
      className={twMerge(
        "h-full w-full self-center",
        isButton ? "hover:cursor-pointer" : "",
      )}
    >
      <div className="h-full w-full drop-shadow-lg">
        <SvgBubble color={color} icon={icon} />
      </div>
    </div>
  );
};
