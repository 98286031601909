import React, { useMemo } from "react";
import { EnabledPage } from "shared/model/pages";

import { Iframe } from "./iframe";
import { TranslationFeKey } from "./text";
import { useT } from "../i18n/use-t";
import { pageLockImplementations } from "../page-lock/page-lock";
import { useTenantId } from "../util/use-active-tenant-id";

export const LockGuard: React.FC<{
  page?: EnabledPage;
  children: React.ReactNode;
}> = ({ children, page }) => {
  const { isLocked, reasonWhyLockedIframeUrl } = useIsPageLocked(page);
  const { disease } = useTenantId();

  const t = useT();

  const url = useMemo(
    () =>
      t({
        tx: "tabs.mamaIframeOrigin",
      }) +
      `${t({
        tx: reasonWhyLockedIframeUrl ?? "tabs.locks.defaultTabBlocked",
      })}?=${disease}`,
    [disease, reasonWhyLockedIframeUrl, t],
  );

  return isLocked ? (
    <Iframe url={url} />
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};

export const useIsPageLocked = (
  page?: EnabledPage,
): {
  isLocked: boolean;
  reasonWhyLockedIframeUrl?: TranslationFeKey;
} => {
  const locksMap = new Map<string, boolean>();
  for (const [key, value] of Object.entries(pageLockImplementations)) {
    locksMap.set(key, value(page));
  }

  const isLocked =
    !!page && !page.pageLock.every((lock) => !!locksMap.get(lock.lockType));

  const lockedPageIndex = !!page
    ? page.pageLock.findIndex((lock) => !locksMap.get(lock.lockType))
    : -1;

  return useMemo(
    () =>
      !!page
        ? {
            isLocked,
            reasonWhyLockedIframeUrl:
              lockedPageIndex === -1
                ? undefined
                : page.pageLock[lockedPageIndex].reasonWhyLockedIframeUrl,
          }
        : { isLocked: false },
    [isLocked, lockedPageIndex, page],
  );
};
