import { useMemo } from "react";
import { twMerge } from "tailwind-merge";

import { PlainI18nProps, Text } from "./text";
import { i18nPropsHaveContent } from "../i18n/i18n-props-have-content";

export type LoadingSpinnerProps = {
  as: "spinner" | "dots";
  indicatorClassName?: string;
  message?: PlainI18nProps;
} & JSX.IntrinsicElements["div"];

export const LoadingIndicator: React.FC<LoadingSpinnerProps> = ({
  as,
  className,
  message,
  indicatorClassName,
  ...rest
}) => {
  const hasMessage = useMemo(() => i18nPropsHaveContent(message), [message]);

  return (
    <div
      role="status"
      className={twMerge("flex items-center justify-end", className)}
      {...rest}
    >
      {as === "spinner" ? (
        <span
          className={twMerge(
            "loading loading-spinner loading-md mx-2",
            indicatorClassName,
          )}
        />
      ) : as === "dots" ? (
        <span
          className={twMerge(
            "loading loading-dots loading-md mx-2",
            indicatorClassName,
          )}
        />
      ) : null}
      {hasMessage ? <Text className="text-sm font-light" {...message} /> : null}
    </div>
  );
};
