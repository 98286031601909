import { FC, useState } from "react";
import { twMerge } from "tailwind-merge";

import { PlainI18nProps, Text } from "./text";
import { i18nPropsHaveContent } from "../i18n/i18n-props-have-content";
import { useT } from "../i18n/use-t";

export type TooltipProps = {
  text?: PlainI18nProps;
  tooltipPosition?:
    | "tooltip-right"
    | "tooltip-top"
    | "tooltip-bottom"
    | "tooltip-left";
} & JSX.IntrinsicElements["div"];

export const Tooltip: FC<TooltipProps> = ({
  className,
  text,
  children,
  tooltipPosition = "tooltip",
  ...rest
}) => {
  const t = useT();
  return i18nPropsHaveContent(text) ? (
    <div
      {...rest}
      className={twMerge(tooltipPosition, className)}
      data-tip={t(text)}
    >
      {children}
    </div>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};

interface MultiLineTooltipProps extends TooltipProps {
  tooltipClassName?: string;
}

export const MultiLineTooltip: FC<MultiLineTooltipProps> = ({
  text,
  children,
  className,
  tooltipClassName,
  ...rest
}) => {
  const [isHovering, setIsHovering] = useState(false);

  return i18nPropsHaveContent(text) ? (
    <div
      {...rest}
      onPointerEnter={() => setIsHovering(true)}
      onPointerLeave={() => setIsHovering(false)}
    >
      {children}
      {isHovering && (
        <Text
          className={twMerge(
            "absolute z-10 flex w-max max-w-[280px] rounded-lg bg-slate-200 p-2",
            tooltipClassName,
          )}
          {...text}
        />
      )}
    </div>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};
