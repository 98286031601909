import React, { HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

import { BuySubscriptionButton } from "./buy-subscription-button";
import { CancelSubscriptionsButton } from "./cancel-subscriptions-button";
import { RenewSubscriptionButton } from "./renew-subscription-button";
import { SwitchSubscriptionButton } from "./switch-subscription-button";
import { UpdatePaymentMethodButton } from "./update-payment-method-button";
import { StripeProduct } from "../../api/generated/backend";
import {
  Subscription as SubscriptionModel,
  SubscriptionPeriodType,
} from "../../types/subscription";
import { SvgIcon } from "../icons/svg-icon";
import { Text } from "../text";

export const Subscription: React.FC<{
  subscription: SubscriptionModel;
  customerHasAnySubscription: boolean;
}> = ({
  subscription: {
    price,
    priceId,
    product,
    subscriptionPeriod,
    customerHasSubscription,
    currentSubscriptionEndsAt,
    currentSubscriptionRenewsAt,
    scheduledSubscriptionStartAt,
    customerHasScheduledSubscription,
  },
  customerHasAnySubscription,
}) => {
  return (
    <div
      className={`flex w-[30rem] max-w-[100%] flex-col gap-3 overflow-hidden rounded-lg p-6 md:w-[16rem] ${
        product?.highlighted
          ? "border border-solid border-base-300 bg-base-200"
          : ""
      }`}
    >
      <SubscriptionBanner banner={product?.banner} />
      <SubscriptionTitle title={product?.name} />
      <SubscriptionDetails details={product?.description} />
      <SubscriptionPricing
        price={price}
        subscriptionPeriod={subscriptionPeriod}
      />

      <SubscribeButton
        priceId={priceId}
        product={product}
        customerHasSubscription={customerHasSubscription}
        currentSubscriptionEndsAt={currentSubscriptionEndsAt}
        customerHasAnySubscription={customerHasAnySubscription}
        customerHasActiveCancelledSubscription={!!currentSubscriptionEndsAt}
        customerHasScheduledSubscription={customerHasScheduledSubscription}
      />
      <SubscriptionFeatures features={product?.features} />
      <SubscriptionMessage
        currentSubscriptionEndsAt={currentSubscriptionEndsAt}
        scheduledSubscriptionStartAt={scheduledSubscriptionStartAt}
        currentSubscriptionRenewsAt={currentSubscriptionRenewsAt}
      />
    </div>
  );
};

const SubscriptionBanner: React.FC<{ banner?: string }> = ({ banner }) => {
  return (
    <div
      className={`h-5 leading-5 ${
        banner
          ? "animate-subscription-badge w-fit rounded-lg bg-white px-2 text-sm font-light"
          : "opacity-0"
      }`}
      style={{
        backgroundSize: "400% 400%",
        backgroundImage: `repeating-linear-gradient(
                -45deg,
                hsl(0,0%,100%) 0%, 
                hsl(0,0%,100%) 40%,
                hsl(0,0%,90%) 50%,
                hsl(0,0%,100%) 60%,
                hsl(0,0%,100%) 100%
            )`,
      }}
    >
      {banner}
    </div>
  );
};

const SubscriptionTitle: React.FC<
  { title?: string } & HTMLAttributes<HTMLDivElement>
> = ({ title, className, ...rest }) => {
  return (
    <div className={twMerge("text-xl font-bold", className)} {...rest}>
      {title}
    </div>
  );
};

const SubscriptionDetails: React.FC<{ details?: string | null }> = ({
  details,
}) => {
  return (
    <div className="h-[fit-content] min-h-[4.7rem] hyphens-auto break-words text-base font-light">
      {details}
    </div>
  );
};

const SubscriptionPricing: React.FC<{
  price: string;
  color?: string;
  subscriptionPeriod?: SubscriptionPeriodType;
}> = ({ price, subscriptionPeriod, color = "text-base-content" }) => {
  return (
    <div className="flex flex-row items-end justify-start gap-1 ">
      <div className={`text-4xl font-bold ${color}`}>{price}</div>
      {subscriptionPeriod && (
        <Text className="text-sm" tx={`subscriptions.${subscriptionPeriod}`} />
      )}
    </div>
  );
};

const SubscribeButton: React.FC<{
  priceId: string;
  product: StripeProduct;
  customerHasSubscription: boolean;
  currentSubscriptionEndsAt?: number;
  customerHasAnySubscription: boolean;
  customerHasScheduledSubscription: boolean;
  customerHasActiveCancelledSubscription: boolean;
}> = ({
  priceId,
  product,
  customerHasSubscription,
  currentSubscriptionEndsAt,
  customerHasAnySubscription,
  customerHasScheduledSubscription,
  customerHasActiveCancelledSubscription,
}) => {
  const language = useTranslation().i18n.language;

  return !customerHasAnySubscription && !customerHasScheduledSubscription ? (
    <BuySubscriptionButton priceId={priceId} />
  ) : customerHasActiveCancelledSubscription && currentSubscriptionEndsAt ? (
    <RenewSubscriptionButton
      priceId={priceId}
      language={language}
      currentSubscriptionEndsAt={currentSubscriptionEndsAt}
    />
  ) : customerHasScheduledSubscription || customerHasSubscription ? (
    <>
      <CancelSubscriptionsButton priceId={priceId} />
      <UpdatePaymentMethodButton />
    </>
  ) : (
    <SwitchSubscriptionButton
      priceId={priceId}
      language={language}
      product={product}
    />
  );
};

const SubscriptionFeatures: React.FC<{
  features?: string[];
}> = ({ features }) => {
  return (
    <div className="mt-2 flex flex-col gap-2 text-base">
      {features?.map((feature, index) => (
        <SubscriptionFeature key={index + "_" + feature} feature={feature} />
      ))}
    </div>
  );
};

const SubscriptionFeature: React.FC<{
  feature: string;
}> = ({ feature }) => {
  return (
    <div className="flex flex-row gap-2">
      <div className="h-7 w-7">
        <SvgIcon icon="tag" color="fill-base-content" />
      </div>
      <div className="w-full hyphens-auto break-words font-light text-base-content">
        {feature}
      </div>
    </div>
  );
};

const SubscriptionMessage: React.FC<{
  currentSubscriptionEndsAt?: number;
  scheduledSubscriptionStartAt?: number;
  currentSubscriptionRenewsAt?: number;
}> = ({
  currentSubscriptionEndsAt,
  scheduledSubscriptionStartAt,
  currentSubscriptionRenewsAt,
}) => {
  const language = useTranslation().i18n.language;

  return currentSubscriptionEndsAt ? (
    <Text
      txData={{
        cancelAt: new Date(currentSubscriptionEndsAt * 1000).toLocaleDateString(
          language,
        ),
      }}
      className="text-sm font-medium"
      tx="subscriptions.membershipEndMessage"
    />
  ) : scheduledSubscriptionStartAt ? (
    <Text
      txData={{
        startAt: new Date(
          scheduledSubscriptionStartAt * 1000,
        ).toLocaleDateString(language),
      }}
      className="text-sm font-medium"
      tx="subscriptions.membershipStartMessage"
    />
  ) : currentSubscriptionRenewsAt ? (
    <Text
      txData={{
        renewsAt: new Date(
          currentSubscriptionRenewsAt * 1000,
        ).toLocaleDateString(language),
      }}
      className="text-sm font-medium"
      tx="subscriptions.activeMemebershipMessage"
    />
  ) : null;
};
