/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserSettings } from './UserSettings';
import {
    UserSettingsFromJSON,
    UserSettingsFromJSONTyped,
    UserSettingsToJSON,
} from './UserSettings';

/**
 * Represents a LandbotSettings record
 * @export
 * @interface LandbotSettings
 */
export interface LandbotSettings {
    /**
     * 
     * @type {string}
     * @memberof LandbotSettings
     */
    landbotUrlTx: string;
    /**
     * 
     * @type {string}
     * @memberof LandbotSettings
     */
    landbotSettingsJson?: string;
    /**
     * 
     * @type {UserSettings}
     * @memberof LandbotSettings
     */
    settings?: UserSettings;
    /**
     * 
     * @type {string}
     * @memberof LandbotSettings
     */
    settingsId: string;
}

/**
 * Check if a given object implements the LandbotSettings interface.
 */
export function instanceOfLandbotSettings(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "landbotUrlTx" in value;
    isInstance = isInstance && "settingsId" in value;

    return isInstance;
}

export function LandbotSettingsFromJSON(json: any): LandbotSettings {
    return LandbotSettingsFromJSONTyped(json, false);
}

export function LandbotSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandbotSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'landbotUrlTx': json['landbotUrlTx'],
        'landbotSettingsJson': !exists(json, 'landbotSettingsJson') ? undefined : json['landbotSettingsJson'],
        'settings': !exists(json, 'settings') ? undefined : UserSettingsFromJSON(json['settings']),
        'settingsId': json['settingsId'],
    };
}

export function LandbotSettingsToJSON(value?: LandbotSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'landbotUrlTx': value.landbotUrlTx,
        'landbotSettingsJson': value.landbotSettingsJson,
        'settings': UserSettingsToJSON(value.settings),
        'settingsId': value.settingsId,
    };
}

