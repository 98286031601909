/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Media } from './Media';
import {
    MediaFromJSON,
    MediaFromJSONTyped,
    MediaToJSON,
} from './Media';

/**
 * 
 * @export
 * @interface SocialMediaPost
 */
export interface SocialMediaPost {
    /**
     * 
     * @type {string}
     * @memberof SocialMediaPost
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SocialMediaPost
     */
    body: string;
    /**
     * 
     * @type {boolean}
     * @memberof SocialMediaPost
     */
    liked: boolean;
    /**
     * 
     * @type {number}
     * @memberof SocialMediaPost
     */
    likes: number;
    /**
     * 
     * @type {number}
     * @memberof SocialMediaPost
     */
    comments: number;
    /**
     * 
     * @type {Media}
     * @memberof SocialMediaPost
     */
    media: Media;
    /**
     * 
     * @type {string}
     * @memberof SocialMediaPost
     */
    language: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SocialMediaPost
     */
    categories?: Array<string>;
}

/**
 * Check if a given object implements the SocialMediaPost interface.
 */
export function instanceOfSocialMediaPost(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "body" in value;
    isInstance = isInstance && "liked" in value;
    isInstance = isInstance && "likes" in value;
    isInstance = isInstance && "comments" in value;
    isInstance = isInstance && "media" in value;
    isInstance = isInstance && "language" in value;

    return isInstance;
}

export function SocialMediaPostFromJSON(json: any): SocialMediaPost {
    return SocialMediaPostFromJSONTyped(json, false);
}

export function SocialMediaPostFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocialMediaPost {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'body': json['body'],
        'liked': json['liked'],
        'likes': json['likes'],
        'comments': json['comments'],
        'media': MediaFromJSON(json['media']),
        'language': json['language'],
        'categories': !exists(json, 'categories') ? undefined : json['categories'],
    };
}

export function SocialMediaPostToJSON(value?: SocialMediaPost | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'body': value.body,
        'liked': value.liked,
        'likes': value.likes,
        'comments': value.comments,
        'media': MediaToJSON(value.media),
        'language': value.language,
        'categories': value.categories,
    };
}

