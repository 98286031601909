/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserCountryAndPostalCode
 */
export interface UserCountryAndPostalCode {
    /**
     * 
     * @type {string}
     * @memberof UserCountryAndPostalCode
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCountryAndPostalCode
     */
    country?: string;
}

/**
 * Check if a given object implements the UserCountryAndPostalCode interface.
 */
export function instanceOfUserCountryAndPostalCode(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserCountryAndPostalCodeFromJSON(json: any): UserCountryAndPostalCode {
    return UserCountryAndPostalCodeFromJSONTyped(json, false);
}

export function UserCountryAndPostalCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCountryAndPostalCode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'country': !exists(json, 'country') ? undefined : json['country'],
    };
}

export function UserCountryAndPostalCodeToJSON(value?: UserCountryAndPostalCode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'postalCode': value.postalCode,
        'country': value.country,
    };
}

