import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import { CancelSubscriptionRequest } from "../../api/generated/backend";
import {
  toTenantId,
  toTenantIdHeader,
  useApiMutation,
} from "../../api/use-api";
import { useModal } from "../../models/modal-provider";
import {
  USER_OWNED_ACTIVE_SUBSCRIPTIONS_KEY,
  USER_OWNED_SCHEDULED_SUBSCRIPTIONS_KEY,
} from "../../types/query-keys";
import { useTenantId } from "../../util/use-active-tenant-id";
import { ErrorButton } from "../form/button";

export const CancelSubscriptionsButton: React.FC<{ priceId: string }> = ({
  priceId,
}) => {
  const {
    showModal: showSharedModal,
    hideModal: hideSharedModal,
    updateModal: updateSharedModal,
  } = useModal();
  const queryClient = useQueryClient();
  const tenantId = useTenantId();

  const { mutate: cancelSubscriptions } = useApiMutation(
    "backend",
    (api) => (request: CancelSubscriptionRequest) => {
      updateSharedModal({
        loading: { isLoading: true, message: { tx: "general.loading" } },
        description: {},
        disabled: {
          isCloseButtonDisabled: true,
          isConfirmButtonDisabled: true,
        },
      });
      return api.cancelSubscription(request);
    },
    undefined,
    { successMessage: { tx: "stripe.subscriptionCancelled" } },
    {
      onSuccess: (_, header) => {
        queryClient.invalidateQueries(
          USER_OWNED_ACTIVE_SUBSCRIPTIONS_KEY(toTenantId(header)),
        );
        queryClient.invalidateQueries(
          USER_OWNED_SCHEDULED_SUBSCRIPTIONS_KEY(toTenantId(header)),
        );
      },
      onSettled: () => {
        hideSharedModal();
      },
    },
  );

  const openCancelSubscriptionModal = useCallback(() => {
    showSharedModal({
      title: { tx: "subscriptions.cancelMembership" },
      description: {
        tx: "subscriptions.cancelMembershipExplanation",
      },
      onConfirm: () => {
        cancelSubscriptions({
          cancelSubscriptionDto: { priceId },
          ...toTenantIdHeader(tenantId),
        });
      },
    });
  }, [cancelSubscriptions, priceId, showSharedModal, tenantId]);

  return (
    <ErrorButton
      tx="subscriptions.cancelMembership"
      onClick={openCancelSubscriptionModal}
    />
  );
};
