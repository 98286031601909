import { css } from "@emotion/css";
import React from "react";

import { EnumIcon, EnumIconConfig } from "./enum-icon";
import { IconType, SvgIcon } from "./svg-icon";

export const SvgBubble: React.FC<{
  color: string;
  icon: IconType | EnumIconConfig;
}> = ({ color, icon }) => {
  const svgProps = {
    x: "15",
    y: "15",
    width: "70",
    height: "70",
  };

  const customCss = css`
    position: relative;
    float: left;
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    &:focus {
      outline-width: 0;
    }
  `;

  return (
    <svg viewBox="0 0 100 100" className={customCss}>
      <circle cx="50" cy="50" r="42" style={{ fill: color }} />
      {typeof icon === "string" ? (
        <SvgIcon icon={icon} {...svgProps} color="fill-primary-content" />
      ) : (
        <EnumIcon {...icon} {...svgProps} color="fill-primary-content" />
      )}
    </svg>
  );
};
