"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.customers = void 0;
const mg_at_1 = require("./astrazeneca/mg-at");
const copd_it_1 = require("./chieri/copd-it");
const che_ch_1 = require("./demo/che-ch");
const che_ch_2 = require("./leo-pharma/che-ch");
const cu_it_1 = require("./novartis/cu-it");
const mg_de_1 = require("./ucb/mg-de");
const mg_it_1 = require("./ucb/mg-it");
exports.customers = [
    che_ch_2.leoCheCh,
    cu_it_1.novCuIt,
    mg_de_1.ucbMgDe,
    mg_it_1.ucbMgIt,
    copd_it_1.chiCopdIt,
    mg_at_1.aszMgAT,
    che_ch_1.demoCheCh,
];
