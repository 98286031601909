/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewsStory
 */
export interface NewsStory {
    /**
     * 
     * @type {string}
     * @memberof NewsStory
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NewsStory
     */
    link?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsStory
     */
    body?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsStory
     */
    headline: string;
    /**
     * 
     * @type {string}
     * @memberof NewsStory
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof NewsStory
     */
    imageLink: string;
    /**
     * 
     * @type {string}
     * @memberof NewsStory
     */
    isPublished: string;
    /**
     * 
     * @type {boolean}
     * @memberof NewsStory
     */
    isPinned?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof NewsStory
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof NewsStory
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof NewsStory
     */
    publishedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof NewsStory
     */
    organisation?: NewsStoryOrganisationEnum;
    /**
     * 
     * @type {string}
     * @memberof NewsStory
     */
    language: string;
}


/**
 * @export
 */
export const NewsStoryOrganisationEnum = {
    Ailc: 'ailc',
    Dadi: 'dadi',
    Tanalentamente: 'tanalentamente',
    Dermatiteatopicadiary: 'dermatiteatopicadiary',
    MiasteniaItalia: 'miastenia-italia',
    Aendo: 'aendo',
    NoiConVoi: 'noi-con-voi',
    EndoCare: 'endo-care',
    Endometriosevereinigung: 'endometriosevereinigung'
} as const;
export type NewsStoryOrganisationEnum = typeof NewsStoryOrganisationEnum[keyof typeof NewsStoryOrganisationEnum];


/**
 * Check if a given object implements the NewsStory interface.
 */
export function instanceOfNewsStory(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "headline" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "imageLink" in value;
    isInstance = isInstance && "isPublished" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "language" in value;

    return isInstance;
}

export function NewsStoryFromJSON(json: any): NewsStory {
    return NewsStoryFromJSONTyped(json, false);
}

export function NewsStoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewsStory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'link': !exists(json, 'link') ? undefined : json['link'],
        'body': !exists(json, 'body') ? undefined : json['body'],
        'headline': json['headline'],
        'description': json['description'],
        'imageLink': json['imageLink'],
        'isPublished': json['isPublished'],
        'isPinned': !exists(json, 'isPinned') ? undefined : json['isPinned'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'publishedAt': !exists(json, 'publishedAt') ? undefined : (new Date(json['publishedAt'])),
        'organisation': !exists(json, 'organisation') ? undefined : json['organisation'],
        'language': json['language'],
    };
}

export function NewsStoryToJSON(value?: NewsStory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'link': value.link,
        'body': value.body,
        'headline': value.headline,
        'description': value.description,
        'imageLink': value.imageLink,
        'isPublished': value.isPublished,
        'isPinned': value.isPinned,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'publishedAt': value.publishedAt === undefined ? undefined : (value.publishedAt.toISOString()),
        'organisation': value.organisation,
        'language': value.language,
    };
}

