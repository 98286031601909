import { ChatType, FrontendPageEnum } from "shared/model/pages";

import { toTenantIdHeader, useApiQuery } from "../../api/use-api";
import { usePageSettings } from "../../tenant-settings";
import {
  CHAT_COMPLETENESS_KEY,
  CONVERSATIONS_KEY,
} from "../../types/query-keys";
import { useTenantId } from "../../util/use-active-tenant-id";
import { useUser } from "../../util/use-user";

const AI_CHAT_PERCENTAGE_THRESHOLD = 90;

export const useChatCompletenessLock = (): boolean => {
  const type = usePageSettings(FrontendPageEnum.CHAT);
  if (type?.chatType === ChatType.AI) {
    return useGetMultiAgentMessageCount();
  }
  return useGetLandbotCompleteness();
};
const useGetMultiAgentMessageCount = (): boolean => {
  const tenantId = useTenantId();

  const { data: conversations } = useApiQuery(
    "multiagent",
    (api) => api.getConversations({ mamaDisease: tenantId.disease }),
    CONVERSATIONS_KEY(tenantId.disease),
    undefined,
  );

  const conversationId = conversations?.[0]?.id;

  const { data: chatCompletenessPercentage } = useApiQuery(
    "multiagent",
    (api) =>
      api.getMultiagentChatCompleteness({
        ...toTenantIdHeader(tenantId),
        conversationId: conversationId as string,
      }),
    CHAT_COMPLETENESS_KEY(tenantId.disease),
    undefined,
    { enabled: !!conversationId },
  );

  return (chatCompletenessPercentage || 0) >= AI_CHAT_PERCENTAGE_THRESHOLD;
};

const useGetLandbotCompleteness = (): boolean => {
  const user = useUser();

  return !!user?.settings?.landbotUrlTx;
};
