/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateSocialMediaPostDto
 */
export interface UpdateSocialMediaPostDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateSocialMediaPostDto
     */
    contentBody: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateSocialMediaPostDto
     */
    media?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateSocialMediaPostDto
     */
    language: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateSocialMediaPostDto
     */
    categories?: Array<string>;
}

/**
 * Check if a given object implements the UpdateSocialMediaPostDto interface.
 */
export function instanceOfUpdateSocialMediaPostDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "contentBody" in value;
    isInstance = isInstance && "language" in value;

    return isInstance;
}

export function UpdateSocialMediaPostDtoFromJSON(json: any): UpdateSocialMediaPostDto {
    return UpdateSocialMediaPostDtoFromJSONTyped(json, false);
}

export function UpdateSocialMediaPostDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSocialMediaPostDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contentBody': json['contentBody'],
        'media': !exists(json, 'media') ? undefined : json['media'],
        'language': json['language'],
        'categories': !exists(json, 'categories') ? undefined : json['categories'],
    };
}

export function UpdateSocialMediaPostDtoToJSON(value?: UpdateSocialMediaPostDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contentBody': value.contentBody,
        'media': value.media,
        'language': value.language,
        'categories': value.categories,
    };
}

