import i18next from "i18next";
import { useEffect, useMemo, useState } from "react";

import { toTenantIdHeader, useApiQuery } from "../api/use-api";
import { LoadingScreen } from "../components/loading-screen";
import { Subscription } from "../components/subscription/subscription";
import { Text } from "../components/text";
import { DefaultContentContainer } from "../layouts/default-content-container";
import {
  PRODUCT_PRICES_KEY,
  USER_OWNED_ACTIVE_SUBSCRIPTIONS_KEY,
} from "../types/query-keys";
import { useTenantId } from "../util/use-active-tenant-id";

export const Subscriptions: React.FC = () => {
  const tenantId = useTenantId();
  const [subscriptionInterval, setSubscriptionInterval] = useState<
    StripeSubscriptionInterval | undefined
  >();

  const { data: userOwnedSubscriptions, isLoading: loadingSubscriptions } =
    useApiQuery(
      "backend",
      (api) => api.getUserOwnedSubscriptions(toTenantIdHeader(tenantId)),
      USER_OWNED_ACTIVE_SUBSCRIPTIONS_KEY(tenantId),
    );

  const { data: prices, isLoading: loadingPrices } = useApiQuery(
    "backend",
    (api) => api.getPriceList(toTenantIdHeader(tenantId)),
    PRODUCT_PRICES_KEY(tenantId),
  );

  const userOwnedSubscriptionsIds = useMemo(
    () =>
      Object.values(userOwnedSubscriptions?.activeSubscriptions ?? {}).map(
        (value) => value.id,
      ),
    [userOwnedSubscriptions],
  );

  const userOwnedScheduledSubscriptionsIds = useMemo(
    () =>
      Object.values(userOwnedSubscriptions?.scheduledSubscriptions ?? {}).map(
        (value) => value.price,
      ),
    [userOwnedSubscriptions],
  );

  const availablePrices = useMemo(
    () =>
      prices?.prices.filter(
        (price) =>
          (price.active && price.product.active) ||
          userOwnedSubscriptionsIds.includes(price.id),
      ),
    [prices?.prices, userOwnedSubscriptionsIds],
  );

  const availableIntervals = useMemo(() => {
    return [
      ...new Set(
        availablePrices?.map(
          (price) =>
            (price.recurring?.interval as StripeSubscriptionInterval) || "",
        ),
      ),
    ];
  }, [availablePrices]);

  useEffect(() => {
    const interval = userOwnedSubscriptions?.activeSubscriptions[0]?.interval;
    if (interval) {
      setSubscriptionInterval(interval as StripeSubscriptionInterval);
    } else {
      setSubscriptionInterval(availableIntervals[0]);
    }
  }, [availableIntervals, userOwnedSubscriptions]);

  return !loadingSubscriptions && !loadingPrices ? (
    <DefaultContentContainer>
      <div className="flex gap-10" />
      <SubscriptionIntervalSelector
        availableIntervals={availableIntervals}
        setSubscriptionInterval={setSubscriptionInterval}
        subscriptionInterval={subscriptionInterval}
      />
      <div className="flex flex-row flex-wrap justify-center gap-6">
        {availablePrices?.map(
          (price) =>
            subscriptionInterval &&
            price.recurring?.interval === subscriptionInterval && (
              <Subscription
                key={price.id}
                subscription={{
                  priceId: price.id,
                  product: price.product,
                  price: formatPrice(price),
                  subscriptionPeriod: price.recurring?.interval,
                  customerHasSubscription: userOwnedSubscriptionsIds.includes(
                    price.id,
                  ),
                  customerHasScheduledSubscription:
                    userOwnedScheduledSubscriptionsIds.includes(price.id),
                  currentSubscriptionEndsAt:
                    userOwnedSubscriptions?.activeSubscriptions.find(
                      (plan) => plan.id === price.id,
                    )?.cancelAt,
                  scheduledSubscriptionStartAt:
                    userOwnedSubscriptions?.scheduledSubscriptions.find(
                      (plan) => plan.price === price.id,
                    )?.startDate,
                  currentSubscriptionRenewsAt:
                    userOwnedSubscriptions?.activeSubscriptions.find(
                      (plan) => plan.price === price.id,
                    )?.renewsAt,
                }}
                customerHasAnySubscription={!!userOwnedSubscriptionsIds.length}
              />
            ),
        )}
      </div>
    </DefaultContentContainer>
  ) : (
    <LoadingScreen />
  );
};

enum StripeSubscriptionInterval {
  MONTH = "month",
  YEAR = "year",
}

export const formatPrice = (price: {
  unitAmount?: number | null;
  currency: string;
}): string =>
  price
    ? `${((price.unitAmount || 0) / 100).toLocaleString(i18next.language, {
        style: "currency",
        currency: price.currency.toUpperCase(),
      })}`
    : "";

const SubscriptionIntervalSelector: React.FC<{
  availableIntervals: StripeSubscriptionInterval[];
  subscriptionInterval?: StripeSubscriptionInterval;
  setSubscriptionInterval?: (value: StripeSubscriptionInterval) => void;
}> = ({ setSubscriptionInterval, subscriptionInterval, availableIntervals }) =>
  availableIntervals.length > 1 ? (
    <div className="mb-10 flex items-center justify-center">
      <div className="tabs-boxed flex self-center">
        {availableIntervals.map((interval) => (
          <Text
            key={interval}
            tx={`stripe.${interval}Subscriptions`}
            className={`tab-md tab tab-${
              subscriptionInterval === interval ? "active" : ""
            }`}
            onClick={() => {
              setSubscriptionInterval?.(interval);
            }}
          />
        ))}
      </div>
    </div>
  ) : null;
