/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserDetails
 */
export interface UserDetails {
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    conditionStartDate?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    medications?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    careProvider?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    conditionVariant?: string;
}

/**
 * Check if a given object implements the UserDetails interface.
 */
export function instanceOfUserDetails(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserDetailsFromJSON(json: any): UserDetails {
    return UserDetailsFromJSONTyped(json, false);
}

export function UserDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'conditionStartDate': !exists(json, 'conditionStartDate') ? undefined : json['conditionStartDate'],
        'medications': !exists(json, 'medications') ? undefined : json['medications'],
        'careProvider': !exists(json, 'careProvider') ? undefined : json['careProvider'],
        'conditionVariant': !exists(json, 'conditionVariant') ? undefined : json['conditionVariant'],
    };
}

export function UserDetailsToJSON(value?: UserDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'conditionStartDate': value.conditionStartDate,
        'medications': value.medications,
        'careProvider': value.careProvider,
        'conditionVariant': value.conditionVariant,
    };
}

