import { useState } from "react";

import { Button } from "../../form/button";
import { TextInput } from "../../form/text-input";
import { SvgIcon } from "../../icons/svg-icon";
import { useFilterElementContext } from "../filter-element";
import {
  FilterComponent,
  FilterProperty,
  FilterType,
  PropertyFilterRule,
} from "../types";

export const BodyTextInputFilter: FilterComponent = () => {
  const [filterTextInput, setFilterTextInput] = useState("");
  const { filterConfig, onFilterChange } = useFilterElementContext();
  const isBodyFilterSet = filterConfig?.some(
    (propFilter) => propFilter.property === FilterProperty.BODY,
  );
  return isBodyFilterSet ? (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onFilterChange({
          type: FilterType.FILTER,
          rule: PropertyFilterRule.CONTAINS,
          value: filterTextInput,
          property: FilterProperty.BODY,
        });
      }}
    >
      <div className="flex gap-2">
        <div className="relative">
          <TextInput
            className="pr-12"
            value={filterTextInput}
            onChange={(e) => {
              setFilterTextInput(e.target.value);
            }}
          />
          <SvgIcon
            color="fill-base-300"
            className="absolute right-3 top-0 w-10 cursor-pointer"
            icon="cross"
            onClick={() => {
              setFilterTextInput("");
              onFilterChange({
                value: "",
                type: FilterType.FILTER,
                rule: PropertyFilterRule.CONTAINS,
                property: FilterProperty.BODY,
              });
            }}
          />
        </div>
        <Button type="submit" tx="filters.search" />
      </div>
    </form>
  ) : null;
};
