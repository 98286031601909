import { cognitoPaths } from "./paths";
import { PrimaryButton } from "../../../../components/form/button";
import { useAuthNavigate } from "../../generic/use-auth-navigate";

export const CognitoUpdateEmailInfo: React.FC = () => {
  const navigate = useAuthNavigate();
  return (
    <div className="min-h-16 flex flex-row items-center justify-center self-stretch">
      <PrimaryButton
        tx="profile.emailConfirmedContinueToAppButton"
        className="w-full"
        onClick={() =>
          navigate({
            to: { type: "uri", uri: cognitoPaths.signIn },
          })
        }
      />
    </div>
  );
};
