import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { AuthMessageLevel, AuthNavigateState } from "./types";

export const useAuthNavigateState: () => AuthNavigateState = () => {
  const { state } = useLocation();

  return useMemo(() => {
    const authNavigateState: AuthNavigateState = {};
    if (state) {
      if (typeof state.redirectUrl === "string") {
        authNavigateState.redirectUrl = state.redirectUrl;
      }
      if (
        typeof state.message === "object" &&
        Object.values(AuthMessageLevel).includes(state.message?.level)
      ) {
        authNavigateState.message = {
          level: state.message.level,
          text: state.message.text,
          tx: state.message.tx,
          txUnchecked: state.message.txUnchecked,
          txData: state.message.txData,
        };
      }
      if (
        typeof state.prefillValues === "object" &&
        state.prefillValues != null
      ) {
        authNavigateState.prefillValues = state.prefillValues;
      }
    }
    return authNavigateState;
  }, [state]);
};
