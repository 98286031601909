/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EventDuration
 */
export interface EventDuration {
    /**
     * 
     * @type {number}
     * @memberof EventDuration
     */
    years?: number;
    /**
     * 
     * @type {number}
     * @memberof EventDuration
     */
    months?: number;
    /**
     * 
     * @type {number}
     * @memberof EventDuration
     */
    weeks?: number;
    /**
     * 
     * @type {number}
     * @memberof EventDuration
     */
    days?: number;
    /**
     * 
     * @type {number}
     * @memberof EventDuration
     */
    hours?: number;
    /**
     * 
     * @type {number}
     * @memberof EventDuration
     */
    minutes?: number;
    /**
     * 
     * @type {number}
     * @memberof EventDuration
     */
    seconds?: number;
}

/**
 * Check if a given object implements the EventDuration interface.
 */
export function instanceOfEventDuration(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EventDurationFromJSON(json: any): EventDuration {
    return EventDurationFromJSONTyped(json, false);
}

export function EventDurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventDuration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'years': !exists(json, 'years') ? undefined : json['years'],
        'months': !exists(json, 'months') ? undefined : json['months'],
        'weeks': !exists(json, 'weeks') ? undefined : json['weeks'],
        'days': !exists(json, 'days') ? undefined : json['days'],
        'hours': !exists(json, 'hours') ? undefined : json['hours'],
        'minutes': !exists(json, 'minutes') ? undefined : json['minutes'],
        'seconds': !exists(json, 'seconds') ? undefined : json['seconds'],
    };
}

export function EventDurationToJSON(value?: EventDuration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'years': value.years,
        'months': value.months,
        'weeks': value.weeks,
        'days': value.days,
        'hours': value.hours,
        'minutes': value.minutes,
        'seconds': value.seconds,
    };
}

