import { DataGuardType } from "shared/model/data-collection/guard/data-guard-type";

import { blankGuardImplementation } from "./blank/blank-guard";
import { cardGuardImplementation } from "./card/card-data.guard";
import { chatDataGuardImplementation } from "./chat/chat-data-guard";
import { formDataGuardImplementation } from "./form/form-data-guards";
import { DataGuardImplementation } from "./types";
import { videoGuardImplementation } from "./video/video-guard";

export const dataGuardImplemenetations: Record<
  DataGuardType,
  DataGuardImplementation
> = {
  [DataGuardType.CHAT_COMPLETION_DATA_GUARD]: chatDataGuardImplementation,
  [DataGuardType.FORM_DATA_GUARD]: formDataGuardImplementation,
  [DataGuardType.CARD_DATA_GUARD]: cardGuardImplementation,
  [DataGuardType.VIDEO_GUARD]: videoGuardImplementation,
  [DataGuardType.BLANK_GUARD]: blankGuardImplementation,
};
