import { resetPassword } from "aws-amplify/auth";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CognitoClientMetadata } from "shared/types/cognito-client-metadata";

import { cognitoPaths } from "./paths";
import { SecondaryButton } from "../../../../components/form/button";
import { Form } from "../../../../components/form/form";
import { FormTextInput } from "../../../../components/form/form-text-input";
import { LoadingButton } from "../../../../components/form/loading-button";
import { Link } from "../../../../components/link";
import { Text, tx } from "../../../../components/text";
import { isEmailRegex } from "../../../../util/regex";
import { useTenantId } from "../../../../util/use-active-tenant-id";
import { AuthMessageLevel } from "../../generic/types";
import { useAuthNavigate } from "../../generic/use-auth-navigate";
import { useAuthNavigateState } from "../../generic/use-auth-navigate-state";
import { CognitoError, handleCognitoError } from "../misc/cognito-errors";

export const CognitoResetPassword: React.FC = () => {
  const { prefillValues } = useAuthNavigateState();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<{ email: string }>({ defaultValues: prefillValues });
  const navigate = useAuthNavigate(
    () => ({
      email: getValues("email"),
    }),
    [getValues],
  );
  const { disease, organisation } = useTenantId();
  const {
    i18n: { language },
  } = useTranslation();

  const onSubmitTryResetPassword = handleSubmit(({ email }) => {
    const clientMetadata: CognitoClientMetadata & {
      [key: string]: string;
    } = {
      disease,
      ...(organisation ? { organisation } : {}),
      isLocalhost: process.env.NODE_ENV === "development" ? "1" : "0",
      language,
    };
    resetPassword({ username: email, options: { clientMetadata } })
      .then(() => {
        navigate({
          to: { type: "uri", uri: cognitoPaths.confirmResetPassword },
          state: {
            message: {
              level: AuthMessageLevel.INFO,
              tx: "auth.resetPassword.codeSentToMail",
            },
          },
        });
      })
      .catch((err: CognitoError) => {
        if (err.name === "NotAuthorizedException") {
          // when the user is in a "Force change password" state (after adminCreateUser; only used when migrating users from another tenant)
          navigate({
            to: { type: "uri", uri: cognitoPaths.needToMigrateAccountNotice },
          });
          return;
        }
        navigate({
          to: { type: "uri", uri: cognitoPaths.resetPassword },
          replace: true,
          state: {
            message: {
              level: AuthMessageLevel.ERROR,
              ...handleCognitoError(err),
            },
          },
        });
      });
  });

  return (
    <>
      <Form onSubmit={onSubmitTryResetPassword}>
        <FormTextInput
          title={{ tx: "auth.resetPassword.inputs.email.title" }}
          placeholder={{ tx: "auth.resetPassword.inputs.email.placeholder" }}
          autoComplete="username"
          {...register("email", {
            required: tx("auth.resetPassword.inputs.email.fieldMissingError"),
            pattern: {
              value: isEmailRegex,
              message: tx("auth.resetPassword.inputs.email.notAnEmailError"),
            },
          })}
          error={{ txUnchecked: errors.email?.message }}
        />
        <LoadingButton
          type="submit"
          loading={isSubmitting}
          Button={SecondaryButton}
          tx="auth.resetPassword.resetPasswordButton"
        />
      </Form>
      <div>
        <Text
          as="p"
          tx="auth.navigation.signInInstead"
          txComponents={{
            SignInLink: (
              <Link
                onClick={() =>
                  navigate({ to: { type: "uri", uri: cognitoPaths.signIn } })
                }
              />
            ),
          }}
        />
        <Text
          as="p"
          tx="auth.navigation.signUpInstead"
          txComponents={{
            SignUpLink: (
              <Link
                onClick={() =>
                  navigate({ to: { type: "uri", uri: cognitoPaths.signUp } })
                }
              />
            ),
          }}
        />
      </div>
    </>
  );
};
