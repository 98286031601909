import React from "react";
import { ChatDataGuard } from "shared/model/data-collection/guard/chat-data-guard/chat-data-guard";
import { ChatType, FrontendPageEnum } from "shared/model/pages";

import { ChatGuard } from "./guard/chat-guard";
import { toTenantIdHeader, useApiQuery } from "../../../../api/use-api";
import { Chat } from "../../../../routes/chat";
import { usePageSettings } from "../../../../tenant-settings";
import {
  CHAT_MESSAGE_COUNT_KEY,
  LANDBOT_CHAT_EVENT_COMPLETENESS_KEY,
} from "../../../../types/query-keys";
import { useTenantId } from "../../../../util/use-active-tenant-id";
import { useUser } from "../../../../util/use-user";
import { DataGuardImplementation } from "../types";

export const chatDataGuardImplementation: DataGuardImplementation = {
  useWasCompleted: () => {
    const type = usePageSettings(FrontendPageEnum.CHAT);

    if (type?.chatType === ChatType.AI) {
      return useAiChatCompleteness();
    } else if (type?.chatType === ChatType.LANDBOT) {
      return useLandbotChatCompleteness();
    } else {
      // if something goes wrong, we do not want to block users from accessing the platform
      return true;
    }
  },
  Component: ({ config }) => {
    return (
      <ChatGuard config={config as ChatDataGuard}>
        <Chat />
      </ChatGuard>
    );
  },
};

export const MULTIAGENT_MESSAGE_THRESHOLD = 5;
const useAiChatCompleteness = (): boolean | undefined => {
  const tenantId = useTenantId();
  const user = useUser();

  const { data } = useApiQuery(
    "multiagent",
    (api) => api.getMultiagentMessageCount(toTenantIdHeader(tenantId)),
    CHAT_MESSAGE_COUNT_KEY(tenantId.disease),
  );

  if (data === undefined) return false;

  return (
    data >= MULTIAGENT_MESSAGE_THRESHOLD ||
    user?.userAttributes?.finishedSignUpViaCall
  );
};

export const LANDBOT_EVENT_THRESHOLD = 2;
const useLandbotChatCompleteness = (): boolean | undefined => {
  const tenantId = useTenantId();

  const user = useUser();
  const { data } = useApiQuery(
    "landbot",
    (api) => api.getLandbotChatEventCompleteness(toTenantIdHeader(tenantId)),
    LANDBOT_CHAT_EVENT_COMPLETENESS_KEY(tenantId.disease),
  );

  if (!data) return false;

  return (
    data?.landbotChatComplete >= LANDBOT_EVENT_THRESHOLD ||
    user?.userAttributes?.finishedSignUpViaCall
  );
};
