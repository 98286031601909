import { useCallback, useState } from "react";
import { twMerge } from "tailwind-merge";

import { Dropdown } from "../form/dropdown";
import { Text } from "../text";

const hoursOptions = Array.from({ length: 24 }, (_, i) => ({
  label: { text: i.toString().padStart(2, "0") },
  value: i,
}));

const minutesOptions = Array.from({ length: 60 }, (_, i) => ({
  label: { text: i.toString().padStart(2, "0") },
  value: i,
}));

export interface TimePickerProps {
  className?: string;
  time: { hours: number; minutes: number };
  onChangeTime?: (time: { hours: number; minutes: number }) => void;
}

export const TimePicker: React.FC<TimePickerProps> = ({
  className,
  onChangeTime,
  time,
}) => {
  const [hours, setHours] = useState(time.hours);
  const [minutes, setMinutes] = useState(time.minutes);

  const onChangeHours = useCallback(
    (value: number) => {
      setHours(value);
      onChangeTime?.({ ...time, hours: value });
    },
    [onChangeTime, time],
  );

  const onChangeMinutes = useCallback(
    (value: number) => {
      setMinutes(value);
      onChangeTime?.({ ...time, minutes: value });
    },
    [onChangeTime, time],
  );

  return (
    <div className={twMerge("flex items-center gap-1", className)}>
      <div className="flex-1">
        <Dropdown
          value={hours}
          options={hoursOptions}
          noValueSelected={{ text: "hours" }}
          onChange={onChangeHours}
        />
      </div>

      <Text text=":" className="font-bold" />

      <div className="flex-1">
        <Dropdown
          value={minutes}
          options={minutesOptions}
          noValueSelected={{ text: "minutes" }}
          onChange={onChangeMinutes}
        />
      </div>
    </div>
  );
};
