/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ChatTechnology = {
    Ai: 'AI',
    Landbot: 'Landbot'
} as const;
export type ChatTechnology = typeof ChatTechnology[keyof typeof ChatTechnology];


export function ChatTechnologyFromJSON(json: any): ChatTechnology {
    return ChatTechnologyFromJSONTyped(json, false);
}

export function ChatTechnologyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatTechnology {
    return json as ChatTechnology;
}

export function ChatTechnologyToJSON(value?: ChatTechnology | null): any {
    return value as any;
}

