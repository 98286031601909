import { useEffect, useState } from "react";

import { StripeCard } from "./stripe-card";
import { toTenantIdHeader, useApiQuery } from "../../api/use-api";
import {
  PAYMENT_METHODS_KEY,
  STRIPE_CUSTOMER_KEY,
} from "../../types/query-keys";
import { useTenantId } from "../../util/use-active-tenant-id";
import { LoadingButton } from "../form/loading-button";

export const StripeCardList: React.FC<{
  onConfirm?: (paymentMethodId: string) => void;
  confirmButtonControls: {
    isLoading?: boolean;
    isDisabledIfDefaultPaymentSelected?: boolean;
  };
}> = ({ onConfirm, confirmButtonControls }) => {
  const tenantId = useTenantId();
  const [paymentMethodId, setPaymentMethodId] = useState("");

  const { data: cards } = useApiQuery(
    "backend",
    (api) => api.getCreditCards(toTenantIdHeader(tenantId)),
    PAYMENT_METHODS_KEY(tenantId),
  );

  const { data: stripeCustomer } = useApiQuery(
    "backend",
    (api) => api.getStripeCustomer(toTenantIdHeader(tenantId)),
    STRIPE_CUSTOMER_KEY(tenantId),
  );

  useEffect(() => {
    if (stripeCustomer?.defaultPaymentMethod) {
      setPaymentMethodId(stripeCustomer.defaultPaymentMethod);
    }
  }, [stripeCustomer]);

  return (
    <div className="flex flex-col gap-3">
      {cards?.map((card) => (
        <StripeCard
          card={card}
          key={card.paymentMethodId}
          paymentMethodId={paymentMethodId}
          setPaymentMethodId={setPaymentMethodId}
        />
      ))}
      {onConfirm && (
        <LoadingButton
          onClick={() => onConfirm(paymentMethodId)}
          tx="general.confirm"
          loading={confirmButtonControls.isLoading || false}
          isDisabled={
            !paymentMethodId ||
            (confirmButtonControls.isDisabledIfDefaultPaymentSelected &&
              stripeCustomer?.defaultPaymentMethod === paymentMethodId)
          }
        />
      )}
    </div>
  );
};
