"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AcquisitionSources = void 0;
var AcquisitionSources;
(function (AcquisitionSources) {
    AcquisitionSources["DOCTOR"] = "DOCTOR";
    AcquisitionSources["FRIEND"] = "FRIEND";
    AcquisitionSources["GOOGLE"] = "GOOGLE";
    AcquisitionSources["SOCIAL_MEDIA"] = "SOCIAL_MEDIA";
    AcquisitionSources["ON_THE_NEWS"] = "ON_THE_NEWS";
    AcquisitionSources["PATIENT_ORGANISATION"] = "PATIENT_ORGANISATION";
    AcquisitionSources["OTHER"] = "OTHER";
})(AcquisitionSources || (exports.AcquisitionSources = AcquisitionSources = {}));
