import { useMemo, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

import { Button } from "./button";
import { useDetailsBlur } from "../../util/hooks";
import { SvgIcon } from "../icons/svg-icon";
import { I18nProps, Text } from "../text";

export interface MultiSelectDropdownProps<T> {
  values: T[];
  noValueSelected: I18nProps;
  onChange?: (value: T[]) => void;
  options: { value: T; label: I18nProps }[];
  className?: string;
  disabled?: boolean;
  defaultValueClassName?: string;
  searchResultsOnTop?: boolean;
}

export const MultiSelectDropdown = <T extends string | number>({
  values = [],
  noValueSelected,
  options,
  onChange,
  className,
  disabled,
  defaultValueClassName,
  searchResultsOnTop,
}: MultiSelectDropdownProps<T>): ReturnType<React.FC> => {
  const [selectedValues, setSelectedValues] = useState(values);
  const dropDownRef = useRef<HTMLDetailsElement>(null);

  const label = useMemo(() => {
    return { text: values.join(", ") };
  }, [values]);

  useDetailsBlur(dropDownRef);

  return (
    <details
      className={twMerge(
        "dropdown",
        className,
        disabled ? "pointer-events-none opacity-30" : "",
        searchResultsOnTop ? "dropdown-top" : "dropdown-end",
      )}
      ref={dropDownRef}
    >
      <summary
        className={twMerge("btn-ghost btn w-full", defaultValueClassName)}
      >
        <div className="flex w-full flex-row items-center justify-between gap-1 py-2">
          <Text
            className="text-ellipsis text-start	"
            {...(values.length > 0 ? label : noValueSelected)}
          />
          <SvgIcon icon="down" width={20} height={20} />
        </div>
      </summary>

      <ul className="dropdown-content menu z-10 w-fit rounded-box bg-base-100 p-2 shadow">
        {options.map((option, index) => (
          <Button
            className="btn-ghost btn  m-1 flex w-fit flex-nowrap items-center justify-start whitespace-nowrap"
            type="button"
            key={index}
            onClick={(e) => {
              const updatedValues = selectedValues.includes(option.value)
                ? selectedValues.filter((v) => v !== option.value)
                : [...selectedValues, option.value];

              setSelectedValues(updatedValues);
              onChange?.(updatedValues);
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <input
              type="checkbox"
              checked={selectedValues.includes(option.value)}
              className="checkbox-primary checkbox pointer-events-none bg-base-100"
              readOnly
            />
            <span className="label-text ml-2 truncate">
              <Text {...option.label} />
            </span>
          </Button>
        ))}
      </ul>
    </details>
  );
};
