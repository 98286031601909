import { useEffect, useRef } from "react";

import { DataGuardRouter } from "./data-guard-router";
import { StepsLayout } from "./steps-layout";
import { DataGuardContainer } from "./types";
import { ModalConfig, useModal } from "../../models/modal-provider";

export const DataGuardModalContainer: DataGuardContainer = ({
  children,
  DataGuardConfig,
}) => {
  const modal = useModal();
  const modalIsOpen = useRef(false);

  useEffect(() => {
    if (!DataGuardConfig && modalIsOpen.current) {
      modal.hideModal();
      modalIsOpen.current = false;
    }
    if (DataGuardConfig) {
      const modalConfig: ModalConfig = {
        className: "overflow-scroll",
        showsWindowCloseButton: false,
        closeOnClickOutside: false,
        showCloseButton: false,
        overflowVisible: false,
        children: (
          <StepsLayout
            hideLastStep
            activeIndex={DataGuardConfig.activeIndex}
            titles={DataGuardConfig.titles}
          >
            <DataGuardRouter
              dataGuardRoutes={DataGuardConfig.dataGuardRoutes}
              dataGuardComponents={DataGuardConfig.dataGuardComponents}
              numberOfGuardsCompleted={DataGuardConfig.numberOfGuardsCompleted}
            />
          </StepsLayout>
        ),
      };

      if (!modalIsOpen.current) {
        modal.showModal(modalConfig);
        modalIsOpen.current = true;
      } else {
        modal.updateModal(modalConfig);
      }
    }
  }, [modal, DataGuardConfig]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
