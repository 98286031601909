/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Recurring
 */
export interface Recurring {
    /**
     * 
     * @type {string}
     * @memberof Recurring
     */
    interval: string;
}

/**
 * Check if a given object implements the Recurring interface.
 */
export function instanceOfRecurring(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "interval" in value;

    return isInstance;
}

export function RecurringFromJSON(json: any): Recurring {
    return RecurringFromJSONTyped(json, false);
}

export function RecurringFromJSONTyped(json: any, ignoreDiscriminator: boolean): Recurring {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'interval': json['interval'],
    };
}

export function RecurringToJSON(value?: Recurring | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'interval': value.interval,
    };
}

