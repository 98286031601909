"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PROFILE_COMPLETENESS_MAX_SCORE = exports.ProfileCompletionLevels = exports.getPointsPerProfileCompletionLevel = void 0;
const getPointsPerProfileCompletionLevel = () => {
    return pointsPerProfileCompletionLevel;
};
exports.getPointsPerProfileCompletionLevel = getPointsPerProfileCompletionLevel;
var ProfileCompletionLevels;
(function (ProfileCompletionLevels) {
    ProfileCompletionLevels["CHAT_COMPLETED"] = "CHAT_COMPLETED";
    ProfileCompletionLevels["PROFILE_PICTURE"] = "PROFILE_PICTURE";
    ProfileCompletionLevels["TELEPHONE_NUMBER"] = "TELEPHONE_NUMBER";
    ProfileCompletionLevels["COMPLETE_PROFILE_DATA"] = "COMPLETE_PROFILE_DATA";
})(ProfileCompletionLevels || (exports.ProfileCompletionLevels = ProfileCompletionLevels = {}));
const pointsPerProfileCompletionLevel = {
    [ProfileCompletionLevels.CHAT_COMPLETED]: 40,
    [ProfileCompletionLevels.PROFILE_PICTURE]: 20,
    [ProfileCompletionLevels.TELEPHONE_NUMBER]: 10,
    [ProfileCompletionLevels.COMPLETE_PROFILE_DATA]: 30,
};
exports.PROFILE_COMPLETENESS_MAX_SCORE = Object.values(pointsPerProfileCompletionLevel).reduce((acc, curr) => curr + acc, 0);
