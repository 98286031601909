/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChatTechnology } from './ChatTechnology';
import {
    ChatTechnologyFromJSON,
    ChatTechnologyFromJSONTyped,
    ChatTechnologyToJSON,
} from './ChatTechnology';
import type { EventDetailRecord } from './EventDetailRecord';
import {
    EventDetailRecordFromJSON,
    EventDetailRecordFromJSONTyped,
    EventDetailRecordToJSON,
} from './EventDetailRecord';
import type { EventDuration } from './EventDuration';
import {
    EventDurationFromJSON,
    EventDurationFromJSONTyped,
    EventDurationToJSON,
} from './EventDuration';
import type { EventTime } from './EventTime';
import {
    EventTimeFromJSON,
    EventTimeFromJSONTyped,
    EventTimeToJSON,
} from './EventTime';

/**
 * 
 * @export
 * @interface EventRecord
 */
export interface EventRecord {
    /**
     * 
     * @type {string}
     * @memberof EventRecord
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof EventRecord
     */
    text?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventRecord
     */
    dateWasEstimated?: boolean;
    /**
     * 
     * @type {EventTime}
     * @memberof EventRecord
     */
    time: EventTime;
    /**
     * 
     * @type {EventDuration}
     * @memberof EventRecord
     */
    duration: EventDuration;
    /**
     * 
     * @type {ChatTechnology}
     * @memberof EventRecord
     */
    chatTechnology?: ChatTechnology;
    /**
     * 
     * @type {string}
     * @memberof EventRecord
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof EventRecord
     */
    userId: string;
    /**
     * 
     * @type {Date}
     * @memberof EventRecord
     */
    inputTime: Date;
    /**
     * 
     * @type {Date}
     * @memberof EventRecord
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Array<EventDetailRecord>}
     * @memberof EventRecord
     */
    eventDetails: Array<EventDetailRecord>;
}

/**
 * Check if a given object implements the EventRecord interface.
 */
export function instanceOfEventRecord(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "time" in value;
    isInstance = isInstance && "duration" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "inputTime" in value;
    isInstance = isInstance && "eventDetails" in value;

    return isInstance;
}

export function EventRecordFromJSON(json: any): EventRecord {
    return EventRecordFromJSONTyped(json, false);
}

export function EventRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'dateWasEstimated': !exists(json, 'dateWasEstimated') ? undefined : json['dateWasEstimated'],
        'time': EventTimeFromJSON(json['time']),
        'duration': EventDurationFromJSON(json['duration']),
        'chatTechnology': !exists(json, 'chatTechnology') ? undefined : ChatTechnologyFromJSON(json['chatTechnology']),
        'id': json['id'],
        'userId': json['user_id'],
        'inputTime': (new Date(json['inputTime'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'eventDetails': ((json['eventDetails'] as Array<any>).map(EventDetailRecordFromJSON)),
    };
}

export function EventRecordToJSON(value?: EventRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'text': value.text,
        'dateWasEstimated': value.dateWasEstimated,
        'time': EventTimeToJSON(value.time),
        'duration': EventDurationToJSON(value.duration),
        'chatTechnology': ChatTechnologyToJSON(value.chatTechnology),
        'id': value.id,
        'user_id': value.userId,
        'inputTime': (value.inputTime.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'eventDetails': ((value.eventDetails as Array<any>).map(EventDetailRecordToJSON)),
    };
}

