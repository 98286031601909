import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useState } from "react";

import { StripeCardList } from "./stripe-card-list";
import { CreateActiveSubscriptionRequest } from "../../api/generated/backend";
import {
  toTenantId,
  toTenantIdHeader,
  useApiMutation,
} from "../../api/use-api";
import { Modal } from "../../models/modal-provider";
import { USER_OWNED_ACTIVE_SUBSCRIPTIONS_KEY } from "../../types/query-keys";
import { useTenantId } from "../../util/use-active-tenant-id";

export const StripeSavedPaymentMethodsForm: React.FC<
  Modal & {
    priceId: string;
    setIsPaymentSwitchDisabled: (value: boolean) => void;
  }
> = ({ priceId, hideModal, updateModal, setIsPaymentSwitchDisabled }) => {
  const [isProccessingPayment, setIsProcessingPayment] = useState(false);
  const queryClient = useQueryClient();
  const tenantId = useTenantId();

  const { mutate: createActiveSubscription } = useApiMutation(
    "backend",
    (api) => (request: CreateActiveSubscriptionRequest) =>
      api.createActiveSubscription(request),
    undefined,
    { successMessage: { tx: "stripe.subscriptionPurchased" } },
    {
      onSuccess: (_, header) => {
        queryClient.invalidateQueries(
          USER_OWNED_ACTIVE_SUBSCRIPTIONS_KEY(toTenantId(header)),
        );
      },
      onSettled: () => {
        hideModal();
        setIsProcessingPayment(false);
        setIsPaymentSwitchDisabled(false);
      },
    },
  );

  const handlePayment = useCallback(
    (paymentMethodId: string) => {
      if (!isProccessingPayment) {
        setIsProcessingPayment(true);
        setIsPaymentSwitchDisabled(true);
        updateModal({ disabled: { isCloseButtonDisabled: true } });

        createActiveSubscription({
          createActiveSubscriptionDto: {
            priceId,
            paymentMethodId,
          },
          ...toTenantIdHeader(tenantId),
        });
      }
    },
    [
      createActiveSubscription,
      isProccessingPayment,
      priceId,
      setIsPaymentSwitchDisabled,
      tenantId,
      updateModal,
    ],
  );

  return (
    <StripeCardList
      onConfirm={(paymentMethodId) => handlePayment(paymentMethodId)}
      confirmButtonControls={{
        isLoading: isProccessingPayment,
      }}
    />
  );
};
