import {
  Control,
  Controller,
  Path,
  PathValue,
  Validate,
} from "react-hook-form";

import { DropdownProps } from "./dropdown";
import { FormInput } from "./form-input";
import { MultiSelectDropdown } from "./multi-select-dropdown";
import { PlainI18nProps } from "../text";

export type FormMultiSelectDropdownInputProps<
  Values extends object,
  Name extends Path<Values>,
> = {
  title: PlainI18nProps;
  error: PlainI18nProps;
  disabled?: boolean;
  name: Name;
  control: Control<Values>;
  defaultValue?: PathValue<Values, Name>;
  options: DropdownProps<
    NonNullable<PathValue<Values, Name>[number]>
  >["options"];
  noValueSelected: DropdownProps<PathValue<Values, Name>>["noValueSelected"];
  validate?: Validate<PathValue<Values, Name>, Values>;
  className?: string;
  labelClassName?: string;
  defaultValueClassName?: string;
};

export const FormMultiSelectDropdown = <
  Values extends object,
  Name extends Path<Values>,
>({
  control,
  error,
  name,
  noValueSelected,
  options,
  title,
  className,
  defaultValue,
  disabled,
  labelClassName,
  validate,
  defaultValueClassName,
}: FormMultiSelectDropdownInputProps<Values, Name>): ReturnType<React.FC> => {
  return (
    <FormInput title={title} error={error} labelClassName={labelClassName}>
      <Controller
        name={name}
        control={control}
        rules={{
          validate,
        }}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => {
          return (
            <MultiSelectDropdown
              values={value}
              options={options}
              disabled={disabled}
              onChange={onChange}
              className={className}
              noValueSelected={noValueSelected}
              defaultValueClassName={defaultValueClassName}
            />
          );
        }}
      />
    </FormInput>
  );
};
