import MDEditor from "@uiw/react-md-editor";
import { useLocation } from "react-router-dom";

import { useApiQuery } from "../api/use-api";
import { GhostButton } from "../components/form/button";
import { SocialShareButtons } from "../components/social-share-buttons";
import { Text } from "../components/text";
import { DefaultContentContainer } from "../layouts/default-content-container";
import { NEWS_STORY_KEY } from "../types/query-keys";
import { useNavigateBack } from "../util/navigation-hooks";

export const NewsDetail: React.FC = () => {
  const navigateBack = useNavigateBack();
  const { state: id } = useLocation();
  const { data: newsStory, isError } = useApiQuery(
    "backend",
    (api) => api.getNewsStory({ id: id || "" }),
    NEWS_STORY_KEY(id),
    undefined,
  );

  return (
    <DefaultContentContainer>
      <GhostButton
        icon="left"
        tx="navigateBack"
        onClick={navigateBack}
        className="mb-4 pl-0 "
      />
      <div className="mx-auto max-w-3xl overflow-auto p-5">
        {isError ? (
          <Text
            as="p"
            tx="news.noNewsArticleFound"
            className="text-center text-2xl"
          />
        ) : (
          <figure className="flex flex-col">
            <img
              className="mb-14 max-w-md self-center rounded-lg object-cover object-center shadow-md"
              src={newsStory?.imageLink}
              alt={newsStory?.headline}
            />
            <SocialShareButtons />
            <Text
              className="my-5 text-2xl font-bold"
              text={newsStory?.headline}
            />
            <div data-color-mode="light">
              <MDEditor.Markdown source={newsStory?.body} />
            </div>
          </figure>
        )}
      </div>
    </DefaultContentContainer>
  );
};
