import { Image } from "../../form/form-image-input";
import { Text } from "../../text";

export const MediaEditor: React.FC<{
  images?: Image[];
  audio?: string;
  onRemoveAudio: () => void;
  onRemoveImage: (image: Image) => void;
}> = ({ images, audio, onRemoveImage, onRemoveAudio }) => {
  return images?.length || audio?.length ? (
    <div className="flex w-full flex-col items-center justify-center gap-5">
      {audio?.length ? (
        <EditableAudio onRemoveAudio={onRemoveAudio} audio={audio} />
      ) : null}

      {images?.length ? (
        <EditableImages onRemoveImage={onRemoveImage} images={images} />
      ) : null}
    </div>
  ) : null;
};

export const EditableImages: React.FC<{
  images?: Image[];
  onRemoveImage: (image: Image) => void;
}> = ({ images, onRemoveImage }) => {
  return images && images.length ? (
    <div className="flex w-full flex-row-reverse gap-5 overflow-x-auto p-5">
      {images?.map((image) => (
        <div className="indicator" key={image.name}>
          <span
            onClick={() => onRemoveImage(image)}
            className="badge badge-error indicator-item cursor-pointer"
          >
            {"X"}
          </span>
          <div className="h-40 w-40">
            <img className="h-full w-full" src={image.image} />
          </div>
        </div>
      ))}
    </div>
  ) : null;
};

export const EditableAudio: React.FC<{
  audio?: string;
  onRemoveAudio: () => void;
}> = ({ audio, onRemoveAudio }) => {
  return audio ? (
    <figure className="indicator flex flex-col items-center">
      <span
        onClick={onRemoveAudio}
        className="badge badge-error indicator-item cursor-pointer"
      >
        {"X"}
      </span>
      <audio className="max-w-[200px]" src={audio} controls />

      <Text
        className="mt-2 max-w-[180px] text-center text-xs text-slate-500"
        tx="chat.canTakeLonger"
      />
    </figure>
  ) : null;
};
