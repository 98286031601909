import i18n, { TFunction } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { allLanguages, Language } from "shared/model/languages";

import { getCurrentScriptFileNameOrDate } from "../util/get-current-script-file-name-or-date";

export const initI18n = async (): Promise<TFunction> => {
  const instance = i18n
    .use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next);

  const t = await instance.init({
    // We purposfully don't use the tenant languages here in order to ensure
    // English is always technically supported as a fallback
    supportedLngs: allLanguages,
    load: "all",
    preload: allLanguages,
    ns: ["translations"],
    defaultNS: "translations",
    interpolation: {
      // React is XSS-safe already
      escapeValue: false,
    },
    compatibilityJSON: "v4",
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?v=${encodeURIComponent(
        getCurrentScriptFileNameOrDate(),
      )}`,
    },
    detection: {
      order: ["querystring", "localStorage", "navigator", "htmlTag"],
      caches: ["localStorage"],
    },
    fallbackLng: setFallbackLanguage,
  });

  return t;
};

const setFallbackLanguage = (language: string) => {
  switch (language) {
    case Language.de_CH:
      return [Language.de_DE, Language.en_US];
    case Language.de_DE:
      return [Language.de_CH, Language.en_US];
    case Language.es_CO:
      return [Language.es_ES, Language.en_US];
    case Language.es_ES:
      return [Language.es_CO, Language.en_US];
    default:
      return Language.en_US;
  }
};
