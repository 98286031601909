import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useState } from "react";

import { StripeElementsProvider } from "./stripe-elements-provider";
import {
  AttachPaymentMethodRequest,
  UpdateDefaultPaymentMethodRequest,
} from "../../api/generated/backend";
import {
  toTenantId,
  toTenantIdHeader,
  useApiMutation,
} from "../../api/use-api";
import { useStore } from "../../models/helpers";
import {
  PAYMENT_METHODS_KEY,
  STRIPE_CUSTOMER_KEY,
} from "../../types/query-keys";
import { useTenantId } from "../../util/use-active-tenant-id";
import { PlainError } from "../events/plain-error";
import { LoadingButton } from "../form/loading-button";

export const StripeCollectCardInformationForm: React.FC = () => {
  return (
    <StripeElementsProvider type="card">
      <CardForm />
    </StripeElementsProvider>
  );
};

const CardForm: React.FC = () => {
  const tenantId = useTenantId();
  const store = useStore();
  const stripe = useStripe();
  const elements = useElements();
  const [isLoadingCards, setIsLoadingCards] = useState(false);
  const queryClient = useQueryClient();

  const { mutate: updateDefaultPaymentMethod } = useApiMutation(
    "backend",
    (api) => (request: UpdateDefaultPaymentMethodRequest) =>
      api.updateDefaultPaymentMethod(request),
    undefined,
    { successMessage: { tx: "stripe.cardUpdated" } },
    {
      onSuccess: (_, header) => {
        queryClient.invalidateQueries(STRIPE_CUSTOMER_KEY(toTenantId(header)));
        queryClient.invalidateQueries(PAYMENT_METHODS_KEY(toTenantId(header)));
      },
    },
  );

  const { mutate: attachPaymentMethod } = useApiMutation(
    "backend",
    (api) => (request: AttachPaymentMethodRequest) =>
      api.attachPaymentMethod(request),
    undefined,
    undefined,
    {
      onSuccess: (_, header) => {
        queryClient.invalidateQueries(PAYMENT_METHODS_KEY(toTenantId(header)));
      },
      onSettled: (res, _, header) => {
        const defaultPaymentMethodId = res?.paymentMethodId;
        if (defaultPaymentMethodId) {
          updateDefaultPaymentMethod({
            ...header,
            defaultPaymentMethodDto: {
              defaultPaymentMethodId,
            },
          });
        }
        setIsLoadingCards(false);
      },
    },
  );

  const submitCardInformation = useCallback(async () => {
    if (isLoadingCards) return;
    try {
      setIsLoadingCards(true);
      const cardElement = elements?.getElement(CardElement);
      if (!stripe || !elements || !cardElement) {
        return store.addToastEvent(
          new PlainError({ tx: "stripe.error.couldNotLoadCardElement" }),
        );
      }
      const { error: submitError, paymentMethod } =
        await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });

      if (submitError) {
        setIsLoadingCards(false);
        return store.addToastEvent(
          new PlainError({ text: submitError.message }),
        );
      }

      attachPaymentMethod({
        attachPaymentMethodDto: { paymentMethodId: paymentMethod.id },
        ...toTenantIdHeader(tenantId),
      });
      cardElement.clear();
    } catch {
      new PlainError({ tx: "stripe.error.cardFormError" });
    }
  }, [isLoadingCards, elements, stripe, attachPaymentMethod, tenantId, store]);

  return (
    <>
      <CardElement options={{ hidePostalCode: true }} />
      <LoadingButton
        tx="stripe.addCard"
        loading={isLoadingCards}
        onClick={submitCardInformation}
      />
    </>
  );
};
