import { FormDateInput } from "../../../../../components/form/form-date-input";
import { FormDataCollectionInput } from "../types";

export const conditionStartDateInput: FormDataCollectionInput = {
  wasCompleted: (user) => !!user.userDetails?.conditionStartDate,
  Component: ({ errors, control }) => (
    <FormDateInput
      labelClassName="font-bold"
      title={{ tx: "profile.conditionStartDate" }}
      error={{ txUnchecked: errors.conditionStartDate?.message }}
      control={control}
      name="conditionStartDate"
    />
  ),
};
