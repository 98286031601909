import { useMemo } from "react";
import { SupportedGroupsEnum } from "shared/model/cognito";
import { EnabledPage } from "shared/model/pages";

import { toTenantIdHeader, useApiQuery } from "../../api/use-api";
import { ME_KEY } from "../../types/query-keys";
import { useTenantId } from "../../util/use-active-tenant-id";

export const useGroupLock = (page?: EnabledPage): boolean => {
  const tenantId = useTenantId();
  const { data: user } = useApiQuery(
    "backend",
    (api) => api.getMe(toTenantIdHeader(tenantId)),
    ME_KEY(tenantId),
  );

  return !!useMemo(
    () =>
      !page ||
      user?.tenantGroups.some((group) =>
        page.groupsWhichCanSeeThePage.includes(group as SupportedGroupsEnum),
      ),
    [page, user],
  );
};
