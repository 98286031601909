import { useEffect } from "react";

import { useCognitoConfiguration } from "./use-cognito-configuration";
import { useAuthStateStore } from "../../../auth-state-store-context";
import { AuthStateEnum } from "../../generic/types";
import { useAuthNavigate } from "../../generic/use-auth-navigate";
import { routesWithoutAutoSignInForward } from "../routes";

export const useCognitoAutoSignIn = (): void => {
  const configurationState = useCognitoConfiguration();
  const navigate = useAuthNavigate();
  const authStateStore = useAuthStateStore();

  useEffect(() => {
    if (
      configurationState === "ready" &&
      !routesWithoutAutoSignInForward.some((route) =>
        window.location.pathname.endsWith(route),
      )
    ) {
      authStateStore.setState(AuthStateEnum.LOADING);
      authStateStore
        .probeIfUserIsSignedIn()
        .then(() => navigate({ to: { type: "redirect_url" }, replace: true }))
        .catch(() => {});
    }
  }, [authStateStore, configurationState, navigate]);
};
