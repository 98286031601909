import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Language } from "shared/model/languages";
import { EnabledPage } from "shared/model/pages";

import { SupportedLanguagesDropdown } from "./form/language/supported-languages-dropdown";

export const LanguageSelector: React.FC<{
  className?: string;
  currentPage?: EnabledPage;
}> = ({ className, currentPage }) => {
  const {
    i18n: { language },
  } = useTranslation();
  return (
    <SupportedLanguagesDropdown
      currentPage={currentPage}
      value={language as Language}
      onChange={(nextLanguage) => {
        i18next.changeLanguage(nextLanguage);
      }}
      className={className}
    />
  );
};
