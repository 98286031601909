import * as Sentry from "@sentry/react";
import {
  MutationFunction,
  MutationKey,
  QueryKey,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { TenantIdentifier } from "shared/config";
import { Disease } from "shared/model/diseases";
import { Organisation } from "shared/model/organisations";
import { v4 as uuidv4 } from "uuid";

import * as BackendClient from "./generated/backend";
import * as LandbotClient from "./generated/landbot";
import * as MultiagentClient from "./generated/multiagent";
import { ApiClient, ApiClientService, apiUrl } from "./types/api";
import { useAuthStore } from "../auth/auth-store-context";
import { FetchError } from "../components/events/fetch-error";
import { RequestError } from "../components/events/request-error";
import { SentryError } from "../components/events/sentry-error";
import { SuccessEvent } from "../components/events/success-event";
import { I18nProps } from "../components/text";
import { useStore } from "../models/helpers";

// Note: these are hard coded in different places. When changing them, please search for occurences of the values!
export const traceIdHeader = "mama-trace-id";
export const frontendPathHeader = "mama-frontend-path";
export const apiGwIdHeader = "apigw-requestid";

export type ContextHeaders = {
  Authorization: string;
} & {
  [traceIdHeader]: string;
  [frontendPathHeader]: string;
  [apiGwIdHeader]: string;
};

interface ApiClientConfig {
  successMessage?: I18nProps;
  trackErrors?: boolean;
}

export const useApiQuery = <
  TClient extends ApiClientService,
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey & Array<unknown> = QueryKey & Array<unknown>,
>(
  client: TClient,
  callApi: (api: ApiClient<TClient>) => Promise<TQueryFnData>,
  queryKey: TQueryKey,
  config: ApiClientConfig = {},
  options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<TData, TError> => {
  // WARNING: Even if `enabled` is set to false, the api client is stil initialized, calling the `useAuthStore` hook
  const apiClient = useApiClient(client, config);

  return useQuery(queryKey, () => callApi(apiClient), {
    ...options,
    enabled: options?.enabled,
  });
};

export const useApiMutation = <
  TClient extends ApiClientService,
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  client: TClient,
  getMutationFunction: (
    api: ApiClient<TClient>,
  ) => MutationFunction<TData, TVariables>,
  mutationKey?: MutationKey & Array<unknown>,
  config: ApiClientConfig = {},
  options?: Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    "mutationKey" | "mutationFn"
  >,
): UseMutationResult<TData, TError, TVariables, TContext> => {
  const apiClient = useApiClient(client, config, mutationKey);

  return useMutation(getMutationFunction(apiClient), {
    ...options,
    mutationKey,
  });
};

const useApiClient = <TClient extends ApiClientService>(
  client: TClient,
  { successMessage, trackErrors = true }: ApiClientConfig = {},
  eventId?: readonly unknown[],
): ApiClient<TClient> => {
  const { getValidJwtToken: ensureValidJwtToken } = useAuthStore();
  const store = useStore();
  const captureError = useCallback((error: SentryError) => {
    const _id = Sentry.captureException(error);

    // if (appEnv == DeploymentEnvironment.DEV) {
    //   const plainError = new PlainError(
    //     { text: error.message },
    //     error.stack,
    //     error.details,
    //   );

    //   plainError.sentryEventId = id;

    //   store.addToastEvent(plainError);
    // }
  }, []);

  const defaultApi = useMemo(() => {
    const config: BackendClient.ConfigurationParameters = {
      credentials: "same-origin",
      basePath: apiUrl[client],
      middleware: [
        {
          pre: async (ctx: BackendClient.RequestContext): Promise<void> => {
            const jwtToken = await ensureValidJwtToken();
            const path = window.location.pathname;

            ctx.init.headers = {
              Authorization: `Bearer ${jwtToken}`,
              ...ctx.init.headers,
              [traceIdHeader]: uuidv4(),
              [frontendPathHeader]: path,
            };
          },
          post: async (context) => {
            if (context.response.ok) {
              if (successMessage) {
                store.addToastEvent(
                  new SuccessEvent(successMessage, eventId?.toString()),
                );
              } else if (eventId) {
                store.removeToastEvent(eventId.toString());
              }
            }

            if (!context.response.ok && trackErrors) {
              let response: object | undefined = undefined;

              try {
                response = await context.response.json();
              } catch {
                /* empty */
              }

              captureError(new RequestError(context, response));
            }
          },
          onError: trackErrors
            ? async (context) => {
                captureError(new FetchError(context));
              }
            : undefined,
        },
      ],
    };

    return (
      client === "backend"
        ? new BackendClient.DefaultApi(new BackendClient.Configuration(config))
        : client === "multiagent"
        ? new MultiagentClient.DefaultApi(
            new MultiagentClient.Configuration(config),
          )
        : new LandbotClient.DefaultApi(new LandbotClient.Configuration(config))
    ) as ApiClient<TClient>;
  }, [
    client,
    eventId,
    ensureValidJwtToken,
    store,
    captureError,
    successMessage,
    trackErrors,
  ]);

  return defaultApi;
};

export type TenantIdHeader = {
  mamaDisease: BackendClient.GetMeMamaDiseaseEnum;
  mamaOrganisation?: BackendClient.GetMeMamaOrganisationEnum;
};

export const toTenantIdHeader = (
  tenantId: TenantIdentifier,
): TenantIdHeader => ({
  mamaDisease: tenantId.disease,
  mamaOrganisation: tenantId.organisation,
});

export const toTenantId = (tenantId: {
  mamaDisease: string;
  mamaOrganisation?: string;
}): TenantIdentifier => ({
  disease: tenantId.mamaDisease as Disease,
  organisation: tenantId.mamaOrganisation as Organisation | undefined,
});

export const toTenantDisease = (tenantId: {
  mamaDisease: string;
  mamaOrganisation?: string;
}): TenantIdentifier => ({
  disease: tenantId.mamaDisease as Disease,
});
