import { useQueryClient } from "@tanstack/react-query";
import { LatLngExpression } from "leaflet";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { MedicalSpecialty } from "shared/types/medical-specialty";
import { twMerge } from "tailwind-merge";

import { AddressInputField } from "./address-input-field";
import { DoctorList } from "./doctor-list";
import { Pin } from "./map-pin";
import { PinPopup } from "./pin-popup";
import { useMapLocation, useMyMapLocation } from "./use-map-location";
import {
  DeleteDoctorRequest,
  Doctor,
  DoctorGeneralMedicalSpecialityEnum,
  GetMarkersRequest,
  SaveDoctorRequest,
} from "../../api/generated/backend";
import { useApiMutation, useApiQuery } from "../../api/use-api";
import { useStore } from "../../models/helpers";
import { ModalConfig, useModal } from "../../models/modal-provider";
import { MY_DOCTORS } from "../../types/query-keys";
import { useTenantId } from "../../util/use-active-tenant-id";
import { PlainError } from "../events/plain-error";
import { NeutralButton } from "../form/button";
import { Dropdown, DropdownProps } from "../form/dropdown";
import { TextInput } from "../form/text-input";
import { LoadingScreen } from "../loading-screen";
import { Slider } from "../slider/slider";

const options: DropdownProps<MedicalSpecialty>["options"] = [
  {
    label: {
      tx: `networkBuilder.${MedicalSpecialty.Allgemeinmedizin}`,
    },
    value: MedicalSpecialty.Allgemeinmedizin,
  },
  {
    label: {
      tx: `networkBuilder.${MedicalSpecialty.Physiotherapeut}`,
    },
    value: MedicalSpecialty.Physiotherapeut,
  },
  {
    label: {
      tx: `networkBuilder.${MedicalSpecialty.Neurologie}`,
    },
    value: MedicalSpecialty.Neurologie,
  },
  {
    label: {
      tx: `networkBuilder.${MedicalSpecialty.Ms}`,
    },
    value: MedicalSpecialty.Ms,
  },
];

export const Map: React.FC = () => {
  const [radiusInKm, setRadiusInKm] = useState(5);
  const [markers, setMarkers] = useState<Doctor[]>([]);
  const [flyToLocation, setFlyToLocation] = useState<LatLngExpression>();
  const [areSearchFieldsHidden, setAreSearchFieldsHidden] = useState(false);

  const queryClient = useQueryClient();
  const tenandId = useTenantId();
  const { showModal, hideModal } = useModal();

  const showLoadingModal = useCallback(() => {
    showModal({
      className: "flex justify-center items-center h-24",
      title: { tx: "networkBuilder.lookingForDoctors" },
      showCloseButton: false,
    });
  }, [showModal]);

  const { data: myDoctors } = useApiQuery(
    "backend",
    (api) =>
      api.getMyDoctors({
        mamaDisease: tenandId.disease,
        mamaOrganisation: tenandId.organisation,
      }),
    MY_DOCTORS(tenandId.disease),
  );

  const { mutate: deleteDoctor } = useApiMutation(
    "backend",
    (api) => (request: DeleteDoctorRequest) => api.deleteDoctor(request),
    undefined,
    undefined,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(MY_DOCTORS(tenandId.disease));
        getMarkers();
      },
      onSettled: hideModal,
    },
  );

  const {
    location: myDoctorLocation,
    coordinates: myDoctorCoordinates,
    setLocation: setMyDoctorLocation,
    updateCoordinatesBasedOnAddress: updateMyDoctorCoordinatesBasedOnAddress,
  } = useMapLocation();

  const {
    myLocation,
    myCoordinates,
    setMyLocation,
    updateMyCoordinatesBasedOnAddress,
  } = useMyMapLocation();

  const updateMyCoordinates = useCallback(async () => {
    return updateMyCoordinatesBasedOnAddress(myLocation);
  }, [myLocation, updateMyCoordinatesBasedOnAddress]);

  const updateMyDoctorCoordinates = useCallback(async () => {
    return updateMyDoctorCoordinatesBasedOnAddress(myDoctorLocation);
  }, [myDoctorLocation, updateMyDoctorCoordinatesBasedOnAddress]);

  const MyMarker = useMemo(
    () =>
      myCoordinates?.[0] && myCoordinates?.[1] ? (
        <Pin
          position={myCoordinates}
          radius={radiusInKm}
          iconUrl="/resources/person.svg"
        />
      ) : null,
    [myCoordinates, radiusInKm],
  );

  const MyDoctorMarker = useMemo(
    () =>
      myDoctorCoordinates ? (
        <Pin iconUrl="/resources/doctor.svg" position={myDoctorCoordinates} />
      ) : null,
    [myDoctorCoordinates],
  );

  const { mutateAsync, isLoading } = useApiMutation(
    "backend",
    (api) => (request: GetMarkersRequest) => {
      showLoadingModal();
      return api.getMarkers(request);
    },
  );

  const getMarkers = useCallback(() => {
    if (myCoordinates?.[0] && myCoordinates?.[1]) {
      mutateAsync(
        {
          mamaDisease: tenandId.disease,
          getMarkersDto: {
            latitude: myCoordinates[0],
            longitude: myCoordinates[1],
            radius: radiusInKm,
          },
        },
        {
          onSuccess: setMarkers,
        },
      );
    }
  }, [mutateAsync, myCoordinates, radiusInKm, tenandId.disease]);

  const savedDoctorsMarkers = useMemo(
    () =>
      myDoctors
        ? myDoctors.map((doctor) => (
            <Pin
              key={doctor.id}
              iconUrl={getIcon(doctor.generalMedicalSpeciality)}
              position={[doctor.lat, doctor._long] as [number, number]}
            >
              <PinPopup
                id={doctor.id}
                onDeleteDoctor={getMarkers}
                isLinked={doctor.isLinked ?? false}
                showLinkDoctorButton={doctor.isSaved}
                title={{ text: doctor.doctorName }}
                type={doctor.generalMedicalSpeciality}
                description={{ text: doctor.practiceName }}
              />
            </Pin>
          ))
        : null,
    [getMarkers, myDoctors],
  );

  useEffect(() => {
    if (!isLoading) {
      hideModal();
    }
  }, [hideModal, isLoading]);

  useEffect(() => {
    if (myCoordinates?.[0] && myCoordinates?.[1]) {
      getMarkers();
    }
  }, [getMarkers, mutateAsync, myCoordinates]);

  useEffect(() => {
    if (myDoctorCoordinates) {
      setFlyToLocation(myDoctorCoordinates);
    }
  }, [myDoctorCoordinates]);

  useEffect(() => {
    if (myCoordinates) {
      setFlyToLocation(myCoordinates);
    }
  }, [myCoordinates, myDoctorCoordinates]);

  const MemoizedMap = useMemo(() => {
    return (
      <div className="relative h-full">
        <DoctorList
          doctors={myDoctors}
          onDeleteButtonClick={deleteDoctor}
          onGoToButtonClick={setFlyToLocation}
        />
        <MapContainer
          zoom={12}
          minZoom={5}
          scrollWheelZoom
          center={myCoordinates}
          style={{
            height: "100%",
            marginTop: "12px",
            borderTopLeftRadius: "15px",
            borderTopRightRadius: "15px",
          }}
        >
          <FlyToLocation location={flyToLocation} />
          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}@2x.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <MarkerClusterGroup chunkedLoading>
            {markers?.map((marker, idx) => (
              <Pin
                iconUrl={getIcon(marker.generalMedicalSpeciality)}
                key={`${marker.lat}-${marker._long}-${idx}`}
                position={[marker.lat, marker._long]}
              >
                <PinPopup
                  id={marker.id}
                  onLinkDoctor={getMarkers}
                  phoneNumbers={marker.phones}
                  isLinked={marker.isLinked ?? false}
                  title={{ text: marker.doctorName }}
                  type={marker.generalMedicalSpeciality}
                  description={{ text: marker.practiceName }}
                />
              </Pin>
            ))}
            {MyDoctorMarker}
            {savedDoctorsMarkers}
          </MarkerClusterGroup>
          {MyMarker}
        </MapContainer>
      </div>
    );
  }, [
    getMarkers,
    deleteDoctor,
    markers,
    MyMarker,
    myDoctors,
    myCoordinates,
    flyToLocation,
    MyDoctorMarker,
    savedDoctorsMarkers,
  ]);

  return myCoordinates && markers ? (
    <div className="flex h-full flex-col">
      <div className="relative">
        <div
          className={twMerge(
            "transition-height h-full duration-500 ease-in-out",
            areSearchFieldsHidden ? "max-h-0" : "max-h-screen",
          )}
        >
          <MapSearchFields
            radiusInKm={radiusInKm}
            setFlyToLocation={setFlyToLocation}
            setMyDoctorLocation={setMyDoctorLocation}
            setMyLocation={setMyLocation}
            setRadiusInKm={setRadiusInKm}
            updateMyCoordinates={updateMyCoordinates}
            myLocation={myLocation}
            myDoctorLocation={myDoctorLocation}
            updateMyDoctorCoordinates={updateMyDoctorCoordinates}
          />
        </div>
        <NeutralButton
          iconColor="fill-base-100"
          icon={areSearchFieldsHidden ? "down" : "up"}
          onClick={() => setAreSearchFieldsHidden(!areSearchFieldsHidden)}
          className="absolute left-[50%] right-[50%] z-[10008] w-16"
        />
      </div>
      {MemoizedMap}
    </div>
  ) : (
    <LoadingScreen />
  );
};

const MapSearchFields: React.FC<{
  radiusInKm: number;
  myLocation: string;
  myDoctorLocation: string;
  myDoctorCoordinates?: [number, number];
  setRadiusInKm: (value: number) => void;
  setMyLocation: (value: string) => void;
  setFlyToLocation: (coords: LatLngExpression) => void;
  updateMyCoordinates: () => void;
  setMyDoctorLocation: (value: string) => void;
  updateMyDoctorCoordinates: () => void;
}> = ({
  radiusInKm,
  myLocation,
  myDoctorLocation,
  setRadiusInKm,
  setMyLocation,
  setFlyToLocation,
  updateMyCoordinates,
  setMyDoctorLocation,
  updateMyDoctorCoordinates,
}) => {
  const [radiusInKmInternal, setRadiusInKmInternal] = useState(radiusInKm);

  const { showModal, hideModal, updateModal } = useModal();

  return (
    <div className="relative">
      <div className="flex flex-col gap-8 p-5 xl:flex-row">
        <Slider
          min={5}
          max={120}
          label={{ text: "Km" }}
          value={radiusInKmInternal}
          className="w-full flex-[0.35]"
          onChange={setRadiusInKmInternal}
          onSubmitValue={setRadiusInKm}
        />
        <AddressInputField
          label={{ tx: "networkBuilder.myLocation" }}
          value={myLocation}
          onChange={setMyLocation}
          onConfirm={updateMyCoordinates}
        />
        <AddressInputField
          label={{ tx: "networkBuilder.myDoctorLocation" }}
          value={myDoctorLocation}
          onChange={setMyDoctorLocation}
          onConfirm={() => {
            showModal({
              confirmButtonTx: "general.yes",
              closeButtonTx: "general.no",
              title: { tx: "networkBuilder.doYouWantToSaveDoctor" },
              description: { tx: "networkBuilder.saveYourDoctorExplanaiton" },
              onClose: updateMyDoctorCoordinates,
              onConfirm: () => {
                showModal({
                  children: (
                    <SaveDoctorForm
                      hideModal={hideModal}
                      updateModal={updateModal}
                      setFlyTo={setFlyToLocation}
                      doctorAddress={myDoctorLocation}
                    />
                  ),
                });
              },
            });
          }}
        />
      </div>
    </div>
  );
};

const SaveDoctorForm: React.FC<{
  doctorAddress: string;
  hideModal: () => void;
  updateModal: (config: ModalConfig) => void;
  setFlyTo: (coords: LatLngExpression) => void;
}> = ({ hideModal, updateModal, doctorAddress, setFlyTo }) => {
  const store = useStore();

  const [type, setType] = useState(MedicalSpecialty.Allgemeinmedizin);
  const [name, setName] = useState("");
  const [details, setDetails] = useState("");

  const queryClient = useQueryClient();
  const tenandId = useTenantId();

  const {
    location: myDoctorLocation,
    coordinates: myDoctorCoordinates,
    setCoordinates: setMyDoctorCoordinates,
    updateCoordinatesBasedOnAddress: updateMyDoctorCoordinatesBasedOnAddress,
  } = useMapLocation();

  const { mutate: saveDoctor } = useApiMutation(
    "backend",
    (api) => (request: SaveDoctorRequest) => api.saveDoctor(request),
    undefined,
    { successMessage: { tx: "networkBuilder.success.doctorSaved" } },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(MY_DOCTORS(tenandId.disease));
      },
      onMutate: hideModal,
    },
  );

  const saveDoctorToDatabase = useCallback(
    (coords?: [number, number]) => {
      if (!coords) {
        return store.addToastEvent(
          new PlainError({
            tx: "networkBuilder.error.invalidAddress",
          }),
        );
      }

      if (!name) {
        return store.addToastEvent(
          new PlainError({
            tx: "networkBuilder.error.invalidName",
          }),
        );
      }

      setFlyTo(coords);

      saveDoctor({
        mamaDisease: tenandId.disease,
        saveDoctorDto: {
          doctorName: name,
          generalMedicalSpeciality: type,
          lat: coords[0],
          _long: coords[1],
          streetAddress: doctorAddress,
        },
      });
    },
    [doctorAddress, name, saveDoctor, setFlyTo, store, tenandId.disease, type],
  );

  const updateMyDoctorCoordinates = useCallback(() => {
    if (!doctorAddress) {
      return store.addToastEvent(
        new PlainError({
          tx: "networkBuilder.error.invalidAddress",
        }),
      );
    }

    updateMyDoctorCoordinatesBasedOnAddress(doctorAddress);
  }, [doctorAddress, store, updateMyDoctorCoordinatesBasedOnAddress]);

  useEffect(() => {
    if (myDoctorCoordinates) {
      saveDoctorToDatabase(myDoctorCoordinates);
      setMyDoctorCoordinates(undefined);
    }
  }, [
    myDoctorLocation,
    myDoctorCoordinates,
    saveDoctorToDatabase,
    setMyDoctorCoordinates,
  ]);

  useEffect(() => {
    updateModal({
      onConfirm: updateMyDoctorCoordinates,
    });
  }, [
    store,
    doctorAddress,
    myDoctorCoordinates,
    hideModal,
    updateModal,
    saveDoctorToDatabase,
    setMyDoctorCoordinates,
    updateMyDoctorCoordinates,
    updateMyDoctorCoordinatesBasedOnAddress,
  ]);

  const handleNameChange = useCallback(
    (e?: React.ChangeEvent<HTMLInputElement>) => {
      setName(e?.currentTarget?.value ?? "");
    },
    [],
  );

  const handleDetailsChange = useCallback(
    (e?: React.ChangeEvent<HTMLInputElement>) => {
      setDetails(e?.currentTarget?.value ?? "");
    },
    [],
  );

  const handleDropdownChange = useCallback((value: MedicalSpecialty) => {
    setType(value);
  }, []);

  return (
    <div className="flex flex-col gap-3">
      <Dropdown
        value={type}
        options={options}
        onChange={handleDropdownChange}
        noValueSelected={options[0].label}
      />
      <TextInput
        value={name}
        onChange={handleNameChange}
        placeholder={{ tx: "networkBuilder.doctorName" }}
      />
      <TextInput
        value={details}
        onChange={handleDetailsChange}
        placeholder={{ tx: "networkBuilder.additionalInfo" }}
      />
    </div>
  );
};

const FlyToLocation: React.FC<{ location?: LatLngExpression }> = ({
  location,
}) => {
  const map = useMap();

  useEffect(() => {
    if (location) {
      map.flyTo(location);
    }
  }, [location, map]);

  return null;
};

const getIcon = (specialty: DoctorGeneralMedicalSpecialityEnum): string => {
  return specialty === MedicalSpecialty.Allgemeinmedizin
    ? "/resources/doctor.svg"
    : specialty === MedicalSpecialty.Physiotherapeut
    ? "/resources/physiotherapy.svg"
    : specialty === MedicalSpecialty.Neurologie
    ? "/resources/brain.svg"
    : specialty === MedicalSpecialty.Ms
    ? "/resources/hospital.svg"
    : "/resources/pin.svg";
};
