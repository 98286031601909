"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.chatDataGuard = exports.ChatOptionsType = void 0;
const data_guard_type_1 = require("../data-guard-type");
var ChatOptionsType;
(function (ChatOptionsType) {
    ChatOptionsType["CHAT"] = "CHAT";
    ChatOptionsType["CALL"] = "CALL";
})(ChatOptionsType || (exports.ChatOptionsType = ChatOptionsType = {}));
exports.chatDataGuard = (0, data_guard_type_1.createDataGuard)({
    path: "chat",
    type: data_guard_type_1.DataGuardType.CHAT_COMPLETION_DATA_GUARD,
    title: "dataGuards.chat.title",
});
