import { CognitoConfigurationState } from "../../cognito/misc/types";
import {
  AuthStateEnum,
  IAuthStateDelegate,
  UserData,
} from "../../generic/types";

export const fakeJwtToken =
  "e30=.eyJzdWIiOiI1M2I0ZTgyMi1jMDExLTcwNDMtOTY4Mi05ZjBlZWI4NTlhNzgiLCJpc3MiOiJodHRwczovL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tL2V1LWNlbnRyYWwtMV9rWkZQQjBJejIiLCJjbGllbnRfaWQiOiI2bzRlOHZnaDgwNmc5cGhlbm84MjE3ZmxzdiIsIm9yaWdpbl9qdGkiOiI2NDRmNWQ1NS05NTRhLTRjYTgtOTVkYS1jNzFhMzE5ZDlhYTkiLCJ0b2tlbl91c2UiOiJhY2Nlc3MiLCJzY29wZSI6ImF3cy5jb2duaXRvLnNpZ25pbi51c2VyLmFkbWluIiwiYXV0aF90aW1lIjoxNjg3MzM3MjM4LCJleHAiOjE2ODczNjgzNzYsImlhdCI6MTY4NzM2NDc3NiwianRpIjoiMThjNTg1MGQtNjZiMC00YjZlLThkMzktNzY5Njg0MWQ4MTEyIiwidXNlcm5hbWUiOiI1M2I0ZTgyMi1jMDExLTcwNDMtOTY4Mi05ZjBlZWI4NTlhNzgiLCJjb2duaXRvOmdyb3VwcyI6W119.e30=";

export class MockAuthStore {
  public jwtToken?: string = fakeJwtToken;
  public get userData(): UserData {
    const token = this.jwtToken ?? fakeJwtToken;
    const base64Payload = token.split(".")[1];

    const decodeBase64 = (str: string) => {
      try {
        if (typeof Buffer !== "undefined") {
          // Node.js environment
          return Buffer.from(str, "base64").toString();
        } else {
          // Browser environment
          return window.atob(str);
        }
      } catch (error) {
        console.error("Error decoding base64 string:", error);
        return null;
      }
    };
    const payload = decodeBase64(base64Payload);
    return payload ? JSON.parse(payload) : {};
  }

  public constructor(
    cognitoConfigState: CognitoConfigurationState,
    private authStateDelegate: IAuthStateDelegate,
  ) {
    if (cognitoConfigState === "loading") {
      throw new Error(
        "also the mock should only be called if the config is loaded",
      );
    }

    this.setState(AuthStateEnum.SIGNED_IN);
  }

  public signOut = (): Promise<void> => {
    alert("Mock sign out");
    return Promise.resolve();
  };

  public getValidJwtToken = (): Promise<string> => {
    return Promise.resolve(this.jwtToken ?? "");
  };

  public setup = Promise.resolve;

  private setState(newState: AuthStateEnum): void {
    this.authStateDelegate.setState(newState);
  }
}
