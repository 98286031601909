import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { FrontendPageEnum } from "shared/model/pages";
import { twMerge } from "tailwind-merge";

import {
  DeleteNewsStoryRequest,
  NewsStory,
  PinNewsStoryRequest,
  PublishNewsStoryRequest,
  UnpinNewsStoryRequest,
} from "../../api/generated/backend";
import {
  toTenantId,
  toTenantIdHeader,
  useApiMutation,
} from "../../api/use-api";
import { useModal } from "../../models/modal-provider";
import {
  NEWS_STORIES_KEY,
  NEWS_STORY_DRAFTS_KEY,
  NEWS_STORY_PINNED_KEY,
} from "../../types/query-keys";
import { useNavigateInsideTenant } from "../../util/navigation-hooks";
import { useTenantId } from "../../util/use-active-tenant-id";
import { ActionMenu, ActionMenuAction } from "../form/action-menu";
import { Button } from "../form/button";
import { Text } from "../text";

type NewsDataProps = {
  newsStory: Omit<
    NewsStory,
    "tenantId" | "createdAt" | "updatedAt" | "isPublished"
  > &
    Partial<Pick<NewsStory, "isPublished">>;
  isAdmin?: boolean;
  isEdit?: boolean;
} & JSX.IntrinsicElements["div"];

export const NewsCard: React.FC<NewsDataProps> = ({
  // TODO: these stories are not localized yet but are displayed in the language entered by the admin
  isAdmin,
  isEdit,
  className,
  newsStory,
  ...rest
}) => {
  const tenantId = useTenantId();
  const navigate = useNavigateInsideTenant();
  const { showModal, hideModal } = useModal();
  const queryClient = useQueryClient();

  const { mutate: publishNewsStory } = useApiMutation(
    "backend",
    (api) => (request: PublishNewsStoryRequest) =>
      api.publishNewsStory(request),
    undefined,
    undefined,
    {
      onSuccess: (_, header) => {
        queryClient.invalidateQueries(
          NEWS_STORY_DRAFTS_KEY(toTenantId(header)),
        );
        queryClient.invalidateQueries(NEWS_STORIES_KEY(toTenantId(header)));
      },
    },
  );

  const { mutate: pinNewsStory } = useApiMutation(
    "backend",
    (api) => (request: PinNewsStoryRequest) => api.pinNewsStory(request),
    undefined,
    undefined,
    {
      onSuccess: (_, header) => {
        queryClient.invalidateQueries(NEWS_STORIES_KEY(toTenantId(header)));
        queryClient.invalidateQueries(
          NEWS_STORY_PINNED_KEY(toTenantId(header)),
        );
      },
    },
  );

  const { mutate: unpinNewsStory } = useApiMutation(
    "backend",
    (api) => (request: UnpinNewsStoryRequest) => api.unpinNewsStory(request),
    undefined,
    undefined,
    {
      onSuccess: (_, header) => {
        queryClient.invalidateQueries(NEWS_STORIES_KEY(toTenantId(header)));
        queryClient.invalidateQueries(
          NEWS_STORY_PINNED_KEY(toTenantId(header)),
        );
      },
    },
  );

  const { mutate: deleteNewsStory } = useApiMutation(
    "backend",
    (api) => (request: DeleteNewsStoryRequest) => api.deleteNewsStory(request),
    undefined,
    undefined,
    {
      onSuccess: (_, header) => {
        if (newsStory.isPublished) {
          queryClient.invalidateQueries(NEWS_STORIES_KEY(toTenantId(header)));
        } else {
          queryClient.invalidateQueries(
            NEWS_STORY_DRAFTS_KEY(toTenantId(header)),
          );
        }
      },
    },
  );

  const actions = useMemo<ActionMenuAction[]>(
    () => [
      {
        action: () => {
          navigate(
            FrontendPageEnum.ADMIN_NEWS_EDIT.replace(":id", newsStory.id),
          );
        },
        label: { tx: "news.actions.edit" },
      },
      {
        isEnabled: !newsStory.isPublished,
        action: () => {
          showModal({
            title: {
              tx: "news.confirmPublishTitle",
              txData: {
                newsName: newsStory.headline,
              },
            },
            description: {
              tx: "news.confirmPublishText",
              txData: {
                newsName: newsStory.headline,
              },
            },
            onConfirm: () => {
              publishNewsStory({
                id: newsStory.id,
                ...toTenantIdHeader(tenantId),
              });
              hideModal();
            },
          });
        },
        label: { tx: "news.actions.publish" },
      },
      {
        isEnabled: !newsStory.isPinned,

        action: () => {
          showModal({
            title: {
              tx: "news.confirmPinTitle",
              txData: {
                newsName: newsStory.headline,
              },
            },
            description: {
              tx: "news.confirmPinText",
              txData: {
                newsName: newsStory.headline,
              },
            },
            onConfirm: () => {
              pinNewsStory({
                id: newsStory.id,
                ...toTenantIdHeader(tenantId),
              });
              hideModal();
            },
          });
        },
        label: { tx: "news.actions.pin" },
      },
      {
        isEnabled: newsStory.isPinned,

        action: () => {
          showModal({
            title: {
              tx: "news.confirmUnpinTitle",
              txData: {
                newsName: newsStory.headline,
              },
            },
            description: {
              tx: "news.confirmUnpinText",
              txData: {
                newsName: newsStory.headline,
              },
            },
            onConfirm: () => {
              unpinNewsStory({
                id: newsStory.id,
                ...toTenantIdHeader(tenantId),
              });
              hideModal();
            },
          });
        },
        label: { tx: "news.actions.unpin" },
      },
      {
        action: () => {
          showModal({
            title: {
              tx: "news.confirmDeleteTitle",
              txData: {
                newsName: newsStory.headline,
              },
            },
            description: {
              tx: "news.confirmDeleteText",
              txData: {
                newsName: newsStory.headline,
              },
            },
            onConfirm: () => {
              deleteNewsStory({
                id: newsStory.id,
                ...toTenantIdHeader(tenantId),
              });
              hideModal();
            },
          });
        },
        label: { tx: "news.actions.delete" },
      },
    ],
    [
      deleteNewsStory,
      hideModal,
      navigate,
      newsStory.headline,
      newsStory.id,
      newsStory.isPublished,
      newsStory.isPinned,
      pinNewsStory,
      unpinNewsStory,
      publishNewsStory,
      showModal,
      tenantId,
    ],
  );

  const handleButtonPress = useCallback(() => {
    return newsStory.body && !isEdit
      ? navigate(FrontendPageEnum.NEWS_DETAIL.replace(":id", newsStory.id), {
          state: newsStory.id,
        })
      : newsStory.link
      ? window.open(newsStory.link, "_blank")
      : null;
  }, [isEdit, navigate, newsStory.body, newsStory.id, newsStory.link]);

  return (
    <div
      className={twMerge(
        "card card-compact relative overflow-hidden bg-base-100 shadow-xl",
        className,
      )}
      {...rest}
    >
      {isAdmin && (
        <ActionMenu className="absolute right-4 top-4" actions={actions} />
      )}

      <figure>
        {!newsStory.imageLink && (
          <div className="flex aspect-video w-full items-center justify-center border object-cover object-center p-5">
            <Text className=" text-center" tx="editNews.image.placeholder" />
          </div>
        )}
        {newsStory.imageLink && (
          <img
            className="aspect-video w-full object-cover object-center"
            src={newsStory.imageLink}
          />
        )}
      </figure>

      <div className="card-body">
        <h3 className="card-title">{newsStory.headline}</h3>
        <p>{newsStory.description}</p>
        <div className="card-actions justify-end">
          <Button
            onClick={handleButtonPress}
            className="w-fit max-w-full gap-0"
            tx="news.readMoreButton"
            icon={newsStory.link ? "chain" : undefined}
          />
        </div>
      </div>
    </div>
  );
};
