"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.socialDemographicsDataGuard = void 0;
const data_guard_type_1 = require("../../data-guard-type");
const form_data_guard_1 = require("../form-data-guard");
const socialDemographicsDataGuard = (inputs) => (0, data_guard_type_1.createDataGuard)({
    path: "social",
    type: data_guard_type_1.DataGuardType.FORM_DATA_GUARD,
    title: "dataGuards.socialDemographics.title",
    inputs,
    layout: form_data_guard_1.FormDataGuardLayoutType.SIGN_UP_LAYOUT,
});
exports.socialDemographicsDataGuard = socialDemographicsDataGuard;
