/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateAndLinkAccountDto
 */
export interface CreateAndLinkAccountDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAndLinkAccountDto
     */
    refreshUrl: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAndLinkAccountDto
     */
    returnUrl: string;
}

/**
 * Check if a given object implements the CreateAndLinkAccountDto interface.
 */
export function instanceOfCreateAndLinkAccountDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "refreshUrl" in value;
    isInstance = isInstance && "returnUrl" in value;

    return isInstance;
}

export function CreateAndLinkAccountDtoFromJSON(json: any): CreateAndLinkAccountDto {
    return CreateAndLinkAccountDtoFromJSONTyped(json, false);
}

export function CreateAndLinkAccountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAndLinkAccountDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'refreshUrl': json['refresh_url'],
        'returnUrl': json['return_url'],
    };
}

export function CreateAndLinkAccountDtoToJSON(value?: CreateAndLinkAccountDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'refresh_url': value.refreshUrl,
        'return_url': value.returnUrl,
    };
}

