import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form } from "react-router-dom";

import { FinishedSignUpViaCallRequest } from "../../../../../api/generated/backend";
import { toTenantIdHeader, useApiMutation } from "../../../../../api/use-api";
import { PlainError } from "../../../../../components/events/plain-error";
import { FormTelephoneInput } from "../../../../../components/form/form-telephone-input";
import { LoadingButton } from "../../../../../components/form/loading-button";
import { Text } from "../../../../../components/text";
import { useStore } from "../../../../../models/helpers";
import { queryClient } from "../../../../../query-client";
import { ME_KEY } from "../../../../../types/query-keys";
import { useTenantId } from "../../../../../util/use-active-tenant-id";
import { useUser } from "../../../../../util/use-user";

export const CallCard: React.FC = () => {
  const user = useUser();
  const store = useStore();

  const {
    handleSubmit,
    control,
    resetField,
    formState: { errors, isSubmitting },
  } = useForm<{
    telephone: string;
  }>();

  useEffect(() => {
    resetField("telephone", {
      defaultValue: user?.userAttributes?.telephone ?? undefined,
    });
  }, [resetField, user?.userAttributes?.telephone]);

  const tenantId = useTenantId();

  const { mutate: finishedSignUpViaCall } = useApiMutation(
    "backend",
    (api) => (request: FinishedSignUpViaCallRequest) =>
      api.finishedSignUpViaCall(request),
    undefined,
    undefined,
    {
      onSuccess() {
        queryClient.invalidateQueries(ME_KEY(tenantId));
      },
    },
  );

  const handleFormSubmit = handleSubmit(({ telephone }) => {
    if (!telephone) {
      return store.addToastEvent(
        new PlainError({ tx: "dataGuards.chat.errors.phoneNumberRequired" }),
      );
    }

    finishedSignUpViaCall({
      ...toTenantIdHeader(tenantId),
      finishedSignUpViaCallDto: { telephone },
    });
  });

  return (
    <Form
      className="flex h-full items-center justify-center gap-5 "
      onSubmit={handleFormSubmit}
    >
      <div className="flex max-w-[540px] flex-col gap-5">
        <Text
          className="mb-5 text-center text-xl font-bold"
          tx="dataGuards.chat.cards.leaveYourNumberHere"
        />
        <div className="flex w-full items-center rounded-xl border-2 border-primary p-5">
          <FormTelephoneInput
            name="telephone"
            control={control}
            title={{ tx: "profile.telephone" }}
            error={{ txUnchecked: errors.telephone?.message }}
          />
          <LoadingButton
            type="submit"
            icon="paperPlane"
            loading={isSubmitting}
            className="mt-8"
          />
        </div>
      </div>
    </Form>
  );
};
