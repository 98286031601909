import { GhostButton } from "../../form/button";
import { useFilterElementContext } from "../filter-element";
import {
  FilterComponent,
  FilterType,
  SortConfigDirection,
  SortConfigProperty,
} from "../types";

export const LikesSorter: FilterComponent = () => {
  const { sortConfig, onFilterChange } = useFilterElementContext();
  return sortConfig ? (
    <GhostButton
      tx="filters.likes"
      icon={sortConfig.direction === SortConfigDirection.DESC ? "down" : "up"}
      onClick={() => {
        onFilterChange({
          type: FilterType.SORT,
          property: SortConfigProperty.LIKES,
          direction:
            sortConfig.direction === SortConfigDirection.ASC
              ? SortConfigDirection.DESC
              : SortConfigDirection.ASC,
        });
      }}
    />
  ) : null;
};
