"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.chiCopdIt = void 0;
const analytics_app_pages_1 = require("../../../model/analytics-app-pages");
const customers_1 = require("../../../model/customers");
exports.chiCopdIt = {
    sidebarLogo: "/resources/mama-logo-wide.svg",
    customerLogo: "/resources/mama-logo-wide.svg",
    customer: customers_1.CustomerGroup.CHI_COPD_IT,
    homePageRoute: analytics_app_pages_1.AnalyticsAppPagesEnum.OVERVIEW,
    pages: [
        {
            title: "Overview",
            route: analytics_app_pages_1.AnalyticsAppPagesEnum.OVERVIEW,
            iframeRoute: "/chronic-obstructive-pulmonary-disease/chi/ita/overview",
        },
        {
            title: "Symptoms Explorer",
            route: analytics_app_pages_1.AnalyticsAppPagesEnum.SYMPTOMS_EXPLORER,
            iframeRoute: "/chronic-obstructive-pulmonary-disease/chi/ita/symptoms-explorer",
        },
        {
            title: "Referral pathway",
            route: analytics_app_pages_1.AnalyticsAppPagesEnum.REFERRAL_PATHWAY,
            iframeRoute: "/chronic-obstructive-pulmonary-disease/chi/ita/referral-pathway",
        },
        {
            title: "Treatment Pathway",
            route: analytics_app_pages_1.AnalyticsAppPagesEnum.TREATMENT_PATHWAY,
            iframeRoute: "/chronic-obstructive-pulmonary-disease/chi/ita/treatment-pathway",
        },
        {
            title: "Mind and Behaviour",
            route: analytics_app_pages_1.AnalyticsAppPagesEnum.MIND_AND_BEHAVIOUR,
            iframeRoute: "/chronic-obstructive-pulmonary-disease/chi/ita/mind-behaviour",
        },
    ],
    helpPage: {
        route: analytics_app_pages_1.AnalyticsAppPagesEnum.HELP,
        iframeRoute: "https://www.mamahealth.io/iframes/mama-answer-waitlist",
    },
};
