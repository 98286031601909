import { makeAutoObservable, reaction, runInAction } from "mobx";

import {
  AuthStateEnum,
  IAuthStore,
  IStaticAuthStore,
} from "./implementations/generic/types";

export enum AuthBroadcastMessage {
  SIGN_OUT = "SIGN_OUT",
  POTENTIAL_SIGN_IN = "SIGN_IN",
}

export interface IBroadcastChannel<T> {
  addEventListener: (
    type: "message",
    listener: (event: MessageEvent<T>) => void,
  ) => void;
  removeEventListener: (
    type: "message",
    listener: (event: MessageEvent<T>) => void,
  ) => void;
  postMessage: (message: T) => void;
}

export class AuthStateStore {
  private _state: AuthStateEnum = AuthStateEnum.LOADING;
  private authStoreDelegate?: IAuthStore;
  private broadcastReactionDisposer?: () => void;

  public get state(): AuthStateEnum {
    return this._state;
  }

  public constructor(
    private updateChannel: IBroadcastChannel<AuthBroadcastMessage>,
    private staticAuthStoreDelegate: IStaticAuthStore,
  ) {
    makeAutoObservable(this);
    this.setupReactions();
    this.setupEventListener();
  }

  public setState(newState: AuthStateEnum): void {
    if (newState === this._state) {
      return;
    }
    runInAction(() => {
      this._state = newState;
    });
  }

  public setAuthStoreDelegate = (authStoreDelegate: IAuthStore): void => {
    this.authStoreDelegate = authStoreDelegate;
  };

  public cleanup = (): void => {
    this.broadcastReactionDisposer?.();
    this.removeEventListener();
  };

  public probeIfUserIsSignedIn = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      this.staticAuthStoreDelegate
        ?.probeIfUserIsSignedIn()
        .then(() => {
          this.setState(AuthStateEnum.SIGNED_IN);
          resolve();
        })
        .catch(() => {
          this.setState(AuthStateEnum.SIGNED_OUT);
          reject();
        });
    });
  };

  private setupReactions(): void {
    this.broadcastReactionDisposer = reaction(
      () => this._state,
      (newState) => {
        switch (newState) {
          case AuthStateEnum.SIGNED_IN:
            this.broadcastPotentialSignInToOtherContexts();
            break;
          case AuthStateEnum.SIGNED_OUT:
            this.broadcastSignOutToOtherContexts();
            break;
        }
      },
    );
  }

  private setupEventListener(): void {
    this.updateChannel.addEventListener(
      "message",
      this.signalAuthStoreInLowerBranchOfComponentTree,
    );
  }

  private removeEventListener(): void {
    this.updateChannel.removeEventListener(
      "message",
      this.signalAuthStoreInLowerBranchOfComponentTree,
    );
  }

  private signalAuthStoreInLowerBranchOfComponentTree = async (
    message: MessageEvent<AuthBroadcastMessage>,
  ): Promise<void> => {
    const actions: Record<AuthBroadcastMessage, () => void> = {
      // Note: if the delegate is present, the delegate decides the state and sets it
      [AuthBroadcastMessage.POTENTIAL_SIGN_IN]: () =>
        this.authStoreDelegate?.setup(),
      [AuthBroadcastMessage.SIGN_OUT]: () => this.authStoreDelegate?.signOut(),
    };
    actions[message.data as AuthBroadcastMessage]?.();
  };

  private broadcastSignOutToOtherContexts(): void {
    this.updateChannel.postMessage(AuthBroadcastMessage.SIGN_OUT);
  }

  private broadcastPotentialSignInToOtherContexts(): void {
    this.updateChannel.postMessage(AuthBroadcastMessage.POTENTIAL_SIGN_IN);
  }
}
