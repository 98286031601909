import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import { Text, TextProps } from "./text";

export const SectionHeading: React.FC<TextProps> = ({
  className,
  children,
  ...rest
}) => (
  <div
    className={twMerge(
      "flex items-center justify-start rounded-2xl bg-base-200 p-4 pl-8 font-bold",
      className,
    )}
  >
    <Text {...rest} />
    {children}
  </div>
);

export const MultiColumnSection: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <div className="xl-grid-cols-4 grid grid-cols-1 gap-10 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
      {children}
    </div>
  );
};

export const SingleColumnSection: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return <div className="flex flex-col gap-8">{children}</div>;
};
