/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GroupInfoDto } from './GroupInfoDto';
import {
    GroupInfoDtoFromJSON,
    GroupInfoDtoFromJSONTyped,
    GroupInfoDtoToJSON,
} from './GroupInfoDto';

/**
 * 
 * @export
 * @interface MultipleMembershipsDto
 */
export interface MultipleMembershipsDto {
    /**
     * 
     * @type {Array<GroupInfoDto>}
     * @memberof MultipleMembershipsDto
     */
    groupInfos: Array<GroupInfoDto>;
}

/**
 * Check if a given object implements the MultipleMembershipsDto interface.
 */
export function instanceOfMultipleMembershipsDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "groupInfos" in value;

    return isInstance;
}

export function MultipleMembershipsDtoFromJSON(json: any): MultipleMembershipsDto {
    return MultipleMembershipsDtoFromJSONTyped(json, false);
}

export function MultipleMembershipsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MultipleMembershipsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groupInfos': ((json['groupInfos'] as Array<any>).map(GroupInfoDtoFromJSON)),
    };
}

export function MultipleMembershipsDtoToJSON(value?: MultipleMembershipsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'groupInfos': ((value.groupInfos as Array<any>).map(GroupInfoDtoToJSON)),
    };
}

