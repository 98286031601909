/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SaveDoctorDto
 */
export interface SaveDoctorDto {
    /**
     * 
     * @type {number}
     * @memberof SaveDoctorDto
     */
    lat: number;
    /**
     * 
     * @type {number}
     * @memberof SaveDoctorDto
     */
    _long: number;
    /**
     * 
     * @type {string}
     * @memberof SaveDoctorDto
     */
    streetAddress: string;
    /**
     * 
     * @type {string}
     * @memberof SaveDoctorDto
     */
    doctorName: string;
    /**
     * 
     * @type {string}
     * @memberof SaveDoctorDto
     */
    generalMedicalSpeciality: SaveDoctorDtoGeneralMedicalSpecialityEnum;
}


/**
 * @export
 */
export const SaveDoctorDtoGeneralMedicalSpecialityEnum = {
    Neurologie: 'neurologie',
    MsSchwerpunktzentrum: 'MS-Schwerpunktzentrum',
    Allgemeinmedizin: 'allgemeinmedizin',
    Physiotherapeut: 'Physiotherapeut'
} as const;
export type SaveDoctorDtoGeneralMedicalSpecialityEnum = typeof SaveDoctorDtoGeneralMedicalSpecialityEnum[keyof typeof SaveDoctorDtoGeneralMedicalSpecialityEnum];


/**
 * Check if a given object implements the SaveDoctorDto interface.
 */
export function instanceOfSaveDoctorDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lat" in value;
    isInstance = isInstance && "_long" in value;
    isInstance = isInstance && "streetAddress" in value;
    isInstance = isInstance && "doctorName" in value;
    isInstance = isInstance && "generalMedicalSpeciality" in value;

    return isInstance;
}

export function SaveDoctorDtoFromJSON(json: any): SaveDoctorDto {
    return SaveDoctorDtoFromJSONTyped(json, false);
}

export function SaveDoctorDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SaveDoctorDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lat': json['lat'],
        '_long': json['long'],
        'streetAddress': json['streetAddress'],
        'doctorName': json['doctorName'],
        'generalMedicalSpeciality': json['generalMedicalSpeciality'],
    };
}

export function SaveDoctorDtoToJSON(value?: SaveDoctorDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lat': value.lat,
        'long': value._long,
        'streetAddress': value.streetAddress,
        'doctorName': value.doctorName,
        'generalMedicalSpeciality': value.generalMedicalSpeciality,
    };
}

