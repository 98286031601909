"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatTime = exports.joinDetails = void 0;
const joinDetails = (detail) => [
    detail.text,
    detail.number,
    detail.unit,
    detail.select != null ? (detail.select ? "✅" : "❌") : null,
]
    .filter((x) => x != null)
    .join(" ");
exports.joinDetails = joinDetails;
const formatTime = (time, language) => {
    if (time.day !== undefined &&
        time.month !== undefined &&
        time.year !== undefined) {
        return `${monthName(time.month, language)} ${time.day}, ${time.year}`;
    }
    else if (time.day !== undefined && time.month !== undefined) {
        return `${monthName(time.month, language)} ${time.day}`;
    }
    else if (time.month !== undefined && time.year !== undefined) {
        return `${monthName(time.month, language)}, ${time.year}`;
    }
    else if (time.day !== undefined && time.year !== undefined) {
        return `? ${time.day}, ${time.year}`;
    }
    else if (time.year !== undefined) {
        return `${time.year}`;
    }
    else if (time.month !== undefined) {
        return monthName(time.month, language);
    }
    else if (time.day !== undefined) {
        return `? ${time.day}`;
    }
    else {
        return "";
    }
};
exports.formatTime = formatTime;
const monthName = (month, language) => {
    if (typeof month === "number" &&
        Number.isInteger(month) &&
        month >= 1 &&
        month <= 12) {
        const date = new Date();
        date.setMonth(month - 1);
        return date.toLocaleString(language, { month: "short" });
    }
    else {
        return `${month}`;
    }
};
