/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Recurring } from './Recurring';
import {
    RecurringFromJSON,
    RecurringFromJSONTyped,
    RecurringToJSON,
} from './Recurring';
import type { StripeProduct } from './StripeProduct';
import {
    StripeProductFromJSON,
    StripeProductFromJSONTyped,
    StripeProductToJSON,
} from './StripeProduct';

/**
 * 
 * @export
 * @interface StripePrice
 */
export interface StripePrice {
    /**
     * 
     * @type {string}
     * @memberof StripePrice
     */
    id: string;
    /**
     * 
     * @type {Recurring}
     * @memberof StripePrice
     */
    recurring?: Recurring | null;
    /**
     * 
     * @type {number}
     * @memberof StripePrice
     */
    unitAmount?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StripePrice
     */
    currency: string;
    /**
     * 
     * @type {boolean}
     * @memberof StripePrice
     */
    active: boolean;
    /**
     * 
     * @type {StripeProduct}
     * @memberof StripePrice
     */
    product: StripeProduct;
}

/**
 * Check if a given object implements the StripePrice interface.
 */
export function instanceOfStripePrice(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "product" in value;

    return isInstance;
}

export function StripePriceFromJSON(json: any): StripePrice {
    return StripePriceFromJSONTyped(json, false);
}

export function StripePriceFromJSONTyped(json: any, ignoreDiscriminator: boolean): StripePrice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'recurring': !exists(json, 'recurring') ? undefined : RecurringFromJSON(json['recurring']),
        'unitAmount': !exists(json, 'unit_amount') ? undefined : json['unit_amount'],
        'currency': json['currency'],
        'active': json['active'],
        'product': StripeProductFromJSON(json['product']),
    };
}

export function StripePriceToJSON(value?: StripePrice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'recurring': RecurringToJSON(value.recurring),
        'unit_amount': value.unitAmount,
        'currency': value.currency,
        'active': value.active,
        'product': StripeProductToJSON(value.product),
    };
}

