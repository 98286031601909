/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const UserFeedbackState = {
    Rated: 'RATED',
    Unrated: 'UNRATED',
    Unseen: 'UNSEEN'
} as const;
export type UserFeedbackState = typeof UserFeedbackState[keyof typeof UserFeedbackState];


export function UserFeedbackStateFromJSON(json: any): UserFeedbackState {
    return UserFeedbackStateFromJSONTyped(json, false);
}

export function UserFeedbackStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserFeedbackState {
    return json as UserFeedbackState;
}

export function UserFeedbackStateToJSON(value?: UserFeedbackState | null): any {
    return value as any;
}

