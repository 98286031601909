import { useMemo } from "react";

import { DataGuardRouter } from "./data-guard-router";
import { StepsLayout } from "./steps-layout";
import { DataGuardContainer } from "./types";
import { useMetadata } from "../../util/metadata-for-active-route";

export const DataGuardFullscreenContainer: DataGuardContainer = ({
  children,
  DataGuardConfig,
}) => {
  if (!DataGuardConfig) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  const title = useMemo(
    () =>
      DataGuardConfig.activeIndex === -1
        ? undefined
        : DataGuardConfig.titles[DataGuardConfig.activeIndex],
    [DataGuardConfig.activeIndex, DataGuardConfig.titles],
  );

  useMetadata(title);

  return (
    <StepsLayout
      hideLastStep={false}
      titles={DataGuardConfig.titles}
      dataGuards={DataGuardConfig.dataGuards}
      activeIndex={DataGuardConfig.activeIndex}
      setActiveStep={DataGuardConfig.navigateToIndex}
    >
      <DataGuardRouter
        dataGuardRoutes={DataGuardConfig.dataGuardRoutes}
        dataGuardComponents={DataGuardConfig.dataGuardComponents}
        numberOfGuardsCompleted={DataGuardConfig.numberOfGuardsCompleted}
      />
    </StepsLayout>
  );
};
