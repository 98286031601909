import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Language } from "shared/model/languages";
import { EnabledPage } from "shared/model/pages";

export const useLanguageLock = (page?: EnabledPage): boolean => {
  const {
    i18n: { language },
  } = useTranslation();

  return !!useMemo(
    () =>
      !!page && page.supportedLanguages
        ? page.supportedLanguages?.includes(language as Language)
        : true,
    [language, page],
  );
};
