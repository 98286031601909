/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { QuestionnaireGraph } from './QuestionnaireGraph';
import {
    QuestionnaireGraphFromJSON,
    QuestionnaireGraphFromJSONTyped,
    QuestionnaireGraphToJSON,
} from './QuestionnaireGraph';

/**
 * Represents a FollowUpEdge record
 * @export
 * @interface FollowUpEdge
 */
export interface FollowUpEdge {
    /**
     * 
     * @type {string}
     * @memberof FollowUpEdge
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FollowUpEdge
     */
    sourceNodeId: string;
    /**
     * 
     * @type {string}
     * @memberof FollowUpEdge
     */
    targetNodeId: string;
    /**
     * 
     * @type {QuestionnaireGraph}
     * @memberof FollowUpEdge
     */
    questionnaireGraph?: QuestionnaireGraph;
    /**
     * 
     * @type {string}
     * @memberof FollowUpEdge
     */
    questionnaireGraphId: string;
}

/**
 * Check if a given object implements the FollowUpEdge interface.
 */
export function instanceOfFollowUpEdge(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "sourceNodeId" in value;
    isInstance = isInstance && "targetNodeId" in value;
    isInstance = isInstance && "questionnaireGraphId" in value;

    return isInstance;
}

export function FollowUpEdgeFromJSON(json: any): FollowUpEdge {
    return FollowUpEdgeFromJSONTyped(json, false);
}

export function FollowUpEdgeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FollowUpEdge {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sourceNodeId': json['sourceNodeId'],
        'targetNodeId': json['targetNodeId'],
        'questionnaireGraph': !exists(json, 'questionnaireGraph') ? undefined : QuestionnaireGraphFromJSON(json['questionnaireGraph']),
        'questionnaireGraphId': json['questionnaireGraphId'],
    };
}

export function FollowUpEdgeToJSON(value?: FollowUpEdge | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sourceNodeId': value.sourceNodeId,
        'targetNodeId': value.targetNodeId,
        'questionnaireGraph': QuestionnaireGraphToJSON(value.questionnaireGraph),
        'questionnaireGraphId': value.questionnaireGraphId,
    };
}

