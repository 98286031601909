import { twMerge } from "tailwind-merge";

import { ApplyFilterType, FilterType } from "./filter/types";

export const Pagination: React.FC<{
  pages: number;
  page: number;
  onPageChange: (type: ApplyFilterType) => void;
  className?: string;
}> = ({ pages, page, onPageChange, className }) => {
  return (
    <div className={twMerge("join justify-center", className)}>
      {Array.from({ length: pages }, (_, index) => index + 1).map(
        (pageNumber) => (
          <button
            onClick={() => {
              onPageChange({
                type: FilterType.PAGINATION,
                page: pageNumber,
              });
            }}
            key={pageNumber}
            className={`btn join-item ${
              pageNumber === page ? "btn-active" : ""
            }`}
          >
            {pageNumber}
          </button>
        ),
      )}
    </div>
  );
};
