/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Conversation } from './Conversation';
import {
    ConversationFromJSON,
    ConversationFromJSONTyped,
    ConversationToJSON,
} from './Conversation';
import type { QuestionNode } from './QuestionNode';
import {
    QuestionNodeFromJSON,
    QuestionNodeFromJSONTyped,
    QuestionNodeToJSON,
} from './QuestionNode';
import type { Role } from './Role';
import {
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
} from './Role';
import type { UserMedia } from './UserMedia';
import {
    UserMediaFromJSON,
    UserMediaFromJSONTyped,
    UserMediaToJSON,
} from './UserMedia';

/**
 * Represents a Message record
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof Message
     */
    inputTime: Date;
    /**
     * 
     * @type {Role}
     * @memberof Message
     */
    role: Role;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    content: string;
    /**
     * 
     * @type {Conversation}
     * @memberof Message
     */
    conversation?: Conversation;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    conversationId: string;
    /**
     * 
     * @type {Array<UserMedia>}
     * @memberof Message
     */
    media?: Array<UserMedia>;
    /**
     * 
     * @type {boolean}
     * @memberof Message
     */
    hasMedicationMentioned?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    contentLocalized: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    language: string;
    /**
     * 
     * @type {QuestionNode}
     * @memberof Message
     */
    questionNode?: QuestionNode;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    questionNodeId?: string;
}

/**
 * Check if a given object implements the Message interface.
 */
export function instanceOfMessage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "inputTime" in value;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "content" in value;
    isInstance = isInstance && "conversationId" in value;
    isInstance = isInstance && "contentLocalized" in value;
    isInstance = isInstance && "language" in value;

    return isInstance;
}

export function MessageFromJSON(json: any): Message {
    return MessageFromJSONTyped(json, false);
}

export function MessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Message {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'inputTime': (new Date(json['inputTime'])),
        'role': RoleFromJSON(json['role']),
        'content': json['content'],
        'conversation': !exists(json, 'conversation') ? undefined : ConversationFromJSON(json['conversation']),
        'conversationId': json['conversationId'],
        'media': !exists(json, 'media') ? undefined : ((json['media'] as Array<any>).map(UserMediaFromJSON)),
        'hasMedicationMentioned': !exists(json, 'hasMedicationMentioned') ? undefined : json['hasMedicationMentioned'],
        'contentLocalized': json['contentLocalized'],
        'language': json['language'],
        'questionNode': !exists(json, 'questionNode') ? undefined : QuestionNodeFromJSON(json['questionNode']),
        'questionNodeId': !exists(json, 'questionNodeId') ? undefined : json['questionNodeId'],
    };
}

export function MessageToJSON(value?: Message | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'inputTime': (value.inputTime.toISOString()),
        'role': RoleToJSON(value.role),
        'content': value.content,
        'conversation': ConversationToJSON(value.conversation),
        'conversationId': value.conversationId,
        'media': value.media === undefined ? undefined : ((value.media as Array<any>).map(UserMediaToJSON)),
        'hasMedicationMentioned': value.hasMedicationMentioned,
        'contentLocalized': value.contentLocalized,
        'language': value.language,
        'questionNode': QuestionNodeToJSON(value.questionNode),
        'questionNodeId': value.questionNodeId,
    };
}

