import { FC } from "react";

import { DeleteUserButton } from "./delete-user-button";
import { ChangePasswordButton } from "../../auth/implementations/cognito/components/change-password-button";
import { Button } from "../../components/form/button";
import { useModal } from "../../models/modal-provider";

export const ManageAccountModal: FC<JSX.IntrinsicElements["div"]> = ({
  className,
}) => {
  const { showModal } = useModal();

  return (
    <Button
      className={className}
      tx="profile.manageAccount"
      onClick={() => {
        showModal({
          title: { tx: "profile.manageAccount" },
          children: (
            <div className="flex w-full flex-col gap-4">
              <ChangePasswordButton />
              <DeleteUserButton />
            </div>
          ),
        });
      }}
    />
  );
};
