import { t } from "i18next";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Language } from "shared/model/languages";

import { useTenantConfig } from "../../../tenant-settings";
import { Dropdown, DropdownProps } from "../../form/dropdown";
import { useFilterElementContext } from "../filter-element";
import {
  FilterComponent,
  FilterProperty,
  FilterType,
  PropertyFilterRule,
} from "../types";

export const LanguageDropdownFilter: FilterComponent = () => {
  const { filterConfig, onFilterChange } = useFilterElementContext();
  const isLanguageFilterSet = filterConfig?.some(
    (propFilter) => propFilter.property === FilterProperty.LANGUAGE,
  );
  const tenantConfig = useTenantConfig();
  const {
    i18n: { language: activeLanguage },
  } = useTranslation();

  const [language, setLanguage] = useState<Language>(
    activeLanguage as Language,
  );

  const supportedLanguages = useMemo(
    () => tenantConfig?.supportedLanguages,
    [tenantConfig],
  );

  const languages = useMemo<DropdownProps<Language>["options"]>(
    () =>
      supportedLanguages?.map((lang) => ({
        label: { text: t(`i18n.languages.${lang}`, { lng: lang }) },
        value: lang,
      })) ?? [],
    [supportedLanguages],
  );

  return isLanguageFilterSet && languages.length > 1 ? (
    <Dropdown
      value={language}
      overflowVisible
      options={languages}
      className="z-10"
      onChange={(lang: Language) => {
        setLanguage(lang);

        onFilterChange({
          type: FilterType.FILTER,
          rule: PropertyFilterRule.IS,
          value: lang,
          property: FilterProperty.LANGUAGE,
        });
      }}
      noValueSelected={{ tx: "i18n.selectLanguage" }}
    />
  ) : null;
};
