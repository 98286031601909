import { Icon } from "leaflet";
import { Circle, Marker } from "react-leaflet";

export const Pin: React.FC<{
  radius?: number;
  position: [number, number];
  children?: React.ReactNode;
  iconUrl: string;
}> = ({ position, children, radius, iconUrl }) => {
  return (
    <>
      {radius && (
        <Circle
          radius={radius * 1000}
          color="none"
          fillColor="green"
          fillOpacity={0.1}
          center={position}
        />
      )}
      <Marker
        icon={
          new Icon({
            iconUrl,
            iconSize: [50, 50],
          })
        }
        position={position}
      >
        {children}
      </Marker>
    </>
  );
};
