import {
  Control,
  FormState,
  UseFormGetValues,
  UseFormRegister,
} from "react-hook-form";

import { CreateNewsStoryDto } from "../../api/generated/backend";

export interface EditNewsStoryProps {
  onSave: () => void;
  isError: boolean;
  isLoading: boolean;
  control: Control<CreateNewsStoryDto>;
  register: UseFormRegister<CreateNewsStoryDto>;
  getValues?: UseFormGetValues<CreateNewsStoryDto>;
  formState: Pick<FormState<CreateNewsStoryDto>, "errors" | "isSubmitting">;
}

export enum StoryEnum {
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
}
