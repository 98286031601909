/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SocialMediaComment
 */
export interface SocialMediaComment {
    /**
     * 
     * @type {string}
     * @memberof SocialMediaComment
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SocialMediaComment
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof SocialMediaComment
     */
    body: string;
    /**
     * 
     * @type {boolean}
     * @memberof SocialMediaComment
     */
    isCurrentUserComment: boolean;
    /**
     * 
     * @type {Date}
     * @memberof SocialMediaComment
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the SocialMediaComment interface.
 */
export function instanceOfSocialMediaComment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "body" in value;
    isInstance = isInstance && "isCurrentUserComment" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function SocialMediaCommentFromJSON(json: any): SocialMediaComment {
    return SocialMediaCommentFromJSONTyped(json, false);
}

export function SocialMediaCommentFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocialMediaComment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userId': json['userId'],
        'body': json['body'],
        'isCurrentUserComment': json['isCurrentUserComment'],
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function SocialMediaCommentToJSON(value?: SocialMediaComment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'body': value.body,
        'isCurrentUserComment': value.isCurrentUserComment,
        'createdAt': (value.createdAt.toISOString()),
    };
}

