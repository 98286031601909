import { useCallback } from "react";

import { CreateSubscriptionRequest } from "../../api/generated/backend";
import { toTenantIdHeader, useApiMutation } from "../../api/use-api";
import { useModal } from "../../models/modal-provider";
import { useTenantId } from "../../util/use-active-tenant-id";
import { PrimaryButton } from "../form/button";
import { StripeCheckout } from "../stripe/stripe-checkout";
import { StripeElementsProvider } from "../stripe/stripe-elements-provider";

export const BuySubscriptionButton: React.FC<{ priceId: string }> = ({
  priceId,
}) => {
  const modal = useModal();
  const tenantId = useTenantId();

  const { mutate: createInactiveSubscription } = useApiMutation(
    "backend",
    (api) => (request: CreateSubscriptionRequest) =>
      api.createSubscription(request),
    undefined,
    undefined,
    {
      onSuccess: (s) => {
        modal.updateModal({
          loading: { isLoading: false },
          children: (
            <StripeElementsProvider
              clientSecret={s.clientSecret}
              type="payment"
            >
              <StripeCheckout priceId={priceId} {...modal} />
            </StripeElementsProvider>
          ),
        });
      },
    },
  );

  const openBuySubscriptionModal = useCallback(() => {
    modal.showModal({
      title: { tx: "subscriptions.subscribeButton" },
      loading: { isLoading: true, message: { tx: "general.loading" } },
    });

    createInactiveSubscription({
      createSubscriptionDto: { priceId },
      ...toTenantIdHeader(tenantId),
    });
  }, [modal, createInactiveSubscription, priceId, tenantId]);

  return (
    <PrimaryButton
      tx="subscriptions.subscribeButton"
      onClick={openBuySubscriptionModal}
    />
  );
};
