/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CoordinatesDto
 */
export interface CoordinatesDto {
    /**
     * 
     * @type {number}
     * @memberof CoordinatesDto
     */
    lat: number;
    /**
     * 
     * @type {number}
     * @memberof CoordinatesDto
     */
    lng: number;
    /**
     * 
     * @type {string}
     * @memberof CoordinatesDto
     */
    language: string;
}

/**
 * Check if a given object implements the CoordinatesDto interface.
 */
export function instanceOfCoordinatesDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lat" in value;
    isInstance = isInstance && "lng" in value;
    isInstance = isInstance && "language" in value;

    return isInstance;
}

export function CoordinatesDtoFromJSON(json: any): CoordinatesDto {
    return CoordinatesDtoFromJSONTyped(json, false);
}

export function CoordinatesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CoordinatesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lat': json['lat'],
        'lng': json['lng'],
        'language': json['language'],
    };
}

export function CoordinatesDtoToJSON(value?: CoordinatesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lat': value.lat,
        'lng': value.lng,
        'language': value.language,
    };
}

