import { useMemo } from "react";
import { DataGuardType } from "shared/model/data-collection/guard/data-guard-type";
import {
  PROFILE_COMPLETENESS_MAX_SCORE,
  ProfileCompletionLevels,
} from "shared/model/data-collection/profile/profile-completeness-score";

import { pointsPerProfileCompletionLevel } from "./constants";
import { UserTenantGroupsEnum } from "../../api/generated/backend";
import { useTenantConfig } from "../../tenant-settings";
import { useUser } from "../../util/use-user";
import { dataGuardImplemenetations } from "../guard/implementations/data-guard-implementations";

interface UseProfileCompletenessScoreType {
  profileCompletenessScore: number;
  profileCompletenessMaxScore: number;
  isProfileComplete: boolean;
  isLoading: boolean;
}

export const useProfileCompleteness = (): UseProfileCompletenessScoreType => {
  const user = useUser();
  const config = useTenantConfig();

  const userIsAdmin = useMemo(
    () => user?.tenantGroups.includes(UserTenantGroupsEnum.Admin),
    [user],
  );

  const formDataGuards = useMemo(
    () =>
      userIsAdmin
        ? config.dataGuards.signUpAdmin
        : config.dataGuards.signUpUser,
    [config, userIsAdmin],
  )
    .filter((guard) => guard.type === DataGuardType.FORM_DATA_GUARD)
    .map((guard) =>
      dataGuardImplemenetations[guard?.type].useWasCompleted(guard),
    );

  const isProfileDataComplete = formDataGuards.includes(undefined)
    ? undefined
    : formDataGuards.every((isCompleted) => isCompleted);

  const chatDataGuard = useMemo(
    () =>
      userIsAdmin
        ? config.dataGuards.signUpAdmin
        : config.dataGuards.signUpUser,
    [config, userIsAdmin],
  )
    .filter((guard) => guard.type === DataGuardType.CHAT_COMPLETION_DATA_GUARD)
    .map((guard) =>
      dataGuardImplemenetations[guard?.type].useWasCompleted(guard),
    );

  const isChatComplete = chatDataGuard.includes(undefined)
    ? undefined
    : chatDataGuard.every((isCompleted) => isCompleted);

  const isProfileLevelCompleted = useMemo(
    () => ({
      [ProfileCompletionLevels.CHAT_COMPLETED]: isChatComplete,
      [ProfileCompletionLevels.PROFILE_PICTURE]: !!user?.userAttributes?.avatar,
      [ProfileCompletionLevels.TELEPHONE_NUMBER]:
        !!user?.userAttributes?.telephone,
      [ProfileCompletionLevels.COMPLETE_PROFILE_DATA]: isProfileDataComplete,
    }),
    [
      isChatComplete,
      isProfileDataComplete,
      user?.userAttributes?.avatar,
      user?.userAttributes?.telephone,
    ],
  );

  const profileCompletenessScore = useMemo(
    () =>
      Object.entries(pointsPerProfileCompletionLevel).reduce(
        (acc, [key, value]) =>
          isProfileLevelCompleted[key as keyof typeof isProfileLevelCompleted]
            ? acc + value
            : acc,
        0,
      ),
    [isProfileLevelCompleted],
  );
  const isProfileComplete = useMemo(
    () => profileCompletenessScore === PROFILE_COMPLETENESS_MAX_SCORE,
    [profileCompletenessScore],
  );

  const isLoading = useMemo(
    () => Object.values(isProfileLevelCompleted).includes(undefined),
    [isProfileLevelCompleted],
  );

  return {
    profileCompletenessScore,
    profileCompletenessMaxScore: PROFILE_COMPLETENESS_MAX_SCORE,
    isProfileComplete,
    isLoading,
  };
};
