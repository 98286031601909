import { Country } from "shared/model/countries";

import { FormSearchableDropdownInput } from "../../../../../components/form/form-searchable-dropdown-input";
import { tx } from "../../../../../components/text";
import { useT } from "../../../../../i18n/use-t";
import { FormDataCollectionInput } from "../types";

export const countryInput: FormDataCollectionInput = {
  wasCompleted: (user) => !!user.userAttributes?.country,
  Component: ({ errors, control, user }) => {
    const t = useT();

    return (
      <FormSearchableDropdownInput
        name="country"
        control={control}
        labelClassName="font-bold"
        title={{ tx: "userData.country" }}
        error={{ txUnchecked: errors.country?.message }}
        validate={(value) => {
          if (!value || value === "") {
            return tx("userData.countryIsRequiredError");
          }
        }}
        options={Object.values(Country).map((v) => ({
          label: { tx: `countries.${v}` },
          value: v,
        }))}
        formatValue={
          user.userAttributes?.country
            ? (value) => t({ txUnchecked: `countries.${value}` })
            : undefined
        }
      />
    );
  },
};
