import React, { useEffect } from "react";
import { twMerge } from "tailwind-merge";

import { EventProps } from "./event-props";
import { useStore } from "../../models/helpers";
import { openSentryPopup } from "../../util/open-sentry-popup";
import { GhostButton } from "../form/button";
import { Text } from "../text";

export const Event: React.FC<EventProps & JSX.IntrinsicElements["div"]> = ({
  kind,
  eventId,
  sentryAction,
  message,
  className,
  ...rest
}) => {
  const store = useStore();

  useEffect(() => {
    const timeout = setTimeout(() => {
      store.removeToastEvent(eventId);
    }, 10000);

    return () => clearTimeout(timeout);
  }, [eventId, store]);

  return (
    <div
      className={twMerge(
        `alert ${
          kind === "error" ? "bg-toned-down-error" : "alert-success"
        } flex w-72 max-w-full flex-row justify-between`,
        className,
      )}
      {...rest}
    >
      <div className="flex w-[calc(100%-2.5rem)] flex-col">
        <Text {...message} className="whitespace-normal" />

        {sentryAction && (
          <Text
            className="cursor-pointer whitespace-normal underline"
            onClick={() => openSentryPopup(sentryAction)}
            {...sentryAction.sentryButtonText}
          />
        )}
      </div>

      <GhostButton
        onClick={() => store.removeToastEvent(eventId)}
        icon="cross"
        iconColor={
          kind === "error" ? "fill-error-content" : "fill-success-content"
        }
        className="h-[2.5rem] min-h-[2.5rem] w-[2.5rem] p-0"
      />
    </div>
  );
};
