/* eslint-disable quotes */
import React, { useEffect, useRef } from "react";
import { TenantIdentifier } from "shared/config";

import {
  useLatestJwtToken,
  useObservedJwtToken,
} from "../../../auth/auth-store-context";
import { useDestructable } from "../../../util/hooks";
import { useTenantId } from "../../../util/use-active-tenant-id";
import { useUser } from "../../../util/use-user";

type RedirectMessageType = {
  type?: "redirect";
  timeout?: number;
  url?: string;
};
type UpdateLandbotValuesMessageType = {
  type?: "update_landbot_values";
  values?: object;
  persist: boolean;
};
type MessageType = RedirectMessageType | UpdateLandbotValuesMessageType;
type UpdateLandbotValues = (newLandbotValues: object, persist: boolean) => void;

export interface LoadedLandbotChatProps {
  configUrl: string;
  initialLandbotValues: object;
  updateLandbotValues: UpdateLandbotValues;
}

export const LoadedLandbotChat: React.FC<LoadedLandbotChatProps> = ({
  configUrl,
  initialLandbotValues,
  updateLandbotValues,
}) => {
  const jwtToken = useObservedJwtToken();
  const initialJwtToken = useLatestJwtToken();
  const user = useUser();
  const activeTenant = useTenantId();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const tokenRef = useRef(jwtToken);

  useEffect(() => {
    tokenRef.current = jwtToken;
  }, [jwtToken]);

  useEffect(() => {
    // send token update to the iframe when the jwt changes
    const iframe = iframeRef.current;
    if (
      iframe &&
      iframe.contentWindow &&
      jwtToken &&
      jwtToken !== initialJwtToken
    ) {
      console.log("sending updated token to iframe");
      iframe.contentWindow.postMessage(jwtToken, "*");
    }
  }, [iframeRef, jwtToken, initialJwtToken]);

  useEffect(() => {
    // receives an `iframeFocused` message from the iframe and sends the current token to the iframe
    const handleMessage = (event: MessageEvent) => {
      const iframe = iframeRef.current;
      if (
        iframe &&
        iframe.contentWindow &&
        event.source === iframeRef.current.contentWindow
      ) {
        if (event.data === "iframeFocused") {
          iframe.contentWindow.postMessage(tokenRef.current, "*");
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const landbotUrl = useDestructable(
    (lConfigUrl, lJwtToken, lNickname, lActiveTenant) =>
      URL.createObjectURL(
        new Blob(
          [
            landbotHtml(
              lConfigUrl,
              lJwtToken ?? "",
              initialLandbotValues,
              lNickname ?? "",
              lActiveTenant,
            ),
          ],
          {
            type: "text/html",
          },
        ),
      ),
    (url) => URL.revokeObjectURL(url),
    // on purpose does not contain initialLandbotValues as we do not want to reconstruct the landbot when they change
    [
      configUrl,
      initialJwtToken, // prevents rerendering when jwtToken changes
      user?.userAttributes?.name,
      activeTenant,
    ] as const,
  );

  useEffect(() => {
    const handleMessage = (e: MessageEvent<MessageType>) => {
      if (!landbotUrl?.includes(e.origin)) {
        return;
      }
      handleRedirects(e);
      handleUpdateLandbotValues(e, updateLandbotValues);
    };
    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, [landbotUrl, updateLandbotValues]);

  return <iframe className="h-full w-full" ref={iframeRef} src={landbotUrl} />;
};

const handleRedirects = (e: MessageEvent<MessageType>) => {
  if (
    e.data?.type === "redirect" &&
    typeof e.data?.url === "string" &&
    typeof e.data?.timeout === "number"
  ) {
    const url = e.data.url;
    setTimeout(
      () =>
        // we redirect by setting location instead of useNavigate on purpose, such that every API call executed on the target page is guaranteed to be fresh
        (location.href =
          // we always redirect to the current origin and only take the pathname of the url given by landbot because, unfortunately, we have no way of telling Landbot to use the "current URL"
          location.origin + new URL(url).pathname),
      e.data.timeout,
    );
  }
};

const handleUpdateLandbotValues = (
  e: MessageEvent<MessageType>,
  updateLandbotValues: UpdateLandbotValues,
) => {
  if (
    e.data.type === "update_landbot_values" &&
    typeof e.data.values === "object" &&
    typeof e.data.persist === "boolean"
  ) {
    updateLandbotValues(e.data.values, e.data.persist);
  }
};

function landbotHtml(
  configUrl: string,
  jwtToken: string,
  initialLandbotValues: object,
  nickname: string,
  activeTenant: TenantIdentifier,
): string {
  return `<!DOCTYPE html>
    <html lang="en">
    <head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    </head>
    <body>
    <script
    SameSite="None; Secure"
    src="https://static.landbot.io/landbot-3/landbot-3.0.0.js"
    ></script>
    <script>
    class CustomStorage {
      constructor(persist) {
        this.storage = ${JSON.stringify(initialLandbotValues)};
        this.persist = persist;
      }
      get(e) {
        return this.storage[e];
      }
      set(e, t) {
          this.storage[e] = t;
          this._updateMamaLandbotSettings();
      }
      remove(e) {
        delete this.storage[e];
        this._updateMamaLandbotSettings();
      }

      _updateMamaLandbotSettings() {
        window.parent.postMessage({
          type: "update_landbot_values",
          values: this.storage,
          persist: this.persist
        }, "*");
      }
    }

    Landbot.Core.prototype.createStorage = function () {
        return new CustomStorage(!this.config.storage_off);
    };

    var myLandbot = new Landbot.Native({
        configUrl: "${configUrl}",
        customData: {
          accesstoken: "${jwtToken}",
          nickname: "${nickname.replace(/[^a-zA-Z0-9 ]/g, "")}",
          environment: "${process.env.REACT_APP_APP_ENV || "dev"}",
          active_tenant_disease: "${activeTenant.disease}",
          active_tenant_organisation: "${activeTenant.organisation ?? "_"}"
        }
    });
    myLandbot.core.pipelines.$sequence.subscribe(data => {
      if(data.type === "redirect") {
        window.parent.postMessage({
          type: "redirect",
          timeout: ((data.extra?.redirect?.timeout ?? -1) + 1) * 1000,
          url: data.extra?.redirect?.url
        }, "*");
      }
    });

    window.addEventListener('focus', function() {
      window.parent.postMessage('iframeFocused', '*');
    });

    window.addEventListener('message', (event) => {    
      if (event.origin !== "${
        window.location.origin
      }" || !(typeof event.data === "string" && event.data.split(".").length === 3)) {
        console.log("other post data send to iframe");
        return;
      }
      
      myLandbot.setCustomData({
        accesstoken: event.data
      })

      console.log("set custom data in landbot");
    });
    </script>
    </body>
    </html>
  `;
}
