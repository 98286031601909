import React from "react";
import { Control, FieldErrors, UseFormRegister } from "react-hook-form";

import {
  GroupInfoDtoOrganisationEnum,
  UpdateSettingsDto,
  UpdateUserAttributesDto,
  UpdateUserDetailsDto,
  User,
} from "../../../../api/generated/backend";
import { I18nProps } from "../../../../components/text";

export type UpdateUserProfile = UpdateUserAttributesDto &
  UpdateUserDetailsDto &
  UpdateSettingsDto & { pags: GroupInfoDtoOrganisationEnum[] };
export interface FormDataCollectionInput {
  wasCompleted: (user: User) => boolean;
  Component: FormDataCollectionComponent;
}

export type FormDataCollectionComponent = React.FC<{
  user: User;
  register: UseFormRegister<UpdateUserProfile>;
  control: Control<UpdateUserProfile>;
  errors: FieldErrors<UpdateUserProfile>;
  prefilledValue?: I18nProps;
  context: FormDataCollectionContext;
}>;

export enum FormDataCollectionContext {
  SIGN_UP = "SIGN_UP",
  POP_UP = "POP_UP",
  PROFILE = "PROFILE",
}

export interface FormDataGuardLayout {
  Layout: React.FC<{
    user: User;
    register: UseFormRegister<UpdateUserProfile>;
    control: Control<UpdateUserProfile>;
    errors: FieldErrors<UpdateUserProfile>;
    inputs: {
      input: FormDataCollectionInput;
      reasonWhyShown?: I18nProps;
      prefilledValue?: I18nProps;
    }[];
    loading: boolean;
    currentGuard?: number;
    goToGuard?: (index: number) => void;
  }>;
}
