import { useCallback, useMemo, useState } from "react";

import {
  ApplyFilterType,
  DEFAULT_RESULTS_PER_PAGE,
  Filters,
  FilterType,
  PaginationConfig,
  PropertyFilter,
  SortConfig,
} from "./types";

export const useQueryParams = (config?: {
  defaultSorting?: SortConfig;
  defaultFilter?: PropertyFilter[];
  defaultPagination?: PaginationConfig;
}): [Filters, (type: ApplyFilterType) => void] => {
  const [propertyFilters, setPropertyFilters] = useState(config?.defaultFilter);
  const [sorting, setSorting] = useState(config?.defaultSorting);
  const [pagination, setPagination] = useState<PaginationConfig | undefined>(
    config?.defaultPagination
      ? {
          ...config.defaultPagination,
          page: 1,
          resultsPerPage:
            config.defaultPagination.resultsPerPage || DEFAULT_RESULTS_PER_PAGE,
        }
      : undefined,
  );

  const applyFilter = useCallback(
    (conf: ApplyFilterType) => {
      setPagination({
        page: 1,
        resultsPerPage: pagination?.resultsPerPage,
        type: FilterType.PAGINATION,
      });

      if (conf.type === FilterType.SORT) {
        setSorting(conf);
      }

      if (conf.type === FilterType.FILTER) {
        const unchangedFilters = propertyFilters?.filter(
          (propertyFilter) => conf.property !== propertyFilter?.property,
        );
        setPropertyFilters([...(unchangedFilters ?? []), conf]);
      }

      if (conf.type === FilterType.PAGINATION) {
        setPagination({
          ...conf,
          resultsPerPage: pagination?.resultsPerPage,
        });
      }
    },
    [propertyFilters, pagination?.resultsPerPage],
  );

  const filters = useMemo(
    () => [sorting, pagination, ...(propertyFilters ?? [])].filter((f) => f),
    [sorting, propertyFilters, pagination],
  );

  return [filters, applyFilter];
};
