import { signIn } from "aws-amplify/auth";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MIN_PASSWORD_LENGTH } from "shared/config/cognito";
import { CognitoClientMetadata } from "shared/types/cognito-client-metadata";

import { SecondaryButton } from "../../../../../components/form/button";
import { Form } from "../../../../../components/form/form";
import { FormTextInput } from "../../../../../components/form/form-text-input";
import { LoadingButton } from "../../../../../components/form/loading-button";
import { TranslationFeKey, tx } from "../../../../../components/text";
import { useTenantId } from "../../../../../util/use-active-tenant-id";
import { AuthMessageLevel } from "../../../generic/types";
import { useAuthNavigate } from "../../../generic/use-auth-navigate";
import { CognitoError, handleCognitoError } from "../../misc/cognito-errors";
import { cognitoPaths } from "../paths";

export const CognitoMigrateAccountWithConfirmedUser: React.FC<{
  email: string;
}> = ({ email }) => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<{
    newPassword: string;
    confirmNewPassword: string;
  }>();
  const navigate = useAuthNavigate();
  const { disease, organisation } = useTenantId();
  const {
    i18n: { language },
  } = useTranslation();

  const onSubmitMigrateAccount = handleSubmit(({ newPassword }) => {
    const clientMetadata: CognitoClientMetadata & {
      [key: string]: string;
    } = {
      disease,
      ...(organisation ? { organisation } : {}),
      isLocalhost: process.env.NODE_ENV === "development" ? "1" : "0",
      language,
    };
    signIn({
      username: email,
      password: newPassword,
      options: { clientMetadata },
    })
      .then(() =>
        navigate({
          to: { type: "redirect_url" },
          replace: true,
          state: {
            message: {
              level: AuthMessageLevel.INFO,
              tx: "auth.migrate.setPasswordSuccessfulAddCustomFields",
            },
          },
        }),
      )
      .catch((err: CognitoError) => {
        navigate({
          replace: true,
          to: { type: "uri", uri: cognitoPaths.migrateAccount },
          state: {
            message: {
              level: AuthMessageLevel.ERROR,
              ...handleCognitoError(err),
            },
          },
        });
      });
  });

  return (
    <Form onSubmit={onSubmitMigrateAccount}>
      <FormTextInput
        title={{ tx: "auth.migrate.inputs.newPassword.title" }}
        placeholder={{
          tx: "auth.migrate.inputs.newPassword.placeholder",
        }}
        autoComplete="new-password"
        type="password"
        {...register("newPassword", {
          required: tx("auth.migrate.inputs.newPassword.fieldMissingError"),
          minLength: {
            value: MIN_PASSWORD_LENGTH,
            message: tx(
              "auth.migrate.inputs.newPassword.passwordTooShortError",
            ),
          },
        })}
        error={{
          txUnchecked: errors.newPassword?.message,
          txData: { min_password_length: MIN_PASSWORD_LENGTH },
        }}
      />
      <FormTextInput
        title={{ tx: "auth.migrate.inputs.confirmPassword.title" }}
        placeholder={{
          tx: "auth.migrate.inputs.confirmPassword.placeholder",
        }}
        autoComplete="new-password"
        type="password"
        {...register("confirmNewPassword", {
          required: tx("auth.migrate.inputs.confirmPassword.fieldMissingError"),
          validate: (val: string): TranslationFeKey | undefined => {
            if (getValues("newPassword") !== val) {
              return "auth.migrate.inputs.confirmPassword.passwordsDoNotMatchError";
            }
          },
        })}
        error={{ txUnchecked: errors.confirmNewPassword?.message }}
      />
      <LoadingButton
        type="submit"
        loading={isSubmitting}
        Button={SecondaryButton}
        tx="auth.migrate.updateAccountButtonFirstStep"
      />
    </Form>
  );
};
