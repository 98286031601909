import React, { useMemo } from "react";
import { organisationsPerDisease } from "shared/config";

import { useApiQuery } from "../api/use-api";
import { OrganisationCard } from "../components/organisation-card";
import { Text } from "../components/text";
import { DefaultContentContainer } from "../layouts/default-content-container";
import { FOLLOWED_PAGS_KEY } from "../types/query-keys";
import { useTenantId } from "../util/use-active-tenant-id";

export const PagList: React.FC = () => {
  const { disease } = useTenantId();
  const tenantId = useTenantId();

  const orgsOfDisease = useMemo(
    () => [...(organisationsPerDisease.get(disease) ?? [])],
    [disease],
  );

  const { data: followedOrganisations } = useApiQuery(
    "backend",
    (api) => api.listFollowedOrganisations({ mamaDisease: tenantId.disease }),
    FOLLOWED_PAGS_KEY(tenantId.disease),
  );
  const groupsPerOrganisation = useMemo(
    () =>
      new Map(
        followedOrganisations?.groupsAndSubscriptionsPerFollowedOrg.map(
          ({ organisation, groups }) => [organisation, groups],
        ),
      ),
    [followedOrganisations],
  );

  return (
    <DefaultContentContainer className="flex flex-col gap-4">
      <div className="flex flex-col gap-8">
        {orgsOfDisease?.map((organisation) => (
          <OrganisationCard
            key={organisation}
            disease={disease}
            organisation={organisation}
            userGroupsInOrganisation={groupsPerOrganisation.get(organisation)}
          />
        ))}
      </div>

      {orgsOfDisease?.length === 0 && (
        <Text tx="pagList.noPags" className="text-center" />
      )}
    </DefaultContentContainer>
  );
};
