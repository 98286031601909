"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MedicalSpecialty = void 0;
var MedicalSpecialty;
(function (MedicalSpecialty) {
    MedicalSpecialty["Neurologie"] = "neurologie";
    MedicalSpecialty["Ms"] = "MS-Schwerpunktzentrum";
    MedicalSpecialty["Allgemeinmedizin"] = "allgemeinmedizin";
    MedicalSpecialty["Physiotherapeut"] = "Physiotherapeut";
})(MedicalSpecialty || (exports.MedicalSpecialty = MedicalSpecialty = {}));
