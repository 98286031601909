/* eslint-disable @typescript-eslint/no-explicit-any */

import { fetchAuthSession, getCurrentUser, signOut } from "aws-amplify/auth";

export type CognitoConfigurationState = "loading" | "ready";

export interface ICognitoAuthGen2 {
  getCurrentUser: typeof getCurrentUser;
  fetchAuthSession: typeof fetchAuthSession;
  signOut: typeof signOut;
}

export const cognitoAuth: ICognitoAuthGen2 = {
  getCurrentUser,
  fetchAuthSession,
  signOut,
};
