import { useCallback, useRef, useState } from "react";

import { PlainError } from "../components/events/plain-error";
import { useStore } from "../models/helpers";

interface UseAudioRecorder {
  removeRecording: () => void;
  toggleRecordAudio: () => void;
  isRecording: boolean;
  base64Audio?: string;
  recordingDuration: string;
}

const constraints = { audio: true };
export const useAudioRecorder = (): UseAudioRecorder => {
  const store = useStore();

  const [isRecording, setIsRecording] = useState(false);
  const [base64Audio, setBase64Audio] = useState<string | undefined>();
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>();
  const [recordingDuration, setRecordingDuration] = useState(0);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const startTimer = useCallback(() => {
    timerRef.current = setInterval(() => {
      setRecordingDuration((prevDuration) => prevDuration + 1);
    }, 1000);
  }, []);

  const stopTimer = useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  }, []);

  const mountRecorder = useCallback(() => {
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        const recorder = new MediaRecorder(stream);
        setMediaRecorder(recorder);

        recorder.start();

        recorder.onstart = () => {
          setIsRecording(true);
          startTimer();
        };

        recorder.onstop = () => {
          stream.getTracks().forEach((track) => track.stop());
          setIsRecording(false);
          stopTimer();
        };

        recorder.ondataavailable = (e) => {
          const reader = new FileReader();
          reader.readAsDataURL(e.data);
          reader.onloadend = () => {
            setBase64Audio(reader.result?.toString());
          };
        };
      })
      .catch(() => {
        store.addToastEvent(
          new PlainError({
            tx: "chat.errors.recordingNotPossible",
          }),
        );
      });
  }, [store, startTimer, stopTimer]);

  const startRecording = useCallback(() => {
    setRecordingDuration(0);
    mountRecorder();
  }, [mountRecorder]);

  const stopRecording = useCallback(() => {
    mediaRecorder?.stop();
  }, [mediaRecorder]);

  const removeRecording = useCallback(() => {
    setBase64Audio(undefined);
    setRecordingDuration(0);
  }, []);

  const toggleRecordAudio = useCallback(() => {
    isRecording ? stopRecording() : startRecording();
  }, [isRecording, startRecording, stopRecording]);

  return {
    isRecording,
    base64Audio,
    removeRecording,
    toggleRecordAudio,
    recordingDuration: formatDuration(recordingDuration),
  };
};

const formatDuration = (duration: number) => {
  const minutes = Math.floor(duration / 60)
    .toString()
    .padStart(2, "0");
  const seconds = (duration % 60).toString().padStart(2, "0");
  return `${minutes}:${seconds}`;
};
