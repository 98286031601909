import { ValidationFunction } from "../lookup";

export const createValidationFunctionForMinLength = (
  minLength: number,
): ValidationFunction => {
  return (value) => {
    if (String(value).length < minLength) {
      return {
        ok: false,
        message: {
          tx: "userData.WhereDidYouHearAboutMama.answerNeedsToBeLonger",
          txData: {
            characters: minLength,
          },
        },
      };
    }

    return {
      ok: true,
    };
  };
};
