import { useCallback, useMemo } from "react";

import { ChatTutorial } from "./chat/chat-tutorial";
import { Dropdown, DropdownProps } from "./form/dropdown";
import { useAuthStore } from "../auth/auth-store-context";
import { useProfile } from "../data-collection/profile/profile";
import { useModal } from "../models/modal-provider";

enum Settings {
  PROFILE = "PROFILE",
  TUTORIAL = "TUTORIAL",
  SIGN_OUT = "SIGN_OUT",
}

const options: DropdownProps<Settings>["options"] = [
  {
    value: Settings.PROFILE,
    label: { tx: "profile.headerTitle" },
  },
  {
    value: Settings.TUTORIAL,
    label: { tx: "profile.tutorial" },
  },
  {
    value: Settings.SIGN_OUT,
    label: { tx: "auth.signOut.signOutButton" },
  },
];

export const SettingsDropdown: React.FC<{
  isMobileView?: boolean;
}> = ({ isMobileView }) => {
  const { showProfile } = useProfile();
  const { signOut } = useAuthStore();
  const { showModal } = useModal();

  const toggleModal = useCallback(() => {
    showModal({
      showCloseButton: false,
      showsWindowCloseButton: true,
      windowCloseButtonClassName: "text-bg-blue-900 border-0",
      className: "max-w-xs",
      children: <ChatTutorial />,
    });
  }, [showModal]);

  const optionsActionMap: Record<Settings, () => void> = useMemo(
    () => ({
      [Settings.PROFILE]: showProfile,
      [Settings.TUTORIAL]: toggleModal,
      [Settings.SIGN_OUT]: signOut ?? (() => {}),
    }),
    [showProfile, toggleModal, signOut],
  );

  const onChangeDropdownValue = useCallback(
    (value: Settings) => {
      optionsActionMap[value]();
    },
    [optionsActionMap],
  );

  return (
    <Dropdown
      noValueSelected={{ tx: "general.settings" }}
      options={options}
      onChange={onChangeDropdownValue}
      className={isMobileView ? "dropdown-top" : undefined}
    />
  );
};
