import { Elements } from "@stripe/react-stripe-js";
import {
  loadStripe,
  Stripe,
  StripeElementsOptions,
  StripeElementsOptionsClientSecret,
} from "@stripe/stripe-js";
import assert from "assert";
import { useEffect, useMemo, useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";

import { toTenantIdHeader, useApiQuery } from "../../api/use-api";
import { TENANT_SETTINGS_KEY } from "../../types/query-keys";
import { useTenantId } from "../../util/use-active-tenant-id";

assert(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  "REACT_APP_STRIPE_PUBLISHABLE_KEY env var is missing",
);

type PaymentElementsProps = {
  type: "payment";
  clientSecret: string;
};

type CardElementsProps = {
  type: "card";
};

type StripeElementsProviderProps = (
  | PaymentElementsProps
  | CardElementsProps
) & {
  children?: React.ReactNode;
};

export const StripeElementsProvider: React.FC<StripeElementsProviderProps> = (
  props,
) => {
  const tenantId = useTenantId();
  const [stripeObject, setStripeObject] = useState<Stripe | null>(null);
  const { data: tenantSettings } = useApiQuery(
    "backend",
    (api) => api.getTenantSettings(toTenantIdHeader(tenantId)),
    TENANT_SETTINGS_KEY(tenantId),
  );
  const language = useTranslation().i18n.language;

  useEffect(() => {
    (async () => {
      if (tenantSettings?.stripeId) {
        setStripeObject(
          await loadStripe(
            process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string,
            {
              stripeAccount: tenantSettings.stripeId,
            },
          ),
        );
      }
    })();
  }, [tenantSettings?.stripeId]);

  const options = useMemo<StripeElementsOptions>(
    () =>
      props.type === "payment"
        ? ({
            clientSecret: props.clientSecret,
            locale: language,
          } as StripeElementsOptionsClientSecret)
        : ({ locale: language } as StripeElementsOptions),

    [props, language],
  );

  return (
    <Elements stripe={stripeObject} options={options}>
      {props.children}
    </Elements>
  );
};
