"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.globalThemeAttributes = void 0;
exports.globalThemeAttributes = {
    colors: {
        "timeline-primary": "#85c2e0",
        "mama-default-primary": "#2c486c",
        "mama-default-secondary": "#43d178",
        "chat-bubble-assistant": "#E2E9F6",
        "toned-down-error": "#80bcd0",
        "bg-blue-900": "#293860",
        "mama-green-100": "#C7EDD5",
        "mama-green-300": "#4ECF81",
        "mama-gray-100": "#ECECEC",
        "mama-gray-200": "#D9D9D9",
        "mama-white": "#ffffff",
    },
};
