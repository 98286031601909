import { FrontendPageEnum } from "shared/model/pages";

import { Link } from "./link";
import { Text } from "./text";
import { DefaultContentContainer } from "../layouts/default-content-container";
import { useNavigateInsideTenant } from "../util/navigation-hooks";

export const EmptyJourney: React.FC = () => {
  const navigate = useNavigateInsideTenant();
  return (
    <DefaultContentContainer className="flex h-full items-center justify-center">
      <Text
        className="text-center text-2xl"
        tx="journey.noEventsEnteredYet"
        txComponents={{
          ChatLink: <Link onClick={() => navigate(FrontendPageEnum.CHAT)} />,
        }}
      />
    </DefaultContentContainer>
  );
};
