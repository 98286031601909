import { I18nProps, Text } from "../components/text";
import { DefaultContentContainer } from "../layouts/default-content-container";

export const NotFound: React.FC<{ title?: I18nProps }> = ({ title }) => {
  return (
    <DefaultContentContainer className="flex h-full items-center justify-center">
      <Text as="div" className="text-2xl" tx={title?.tx ?? "pageNotFound"} />
    </DefaultContentContainer>
  );
};
