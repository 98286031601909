import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AuthNavigateState } from "./types";
import { useAuthNavigateState } from "./use-auth-navigate-state";

type AuthNavigateTarget =
  | {
      type: "redirect_url";
    }
  | {
      type: "current_url";
    }
  | { type: "uri"; uri: string };

type AuthNavigate = (props: {
  to: AuthNavigateTarget;
  replace?: boolean;
  state?: {
    overwritePrefillValues?: Record<string, string>;
    message?: AuthNavigateState["message"];
    overwriteRedirectUrl?: string;
  };
}) => void;

/**
 * !For all navigations within auth pages, this hook should be used.
 *
 * Reason:
 * - State (i.e., to which URI to redirect after successful login) is saved between visits of different authentication pages using the state browser API (wrapped by react-router-dom).
 * - This hook automatically sets that state correctly when navigating between auth pages.
 */
export const useAuthNavigate = (
  autoOverwritePrefillValues: () => Record<string, string> = () => ({}),
  dependencies: unknown[] = [],
): AuthNavigate => {
  const navigate = useNavigate();
  const state = useAuthNavigateState();
  const location = useLocation();

  return useCallback<AuthNavigate>(
    ({
      to,
      replace,
      state: {
        message,
        overwritePrefillValues = {},
        overwriteRedirectUrl,
      } = {},
    }) =>
      navigate(
        to.type === "redirect_url"
          ? overwriteRedirectUrl ?? state.redirectUrl ?? "/"
          : to.type === "current_url"
          ? location
          : to.uri,
        {
          replace,
          state: {
            message,
            prefillValues: {
              ...state.prefillValues,
              ...autoOverwritePrefillValues(),
              ...overwritePrefillValues,
            },
            redirectUrl: overwriteRedirectUrl ?? state.redirectUrl,
          } as AuthNavigateState,
        },
      ),
    [
      autoOverwritePrefillValues,
      navigate,
      state.prefillValues,
      state.redirectUrl,
      location,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ...dependencies,
    ],
  );
};
