import { toDetails } from "./http-error-details";
import { SentryError } from "./sentry-error";
import { ResponseContext } from "../../api/generated/backend";

export class RequestError extends SentryError {
  public constructor(
    public readonly context: ResponseContext,
    public readonly body?: object,
  ) {
    super("RequestError", toDetails(context));
  }
}
