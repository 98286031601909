import { useCallback } from "react";
import { FrontendPageEnum } from "shared/model/pages";

import { useActiveLandbotUrlTx } from "./landbot/active-landbot-url-tx";
import { resetLocalLandbotState } from "./landbot/session-storage";
import { useObservedUserData } from "../../auth/auth-store-context";
import { useNavigateInsideTenant } from "../../util/navigation-hooks";

export const useEditLandbotTimeline = (): (() => void) | null => {
  const landbotUrlTx = useActiveLandbotUrlTx();
  const navigate = useNavigateInsideTenant();
  const userData = useObservedUserData();
  const editLandbotTimeline = useCallback(() => {
    if (landbotUrlTx && userData?.sub) {
      resetLocalLandbotState({ landbotUrlTx, sub: userData.sub });
    }
    navigate(FrontendPageEnum.CHAT);
  }, [navigate, userData?.sub, landbotUrlTx]);
  return landbotUrlTx ? editLandbotTimeline : null;
};
