import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { FrontendPageEnum } from "shared/model/pages";

import {
  CreateNewsStoryDto,
  CreateNewsStoryRequest,
  UpdateNewsStoryRequest,
} from "../api/generated/backend";
import {
  toTenantId,
  toTenantIdHeader,
  useApiMutation,
  useApiQuery,
} from "../api/use-api";
import { GhostButton } from "../components/form/button";
import { LoadingScreen } from "../components/loading-screen";
import { EditExternalNewsStory } from "../components/news/edit-external-news-story";
import { EditInternalNewsStory } from "../components/news/edit-internal-news-story";
import { EditNewsStorySwitch } from "../components/news/edit-news-story-switch";
import { NewsCard } from "../components/news/news-card";
import { StoryEnum } from "../components/news/news-story.props";
import { DefaultContentContainer } from "../layouts/default-content-container";
import {
  NEWS_STORIES_KEY,
  NEWS_STORY_DRAFTS_KEY,
  NEWS_STORY_KEY,
} from "../types/query-keys";
import {
  useNavigateBack,
  useNavigateInsideTenant,
} from "../util/navigation-hooks";
import { useTenantId } from "../util/use-active-tenant-id";

export const EditNewsStory: React.FC = () => {
  const { id } = useParams();
  const tenantId = useTenantId();

  const navigateBack = useNavigateBack();
  const navigate = useNavigateInsideTenant();

  const queryClient = useQueryClient();

  const [storyType, setStoryType] = useState<StoryEnum | undefined>();

  const { data: newsStory, isError } = useApiQuery(
    "backend",
    (api) => api.getNewsStory({ id: id || "" }),
    NEWS_STORY_KEY(id),
    undefined,
    {
      enabled: !!id && id !== "new",
      refetchOnWindowFocus: false,
      onSuccess: (story) => {
        setStoryType(
          story.body && !story.link
            ? StoryEnum.INTERNAL
            : !story.body && story.link
            ? StoryEnum.EXTERNAL
            : undefined,
        );
      },
    },
  );

  const {
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    control,
    formState: { errors, isSubmitting },
  } = useForm<CreateNewsStoryDto>({
    defaultValues: newsStory,
  });

  useEffect(() => {
    reset(newsStory);
  }, [newsStory, reset]);

  const { mutate: updateNewsStory, isLoading: updateIsLoading } =
    useApiMutation(
      "backend",
      (api) => (request: UpdateNewsStoryRequest) =>
        api.updateNewsStory(request),
      undefined,
      { successMessage: { tx: "editNews.updateSuccessfulMessage" } },
      {
        onSuccess: (updated, header) => {
          reset(updated);
          if (updated.isPublished) {
            queryClient.invalidateQueries(NEWS_STORIES_KEY(toTenantId(header)));
          } else {
            queryClient.invalidateQueries(
              NEWS_STORY_DRAFTS_KEY(toTenantId(header)),
            );
          }
          navigate(FrontendPageEnum.ADMIN_NEWS);
        },
      },
    );

  const { mutate: createNewsStory, isLoading: createIsLoading } =
    useApiMutation(
      "backend",
      (api) => (request: CreateNewsStoryRequest) =>
        api.createNewsStory(request),
      undefined,
      { successMessage: { tx: "editNews.createSuccessfulMessage" } },
      {
        onSuccess: (created, header) => {
          queryClient.invalidateQueries(
            NEWS_STORY_DRAFTS_KEY(toTenantId(header)),
          );

          navigate(
            FrontendPageEnum.ADMIN_NEWS_EDIT.replace(":id", created.id),
            {
              replace: true,
            },
          );
          navigate(FrontendPageEnum.ADMIN_NEWS);
        },
      },
    );

  const onSave = handleSubmit(async (data) => {
    if (!id) return;

    if (id !== "new") {
      updateNewsStory({
        id: id,
        updateNewsStoryDto: data,
        ...toTenantIdHeader(tenantId),
      });
    } else {
      createNewsStory({
        createNewsStoryDto: data,
        ...toTenantIdHeader(tenantId),
      });
    }
  });

  return storyType || id === "new" ? (
    <DefaultContentContainer>
      <div className="flex justify-between">
        <GhostButton
          icon="left"
          tx="navigateBack"
          onClick={navigateBack}
          className="mb-4 pl-0 "
        />
        {id === "new" && (
          <EditNewsStorySwitch
            reset={reset}
            setStoryType={setStoryType}
            storyType={storyType ?? StoryEnum.INTERNAL}
          />
        )}
      </div>
      <div className="flex flex-row gap-8">
        {storyType === StoryEnum.EXTERNAL ? (
          <EditExternalNewsStory
            control={control}
            onSave={onSave}
            isError={isError}
            register={register}
            isLoading={createIsLoading || updateIsLoading}
            formState={{ errors, isSubmitting }}
          />
        ) : (
          <EditInternalNewsStory
            control={control}
            onSave={onSave}
            isError={isError}
            register={register}
            getValues={getValues}
            isLoading={createIsLoading || updateIsLoading}
            formState={{ errors, isSubmitting }}
          />
        )}
        <NewsCard
          newsStory={{
            id: "",
            ...newsStory,
            ...watch(),
          }}
          isEdit={true}
          className="w-80 max-w-full self-start"
        />
      </div>
    </DefaultContentContainer>
  ) : (
    <LoadingScreen />
  );
};
