/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Conversation } from './Conversation';
import {
    ConversationFromJSON,
    ConversationFromJSONTyped,
    ConversationToJSON,
} from './Conversation';
import type { DiseaseSettings } from './DiseaseSettings';
import {
    DiseaseSettingsFromJSON,
    DiseaseSettingsFromJSONTyped,
    DiseaseSettingsToJSON,
} from './DiseaseSettings';
import type { Embedding } from './Embedding';
import {
    EmbeddingFromJSON,
    EmbeddingFromJSONTyped,
    EmbeddingToJSON,
} from './Embedding';
import type { FollowUpEdge } from './FollowUpEdge';
import {
    FollowUpEdgeFromJSON,
    FollowUpEdgeFromJSONTyped,
    FollowUpEdgeToJSON,
} from './FollowUpEdge';
import type { QuestionNode } from './QuestionNode';
import {
    QuestionNodeFromJSON,
    QuestionNodeFromJSONTyped,
    QuestionNodeToJSON,
} from './QuestionNode';

/**
 * Represents a QuestionnaireGraph record
 * @export
 * @interface QuestionnaireGraph
 */
export interface QuestionnaireGraph {
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireGraph
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireGraph
     */
    version: string;
    /**
     * 
     * @type {Date}
     * @memberof QuestionnaireGraph
     */
    createdAt: Date;
    /**
     * 
     * @type {Array<QuestionNode>}
     * @memberof QuestionnaireGraph
     */
    nodes?: Array<QuestionNode>;
    /**
     * 
     * @type {Array<FollowUpEdge>}
     * @memberof QuestionnaireGraph
     */
    edges?: Array<FollowUpEdge>;
    /**
     * 
     * @type {DiseaseSettings}
     * @memberof QuestionnaireGraph
     */
    disease?: DiseaseSettings;
    /**
     * 
     * @type {string}
     * @memberof QuestionnaireGraph
     */
    diseaseId: string;
    /**
     * 
     * @type {Array<Conversation>}
     * @memberof QuestionnaireGraph
     */
    conversation?: Array<Conversation>;
    /**
     * 
     * @type {Array<Embedding>}
     * @memberof QuestionnaireGraph
     */
    embeddings?: Array<Embedding>;
}

/**
 * Check if a given object implements the QuestionnaireGraph interface.
 */
export function instanceOfQuestionnaireGraph(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "version" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "diseaseId" in value;

    return isInstance;
}

export function QuestionnaireGraphFromJSON(json: any): QuestionnaireGraph {
    return QuestionnaireGraphFromJSONTyped(json, false);
}

export function QuestionnaireGraphFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionnaireGraph {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'version': json['version'],
        'createdAt': (new Date(json['createdAt'])),
        'nodes': !exists(json, 'nodes') ? undefined : ((json['nodes'] as Array<any>).map(QuestionNodeFromJSON)),
        'edges': !exists(json, 'edges') ? undefined : ((json['edges'] as Array<any>).map(FollowUpEdgeFromJSON)),
        'disease': !exists(json, 'disease') ? undefined : DiseaseSettingsFromJSON(json['disease']),
        'diseaseId': json['diseaseId'],
        'conversation': !exists(json, 'conversation') ? undefined : ((json['conversation'] as Array<any>).map(ConversationFromJSON)),
        'embeddings': !exists(json, 'embeddings') ? undefined : ((json['embeddings'] as Array<any>).map(EmbeddingFromJSON)),
    };
}

export function QuestionnaireGraphToJSON(value?: QuestionnaireGraph | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'version': value.version,
        'createdAt': (value.createdAt.toISOString()),
        'nodes': value.nodes === undefined ? undefined : ((value.nodes as Array<any>).map(QuestionNodeToJSON)),
        'edges': value.edges === undefined ? undefined : ((value.edges as Array<any>).map(FollowUpEdgeToJSON)),
        'disease': DiseaseSettingsToJSON(value.disease),
        'diseaseId': value.diseaseId,
        'conversation': value.conversation === undefined ? undefined : ((value.conversation as Array<any>).map(ConversationToJSON)),
        'embeddings': value.embeddings === undefined ? undefined : ((value.embeddings as Array<any>).map(EmbeddingToJSON)),
    };
}

