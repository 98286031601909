import { useForm } from "react-hook-form";
import { SupportedGroupsEnum } from "shared/model/cognito";

import { JoinGroupRequest } from "../api/generated/backend";
import {
  toTenantId,
  toTenantIdHeader,
  useApiMutation,
  useApiQuery,
} from "../api/use-api";
import { Form } from "../components/form/form";
import { FormTextInput } from "../components/form/form-text-input";
import { LoadingButton } from "../components/form/loading-button";
import { LoadingScreen } from "../components/loading-screen";
import { Text, tx } from "../components/text";
import { DefaultContentContainer } from "../layouts/default-content-container";
import { queryClient } from "../query-client";
import { FOLLOWED_PAGS_KEY, ME_KEY } from "../types/query-keys";
import { useTenantId } from "../util/use-active-tenant-id";

export const AdminSignup: React.FC = () => {
  const tenantId = useTenantId();
  const { data: user } = useApiQuery(
    "backend",
    (api) => api.getMe(toTenantIdHeader(tenantId)),
    ME_KEY(tenantId),
  );

  const { mutateAsync: joinAdminGroup } = useApiMutation(
    "backend",
    (api) => (params: JoinGroupRequest) => api.joinGroup(params),
    undefined,
    { successMessage: { tx: "adminSignup.adminSignupSuccessful" } },
    {
      onSuccess: (_, headers) => {
        queryClient.invalidateQueries(ME_KEY(toTenantId(headers)));
        queryClient.invalidateQueries(FOLLOWED_PAGS_KEY(tenantId.disease));
      },
    },
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<{
    adminPassword: string;
  }>();

  const signUpAsAdmin = handleSubmit(async (values) => {
    try {
      await joinAdminGroup({
        groupInfoDto: {
          groupSecret: values.adminPassword,
          group: SupportedGroupsEnum.ADMIN,
        },
        ...toTenantIdHeader(tenantId),
      });
    } catch {
      /* empty */
    }
  });
  return !user ? (
    <LoadingScreen />
  ) : user.tenantGroups.includes(SupportedGroupsEnum.ADMIN) ? (
    <DefaultContentContainer className="flex h-full items-center justify-center">
      <Text as="div" className="text-2xl" tx="adminSignup.alreadyAdmin" />
    </DefaultContentContainer>
  ) : (
    <DefaultContentContainer className="max-w-[42rem]">
      <Form onSubmit={signUpAsAdmin}>
        <FormTextInput
          type="password"
          className="-mx-3 -mt-3"
          title={{ tx: "auth.signUp.inputs.groupSecret.title" }}
          placeholder={{ tx: "auth.signUp.inputs.groupSecret.placeholder" }}
          {...register("adminPassword", {
            required: tx("auth.signUp.inputs.groupSecret.fieldMissingError"),
          })}
          error={{ txUnchecked: errors.adminPassword?.message }}
        />

        <LoadingButton
          loading={isSubmitting}
          tx="auth.signUp.adminSignUpButton"
        />
      </Form>
    </DefaultContentContainer>
  );
};
