import { PrimaryButton } from "../form/button";
import { Text } from "../text";

export const StripeDashboard: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-5">
      <Text
        className="max-w-xl text-center text-2xl"
        tx="stripe.stripeAccountConnected"
      />
      <PrimaryButton
        tx="stripe.viewInDashboard"
        onClick={() => {
          window.open("https://dashboard.stripe.com");
        }}
      />
    </div>
  );
};
