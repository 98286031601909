import { BodyTextInputFilter } from "../../filter/filter-setters/body-text-input-filter";
import { CategoryMultiSelectDropdownFilter } from "../../filter/filter-setters/category-multi-select-filter";
import { LanguageDropdownFilter } from "../../filter/filter-setters/language-dropdown-filter";
import { LikesSorter } from "../../filter/filter-setters/likes-sorter";
import {
  FilterComponentsConfigType,
  FilterProperty,
  SortConfigProperty,
} from "../../filter/types";

export const SocialMediaFiltersConfig: FilterComponentsConfigType = {
  [SortConfigProperty.LIKES]: LikesSorter,
  [FilterProperty.CATEGORIES]: CategoryMultiSelectDropdownFilter,
  [FilterProperty.LANGUAGE]: LanguageDropdownFilter,
  [FilterProperty.BODY]: BodyTextInputFilter,
};
