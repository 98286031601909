import React from "react";
import {
  Control,
  Controller,
  Path,
  PathValue,
  Validate,
} from "react-hook-form";
import { twMerge } from "tailwind-merge";

import { DropdownProps } from "./dropdown";
import { FormInput } from "./form-input";
import { SearchableDropdown } from "./searchable-dropdown";
import { PlainI18nProps } from "../text";

export type FormSearchableDropdownInputProps<
  Values extends object,
  Name extends Path<Values>,
> = {
  title: PlainI18nProps;
  error: PlainI18nProps;
  disabled?: boolean;
  name: Name;
  control: Control<Values>;
  defaultValue?: PathValue<Values, Name>;
  options: DropdownProps<NonNullable<PathValue<Values, Name>>>["options"];
  validate?: Validate<PathValue<Values, Name>, Values>;
  className?: string;
  labelClassName?: string;
  resultsLimit?: number;
  formatValue?: (value: string) => string;
};

export const FormSearchableDropdownInput = <
  Values extends object,
  Name extends Path<Values>,
>({
  title,
  error,
  disabled,
  name,
  control,
  options,
  validate,
  className,
  labelClassName,
  defaultValue,
  resultsLimit,
  formatValue,
}: FormSearchableDropdownInputProps<Values, Name>): ReturnType<React.FC> => {
  return (
    <FormInput title={title} error={error} labelClassName={labelClassName}>
      <Controller
        name={name}
        control={control}
        rules={{
          validate,
        }}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <SearchableDropdown
            selectedValueClassName="font-normal"
            className={twMerge(className, "w-full")}
            disabled={disabled}
            value={formatValue ? formatValue(value) : value}
            onChange={(lValue) => onChange(lValue ?? "")}
            options={options}
            resultsLimit={resultsLimit}
          />
        )}
      />
    </FormInput>
  );
};
