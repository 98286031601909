import { StoryEnum } from "./news-story.props";
import { Text } from "../text";

export const EditNewsStorySwitch: React.FC<{
  reset: () => void;
  storyType?: StoryEnum;
  setStoryType: (value: StoryEnum) => void;
}> = ({ reset, setStoryType, storyType }) => {
  return (
    <div className="mb-10 flex flex-grow items-center justify-center">
      <div className="tabs-boxed flex self-center">
        <Text
          tx="editNews.internal"
          className={`tab-md tab tab-${
            storyType === StoryEnum.INTERNAL ? "active" : ""
          }`}
          onClick={() => {
            reset();
            setStoryType(StoryEnum.INTERNAL);
          }}
        />
        <Text
          tx="editNews.external"
          className={`tab-md tab tab-${
            storyType === StoryEnum.EXTERNAL ? "active" : ""
          }`}
          onClick={() => {
            reset();
            setStoryType(StoryEnum.EXTERNAL);
          }}
        />
      </div>
    </div>
  );
};
