/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EventDetailDatatype } from './EventDetailDatatype';
import {
    EventDetailDatatypeFromJSON,
    EventDetailDatatypeFromJSONTyped,
    EventDetailDatatypeToJSON,
} from './EventDetailDatatype';

/**
 * 
 * @export
 * @interface UpdateEventDetail
 */
export interface UpdateEventDetail {
    /**
     * 
     * @type {string}
     * @memberof UpdateEventDetail
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEventDetail
     */
    type: string;
    /**
     * 
     * @type {Array<EventDetailDatatype>}
     * @memberof UpdateEventDetail
     */
    expectedDatatypes?: Array<EventDetailDatatype>;
    /**
     * 
     * @type {string}
     * @memberof UpdateEventDetail
     */
    text?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateEventDetail
     */
    number?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateEventDetail
     */
    unit?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEventDetail
     */
    select?: boolean;
    /**
     * 
     * @type {Array<UpdateEventDetail>}
     * @memberof UpdateEventDetail
     */
    details: Array<UpdateEventDetail>;
}

/**
 * Check if a given object implements the UpdateEventDetail interface.
 */
export function instanceOfUpdateEventDetail(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "details" in value;

    return isInstance;
}

export function UpdateEventDetailFromJSON(json: any): UpdateEventDetail {
    return UpdateEventDetailFromJSONTyped(json, false);
}

export function UpdateEventDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateEventDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'expectedDatatypes': !exists(json, 'expected_datatypes') ? undefined : ((json['expected_datatypes'] as Array<any>).map(EventDetailDatatypeFromJSON)),
        'text': !exists(json, 'text') ? undefined : json['text'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'select': !exists(json, 'select') ? undefined : json['select'],
        'details': ((json['details'] as Array<any>).map(UpdateEventDetailFromJSON)),
    };
}

export function UpdateEventDetailToJSON(value?: UpdateEventDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'expected_datatypes': value.expectedDatatypes === undefined ? undefined : ((value.expectedDatatypes as Array<any>).map(EventDetailDatatypeToJSON)),
        'text': value.text,
        'number': value.number,
        'unit': value.unit,
        'select': value.select,
        'details': ((value.details as Array<any>).map(UpdateEventDetailToJSON)),
    };
}

