import { ErrorContext, ResponseContext } from "../../api/generated/backend";

type Context = ResponseContext | ErrorContext;

interface SimpleHeaders {
  headers: Record<string, string>;
}

export interface HTTPErrorDetails {
  request: Pick<
    RequestInit,
    | "method"
    | "mode"
    | "redirect"
    | "referrer"
    | "referrerPolicy"
    | "credentials"
    | "cache"
  > &
    SimpleHeaders;
  response: Partial<
    Pick<
      Response,
      "ok" | "redirected" | "status" | "statusText" | "url" | "type"
    > &
      SimpleHeaders
  >;
}

function headersToRecord(headers: Headers): Record<string, string> {
  const mapped: Record<string, string> = {};

  for (const [key, value] of headers.entries()) {
    mapped[value] = key;
  }

  return mapped;
}

export function toDetails(context: Context): HTTPErrorDetails {
  const { url } = context;
  const {
    headers: reqHeaders,
    method,
    mode,
    redirect,
    referrer,
    referrerPolicy,
    credentials,
    cache,
  } = context.init;
  const responseHeaders = reqHeaders ? new Headers(reqHeaders) : new Headers();

  return {
    request: {
      headers: headersToRecord(responseHeaders),
      method,
      mode,
      redirect,
      referrer,
      referrerPolicy,
      credentials,
      cache,
    },
    response: {
      url: url,
      ...(context.response
        ? {
            headers: headersToRecord(context.response.headers),
            status: context.response.status,
            statusText: context.response.statusText,
            type: context.response.type,
            url: context.response.url,
            ok: context.response.ok,
          }
        : {}),
    },
  };
}

export function errorMessage({
  response: { url: url, status: status },
}: HTTPErrorDetails): string {
  if (status) {
    return `Request to ${url} failed with code ${status}`;
  }

  return `Request to ${url} failed`;
}
