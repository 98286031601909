import * as Sentry from "@sentry/react";
import { useEffect } from "react";

import { useTenantId } from "../util/use-active-tenant-id";
import { useUser } from "../util/use-user";

export const LinkSentryToUser: React.FC = () => {
  const user = useUser();
  const tenantId = useTenantId();

  useEffect(() => {
    Sentry.setTag("disease", tenantId.disease);
    Sentry.setTag("organisation", tenantId.organisation ?? "DEFAULT");
  }, [tenantId]);

  useEffect(() => {
    if (user?.id) {
      Sentry.setUser({ id: user.id });
    }
  }, [user?.id]);

  return null;
};
