"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LookingFor = exports.UserDetailType = exports.Gender = void 0;
var Gender;
(function (Gender) {
    Gender["MALE"] = "MALE";
    Gender["FEMALE"] = "FEMALE";
    Gender["INTERSEX"] = "INTERSEX";
})(Gender || (exports.Gender = Gender = {}));
var UserDetailType;
(function (UserDetailType) {
    UserDetailType["CONDITION_START_DATE"] = "CONDITION_START_DATE";
    UserDetailType["MEDICATIONS"] = "MEDICATIONS";
    UserDetailType["CARE_PROVIDER"] = "CARE_PROVIDER";
    UserDetailType["CONDITION_VARIANT"] = "CONDITION_VARIANT";
})(UserDetailType || (exports.UserDetailType = UserDetailType = {}));
var LookingFor;
(function (LookingFor) {
    LookingFor["CONNECTING_WITH_PEOPLE"] = "CONNECTING_WITH_PEOPLE";
    LookingFor["PSYCHOLOGICAL_SUPPORT"] = "PSYCHOLOGICAL_SUPPORT";
    LookingFor["INFORMATION_ON_NUTRITION"] = "INFORMATION_ON_NUTRITION";
    LookingFor["INFORMATION_ON_PHYSICAL_ACTIVITIES"] = "INFORMATION_ON_PHYSICAL_ACTIVITIES";
    LookingFor["FINDING_SPECIALIZED_CENTERS"] = "FINDING_SPECIALIZED_CENTERS";
    LookingFor["FINDING_SPECIALIZED_PROFESSIONALS"] = "FINDING_SPECIALIZED_PROFESSIONALS";
    LookingFor["OTHER"] = "OTHER";
})(LookingFor || (exports.LookingFor = LookingFor = {}));
