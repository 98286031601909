/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateNewsStoryDto
 */
export interface CreateNewsStoryDto {
    /**
     * 
     * @type {string}
     * @memberof CreateNewsStoryDto
     */
    link?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNewsStoryDto
     */
    body?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNewsStoryDto
     */
    headline: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNewsStoryDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNewsStoryDto
     */
    imageLink: string;
    /**
     * 
     * @type {string}
     * @memberof CreateNewsStoryDto
     */
    language: string;
}

/**
 * Check if a given object implements the CreateNewsStoryDto interface.
 */
export function instanceOfCreateNewsStoryDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "headline" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "imageLink" in value;
    isInstance = isInstance && "language" in value;

    return isInstance;
}

export function CreateNewsStoryDtoFromJSON(json: any): CreateNewsStoryDto {
    return CreateNewsStoryDtoFromJSONTyped(json, false);
}

export function CreateNewsStoryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateNewsStoryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'link': !exists(json, 'link') ? undefined : json['link'],
        'body': !exists(json, 'body') ? undefined : json['body'],
        'headline': json['headline'],
        'description': json['description'],
        'imageLink': json['imageLink'],
        'language': json['language'],
    };
}

export function CreateNewsStoryDtoToJSON(value?: CreateNewsStoryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'link': value.link,
        'body': value.body,
        'headline': value.headline,
        'description': value.description,
        'imageLink': value.imageLink,
        'language': value.language,
    };
}

