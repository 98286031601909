import React, { useEffect, useMemo } from "react";
import { Language } from "shared/model/languages";
import { FrontendPageEnum } from "shared/model/pages";

import { toTenantIdHeader, useApiQuery } from "../api/use-api";
import { FilterElement } from "../components/filter/filter-element";
import { applyQuery } from "../components/filter/query";
import {
  FilterProperty,
  FilterType,
  PaginationConfig,
  PropertyFilter,
  PropertyFilterRule,
  SortConfig,
  SortConfigDirection,
  SortConfigProperty,
} from "../components/filter/types";
import { useQueryParams } from "../components/filter/use-filters";
import { GhostButton } from "../components/form/button";
import { useAppLanguage } from "../components/form/language/use-app-language";
import { MultiColumnSection, SectionHeading } from "../components/gallery";
import { NewsFilterComponentsConfig } from "../components/news/filter/constants";
import { NewsCard } from "../components/news/news-card";
import { PaginatedList } from "../components/paginated-list";
import { Text } from "../components/text";
import { DefaultContentContainer } from "../layouts/default-content-container";
import {
  NEWS_STORIES_KEY,
  NEWS_STORY_DRAFTS_KEY,
  NEWS_STORY_PINNED_KEY,
} from "../types/query-keys";
import { useNavigateInsideTenant } from "../util/navigation-hooks";
import { useTenantId } from "../util/use-active-tenant-id";

const storyClasses = "self-stretch justify-self-stretch";

export const News: React.FC<{ isAdmin?: boolean }> = ({ isAdmin = false }) => {
  const tenantId = useTenantId();
  const { appLanguage } = useAppLanguage();

  const [queryParams, applyQueryParams] = useQueryParams({
    defaultSorting: {
      type: FilterType.SORT,
      property: SortConfigProperty.PUBLISHED_AT,
      direction: SortConfigDirection.DESC,
    },

    defaultFilter: [
      {
        type: FilterType.FILTER,
        rule: PropertyFilterRule.CONTAINS,
        property: FilterProperty.HEADLINE,
        value: "",
      },
      {
        type: FilterType.FILTER,
        rule: PropertyFilterRule.ARE,
        property: FilterProperty.ORGANISATION,
        value: [],
      },
      {
        type: FilterType.FILTER,
        rule: PropertyFilterRule.IS,
        property: FilterProperty.LANGUAGE,
        value: appLanguage,
      },
    ],

    defaultPagination: {
      type: FilterType.PAGINATION,
      page: 1,
      resultsPerPage: 20,
    },
  });

  const sortQuery = useMemo(
    () => queryParams.find((filter) => filter?.type === FilterType.SORT),
    [queryParams],
  ) as SortConfig;

  const filterQuery = useMemo(() => {
    return queryParams.filter((filter) => filter?.type === FilterType.FILTER);
  }, [queryParams]) as PropertyFilter[];

  const paginationQuery = useMemo(
    () => queryParams.find((filter) => filter?.type === FilterType.PAGINATION),
    [queryParams],
  ) as PaginationConfig;

  const filterLanguage =
    (filterQuery.find((query) => query.property === FilterProperty.LANGUAGE)
      ?.value as Language | undefined) ?? appLanguage;

  const { data: newsStoryDrafts, refetch: refetchDrafts } = useApiQuery(
    "backend",
    (api) =>
      api.listDraftNewsStories({
        ...toTenantIdHeader(tenantId),
        language: filterLanguage,
      }),
    NEWS_STORY_DRAFTS_KEY(tenantId),
    undefined,
    { enabled: isAdmin },
  );

  const { data: newsStoryPinned, refetch: refetchPinned } = useApiQuery(
    "backend",
    (api) =>
      api.listPinnedNewsStories({
        ...toTenantIdHeader(tenantId),
        language: filterLanguage,
      }),
    NEWS_STORY_PINNED_KEY(tenantId),
    undefined,
  );
  const { data: rawNewsStories, refetch: refetchRaw } = useApiQuery(
    "backend",
    (api) =>
      api.listNewsStories({
        ...toTenantIdHeader(tenantId),
        language: filterLanguage,
      }),
    [...NEWS_STORIES_KEY(tenantId)],
    undefined,
  );

  useEffect(() => {
    applyQueryParams({ type: FilterType.PAGINATION, page: 1 });
  }, [applyQueryParams, rawNewsStories]);

  useEffect(() => {
    if (isAdmin) {
      refetchDrafts();
    }
    refetchRaw();
    refetchPinned();
  }, [isAdmin, refetchDrafts, refetchPinned, refetchRaw, filterLanguage]);

  const newsStories = useMemo(() => {
    return (
      rawNewsStories &&
      applyQuery(rawNewsStories, {
        sortingParams: sortQuery,
        filterParams: filterQuery as PropertyFilter[],
        paginationParams: paginationQuery,
      })
    );
  }, [rawNewsStories, sortQuery, filterQuery, paginationQuery]);

  return (
    <DefaultContentContainer className="flex flex-col gap-4">
      {(isAdmin || (newsStoryPinned && newsStoryPinned.length > 0)) && (
        <SectionHeading tx="news.pinned" className="relative" />
      )}
      {newsStoryPinned && newsStoryPinned.length > 0 && (
        <MultiColumnSection>
          {newsStoryPinned?.map((newsStory, i) => (
            <NewsCard
              className={storyClasses}
              key={i}
              newsStory={newsStory}
              isAdmin={isAdmin}
            />
          ))}
        </MultiColumnSection>
      )}

      {isAdmin && (
        <>
          {newsStoryPinned?.length === 0 && (
            <Text tx="news.noPinnedNews" className="text-center" />
          )}
          <SectionHeading tx="news.drafts" className="relative">
            <div className="absolute right-1 flex flex-row gap-2">
              <AddNewsButton />
            </div>
          </SectionHeading>
          <MultiColumnSection>
            {newsStoryDrafts?.map((newsStory, i) => (
              <NewsCard
                className={storyClasses}
                key={i}
                newsStory={newsStory}
                isAdmin={isAdmin}
              />
            ))}
          </MultiColumnSection>
          {newsStoryDrafts?.length === 0 && (
            <Text tx="news.noDrafts" className="text-center" />
          )}

          <SectionHeading tx="news.published" className="mt-8" />
        </>
      )}

      <FilterElement
        config={{
          filterConfig: filterQuery,
          sortConfig: sortQuery,
          paginationConfig: paginationQuery,
          onFilterChange: applyQueryParams,
        }}
        components={NewsFilterComponentsConfig}
      />

      <PaginatedList
        page={newsStories?.page}
        pages={newsStories?.pageCount}
        onPageChange={applyQueryParams}
        hidden={!newsStories?.results.length}
      >
        <MultiColumnSection>
          {newsStories?.results.map((newsStory, i) => (
            <NewsCard
              key={i}
              isAdmin={isAdmin}
              newsStory={newsStory}
              className={storyClasses}
            />
          ))}
        </MultiColumnSection>
      </PaginatedList>
      {newsStories?.results.length === 0 && (
        <Text tx="news.noPublished" className="text-center" />
      )}
    </DefaultContentContainer>
  );
};

const AddNewsButton: React.FC = () => {
  const navigate = useNavigateInsideTenant();
  return (
    <GhostButton
      tx="news.actions.add"
      icon="plus"
      onClick={() => {
        navigate(FrontendPageEnum.ADMIN_NEWS_EDIT.replace(":id", "new"));
      }}
    />
  );
};
