import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Language } from "shared/model/languages";

import { LanguageKey } from "./constants";

export const useAppLanguage = (): {
  appLanguageKey: LanguageKey;
  appLanguage: Language;
} => {
  const [searchParams] = useSearchParams();
  const queryLanguage = searchParams.get("language");
  const {
    i18n: { language },
  } = useTranslation();

  const appLanguage = useMemo(
    () => (!!queryLanguage ? queryLanguage : language) as Language,
    [language, queryLanguage],
  );

  const appLanguageKey = useMemo(
    () =>
      Object.keys(Language).find(
        (key) => Language[key as LanguageKey] === appLanguage,
      ) as LanguageKey,
    [appLanguage],
  );

  return {
    appLanguageKey: appLanguageKey,
    appLanguage: appLanguage,
  };
};
