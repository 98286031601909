import { createContext, useContext } from "react";
import { twMerge } from "tailwind-merge";

import {
  FilterComponentsConfigType,
  FilterElementContextType,
  FilterProperty,
  FilterType,
  PropertyFilterRule,
  SortConfigDirection,
  SortConfigProperty,
} from "./types";

export const FilterElement: React.FC<{
  components: Partial<FilterComponentsConfigType>;
  config: FilterElementContextType;
  className?: string;
}> = ({ components, config, className }) => {
  return (
    <FilterElementContext.Provider value={config}>
      <div
        className={twMerge(
          "flex w-full flex-row flex-wrap justify-between",
          className,
        )}
      >
        <div className="flex flex-row gap-4">
          {Object.values(components).map((Component, key) =>
            Component ? <Component key={`${key}`} /> : null,
          )}
        </div>
      </div>
    </FilterElementContext.Provider>
  );
};

export const useFilterElementContext = (): FilterElementContextType => {
  return useContext(FilterElementContext);
};

const noOpFilterElementContext: FilterElementContextType = {
  filterConfig: [
    {
      type: FilterType.FILTER,
      rule: PropertyFilterRule.CONTAINS,
      property: FilterProperty.HEADLINE,
      value: "",
    },
    {
      type: FilterType.FILTER,
      rule: PropertyFilterRule.ARE,
      property: FilterProperty.ORGANISATION,
      value: [],
    },
    {
      type: FilterType.FILTER,
      rule: PropertyFilterRule.CONTAINS,
      property: FilterProperty.BODY,
      value: "",
    },
    {
      type: FilterType.FILTER,
      rule: PropertyFilterRule.CONTAINS,
      property: FilterProperty.NAME,
      value: "",
    },
    {
      type: FilterType.FILTER,
      rule: PropertyFilterRule.IS,
      property: FilterProperty.LANGUAGE,
      value: "",
    },
  ],
  sortConfig: {
    type: FilterType.SORT,
    property: SortConfigProperty.PUBLISHED_AT,
    direction: SortConfigDirection.DESC,
  },
  paginationConfig: {
    type: FilterType.PAGINATION,
    page: 1,
    resultsPerPage: 20,
  },
  onFilterChange: () => {},
};

const FilterElementContext = createContext(noOpFilterElementContext);
