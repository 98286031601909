interface LandbotSessionStorageKey {
  landbotUrlTx: string;
  sub: string;
}

const landbotSessionStorageKey = ({
  landbotUrlTx,
  sub,
}: LandbotSessionStorageKey) => `landbot:${landbotUrlTx}..${sub}`;

export const saveLandbotStateLocally = (
  key: LandbotSessionStorageKey,
  state: string,
): void => window.sessionStorage.setItem(landbotSessionStorageKey(key), state);

export const loadLocalLandbotState = (
  key: LandbotSessionStorageKey,
): string | null => {
  return window.sessionStorage.getItem(landbotSessionStorageKey(key));
};

export const resetLocalLandbotState = (key: LandbotSessionStorageKey): void =>
  window.sessionStorage.removeItem(landbotSessionStorageKey(key));
