import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Language } from "shared/model/languages";
import { FrontendPageEnum } from "shared/model/pages";

import {
  DeleteSocialMediaPostRequest,
  LikeSocialMediaPostRequest,
} from "../api/generated/backend";
import { toTenantIdHeader, useApiMutation, useApiQuery } from "../api/use-api";
import { FilterElement } from "../components/filter/filter-element";
import { applyQuery } from "../components/filter/query";
import {
  FilterProperty,
  FilterType,
  PaginationConfig,
  PropertyFilter,
  PropertyFilterRule,
} from "../components/filter/types";
import { useQueryParams } from "../components/filter/use-filters";
import { GhostButton } from "../components/form/button";
import { useAppLanguage } from "../components/form/language/use-app-language";
import { MultiColumnSection, SectionHeading } from "../components/gallery";
import { LoadingScreen } from "../components/loading-screen";
import { PaginatedList } from "../components/paginated-list";
import { ManageSocialMediaPostCategoriesList } from "../components/social-media/categories/manage-social-media-post-categories-list";
import { SocialMediaFiltersConfig } from "../components/social-media/filter/constants";
import { SocialMediaPost } from "../components/social-media/social-media-post";
import { SocialMediaPostDetail } from "../components/social-media/social-media-post-detail";
import { Text } from "../components/text";
import { DefaultContentContainer } from "../layouts/default-content-container";
import { useModal } from "../models/modal-provider";
import { SOCIAL_MEDIA_POSTS } from "../types/query-keys";
import { useNavigateInsideTenant } from "../util/navigation-hooks";
import { useTenantId } from "../util/use-active-tenant-id";

export const SocialMedia: React.FC<{ isAdmin?: boolean }> = ({ isAdmin }) => {
  const tenantId = useTenantId();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { showModal } = useModal();

  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const { appLanguage } = useAppLanguage();

  const [queryParams, applyQueryParams] = useQueryParams({
    defaultFilter: [
      {
        type: FilterType.FILTER,
        rule: PropertyFilterRule.CONTAINS,
        property: FilterProperty.BODY,
        value: "",
      },
      {
        type: FilterType.FILTER,
        rule: PropertyFilterRule.ARE_PART_OF,
        property: FilterProperty.CATEGORIES,
        value: [],
      },
      {
        type: FilterType.FILTER,
        rule: PropertyFilterRule.IS,
        property: FilterProperty.LANGUAGE,
        value: appLanguage,
      },
    ],
    defaultPagination: {
      type: FilterType.PAGINATION,
      page: 1,
      resultsPerPage: 20,
    },
  });

  const filterQuery = useMemo(
    () => queryParams.filter((filter) => filter?.type === FilterType.FILTER),
    [queryParams],
  ) as PropertyFilter[];

  const paginationQuery = useMemo(
    () => queryParams.find((filter) => filter?.type === FilterType.PAGINATION),
    [queryParams],
  ) as PaginationConfig;

  const filterLanguage = useMemo(
    () =>
      (filterQuery.find((query) => query.property === FilterProperty.LANGUAGE)
        ?.value as Language | undefined) ?? appLanguage,
    [appLanguage, filterQuery],
  );

  const {
    data: socialMediaPostsRaw,
    isLoading,
    isFetching,
    refetch,
  } = useApiQuery(
    "backend",
    (api) =>
      api.listSocialMediaPosts({
        ...toTenantIdHeader(tenantId),
        language: filterLanguage,
      }),
    SOCIAL_MEDIA_POSTS(tenantId),
  );

  const socialMediaPosts = useMemo(() => {
    return (
      socialMediaPostsRaw &&
      applyQuery(socialMediaPostsRaw, {
        filterParams: filterQuery as PropertyFilter[],
        // sortingParams: sortQuery,
        paginationParams: paginationQuery,
      })
    );
  }, [filterQuery, paginationQuery, socialMediaPostsRaw]);

  useEffect(() => {
    refetch();
  }, [filterLanguage, refetch]);

  const { mutate: likePost } = useApiMutation(
    "backend",
    (api) => (request: LikeSocialMediaPostRequest) =>
      api.likeSocialMediaPost(request),
    undefined,
    undefined,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SOCIAL_MEDIA_POSTS(tenantId));
      },
    },
  );

  const { mutate: deletePost } = useApiMutation(
    "backend",
    (api) => (request: DeleteSocialMediaPostRequest) =>
      api.deleteSocialMediaPost(request),
    undefined,
    undefined,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SOCIAL_MEDIA_POSTS(tenantId));
      },
    },
  );

  const navigateToPostDetail = useCallback(
    (postId: string) => {
      navigate({
        search: createSearchParams({
          language: filterLanguage,
          id: postId,
        }).toString(),
      });
    },
    [filterLanguage, navigate],
  );

  useEffect(() => {
    if (id) {
      showModal({
        children: <SocialMediaPostDetail id={id} />,
        className: "max-w-full h-full lg:max-w-[75%] p-6 md:overflow-hidden",
        showCloseButton: false,
        closeOnClickOutside: true,
        showsWindowCloseButton: true,
        onClose() {
          navigate({
            search: createSearchParams({
              language: filterLanguage,
            }).toString(),
          });
        },
      });
    }
  }, [filterLanguage, id, navigate, showModal]);

  return (
    <DefaultContentContainer className="flex flex-col gap-4">
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          {isAdmin && <SocialMediaAdminControlPannel />}
          <FilterElement
            className="mb-6"
            config={{
              filterConfig: filterQuery,
              paginationConfig: paginationQuery,
              onFilterChange: applyQueryParams,
            }}
            components={SocialMediaFiltersConfig}
          />
          <PaginatedList
            className="gap-8"
            page={socialMediaPosts?.page}
            pages={socialMediaPosts?.pageCount}
            onPageChange={applyQueryParams}
            hidden={!socialMediaPosts?.results.length}
          >
            {socialMediaPosts?.results.length ? (
              <MultiColumnSection>
                {socialMediaPosts.results.map((post) => (
                  <SocialMediaPost
                    key={post.id}
                    post={post}
                    isAdmin={isAdmin}
                    onPostLike={likePost}
                    onPostDelete={deletePost}
                    onOpenPostDetail={navigateToPostDetail}
                  />
                ))}
              </MultiColumnSection>
            ) : (
              !isFetching && (
                <Text
                  tx="socialMedia.noPostsAtTheMoment"
                  className="text-center text-2xl"
                />
              )
            )}
          </PaginatedList>
        </>
      )}
    </DefaultContentContainer>
  );
};

const SocialMediaAdminControlPannel: React.FC = () => {
  const navigate = useNavigateInsideTenant();

  return (
    <div className="flex flex-row justify-start gap-4">
      <SectionHeading
        className="w-[80%] justify-between"
        tx="socialMedia.create"
      >
        <GhostButton
          onClick={() => {
            navigate(
              FrontendPageEnum.ADMIN_SOCIAL_MEDIA_EDIT.replace(":id", "new"),
            );
          }}
          icon="plus"
        />
      </SectionHeading>
      <SectionHeading className="justify-between p-0 pl-0">
        <ManageSocialMediaPostCategoriesList />
      </SectionHeading>
    </div>
  );
};
