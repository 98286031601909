/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MedicalProfessional } from './MedicalProfessional';
import {
    MedicalProfessionalFromJSON,
    MedicalProfessionalFromJSONTyped,
    MedicalProfessionalToJSON,
} from './MedicalProfessional';
import type { MedicalProfessionalRating } from './MedicalProfessionalRating';
import {
    MedicalProfessionalRatingFromJSON,
    MedicalProfessionalRatingFromJSONTyped,
    MedicalProfessionalRatingToJSON,
} from './MedicalProfessionalRating';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * Represents a MedicalProfessionalsOnUsers record
 * @export
 * @interface MedicalProfessionalsOnUsers
 */
export interface MedicalProfessionalsOnUsers {
    /**
     * 
     * @type {string}
     * @memberof MedicalProfessionalsOnUsers
     */
    id: string;
    /**
     * 
     * @type {User}
     * @memberof MedicalProfessionalsOnUsers
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof MedicalProfessionalsOnUsers
     */
    userId: string;
    /**
     * 
     * @type {MedicalProfessional}
     * @memberof MedicalProfessionalsOnUsers
     */
    medicalProfessional?: MedicalProfessional;
    /**
     * 
     * @type {string}
     * @memberof MedicalProfessionalsOnUsers
     */
    medicalProfessionalId: string;
    /**
     * 
     * @type {Array<MedicalProfessionalRating>}
     * @memberof MedicalProfessionalsOnUsers
     */
    rating?: Array<MedicalProfessionalRating>;
}

/**
 * Check if a given object implements the MedicalProfessionalsOnUsers interface.
 */
export function instanceOfMedicalProfessionalsOnUsers(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "medicalProfessionalId" in value;

    return isInstance;
}

export function MedicalProfessionalsOnUsersFromJSON(json: any): MedicalProfessionalsOnUsers {
    return MedicalProfessionalsOnUsersFromJSONTyped(json, false);
}

export function MedicalProfessionalsOnUsersFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalProfessionalsOnUsers {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'userId': json['userId'],
        'medicalProfessional': !exists(json, 'medicalProfessional') ? undefined : MedicalProfessionalFromJSON(json['medicalProfessional']),
        'medicalProfessionalId': json['medicalProfessionalId'],
        'rating': !exists(json, 'rating') ? undefined : ((json['rating'] as Array<any>).map(MedicalProfessionalRatingFromJSON)),
    };
}

export function MedicalProfessionalsOnUsersToJSON(value?: MedicalProfessionalsOnUsers | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'user': UserToJSON(value.user),
        'userId': value.userId,
        'medicalProfessional': MedicalProfessionalToJSON(value.medicalProfessional),
        'medicalProfessionalId': value.medicalProfessionalId,
        'rating': value.rating === undefined ? undefined : ((value.rating as Array<any>).map(MedicalProfessionalRatingToJSON)),
    };
}

