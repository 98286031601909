/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SocialMediaPost } from './SocialMediaPost';
import {
    SocialMediaPostFromJSON,
    SocialMediaPostFromJSONTyped,
    SocialMediaPostToJSON,
} from './SocialMediaPost';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * Represents a Comment record
 * @export
 * @interface Comment
 */
export interface Comment {
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    content: string;
    /**
     * 
     * @type {SocialMediaPost}
     * @memberof Comment
     */
    post?: SocialMediaPost;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    postId: string;
    /**
     * 
     * @type {User}
     * @memberof Comment
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    userId: string;
    /**
     * 
     * @type {Date}
     * @memberof Comment
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the Comment interface.
 */
export function instanceOfComment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "content" in value;
    isInstance = isInstance && "postId" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function CommentFromJSON(json: any): Comment {
    return CommentFromJSONTyped(json, false);
}

export function CommentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Comment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'content': json['content'],
        'post': !exists(json, 'post') ? undefined : SocialMediaPostFromJSON(json['post']),
        'postId': json['postId'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'userId': json['userId'],
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function CommentToJSON(value?: Comment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'content': value.content,
        'post': SocialMediaPostToJSON(value.post),
        'postId': value.postId,
        'user': UserToJSON(value.user),
        'userId': value.userId,
        'createdAt': (value.createdAt.toISOString()),
    };
}

