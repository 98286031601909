import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getTenantSettings as getBaseTenantSettings } from "shared/config";
import { DiseaseConfig } from "shared/model/diseases";
import { Language } from "shared/model/languages";
import { OrganisationConfig } from "shared/model/organisations";
import {
  ChatPageSettings,
  ChatType,
  FrontendPage,
  FrontendPageEnum,
  PageSettings,
  UsablePageSettings,
} from "shared/model/pages";

import { useTenantId } from "./util/use-active-tenant-id";

export type TenantConfig = Omit<
  DiseaseConfig | OrganisationConfig,
  "enabledPages"
>;

export const useTenantConfig = (): TenantConfig => {
  return useTenantConfigInternal();
};

export const usePageSettings = <Page extends FrontendPageEnum>(
  page: Page,
): UsablePageSettings<Page> | undefined => {
  const config = useTenantConfigInternal();
  const {
    i18n: { language },
  } = useTranslation();

  return useMemo(() => {
    const targetPageSettings: PageSettings<Page> | undefined = (
      config.enabledPages?.find((enabledPage) => enabledPage.route === page) as
        | FrontendPage<Page>
        | undefined
    )?.settings;

    if (!targetPageSettings) return undefined;

    return convertToUsablePageSettings(
      targetPageSettings,
      language as Language,
    );
  }, [config.enabledPages, language, page]);
};

const useTenantConfigInternal = (): DiseaseConfig | OrganisationConfig => {
  const tenantId = useTenantId();
  return useMemo(() => getBaseTenantSettings(tenantId), [tenantId]);
};

function convertToUsablePageSettings<Page extends FrontendPageEnum>(
  settings: PageSettings<Page>,
  language: Language,
): UsablePageSettings<Page> {
  if (isUsableChatPageSettings(settings)) {
    settings.chatType = overrideChatType(settings, language as Language);
  }
  return settings as UsablePageSettings<Page>;
}

function isUsableChatPageSettings(
  settings: PageSettings<FrontendPageEnum> | undefined,
): settings is ChatPageSettings {
  return (settings as ChatPageSettings).chatType !== undefined;
}

function overrideChatType(
  settings: ChatPageSettings,
  language: Language,
): ChatType {
  return settings.chatType !== ChatType.MIXED
    ? settings.chatType
    : settings.landbotLanguageOverride?.includes(language)
    ? ChatType.LANDBOT
    : ChatType.AI;
}
