import React, { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ChatType, FrontendPageEnum } from "shared/model/pages";
import { formatTime } from "shared/utils/timeline";
import { twMerge } from "tailwind-merge";

import { useEditLandbotTimeline } from "./chat/use-edit-timeline";
import { EnumTitle } from "./enum-title";
import { GhostButton } from "./form/button";
import { SvgIcon } from "./icons/svg-icon";
import { Text } from "./text";
import { EditableEvent } from "./timeline/editable-event";
import { EventDetail } from "./timeline/event-detail";
import { TimelineBubble } from "./timeline-bubble";
import { EventRecord } from "../api/generated/landbot/models/EventRecord";
import { useModal } from "../models/modal-provider";
import { usePageSettings, useTenantConfig } from "../tenant-settings";
import { useIntersectionObserver } from "../util/hooks";

const stickyClasses =
  "sticky bottom-0 bg-gradient-to-t from-base-100 via-base-100 to-transparent";
const EVENT_OFFSET = 80;

export const Timeline: React.FC<{
  timeline: EventRecord[];
  colors: string[];
}> = ({ timeline, colors }) => {
  const addEventRef = useRef<HTMLDivElement>(null);
  const timelineRef = useRef<HTMLDivElement>(null);
  const lastTimelineElementRef = useRef<HTMLDivElement>(null);
  const chatSettings = usePageSettings(FrontendPageEnum.CHAT);

  const isUsingLandbotChat = useMemo(
    () => chatSettings?.chatType === ChatType.LANDBOT,
    [chatSettings?.chatType],
  );

  const {
    i18n: { language },
  } = useTranslation();
  const editTimeline = useEditLandbotTimeline();

  const { showModal, hideModal, updateModal } = useModal();

  const isTimelineIntersecting = useIntersectionObserver(timelineRef, {
    threshold: 1,
  });

  const isLastEventIntersecting = useIntersectionObserver(
    lastTimelineElementRef,
    { threshold: 1 },
  );

  const addEventClass = useMemo(() => {
    if (addEventRef.current && timelineRef.current) {
      const { offsetTop: eventOffset } = addEventRef.current;
      const { offsetTop: timelineOffset } = timelineRef.current;
      if (
        isTimelineIntersecting ||
        eventOffset - EVENT_OFFSET > timelineOffset
      ) {
        return "";
      } else {
        return "invisible";
      }
    }
  }, [isTimelineIntersecting, addEventRef, timelineRef]);

  const addEventVerticalBarClass = useMemo(() => {
    if (lastTimelineElementRef.current && addEventRef.current) {
      const { offsetTop: lastElementOffset } = lastTimelineElementRef.current;
      const { offsetTop: eventOffset } = addEventRef.current;
      const { offsetHeight: lastElementHeight } =
        lastTimelineElementRef.current;
      if (
        isLastEventIntersecting ||
        eventOffset - lastElementHeight >= lastElementOffset
      ) {
        return "";
      } else {
        return "invisible";
      }
    }
    return "invisible";
  }, [isLastEventIntersecting]);

  const tenantConfig = useTenantConfig();

  return editTimeline ? (
    <>
      <div className="grid-rows-auto grid h-full min-h-0 w-full min-w-0 grid-cols-[1.5fr_1fr_5fr_1fr] sm:grid-cols-[3fr_1fr_7fr_1fr] md:grid-cols-[3fr_1fr_8fr_1fr] lg:grid-cols-[3fr_1fr_9fr_1fr] xl:grid-cols-[3fr_1fr_10fr_1fr]">
        {
          <>
            <>
              <div
                className="h-full min-h-0 w-full min-w-0"
                ref={timelineRef}
              />
              <div className="relative mx-auto min-h-0 min-w-0">
                <div className="absolute left-1/2 top-1/2 h-1/2 w-1 -translate-x-1/2 bg-base-300" />
                <div className="flex h-24">
                  <TimelineBubble color={colors[0]} icon="process1" />
                </div>
              </div>
              <div className="flex h-full min-h-0 w-full min-w-0 flex-row">
                <Text
                  as="div"
                  className="ml-4 self-center hyphens-auto text-left text-2xl font-bold"
                  style={{
                    color: colors[0],
                  }}
                  tx="journey.myTimeline"
                />
              </div>
              <div />
            </>

            {timeline.map((ele, i, arr) => (
              <React.Fragment key={i}>
                <div
                  className="flex min-h-0 min-w-0 basis-0 flex-row-reverse text-right"
                  ref={arr.length - 1 === i ? lastTimelineElementRef : null}
                >
                  <div className="text-light-grey my-8 mr-4 text-base font-light">
                    {formatTime(ele.time, language)}
                  </div>
                </div>
                <div className="relative mx-auto flex min-h-0 min-w-0 flex-row">
                  <div className="absolute left-1/2 h-full w-1 -translate-x-1/2 bg-base-300" />
                  <div className="my-3 h-16">
                    <TimelineBubble
                      color={colors[i]}
                      icon={{
                        disease: tenantConfig?.disease || "common",
                        type: "event",
                        enumValue: ele.type,
                      }}
                    />
                  </div>
                </div>
                <div className="my-8 ml-4 flex min-h-0 min-w-0 flex-col justify-center gap-2 text-left">
                  <EnumTitle
                    className="text-light-grey text-lg font-semibold"
                    disease={tenantConfig?.disease || "common"}
                    type="event"
                    enumValue={ele.type}
                  />

                  {ele.eventDetails.length > 0 ? (
                    ele.eventDetails.map((det) => (
                      <EventDetail key={det.id} eventDetail={det} />
                    ))
                  ) : (
                    <Text
                      className="text-light-grey w-[calc(100%-2rem)] hyphens-auto break-words text-sm font-light"
                      text={ele.text}
                    />
                  )}
                </div>

                {isUsingLandbotChat ? (
                  <GhostButton
                    className="my-4"
                    onClick={() => {
                      showModal({
                        children: (
                          <EditableEvent
                            event={ele}
                            updateModal={updateModal}
                            hideModal={hideModal}
                          />
                        ),
                        overflowVisible: false,
                        closeOnClickOutside: true,
                        onConfirm() {},
                      });
                    }}
                  >
                    <SvgIcon icon="edit" height={40} width={40} />
                  </GhostButton>
                ) : (
                  <div />
                )}
              </React.Fragment>
            ))}

            <>
              <div />
              <div className="relative mx-auto flex min-h-0 min-w-0 flex-row">
                <div className="absolute left-1/2 top-0 h-1 w-1 -translate-x-1/2 -translate-y-1/2 rounded-xl bg-base-300" />
                <div className="absolute left-1/2 top-6 h-1 w-1 -translate-x-1/2 rounded-xl bg-base-300 print:hidden" />
                <div className="absolute left-1/2 top-10 h-1 w-1 -translate-x-1/2 rounded-xl bg-base-300 print:hidden" />
                <div className="absolute left-1/2 top-14 h-1 w-1 -translate-x-1/2 rounded-xl bg-base-300 print:hidden" />
                <div className="absolute bottom-0 left-1/2 h-1 w-1 -translate-x-1/2 translate-y-1/2 rounded-xl bg-base-300 print:hidden" />
              </div>
              <div
                className={twMerge(
                  "bottom flex min-h-[5rem] w-full min-w-0 flex-col items-end justify-center text-neutral print:min-h-0",
                )}
              >
                <Text
                  as="div"
                  className="text-bae-300 ml-4 self-center text-left text-sm italic print:hidden"
                  tx="journey.processingInBackgroundInfo"
                />
              </div>
              <div />

              <div
                ref={addEventRef}
                className={twMerge(
                  "flex min-h-0 min-w-0 basis-0 flex-row-reverse text-right print:hidden",
                  stickyClasses,
                  addEventClass,
                )}
              >
                <Text
                  as="div"
                  className="text-light-grey mr-4 self-center text-lg font-normal"
                  tx="general.today"
                />
              </div>

              <div
                className={twMerge(
                  "relative mx-auto min-h-0 min-w-0 cursor-pointer print:hidden",
                  stickyClasses,
                  addEventClass,
                )}
                onClick={() => editTimeline()}
              >
                <div
                  className={twMerge(
                    "absolute left-1/2 h-1/2 w-1 -translate-x-1/2 bg-base-300",
                    addEventVerticalBarClass,
                  )}
                />
                <div className="flex h-20">
                  <TimelineBubble
                    color={colors[timeline.length + 1]}
                    icon="edit"
                    isButton={true}
                  />
                </div>
              </div>
              <div
                className={twMerge(
                  "flex h-full min-h-0 w-full min-w-0 print:hidden",
                  stickyClasses,
                  addEventClass,
                )}
              >
                <Text
                  as="div"
                  className="ml-4 cursor-pointer self-center text-left text-2xl font-bold"
                  onClick={() => editTimeline()}
                  style={{
                    color: colors[timeline.length + 1],
                  }}
                  tx="journey.addAnEventButton"
                />
              </div>
            </>
          </>
        }
      </div>
      <div className="flex w-full items-center justify-center">
        <Text
          className="max-w-xl text-center text-slate-500"
          tx="journey.timelineNotice"
        />
      </div>
    </>
  ) : null;
};
