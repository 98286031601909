/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserEventInfo
 */
export interface UserEventInfo {
    /**
     * 
     * @type {string}
     * @memberof UserEventInfo
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof UserEventInfo
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserEventInfo
     */
    type?: string;
}

/**
 * Check if a given object implements the UserEventInfo interface.
 */
export function instanceOfUserEventInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserEventInfoFromJSON(json: any): UserEventInfo {
    return UserEventInfoFromJSONTyped(json, false);
}

export function UserEventInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserEventInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': !exists(json, 'message') ? undefined : json['message'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function UserEventInfoToJSON(value?: UserEventInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
        'userId': value.userId,
        'type': value.type,
    };
}

