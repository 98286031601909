/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetMarkersDto
 */
export interface GetMarkersDto {
    /**
     * 
     * @type {number}
     * @memberof GetMarkersDto
     */
    radius: number;
    /**
     * 
     * @type {number}
     * @memberof GetMarkersDto
     */
    latitude: number;
    /**
     * 
     * @type {number}
     * @memberof GetMarkersDto
     */
    longitude: number;
}

/**
 * Check if a given object implements the GetMarkersDto interface.
 */
export function instanceOfGetMarkersDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "radius" in value;
    isInstance = isInstance && "latitude" in value;
    isInstance = isInstance && "longitude" in value;

    return isInstance;
}

export function GetMarkersDtoFromJSON(json: any): GetMarkersDto {
    return GetMarkersDtoFromJSONTyped(json, false);
}

export function GetMarkersDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMarkersDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'radius': json['radius'],
        'latitude': json['latitude'],
        'longitude': json['longitude'],
    };
}

export function GetMarkersDtoToJSON(value?: GetMarkersDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'radius': value.radius,
        'latitude': value.latitude,
        'longitude': value.longitude,
    };
}

