import { action, makeObservable, observable } from "mobx";

import { EventProps } from "../components/events/event-props";
import { ToastEvent } from "../components/events/toast-event";

const EVENT_AUTO_CLEAR_MS = 10000;

export class RootStore {
  public events: EventProps[] = [];
  protected readonly eventTimeouts = new Map<string, NodeJS.Timeout>();

  public constructor() {
    makeObservable(this, {
      events: observable,

      addToastEvent: action,
      removeToastEvent: action,
    });
  }

  public removeToastEvent = (eventId: string): void => {
    this.events = this.events.filter((event) => event.eventId !== eventId);

    if (this.eventTimeouts.has(eventId)) {
      clearTimeout(this.eventTimeouts.get(eventId));
      this.eventTimeouts.delete(eventId);
    }
  };

  public addToastEvent = (toastEvent: ToastEvent): void => {
    const event = toastEvent.getEventProps();
    this.removeToastEvent(event.eventId);
    this.events.push(event);

    if (toastEvent.shouldAutoClear()) {
      this.eventTimeouts.set(
        event.eventId,
        setTimeout(() => {
          this.removeToastEvent(event.eventId);
        }, EVENT_AUTO_CLEAR_MS),
      );
    }
  };
}
