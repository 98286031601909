import { MockAuthStore } from "./store/mock-auth-store";
import { CognitoConfigurationState } from "../cognito/misc/types";
import {
  IAuthStateDelegate,
  IAuthStoreInitializer,
  IStaticAuthStore,
} from "../generic/types";

export const MockStoreInitializer: IAuthStoreInitializer & IStaticAuthStore = {
  init: (
    cognitoConfigState: CognitoConfigurationState,
    authStateDelegate: IAuthStateDelegate,
  ) => {
    return new MockAuthStore(cognitoConfigState, authStateDelegate);
  },
  getAuthRoutes: () => [],
  probeIfUserIsSignedIn: async () => {},
};
