import { cognitoAuth, CognitoConfigurationState } from "./misc/types";
import { getCognitoAuthRoutes } from "./routes";
import { CognitoAuthStore } from "./store/cognito-auth-store";
import {
  IAuthStateDelegate,
  IAuthStoreInitializer,
  IStaticAuthStore,
} from "../generic/types";

export const CognitoAuthStoreInitializer: IAuthStoreInitializer &
  IStaticAuthStore = {
  init: (
    cognitoConfigState: CognitoConfigurationState,
    authStateDelegate: IAuthStateDelegate,
  ) => {
    return CognitoAuthStore.create(
      cognitoConfigState,
      authStateDelegate,
      cognitoAuth,
    );
  },
  getAuthRoutes: getCognitoAuthRoutes,
  probeIfUserIsSignedIn: () =>
    CognitoAuthStore.probeIfUserIsSignedIn(cognitoAuth),
};
