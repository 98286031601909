/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Event } from './Event';
import {
    EventFromJSON,
    EventFromJSONTyped,
    EventToJSON,
} from './Event';

/**
 * Represents a EventDetail record
 * @export
 * @interface EventDetail
 */
export interface EventDetail {
    /**
     * 
     * @type {string}
     * @memberof EventDetail
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof EventDetail
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof EventDetail
     */
    text?: string;
    /**
     * 
     * @type {number}
     * @memberof EventDetail
     */
    number?: number;
    /**
     * 
     * @type {string}
     * @memberof EventDetail
     */
    unit?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventDetail
     */
    select?: boolean;
    /**
     * 
     * @type {Event}
     * @memberof EventDetail
     */
    event?: Event;
    /**
     * 
     * @type {string}
     * @memberof EventDetail
     */
    eventId: string;
    /**
     * 
     * @type {Array<EventDetail>}
     * @memberof EventDetail
     */
    details?: Array<EventDetail>;
    /**
     * 
     * @type {EventDetail}
     * @memberof EventDetail
     */
    parentDetail?: EventDetail;
    /**
     * 
     * @type {string}
     * @memberof EventDetail
     */
    parentDetailId?: string;
    /**
     * 
     * @type {Date}
     * @memberof EventDetail
     */
    updatedAt?: Date;
}

/**
 * Check if a given object implements the EventDetail interface.
 */
export function instanceOfEventDetail(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "eventId" in value;

    return isInstance;
}

export function EventDetailFromJSON(json: any): EventDetail {
    return EventDetailFromJSONTyped(json, false);
}

export function EventDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'select': !exists(json, 'select') ? undefined : json['select'],
        'event': !exists(json, 'event') ? undefined : EventFromJSON(json['event']),
        'eventId': json['eventId'],
        'details': !exists(json, 'details') ? undefined : ((json['details'] as Array<any>).map(EventDetailFromJSON)),
        'parentDetail': !exists(json, 'parentDetail') ? undefined : EventDetailFromJSON(json['parentDetail']),
        'parentDetailId': !exists(json, 'parentDetailId') ? undefined : json['parentDetailId'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
    };
}

export function EventDetailToJSON(value?: EventDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'text': value.text,
        'number': value.number,
        'unit': value.unit,
        'select': value.select,
        'event': EventToJSON(value.event),
        'eventId': value.eventId,
        'details': value.details === undefined ? undefined : ((value.details as Array<any>).map(EventDetailToJSON)),
        'parentDetail': EventDetailToJSON(value.parentDetail),
        'parentDetailId': value.parentDetailId,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
    };
}

