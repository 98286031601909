/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NodeType } from './NodeType';
import {
    NodeTypeFromJSON,
    NodeTypeFromJSONTyped,
    NodeTypeToJSON,
} from './NodeType';
import type { Subtype } from './Subtype';
import {
    SubtypeFromJSON,
    SubtypeFromJSONTyped,
    SubtypeToJSON,
} from './Subtype';

/**
 * 
 * @export
 * @interface QuestionNodeInfo
 */
export interface QuestionNodeInfo {
    /**
     * 
     * @type {NodeType}
     * @memberof QuestionNodeInfo
     */
    type: NodeType;
    /**
     * 
     * @type {Subtype}
     * @memberof QuestionNodeInfo
     */
    subtype?: Subtype;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestionNodeInfo
     */
    choices?: Array<string>;
}

/**
 * Check if a given object implements the QuestionNodeInfo interface.
 */
export function instanceOfQuestionNodeInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function QuestionNodeInfoFromJSON(json: any): QuestionNodeInfo {
    return QuestionNodeInfoFromJSONTyped(json, false);
}

export function QuestionNodeInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionNodeInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': NodeTypeFromJSON(json['type']),
        'subtype': !exists(json, 'subtype') ? undefined : SubtypeFromJSON(json['subtype']),
        'choices': !exists(json, 'choices') ? undefined : json['choices'],
    };
}

export function QuestionNodeInfoToJSON(value?: QuestionNodeInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': NodeTypeToJSON(value.type),
        'subtype': SubtypeToJSON(value.subtype),
        'choices': value.choices,
    };
}

