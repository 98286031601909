/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserEventInfo } from './UserEventInfo';
import {
    UserEventInfoFromJSON,
    UserEventInfoFromJSONTyped,
    UserEventInfoToJSON,
} from './UserEventInfo';

/**
 * 
 * @export
 * @interface UserEventDto
 */
export interface UserEventDto {
    /**
     * 
     * @type {string}
     * @memberof UserEventDto
     */
    type: UserEventDtoTypeEnum;
    /**
     * 
     * @type {UserEventInfo}
     * @memberof UserEventDto
     */
    info?: UserEventInfo;
}


/**
 * @export
 */
export const UserEventDtoTypeEnum = {
    Event: 'EVENT',
    Activity: 'ACTIVITY'
} as const;
export type UserEventDtoTypeEnum = typeof UserEventDtoTypeEnum[keyof typeof UserEventDtoTypeEnum];


/**
 * Check if a given object implements the UserEventDto interface.
 */
export function instanceOfUserEventDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function UserEventDtoFromJSON(json: any): UserEventDto {
    return UserEventDtoFromJSONTyped(json, false);
}

export function UserEventDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserEventDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'info': !exists(json, 'info') ? undefined : UserEventInfoFromJSON(json['info']),
    };
}

export function UserEventDtoToJSON(value?: UserEventDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'info': UserEventInfoToJSON(value.info),
    };
}

