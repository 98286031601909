import React from "react";

export const DEFAULT_RESULTS_PER_PAGE = 20;

export enum FilterProperty {
  HEADLINE = "headline",
  BODY = "body",
  ORGANISATION = "organisation",
  CATEGORIES = "categories",
  NAME = "name",
  LANGUAGE = "language",
}

export enum PropertyFilterRule {
  CONTAINS = "CONTAINS",
  IS = "IS",
  ARE = "ARE",
  IS_PART_OF = "IS_PART_OF",
  ARE_PART_OF = "ARE_PART_OF",
}

export enum SortConfigDirection {
  ASC = "asc",
  DESC = "desc",
}

export enum SortConfigProperty {
  PUBLISHED_AT = "publishedAt",
  UPDATED_AT = "updatedAt",
  LIKES = "likes",
}

export enum FilterType {
  SORT = "sort",
  FILTER = "filter",
  PAGINATION = "pagination",
  LANGUAGE = "language",
}

export type SortConfig = {
  type: FilterType.SORT;
  property: SortConfigProperty;
  direction: SortConfigDirection;
};

export type PropertyFilter = {
  type: FilterType.FILTER;
  value: number | string | string[];
  property: FilterProperty;
  rule: PropertyFilterRule;
};

export type PaginationConfig = {
  type: FilterType.PAGINATION;
  resultsPerPage?: number;
  page?: number;
};

export type ApplyFilterType = SortConfig | PropertyFilter | PaginationConfig;

export type Filters = (ApplyFilterType | undefined)[];

export type FilterComponent = React.FC | null;

export type FilterElementContextType = {
  filterConfig?: PropertyFilter[];
  sortConfig?: SortConfig;
  paginationConfig?: PaginationConfig;
  onFilterChange: (type: ApplyFilterType) => void;
};

export type FilterComponentsConfigType = Partial<
  Record<FilterProperty | SortConfigProperty, FilterComponent>
>;
