import { globalThemeAttributes } from "shared/config/global-theme-attributes";
import { ExtendedTheme } from "shared/model/theme";

import { useTenantConfig } from "../tenant-settings";

export const useGetExtendedTheme = (): ExtendedTheme => {
  const { theme } = useTenantConfig();

  return {
    colors: { ...theme.extendedTheme?.colors, ...globalThemeAttributes.colors },
  };
};
