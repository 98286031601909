/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DiseaseSettings } from './DiseaseSettings';
import {
    DiseaseSettingsFromJSON,
    DiseaseSettingsFromJSONTyped,
    DiseaseSettingsToJSON,
} from './DiseaseSettings';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * Represents a UserDetail record
 * @export
 * @interface UserDetail
 */
export interface UserDetail {
    /**
     * 
     * @type {string}
     * @memberof UserDetail
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetail
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetail
     */
    text?: string;
    /**
     * 
     * @type {number}
     * @memberof UserDetail
     */
    number?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDetail
     */
    unit?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetail
     */
    select?: boolean;
    /**
     * 
     * @type {Array<UserDetail>}
     * @memberof UserDetail
     */
    details?: Array<UserDetail>;
    /**
     * 
     * @type {UserDetail}
     * @memberof UserDetail
     */
    parentDetail?: UserDetail;
    /**
     * 
     * @type {string}
     * @memberof UserDetail
     */
    parentDetailId?: string;
    /**
     * 
     * @type {Date}
     * @memberof UserDetail
     */
    inputTime: Date;
    /**
     * 
     * @type {User}
     * @memberof UserDetail
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof UserDetail
     */
    userId: string;
    /**
     * 
     * @type {DiseaseSettings}
     * @memberof UserDetail
     */
    disease?: DiseaseSettings;
    /**
     * 
     * @type {string}
     * @memberof UserDetail
     */
    diseaseId: string;
}

/**
 * Check if a given object implements the UserDetail interface.
 */
export function instanceOfUserDetail(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "inputTime" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "diseaseId" in value;

    return isInstance;
}

export function UserDetailFromJSON(json: any): UserDetail {
    return UserDetailFromJSONTyped(json, false);
}

export function UserDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'select': !exists(json, 'select') ? undefined : json['select'],
        'details': !exists(json, 'details') ? undefined : ((json['details'] as Array<any>).map(UserDetailFromJSON)),
        'parentDetail': !exists(json, 'parentDetail') ? undefined : UserDetailFromJSON(json['parentDetail']),
        'parentDetailId': !exists(json, 'parentDetailId') ? undefined : json['parentDetailId'],
        'inputTime': (new Date(json['inputTime'])),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'userId': json['userId'],
        'disease': !exists(json, 'disease') ? undefined : DiseaseSettingsFromJSON(json['disease']),
        'diseaseId': json['diseaseId'],
    };
}

export function UserDetailToJSON(value?: UserDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'text': value.text,
        'number': value.number,
        'unit': value.unit,
        'select': value.select,
        'details': value.details === undefined ? undefined : ((value.details as Array<any>).map(UserDetailToJSON)),
        'parentDetail': UserDetailToJSON(value.parentDetail),
        'parentDetailId': value.parentDetailId,
        'inputTime': (value.inputTime.toISOString()),
        'user': UserToJSON(value.user),
        'userId': value.userId,
        'disease': DiseaseSettingsToJSON(value.disease),
        'diseaseId': value.diseaseId,
    };
}

