/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';
import type { UserActivityLogType } from './UserActivityLogType';
import {
    UserActivityLogTypeFromJSON,
    UserActivityLogTypeFromJSONTyped,
    UserActivityLogTypeToJSON,
} from './UserActivityLogType';

/**
 * Represents a UserActivityLog record
 * @export
 * @interface UserActivityLog
 */
export interface UserActivityLog {
    /**
     * 
     * @type {string}
     * @memberof UserActivityLog
     */
    id: string;
    /**
     * 
     * @type {UserActivityLogType}
     * @memberof UserActivityLog
     */
    type: UserActivityLogType;
    /**
     * 
     * @type {string}
     * @memberof UserActivityLog
     */
    info?: string;
    /**
     * 
     * @type {User}
     * @memberof UserActivityLog
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof UserActivityLog
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof UserActivityLog
     */
    path: string;
    /**
     * 
     * @type {string}
     * @memberof UserActivityLog
     */
    userAgent: string;
    /**
     * 
     * @type {Date}
     * @memberof UserActivityLog
     */
    timestamp: Date;
}

/**
 * Check if a given object implements the UserActivityLog interface.
 */
export function instanceOfUserActivityLog(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "path" in value;
    isInstance = isInstance && "userAgent" in value;
    isInstance = isInstance && "timestamp" in value;

    return isInstance;
}

export function UserActivityLogFromJSON(json: any): UserActivityLog {
    return UserActivityLogFromJSONTyped(json, false);
}

export function UserActivityLogFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserActivityLog {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': UserActivityLogTypeFromJSON(json['type']),
        'info': !exists(json, 'info') ? undefined : json['info'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'userId': json['userId'],
        'path': json['path'],
        'userAgent': json['userAgent'],
        'timestamp': (new Date(json['timestamp'])),
    };
}

export function UserActivityLogToJSON(value?: UserActivityLog | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': UserActivityLogTypeToJSON(value.type),
        'info': value.info,
        'user': UserToJSON(value.user),
        'userId': value.userId,
        'path': value.path,
        'userAgent': value.userAgent,
        'timestamp': (value.timestamp.toISOString()),
    };
}

