/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DiseaseSettings } from './DiseaseSettings';
import {
    DiseaseSettingsFromJSON,
    DiseaseSettingsFromJSONTyped,
    DiseaseSettingsToJSON,
} from './DiseaseSettings';
import type { OrganisationSettings } from './OrganisationSettings';
import {
    OrganisationSettingsFromJSON,
    OrganisationSettingsFromJSONTyped,
    OrganisationSettingsToJSON,
} from './OrganisationSettings';
import type { SocialMediaPost } from './SocialMediaPost';
import {
    SocialMediaPostFromJSON,
    SocialMediaPostFromJSONTyped,
    SocialMediaPostToJSON,
} from './SocialMediaPost';

/**
 * Represents a SocialMediaPostCategory record
 * @export
 * @interface SocialMediaPostCategory
 */
export interface SocialMediaPostCategory {
    /**
     * 
     * @type {string}
     * @memberof SocialMediaPostCategory
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SocialMediaPostCategory
     */
    name: string;
    /**
     * 
     * @type {DiseaseSettings}
     * @memberof SocialMediaPostCategory
     */
    disease?: DiseaseSettings;
    /**
     * 
     * @type {string}
     * @memberof SocialMediaPostCategory
     */
    diseaseId?: string;
    /**
     * 
     * @type {Array<OrganisationSettings>}
     * @memberof SocialMediaPostCategory
     */
    organisations?: Array<OrganisationSettings>;
    /**
     * 
     * @type {Array<SocialMediaPost>}
     * @memberof SocialMediaPostCategory
     */
    socialMediaPosts?: Array<SocialMediaPost>;
    /**
     * 
     * @type {string}
     * @memberof SocialMediaPostCategory
     */
    translations?: string;
}

/**
 * Check if a given object implements the SocialMediaPostCategory interface.
 */
export function instanceOfSocialMediaPostCategory(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function SocialMediaPostCategoryFromJSON(json: any): SocialMediaPostCategory {
    return SocialMediaPostCategoryFromJSONTyped(json, false);
}

export function SocialMediaPostCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocialMediaPostCategory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'disease': !exists(json, 'disease') ? undefined : DiseaseSettingsFromJSON(json['disease']),
        'diseaseId': !exists(json, 'diseaseId') ? undefined : json['diseaseId'],
        'organisations': !exists(json, 'organisations') ? undefined : ((json['organisations'] as Array<any>).map(OrganisationSettingsFromJSON)),
        'socialMediaPosts': !exists(json, 'socialMediaPosts') ? undefined : ((json['socialMediaPosts'] as Array<any>).map(SocialMediaPostFromJSON)),
        'translations': !exists(json, 'translations') ? undefined : json['translations'],
    };
}

export function SocialMediaPostCategoryToJSON(value?: SocialMediaPostCategory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'disease': DiseaseSettingsToJSON(value.disease),
        'diseaseId': value.diseaseId,
        'organisations': value.organisations === undefined ? undefined : ((value.organisations as Array<any>).map(OrganisationSettingsToJSON)),
        'socialMediaPosts': value.socialMediaPosts === undefined ? undefined : ((value.socialMediaPosts as Array<any>).map(SocialMediaPostToJSON)),
        'translations': value.translations,
    };
}

