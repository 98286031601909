export const OSMUrl = new URL("https://nominatim.openstreetmap.org/search");

export const OSMQueryParams = new URLSearchParams({
  format: "json",
  addressdetails: "1",
}).toString();

export type AddressComponent =
  | AddressComponentEnum.ROAD
  | AddressComponentEnum.CITY
  | AddressComponentEnum.POSTAL_CODE
  | AddressComponentEnum.STATE
  | AddressComponentEnum.COUNTRY;

export type DetailedAddress = {
  [AddressComponentEnum.ROAD]: string | undefined;
  [AddressComponentEnum.CITY]: string | undefined;
  [AddressComponentEnum.POSTAL_CODE]: string | undefined;
  [AddressComponentEnum.STATE]: string | undefined;
  [AddressComponentEnum.COUNTRY]: string | undefined;
};

export enum AddressComponentEnum {
  ROAD = "ROAD",
  CITY = "CITY",
  POSTAL_CODE = "POSTAL_CODE",
  STATE = "STATE",
  COUNTRY = "COUNTRY",
}
