import { useState } from "react";

import { SubmitFeedback, UserFeedback } from "../../../api/generated/backend";
import { TextInput } from "../../form/text-input";

export const SatisfactionUserFeedback: React.FC<{
  feedback: UserFeedback;
  setFeedback: (submitFeedback: SubmitFeedback) => void;
}> = ({ feedback, setFeedback }) => {
  const [value, setValue] = useState("");

  return (
    <TextInput
      as="textarea"
      className="max-h-40 w-full"
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        setFeedback({
          ...feedback,
          details: e.target.value,
        });
      }}
    />
  );
};
