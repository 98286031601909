/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EventsDTO,
  HTTPValidationError,
  HasEventResponse,
  LandbotCompletenessResponse,
  UpdateEvent,
} from '../models/index';
import {
    EventsDTOFromJSON,
    EventsDTOToJSON,
    HTTPValidationErrorFromJSON,
    HTTPValidationErrorToJSON,
    HasEventResponseFromJSON,
    HasEventResponseToJSON,
    LandbotCompletenessResponseFromJSON,
    LandbotCompletenessResponseToJSON,
    UpdateEventFromJSON,
    UpdateEventToJSON,
} from '../models/index';

export interface CreateCaseDataCurrentStateRequest {
    body: object;
}

export interface CreateCaseDataInitialQuestionsRequest {
    body: object;
}

export interface CreateCaseDataPatientProfileRequest {
    body: object;
}

export interface CreateCaseDataUpdateStateRequest {
    body: object;
}

export interface CreateCaseDataWebhook1Request {
    body: object;
}

export interface CreateCaseDataWebhook5Request {
    body: object;
}

export interface CreateCaseDataWebhook6Request {
    body: object;
}

export interface CreateEventDataDiagnosisRequest {
    body: object;
}

export interface CreateEventDataEvent1Request {
    body: object;
}

export interface CreateEventDataEvent2PlusRequest {
    body: object;
}

export interface CreateEventDataPrimaryDysmenorrheaRequest {
    body: object;
}

export interface CreateEventDataWebhook2Request {
    body: object;
}

export interface CreateEventDataWebhook3Request {
    body: object;
}

export interface CreateEventDataWebhook4Request {
    body: object;
}

export interface CreateEventDataWebhook7Request {
    body: object;
}

export interface DeleteEventRequest {
    eventId: string;
}

export interface GetEventsRequest {
    mamaDisease: string;
}

export interface GetLandbotChatEventCompletenessRequest {
    mamaDisease: string;
}

export interface HasEventsRequest {
    mamaDisease: string;
}

export interface UpdateEventRequest {
    updateEvent: UpdateEvent;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Create Case Data Current State
     */
    async createCaseDataCurrentStateRaw(requestParameters: CreateCaseDataCurrentStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling createCaseDataCurrentState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/endometriosis/charite/current_state`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create Case Data Current State
     */
    async createCaseDataCurrentState(requestParameters: CreateCaseDataCurrentStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.createCaseDataCurrentStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Case Data Initial Questions
     */
    async createCaseDataInitialQuestionsRaw(requestParameters: CreateCaseDataInitialQuestionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling createCaseDataInitialQuestions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/endometriosis/charite/initial_questions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create Case Data Initial Questions
     */
    async createCaseDataInitialQuestions(requestParameters: CreateCaseDataInitialQuestionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.createCaseDataInitialQuestionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Case Data Patient Profile
     */
    async createCaseDataPatientProfileRaw(requestParameters: CreateCaseDataPatientProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling createCaseDataPatientProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/endometriosis/charite/patient_profile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create Case Data Patient Profile
     */
    async createCaseDataPatientProfile(requestParameters: CreateCaseDataPatientProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.createCaseDataPatientProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Case Data Update State
     */
    async createCaseDataUpdateStateRaw(requestParameters: CreateCaseDataUpdateStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling createCaseDataUpdateState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/endometriosis/charite/update_state`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create Case Data Update State
     */
    async createCaseDataUpdateState(requestParameters: CreateCaseDataUpdateStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.createCaseDataUpdateStateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Case Data Webhook1
     */
    async createCaseDataWebhook1Raw(requestParameters: CreateCaseDataWebhook1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling createCaseDataWebhook1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/webhooks/opade/webhook1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create Case Data Webhook1
     */
    async createCaseDataWebhook1(requestParameters: CreateCaseDataWebhook1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.createCaseDataWebhook1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Case Data Webhook5
     */
    async createCaseDataWebhook5Raw(requestParameters: CreateCaseDataWebhook5Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling createCaseDataWebhook5.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/webhooks/opade/webhook5`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create Case Data Webhook5
     */
    async createCaseDataWebhook5(requestParameters: CreateCaseDataWebhook5Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.createCaseDataWebhook5Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Case Data Webhook6
     */
    async createCaseDataWebhook6Raw(requestParameters: CreateCaseDataWebhook6Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling createCaseDataWebhook6.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/webhooks/opade/webhook6`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create Case Data Webhook6
     */
    async createCaseDataWebhook6(requestParameters: CreateCaseDataWebhook6Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.createCaseDataWebhook6Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Event Data Diagnosis
     */
    async createEventDataDiagnosisRaw(requestParameters: CreateEventDataDiagnosisRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling createEventDataDiagnosis.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/endometriosis/charite/diagnosis_follow_up`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create Event Data Diagnosis
     */
    async createEventDataDiagnosis(requestParameters: CreateEventDataDiagnosisRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.createEventDataDiagnosisRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Event Data Event1
     */
    async createEventDataEvent1Raw(requestParameters: CreateEventDataEvent1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling createEventDataEvent1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/endometriosis/charite/event1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create Event Data Event1
     */
    async createEventDataEvent1(requestParameters: CreateEventDataEvent1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.createEventDataEvent1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Event Data Event2 Plus
     */
    async createEventDataEvent2PlusRaw(requestParameters: CreateEventDataEvent2PlusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling createEventDataEvent2Plus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/endometriosis/charite/event2_plus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create Event Data Event2 Plus
     */
    async createEventDataEvent2Plus(requestParameters: CreateEventDataEvent2PlusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.createEventDataEvent2PlusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Event Data Primary Dysmenorrhea
     */
    async createEventDataPrimaryDysmenorrheaRaw(requestParameters: CreateEventDataPrimaryDysmenorrheaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling createEventDataPrimaryDysmenorrhea.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/endometriosis/charite/primary_dysmenorrhea`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create Event Data Primary Dysmenorrhea
     */
    async createEventDataPrimaryDysmenorrhea(requestParameters: CreateEventDataPrimaryDysmenorrheaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.createEventDataPrimaryDysmenorrheaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Event Data Webhook2
     */
    async createEventDataWebhook2Raw(requestParameters: CreateEventDataWebhook2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling createEventDataWebhook2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/webhooks/opade/webhook2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create Event Data Webhook2
     */
    async createEventDataWebhook2(requestParameters: CreateEventDataWebhook2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.createEventDataWebhook2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Event Data Webhook3
     */
    async createEventDataWebhook3Raw(requestParameters: CreateEventDataWebhook3Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling createEventDataWebhook3.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/webhooks/opade/webhook3`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create Event Data Webhook3
     */
    async createEventDataWebhook3(requestParameters: CreateEventDataWebhook3Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.createEventDataWebhook3Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * This webhook is only triggered if the user did not mention the \"RECEIVE_DIAGNOSIS\" Event in the Event2+ block (webhook3) yet.
     * Create Event Data Webhook4
     */
    async createEventDataWebhook4Raw(requestParameters: CreateEventDataWebhook4Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling createEventDataWebhook4.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/webhooks/opade/webhook4`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * This webhook is only triggered if the user did not mention the \"RECEIVE_DIAGNOSIS\" Event in the Event2+ block (webhook3) yet.
     * Create Event Data Webhook4
     */
    async createEventDataWebhook4(requestParameters: CreateEventDataWebhook4Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.createEventDataWebhook4Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Event Data Webhook7
     */
    async createEventDataWebhook7Raw(requestParameters: CreateEventDataWebhook7Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling createEventDataWebhook7.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/webhooks/opade/webhook7`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create Event Data Webhook7
     */
    async createEventDataWebhook7(requestParameters: CreateEventDataWebhook7Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.createEventDataWebhook7Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Event
     */
    async deleteEventRaw(requestParameters: DeleteEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.eventId === null || requestParameters.eventId === undefined) {
            throw new runtime.RequiredError('eventId','Required parameter requestParameters.eventId was null or undefined when calling deleteEvent.');
        }

        const queryParameters: any = {};

        if (requestParameters.eventId !== undefined) {
            queryParameters['event_id'] = requestParameters.eventId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/events/event`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Event
     */
    async deleteEvent(requestParameters: DeleteEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteEventRaw(requestParameters, initOverrides);
    }

    /**
     * Get Events
     */
    async getEventsRaw(requestParameters: GetEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventsDTO>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling getEvents.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/events/events`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventsDTOFromJSON(jsonValue));
    }

    /**
     * Get Events
     */
    async getEvents(requestParameters: GetEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventsDTO> {
        const response = await this.getEventsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Landbot Chat Event Completeness
     */
    async getLandbotChatEventCompletenessRaw(requestParameters: GetLandbotChatEventCompletenessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LandbotCompletenessResponse>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling getLandbotChatEventCompleteness.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landbot/event-completeness`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LandbotCompletenessResponseFromJSON(jsonValue));
    }

    /**
     * Get Landbot Chat Event Completeness
     */
    async getLandbotChatEventCompleteness(requestParameters: GetLandbotChatEventCompletenessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LandbotCompletenessResponse> {
        const response = await this.getLandbotChatEventCompletenessRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Has Events
     */
    async hasEventsRaw(requestParameters: HasEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HasEventResponse>> {
        if (requestParameters.mamaDisease === null || requestParameters.mamaDisease === undefined) {
            throw new runtime.RequiredError('mamaDisease','Required parameter requestParameters.mamaDisease was null or undefined when calling hasEvents.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.mamaDisease !== undefined && requestParameters.mamaDisease !== null) {
            headerParameters['mama-disease'] = String(requestParameters.mamaDisease);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HTTPBearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/events/has-events`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HasEventResponseFromJSON(jsonValue));
    }

    /**
     * Has Events
     */
    async hasEvents(requestParameters: HasEventsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HasEventResponse> {
        const response = await this.hasEventsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Root
     */
    async rootRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/alive`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Root
     */
    async root(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.rootRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update Event
     */
    async updateEventRaw(requestParameters: UpdateEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.updateEvent === null || requestParameters.updateEvent === undefined) {
            throw new runtime.RequiredError('updateEvent','Required parameter requestParameters.updateEvent was null or undefined when calling updateEvent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/events/event`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateEventToJSON(requestParameters.updateEvent),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update Event
     */
    async updateEvent(requestParameters: UpdateEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateEventRaw(requestParameters, initOverrides);
    }

}
