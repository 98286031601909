"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDataGuard = exports.DataGuardType = void 0;
var DataGuardType;
(function (DataGuardType) {
    DataGuardType["BLANK_GUARD"] = "BLANK_GUARD";
    DataGuardType["VIDEO_GUARD"] = "VIDEO_GUARD";
    DataGuardType["FORM_DATA_GUARD"] = "FORM_DATA_GUARD";
    DataGuardType["CARD_DATA_GUARD"] = "CARD_DATA_GUARD";
    DataGuardType["CHAT_COMPLETION_DATA_GUARD"] = "CHAT_COMPLETION_DATA_GUARD";
})(DataGuardType || (exports.DataGuardType = DataGuardType = {}));
const createDataGuard = (dataGuard) => dataGuard;
exports.createDataGuard = createDataGuard;
