import { useMemo } from "react";

export interface NullReplacerProps {
  text: string;
}

export const NullReplacer: React.FC<NullReplacerProps> = ({ text }) => {
  const isDev = useMemo(() => process.env.NODE_ENV === "development", []);

  return isDev ? <div>{text}</div> : null;
};
