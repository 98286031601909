import { MultiColumnSection } from "../components/gallery";
import { IconColor } from "../components/icons/svg-icon";
import { UserStoryCard } from "../components/user-story/user-story-card";
import stories from "../components/user-story/user-story-data.json";
import { DefaultContentContainer } from "../layouts/default-content-container";

const cardStyles: { background: string; iconColor: IconColor }[] = [
  { background: "bg-red-200", iconColor: "fill-error" },
  { background: "bg-blue-200", iconColor: "fill-accent" },
  { background: "bg-green-200", iconColor: "fill-success-content" },
];

export const UserStory: React.FC = () => {
  return (
    <DefaultContentContainer>
      <MultiColumnSection>
        {stories.map((story, idx) => (
          <UserStoryCard
            story={story}
            key={story.id}
            styles={cardStyles[idx % cardStyles.length]}
          />
        ))}
      </MultiColumnSection>
    </DefaultContentContainer>
  );
};
