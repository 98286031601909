import React from "react";
import { Disease } from "shared/model/diseases";

import { SvgIcon, SvgIconProps } from "./svg-icon";
import { EnumType, useEnumIcon } from "../../i18n/enum-translation";

export type EnumIconConfig = {
  disease: Disease | "common";
  type: EnumType;
  enumValue: string;
};

export const EnumIcon: React.FC<
  Omit<SvgIconProps, "icon"> & EnumIconConfig
> = ({ disease, type, enumValue, ...rest }) => {
  const icon = useEnumIcon(disease, type, enumValue);

  return <SvgIcon icon={icon} {...rest} />;
};
