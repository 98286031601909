/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StripeProduct } from './StripeProduct';
import {
    StripeProductFromJSON,
    StripeProductFromJSONTyped,
    StripeProductToJSON,
} from './StripeProduct';

/**
 * 
 * @export
 * @interface Subscription
 */
export interface Subscription {
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    id: string;
    /**
     * 
     * @type {StripeProduct}
     * @memberof Subscription
     */
    product?: StripeProduct;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    interval?: string;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    price: string;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    startDate: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    renewsAt?: number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    cancelAt?: number;
}

/**
 * Check if a given object implements the Subscription interface.
 */
export function instanceOfSubscription(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "startDate" in value;

    return isInstance;
}

export function SubscriptionFromJSON(json: any): Subscription {
    return SubscriptionFromJSONTyped(json, false);
}

export function SubscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Subscription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'product': !exists(json, 'product') ? undefined : StripeProductFromJSON(json['product']),
        'interval': !exists(json, 'interval') ? undefined : json['interval'],
        'price': json['price'],
        'startDate': json['startDate'],
        'renewsAt': !exists(json, 'renewsAt') ? undefined : json['renewsAt'],
        'cancelAt': !exists(json, 'cancelAt') ? undefined : json['cancelAt'],
    };
}

export function SubscriptionToJSON(value?: Subscription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'product': StripeProductToJSON(value.product),
        'interval': value.interval,
        'price': value.price,
        'startDate': value.startDate,
        'renewsAt': value.renewsAt,
        'cancelAt': value.cancelAt,
    };
}

