import { useCallback, useEffect, useRef, useState } from "react";

import { IUseWebSocket } from "./types/websocket";

export const usePublicWebSocket = <T extends object, U extends object>(config: {
  url: string;
  enabled?: boolean;
  query?: Record<string, string>;
  onError?: () => Promise<void> | void;
  onResponse?: (event?: MessageEvent<U>) => Promise<void> | void;
}): IUseWebSocket<T> => {
  const [isReady, setIsReady] = useState(false);

  const socketRef = useRef<WebSocket | null>(null);

  useEffect(() => {
    if (!socketRef.current) {
      const queryParams = new URLSearchParams({});

      Object.entries(config.query || {}).forEach(([key, value]) => {
        queryParams.append(key, value);
      });

      const url = `${config.url}?${queryParams.toString()}`;
      const socket = new WebSocket(url);

      socket.onmessage = (event) => {
        config.onResponse?.(JSON.parse(event.data));
      };

      socket.onclose = () => {
        socketRef.current = null;
        setIsReady(false);
      };

      socket.onerror = (event) => {
        setIsReady(false);
        console.log("WebSocket error", event);
        config.onError?.();
      };

      socket.onopen = () => {
        setIsReady(true);
      };

      socketRef.current = socket;
    }
  }, [config]);

  const sendMessage = useCallback((request: T) => {
    socketRef.current?.send(JSON.stringify(request));
  }, []);

  return { sendMessage, webSocket: socketRef.current, isReady };
};
