import { useProfileCompleteness } from "./use-profile-completeness";
import { Text } from "../../components/text";
import { Tooltip } from "../../components/tooltip";

export const ProfileCompletenessScore: React.FC = () => {
  const { profileCompletenessScore, profileCompletenessMaxScore } =
    useProfileCompleteness();

  return (
    <Tooltip
      className="w-full"
      text={{ tx: "profile.profileCompletenessScoreExplaination" }}
    >
      <div className="flex flex-row justify-start">
        <div className="flex w-full items-center px-2">
          <progress
            className="progress-primary progress w-full "
            value={profileCompletenessScore}
            max={profileCompletenessMaxScore}
          />
        </div>
        <Text
          className="w-fit"
          as="div"
          text={`${profileCompletenessScore}/${profileCompletenessMaxScore}`}
        />
      </div>
    </Tooltip>
  );
};
