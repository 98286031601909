const stripeClientId = process.env.REACT_APP_STRIPE_CLIENT_ID;

if (!stripeClientId) {
  throw new Error("Stripe Client Id Missing");
}

export const getStripeConnectRedirectLink = (redirectUri: string): string =>
  `https://connect.stripe.com/oauth/v2/authorize?response_type=code&client_id=${encodeURIComponent(
    stripeClientId,
  )}&scope=read_write&redirect_uri=${encodeURIComponent(redirectUri)}`;
