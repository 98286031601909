/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const EventDetailDatatype = {
    Text: 'TEXT',
    Number: 'NUMBER',
    Select: 'SELECT',
    Unit: 'UNIT'
} as const;
export type EventDetailDatatype = typeof EventDetailDatatype[keyof typeof EventDetailDatatype];


export function EventDetailDatatypeFromJSON(json: any): EventDetailDatatype {
    return EventDetailDatatypeFromJSONTyped(json, false);
}

export function EventDetailDatatypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventDetailDatatype {
    return json as EventDetailDatatype;
}

export function EventDetailDatatypeToJSON(value?: EventDetailDatatype | null): any {
    return value as any;
}

