/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StripeProduct
 */
export interface StripeProduct {
    /**
     * 
     * @type {string}
     * @memberof StripeProduct
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof StripeProduct
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeProduct
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof StripeProduct
     */
    active: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof StripeProduct
     */
    features: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof StripeProduct
     */
    banner?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StripeProduct
     */
    highlighted?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof StripeProduct
     */
    grantAccessToPages: Array<string>;
}

/**
 * Check if a given object implements the StripeProduct interface.
 */
export function instanceOfStripeProduct(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "features" in value;
    isInstance = isInstance && "grantAccessToPages" in value;

    return isInstance;
}

export function StripeProductFromJSON(json: any): StripeProduct {
    return StripeProductFromJSONTyped(json, false);
}

export function StripeProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): StripeProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'name': json['name'],
        'active': json['active'],
        'features': json['features'],
        'banner': !exists(json, 'banner') ? undefined : json['banner'],
        'highlighted': !exists(json, 'highlighted') ? undefined : json['highlighted'],
        'grantAccessToPages': json['grantAccessToPages'],
    };
}

export function StripeProductToJSON(value?: StripeProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
        'name': value.name,
        'active': value.active,
        'features': value.features,
        'banner': value.banner,
        'highlighted': value.highlighted,
        'grantAccessToPages': value.grantAccessToPages,
    };
}

