/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EventTime
 */
export interface EventTime {
    /**
     * 
     * @type {number}
     * @memberof EventTime
     */
    year?: number;
    /**
     * 
     * @type {number}
     * @memberof EventTime
     */
    month?: number;
    /**
     * 
     * @type {number}
     * @memberof EventTime
     */
    day?: number;
    /**
     * 
     * @type {number}
     * @memberof EventTime
     */
    hour?: number;
    /**
     * 
     * @type {number}
     * @memberof EventTime
     */
    minute?: number;
}

/**
 * Check if a given object implements the EventTime interface.
 */
export function instanceOfEventTime(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EventTimeFromJSON(json: any): EventTime {
    return EventTimeFromJSONTyped(json, false);
}

export function EventTimeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventTime {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'year': !exists(json, 'year') ? undefined : json['year'],
        'month': !exists(json, 'month') ? undefined : json['month'],
        'day': !exists(json, 'day') ? undefined : json['day'],
        'hour': !exists(json, 'hour') ? undefined : json['hour'],
        'minute': !exists(json, 'minute') ? undefined : json['minute'],
    };
}

export function EventTimeToJSON(value?: EventTime | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'year': value.year,
        'month': value.month,
        'day': value.day,
        'hour': value.hour,
        'minute': value.minute,
    };
}

