import { FormAddressInput } from "../../../../../components/form/form-address-input";
import { AddressComponentEnum } from "../../../../../components/map/constants";
import { useT } from "../../../../../i18n/use-t";
import { FormDataCollectionInput } from "../types";

const postalCodeMinLength = 3;
export const postalCodeInput: FormDataCollectionInput = {
  wasCompleted: (user) => !!user.userAttributes?.postalCode,
  Component: ({ errors, control }) => {
    const t = useT();
    return (
      <FormAddressInput
        name="postalCode"
        inputType="number"
        labelClassName="font-bold"
        title={{ tx: "userData.postalCode" }}
        control={control}
        error={{ txUnchecked: errors.postalCode?.message }}
        addressComponents={[AddressComponentEnum.POSTAL_CODE]}
        disableUseAddressSearch={true}
        validate={(value) => {
          if (!value || value === "") {
            return t({ tx: "userData.postalCodeIsRequiredError" });
          }

          if (value.length < postalCodeMinLength) {
            return t({
              tx: "userData.postalCodeMustBeLonger",
              txData: { characters: postalCodeMinLength },
            });
          }
        }}
      />
    );
  },
};
