import { CreateAndLinkStripeAccountRequest } from "../../api/generated/backend";
import { toTenantIdHeader, useApiMutation } from "../../api/use-api";
import { useTenantId } from "../../util/use-active-tenant-id";
import { LoadingButton } from "../form/loading-button";

export const StripeCreateAccountButton: React.FC = () => {
  const tenantId = useTenantId();
  const { mutate: createAndLinkStripeAccount, isLoading } = useApiMutation(
    "backend",
    (api) => (request: CreateAndLinkStripeAccountRequest) =>
      api.createAndLinkStripeAccount(request),
    undefined,
    undefined,
    {
      onSuccess: (linkAccount) => {
        linkAccount.url && window.open(linkAccount.url, "_self");
      },
    },
  );

  return (
    <LoadingButton
      loading={isLoading}
      tx="stripe.createAndLinkAccount"
      onClick={() =>
        createAndLinkStripeAccount({
          createAndLinkAccountDto: {
            refreshUrl: `https://${location.host}/admin/stripe`,
            returnUrl: `https://${location.host}/admin/stripe`,
          },
          ...toTenantIdHeader(tenantId),
        })
      }
      isDisabled={isLoading}
    />
  );
};
