import { PropsWithChildren } from "react";
import { EnabledPage } from "shared/model/pages";

import { useHeaderRoutes } from "./use-header-routes";
import { Header } from "../components/header";
import { InjectTypeForm } from "../components/inject-typeform";
import { LoadingScreen } from "../components/loading-screen";
import { LockGuard } from "../components/lock";
import { UserFeedbackModal } from "../components/user-feedback/user-feedback-modal";

export const TenantPage: React.FC<
  PropsWithChildren<{ page?: EnabledPage }>
> = ({ children, page }) => {
  const headerRoutes = useHeaderRoutes();

  return headerRoutes ? (
    <div className="fixed flex h-full w-screen flex-col">
      <Header navbarRoutes={headerRoutes} />
      <div className="min-h-0 grow overflow-auto">
        <UserFeedbackModal />
        <InjectTypeForm page={page}>
          <LockGuard page={page}>{children}</LockGuard>
        </InjectTypeForm>
      </div>
    </div>
  ) : (
    <LoadingScreen />
  );
};
