import { CognitoAuthStoreInitializer } from "./implementations/cognito/cognito-store-initializer";
import {
  IAuthStoreInitializer,
  IStaticAuthStore,
} from "./implementations/generic/types";
import { MockStoreInitializer } from "./implementations/mock/mock-store-initializer";

export const authStoreToUse: IAuthStoreInitializer & IStaticAuthStore =
  process.env.REACT_APP_MOCK_COGNITO === "true"
    ? MockStoreInitializer
    : CognitoAuthStoreInitializer;
