import React from "react";
import { twMerge } from "tailwind-merge";

export const Form: React.FC<JSX.IntrinsicElements["form"]> = ({
  className,
  ...rest
}) => {
  return (
    <form {...rest} className={twMerge("flex flex-col gap-3", className)} />
  );
};
