import { cognitoPaths } from "./paths";
import { Link } from "../../../../components/link";
import { Text } from "../../../../components/text";
import { useAuthNavigate } from "../../generic/use-auth-navigate";

export const CognitoLoginProblem: React.FC = () => {
  const navigate = useAuthNavigate();

  return (
    <Text
      as="p"
      tx="auth.navigation.resendMagicLink"
      txComponents={{
        ResendMagicLink: (
          <Link
            onClick={() =>
              navigate({
                to: { type: "uri", uri: cognitoPaths.magicSignIn },
              })
            }
          />
        ),
      }}
    />
  );
};
