import { PropsWithChildren, useEffect, useState } from "react";

import { AuthStateStore } from "./auth-state-store";
import { AuthStateStoreContext } from "./auth-state-store-context";
import { authStoreToUse } from "./auth-store";
import { NullReplacer } from "../components/null-replacer";

export const cognitoUpdateChannel = new BroadcastChannel("cognito_update");

export const AuthStateStoreProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [authStore, setAuthStore] = useState<AuthStateStore | null>(null);

  useEffect(() => {
    const inializedAuthStore = new AuthStateStore(
      cognitoUpdateChannel,
      authStoreToUse,
    );
    setAuthStore(inializedAuthStore);
  }, []);

  return authStore ? (
    <AuthStateStoreContext.Provider value={authStore}>
      {children}
    </AuthStateStoreContext.Provider>
  ) : (
    <NullReplacer text="AUTH STATE STORE" />
  );
};
