"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createPageLock = exports.defaultPageLock = void 0;
const pages_1 = require("../pages");
exports.defaultPageLock = {
    pageLock: [
        {
            lockType: pages_1.PageLockType.GROUP,
            reasonWhyLockedIframeUrl: "tabs.locks.pageNotFound",
        },
        {
            lockType: pages_1.PageLockType.LANGUAGE,
            reasonWhyLockedIframeUrl: "tabs.locks.pageDoesNotExistInSelectedLanguage",
        },
    ],
};
const createPageLock = (config) => ({
    pageLock: [...exports.defaultPageLock.pageLock, ...config],
});
exports.createPageLock = createPageLock;
