import {
  backendApiUrl,
  landbotApiUrl,
  multiagentApiUrl,
} from "../../util/env-utils";
import * as BackendClient from "../generated/backend";
import * as LandbotClient from "../generated/landbot";
import * as MultiagentClient from "../generated/multiagent";

export type ApiClientService = "backend" | "multiagent" | "landbot";

export type ApiClient<TClient extends ApiClientService> =
  TClient extends "backend"
    ? BackendClient.DefaultApi
    : TClient extends "multiagent"
    ? MultiagentClient.DefaultApi
    : LandbotClient.DefaultApi;

export const apiUrl: Record<ApiClientService, string> = {
  backend: backendApiUrl,
  multiagent: multiagentApiUrl,
  landbot: landbotApiUrl,
};
