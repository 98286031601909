import { v4 as uuid } from "uuid";

import { EventProps } from "./event-props";
import { ToastEvent } from "./toast-event";
import { I18nProps } from "../text";

export class SuccessEvent implements ToastEvent {
  public constructor(
    public readonly successMessage: I18nProps,
    private readonly id = uuid(),
  ) {}

  public shouldAutoClear(): boolean {
    return true;
  }

  public getEventProps(): EventProps {
    return {
      eventId: this.id,
      kind: "success",
      message: this.successMessage,
    };
  }
}
