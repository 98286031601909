import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

import { SvgIcon } from "./icons/svg-icon";

type AvatarProps = {
  src?: string;
  alt: string;
  onClick?: () => void;
  className?: string;
} & PropsWithChildren;
export const Avatar: React.FC<AvatarProps> = ({
  src,
  alt,
  onClick,
  className,
  children,
}) => {
  return (
    <div className={twMerge("avatar", className)}>
      <div className="h-full w-full p-1" onClick={onClick}>
        {src ? (
          <img
            src={src}
            alt={alt}
            className="rounded-full p-1 ring-2 ring-gray-300 dark:ring-gray-500"
          />
        ) : (
          <SvgIcon
            icon="person"
            className="rounded-full ring-2 ring-gray-300"
          />
        )}
        {children}
      </div>
    </div>
  );
};
