/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DiseaseSettings } from './DiseaseSettings';
import {
    DiseaseSettingsFromJSON,
    DiseaseSettingsFromJSONTyped,
    DiseaseSettingsToJSON,
} from './DiseaseSettings';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * Represents a Doctor record
 * @export
 * @interface Doctor
 */
export interface Doctor {
    /**
     * 
     * @type {string}
     * @memberof Doctor
     */
    id: string;
    /**
     * 
     * @type {User}
     * @memberof Doctor
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof Doctor
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof Doctor
     */
    doctorName?: string;
    /**
     * 
     * @type {string}
     * @memberof Doctor
     */
    practiceName?: string;
    /**
     * 
     * @type {number}
     * @memberof Doctor
     */
    lat?: number;
    /**
     * 
     * @type {number}
     * @memberof Doctor
     */
    _long?: number;
    /**
     * 
     * @type {string}
     * @memberof Doctor
     */
    streetAddress: string;
    /**
     * 
     * @type {string}
     * @memberof Doctor
     */
    generalMedicalSpeciality: string;
    /**
     * 
     * @type {DiseaseSettings}
     * @memberof Doctor
     */
    disease?: DiseaseSettings;
    /**
     * 
     * @type {string}
     * @memberof Doctor
     */
    diseaseId: string;
}

/**
 * Check if a given object implements the Doctor interface.
 */
export function instanceOfDoctor(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "streetAddress" in value;
    isInstance = isInstance && "generalMedicalSpeciality" in value;
    isInstance = isInstance && "diseaseId" in value;

    return isInstance;
}

export function DoctorFromJSON(json: any): Doctor {
    return DoctorFromJSONTyped(json, false);
}

export function DoctorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Doctor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'userId': json['userId'],
        'doctorName': !exists(json, 'doctorName') ? undefined : json['doctorName'],
        'practiceName': !exists(json, 'practiceName') ? undefined : json['practiceName'],
        'lat': !exists(json, 'lat') ? undefined : json['lat'],
        '_long': !exists(json, 'long') ? undefined : json['long'],
        'streetAddress': json['streetAddress'],
        'generalMedicalSpeciality': json['generalMedicalSpeciality'],
        'disease': !exists(json, 'disease') ? undefined : DiseaseSettingsFromJSON(json['disease']),
        'diseaseId': json['diseaseId'],
    };
}

export function DoctorToJSON(value?: Doctor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'user': UserToJSON(value.user),
        'userId': value.userId,
        'doctorName': value.doctorName,
        'practiceName': value.practiceName,
        'lat': value.lat,
        'long': value._long,
        'streetAddress': value.streetAddress,
        'generalMedicalSpeciality': value.generalMedicalSpeciality,
        'disease': DiseaseSettingsToJSON(value.disease),
        'diseaseId': value.diseaseId,
    };
}

