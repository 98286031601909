import { t } from "i18next";
import { useEffect, useState } from "react";
import { Language, TranslationPerLanguageType } from "shared/model/languages";
import { Organisation } from "shared/model/organisations";

import { DeleteCategoryModal } from "./delete-category-modal";
import {
  SocialMediaPostCategory,
  UpsertSocialMediaPostCategoryDto,
} from "../../../api/generated/backend";
import { useModal } from "../../../models/modal-provider";
import { Button, PrimaryButton } from "../../form/button";
import { Checkbox } from "../../form/checkbox";
import { LanguageKey } from "../../form/language/constants";
import { TranslationsInputFields } from "../../form/language/translations-input-fields";
import { OrganisationsPerDiseaseMultiSelect } from "../../form/organisations-per-disease-multiselect";
import { TextInput } from "../../form/text-input";
import { SvgIcon } from "../../icons/svg-icon";
import { Text } from "../../text";
import { useSocialMediaCategories } from "../use-social-media-categories";

export const ManageCategoriesModal = ({
  createCategory,
  updateCategory,
  deleteCategory,
}: {
  createCategory: (value: UpsertSocialMediaPostCategoryDto) => void;
  updateCategory: (id: string, value: UpsertSocialMediaPostCategoryDto) => void;
  deleteCategory: (id: string) => void;
}): ReturnType<React.FC> => {
  const { categories } = useSocialMediaCategories();
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [isEditMode, setIsEditMode] = useState<boolean[]>([]);

  useEffect(() => {
    setIsEditMode(categories?.map(() => false) ?? []);
  }, [categories]);

  return (
    <div>
      <Text
        className="mx-2 rounded-md pb-4 text-2xl font-bold"
        as="div"
        tx="socialMedia.categories.manageCategories"
      />
      <div>
        <div className="mb-4 grid grid-cols-5 items-center gap-4 border-b-2 border-b-base-300 pb-4">
          <Text
            as="div"
            className="ml-2 flex w-[70%] flex-row justify-start text-sm font-semibold"
            tx="socialMedia.categories.categoryName"
          />
          <Text
            as="div"
            className="ml-2 flex w-[70%] flex-row justify-start text-sm font-semibold"
            tx="socialMedia.categories.categoryTranslations"
          />
          <Text
            as="div"
            className="ml-2 flex w-[70%] flex-row justify-start text-sm font-semibold"
            tx="socialMedia.categories.categoryOrganisation"
          />
          <Text
            as="div"
            className="ml-2 flex w-[70%] flex-row justify-start text-sm font-semibold"
            tx="socialMedia.categories.setAsGlobalCategory"
          />
          <Text
            as="div"
            className="ml-2 flex w-[70%] flex-row justify-start text-sm font-semibold"
            tx="socialMedia.categories.editCategory"
          />
          {categories?.map((category, index) =>
            isEditMode[index] ? (
              <UpdateCategoryForm
                key={index}
                index={index}
                category={category}
                updateCategory={updateCategory}
                stopEditing={() => {
                  setIsEditMode(
                    isEditMode.map((v, i) => (i === index ? false : v)),
                  );
                }}
              />
            ) : (
              <CategoryInfo
                key={index}
                category={category}
                deleteCategory={deleteCategory}
                edit={() => {
                  setIsEditMode(
                    isEditMode.map((v, i) => (i === index ? true : v)),
                  );
                }}
              />
            ),
          )}
        </div>

        {isAddingCategory ? (
          <AddCategoryForm
            FinishAddingCategory={() => setIsAddingCategory(false)}
            createCategory={createCategory}
          />
        ) : (
          <div className="flex flex-row">
            <Button
              className="btn-ghost btn m-1 flex flex-nowrap items-center justify-start whitespace-nowrap"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsAddingCategory(true);
              }}
            >
              <SvgIcon icon="plus" className="h-6 w-6" />
              <Text
                className="label-text"
                as="span"
                text={t("socialMedia.categories.addACategory")}
              />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const CategoryInfo: React.FC<{
  category: SocialMediaPostCategory;
  edit: () => void;
  deleteCategory: (id: string) => void;
}> = ({ category, deleteCategory, edit }) => {
  const {
    showModal: showDeleteCategoryModal,
    hideModal: hideDeleteCategoryModal,
  } = useModal();

  return (
    <>
      <Text
        as="div"
        className="ml-2 flex w-[70%] flex-row justify-start text-ellipsis text-sm"
        text={category.name}
      />
      <div className="dropdown-right dropdown">
        <Text
          tabIndex={0}
          as="div"
          className="ml-2 flex w-[70%] flex-row justify-start text-ellipsis text-sm"
          text={
            category.translations &&
            Object.entries(JSON.parse(category.translations)).length > 0
              ? Object.keys(JSON.parse(category.translations)).reduce(
                  (acc, key, index) =>
                    `${acc}${index === 0 ? "" : ", "}${
                      Language[key as LanguageKey]
                    }`,
                  "",
                )
              : t("i18n.noTranslations")
          }
        />
        <div className="dropdown-content menu z-[10] w-fit items-center rounded-box bg-base-100 p-2 shadow">
          {Object.entries(
            JSON.parse(
              category.translations ?? "",
            ) as TranslationPerLanguageType,
          ).map(([key, trans], idx) => (
            <div
              key={idx}
              className="mb-2 flex w-fit flex-row items-center gap-4 p-4 "
            >
              <Text
                className="w-20 pr-4 font-normal"
                text={
                  t(`i18n.languages.${Language[key as LanguageKey]}`) + ": "
                }
              />
              <Text className="w-20 pr-4 font-normal" text={trans} />
            </div>
          ))}
        </div>
      </div>
      <Text
        as="div"
        className="ml-2 flex w-[70%] flex-row justify-start text-ellipsis text-sm"
        text={
          category.organisations && category.organisations.length > 0
            ? category.organisations.reduce(
                (acc, value, index) =>
                  `${acc}${index === 0 ? "" : ", "}${value}`,
                "",
              )
            : t("forms.noOrganisationSelected")
        }
      />
      <Text
        as="div"
        className="ml-2 flex w-[70%] flex-row justify-start text-ellipsis text-sm"
        text={(!!category.disease).toString()}
      />
      <div className="ml-2 flex flex-row justify-start gap-4 ">
        <SvgIcon
          icon="edit"
          className="h-8 w-8 rounded-md hover:bg-slate-300"
          onClick={(e) => {
            edit();
            e.preventDefault();
            e.stopPropagation();
          }}
        />
        <SvgIcon
          icon="garbage"
          className="h-8 w-8 rounded-md hover:bg-slate-300"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            showDeleteCategoryModal({
              children: (
                <DeleteCategoryModal
                  deleteCategory={() => {
                    deleteCategory(category.id);
                  }}
                  hideModal={hideDeleteCategoryModal}
                  category={category.name}
                />
              ),
              className:
                "max-w-[50%] lg:max-w-[30%] py-4 px-6 md:overflow-hidden",
              showsWindowCloseButton: false,
              showCloseButton: false,
              closeOnClickOutside: false,
              onClose: () => {},
            });
          }}
        />
      </div>
    </>
  );
};

const UpdateCategoryForm: React.FC<{
  category: SocialMediaPostCategory;
  updateCategory: (id: string, value: UpsertSocialMediaPostCategoryDto) => void;
  stopEditing: () => void;
  index: number;
}> = ({ category, updateCategory, stopEditing, index }) => {
  const [categoryName, setCategoryName] = useState<string>(category.name ?? "");
  const [categoryTranslations, setCategoryTranslations] =
    useState<TranslationPerLanguageType>(
      JSON.parse(category.translations ?? "") as TranslationPerLanguageType,
    );
  const [categoryOrganisations, setCategoryOrganisations] = useState<
    Organisation[]
  >([]);
  const [isGlobalCategory, setIsGlobalCategory] = useState<boolean>(
    !!category.disease,
  );

  return (
    <>
      <TextInput
        placeholder={{
          tx: "socialMedia.categories.categoryName",
        }}
        className="mr-4 h-12"
        value={categoryName}
        onChange={(e) => {
          setCategoryName(e.target.value);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            event.stopPropagation();
          }
        }}
      />
      <TranslationsInputFields
        values={categoryTranslations}
        onChange={(translations: TranslationPerLanguageType) => {
          setCategoryTranslations(translations);
        }}
      />
      <OrganisationsPerDiseaseMultiSelect
        className={`h-12 ${
          index % 2 === 1
            ? "rounded-lg bg-white"
            : "rounded-lg border-[1px] border-base-300"
        }`}
        values={categoryOrganisations}
        onChange={(orgs: Organisation[]) => {
          setCategoryOrganisations(orgs);
        }}
      />
      <Checkbox
        defaultChecked={!!category.disease}
        className="ml-2"
        onChange={(e) => {
          setIsGlobalCategory(e.target.checked);
        }}
      />
      <div className="ml-2 flex flex-row justify-start gap-4 ">
        <SvgIcon
          icon="checkMark"
          className="h-8 w-8 rounded-md hover:bg-slate-300"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            stopEditing();
            if (
              JSON.stringify(categoryOrganisations) !==
                JSON.stringify(category.organisations) ||
              isGlobalCategory !== !!category.disease ||
              JSON.stringify(categoryTranslations) !== category.translations ||
              (categoryName !== category.name && categoryName !== "")
            ) {
              updateCategory(category.id, {
                name: categoryName,
                isDiseaseCategory: isGlobalCategory,
                organisations: categoryOrganisations,
                translations: JSON.stringify(categoryTranslations),
              });
            }
          }}
        />
        <SvgIcon
          icon="cross"
          className="h-8 w-8 rounded-md hover:bg-slate-300"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            stopEditing();
          }}
        />
      </div>
    </>
  );
};

const AddCategoryForm: React.FC<{
  FinishAddingCategory: () => void;
  createCategory: (value: UpsertSocialMediaPostCategoryDto) => void;
}> = ({ FinishAddingCategory, createCategory }) => {
  const [newCategoryName, setNewCategoryName] = useState<string>("");
  const [newCategoryTranslation, setNewCategoryTranslation] =
    useState<TranslationPerLanguageType>({});
  const [newCategoryOrgs, setNewCategoryOrgs] = useState<Organisation[]>([]);
  const [isNewCategoryGlobal, setIsNewCategoryGlobal] = useState(false);

  return (
    <>
      <div className="mb-4 grid grid-cols-5 place-items-center gap-4">
        <TextInput
          placeholder={{ tx: "socialMedia.categories.categoryName" }}
          className=" ml-4"
          value={newCategoryName}
          onChange={(e) => {
            setNewCategoryName(e.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              event.stopPropagation();
            }
          }}
        />
        <TranslationsInputFields
          values={newCategoryTranslation}
          onChange={(translations: TranslationPerLanguageType) => {
            setNewCategoryTranslation(translations);
          }}
        />
        <OrganisationsPerDiseaseMultiSelect
          values={newCategoryOrgs}
          onChange={(org: Organisation[]) => {
            setNewCategoryOrgs(org);
          }}
        />
        <Checkbox
          className="ml-2"
          onChange={(e) => {
            setIsNewCategoryGlobal(e.target.checked);
          }}
        />
      </div>
      <div className="flex flex-row">
        <PrimaryButton
          className="btn m-1 flex flex-nowrap items-center justify-start whitespace-nowrap"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            FinishAddingCategory();
            createCategory({
              name: newCategoryName,
              isDiseaseCategory: isNewCategoryGlobal,
              organisations: newCategoryOrgs,
              translations: JSON.stringify(newCategoryTranslation),
            });
            setNewCategoryName("");
            setNewCategoryTranslation({});
            setNewCategoryOrgs([]);
            setIsNewCategoryGlobal(false);
          }}
          tx="general.confirm"
        />
      </div>
    </>
  );
};
