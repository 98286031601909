/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubmitFeedback
 */
export interface SubmitFeedback {
    /**
     * 
     * @type {string}
     * @memberof SubmitFeedback
     */
    details: string;
    /**
     * 
     * @type {string}
     * @memberof SubmitFeedback
     */
    feedbackId: string;
    /**
     * 
     * @type {string}
     * @memberof SubmitFeedback
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof SubmitFeedback
     */
    type: SubmitFeedbackTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof SubmitFeedback
     */
    activityMinutes: number;
}


/**
 * @export
 */
export const SubmitFeedbackTypeEnum = {
    Rating: 'RATING',
    Scale: 'SCALE',
    Satisfaction: 'SATISFACTION'
} as const;
export type SubmitFeedbackTypeEnum = typeof SubmitFeedbackTypeEnum[keyof typeof SubmitFeedbackTypeEnum];


/**
 * Check if a given object implements the SubmitFeedback interface.
 */
export function instanceOfSubmitFeedback(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "details" in value;
    isInstance = isInstance && "feedbackId" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "activityMinutes" in value;

    return isInstance;
}

export function SubmitFeedbackFromJSON(json: any): SubmitFeedback {
    return SubmitFeedbackFromJSONTyped(json, false);
}

export function SubmitFeedbackFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubmitFeedback {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'details': json['details'],
        'feedbackId': json['feedbackId'],
        'title': json['title'],
        'type': json['type'],
        'activityMinutes': json['activityMinutes'],
    };
}

export function SubmitFeedbackToJSON(value?: SubmitFeedback | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'details': value.details,
        'feedbackId': value.feedbackId,
        'title': value.title,
        'type': value.type,
        'activityMinutes': value.activityMinutes,
    };
}

