/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IsFollowingTenant
 */
export interface IsFollowingTenant {
    /**
     * 
     * @type {boolean}
     * @memberof IsFollowingTenant
     */
    isFollowingTenant: boolean;
}

/**
 * Check if a given object implements the IsFollowingTenant interface.
 */
export function instanceOfIsFollowingTenant(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "isFollowingTenant" in value;

    return isInstance;
}

export function IsFollowingTenantFromJSON(json: any): IsFollowingTenant {
    return IsFollowingTenantFromJSONTyped(json, false);
}

export function IsFollowingTenantFromJSONTyped(json: any, ignoreDiscriminator: boolean): IsFollowingTenant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isFollowingTenant': json['isFollowingTenant'],
    };
}

export function IsFollowingTenantToJSON(value?: IsFollowingTenant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isFollowingTenant': value.isFollowingTenant,
    };
}

