/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DiseaseSettings } from './DiseaseSettings';
import {
    DiseaseSettingsFromJSON,
    DiseaseSettingsFromJSONTyped,
    DiseaseSettingsToJSON,
} from './DiseaseSettings';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * Represents a LandbotEventData record
 * @export
 * @interface LandbotEventData
 */
export interface LandbotEventData {
    /**
     * 
     * @type {string}
     * @memberof LandbotEventData
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LandbotEventData
     */
    data?: string;
    /**
     * 
     * @type {Date}
     * @memberof LandbotEventData
     */
    inputTime: Date;
    /**
     * 
     * @type {User}
     * @memberof LandbotEventData
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof LandbotEventData
     */
    userId: string;
    /**
     * 
     * @type {DiseaseSettings}
     * @memberof LandbotEventData
     */
    disease?: DiseaseSettings;
    /**
     * 
     * @type {string}
     * @memberof LandbotEventData
     */
    diseaseId: string;
}

/**
 * Check if a given object implements the LandbotEventData interface.
 */
export function instanceOfLandbotEventData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "inputTime" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "diseaseId" in value;

    return isInstance;
}

export function LandbotEventDataFromJSON(json: any): LandbotEventData {
    return LandbotEventDataFromJSONTyped(json, false);
}

export function LandbotEventDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): LandbotEventData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'data': !exists(json, 'data') ? undefined : json['data'],
        'inputTime': (new Date(json['inputTime'])),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'userId': json['userId'],
        'disease': !exists(json, 'disease') ? undefined : DiseaseSettingsFromJSON(json['disease']),
        'diseaseId': json['diseaseId'],
    };
}

export function LandbotEventDataToJSON(value?: LandbotEventData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'data': value.data,
        'inputTime': (value.inputTime.toISOString()),
        'user': UserToJSON(value.user),
        'userId': value.userId,
        'disease': DiseaseSettingsToJSON(value.disease),
        'diseaseId': value.diseaseId,
    };
}

