export const cognitoPaths = {
  onboarding: "/auth/onboarding",
  signUp: "/auth/signup",
  confirmSignUpInfo: "/auth/confirm-signup",
  confirmSignInInfo: "/auth/confirm-signin",
  confirmSignUpCallback: "/auth/signup-confirmation-callback",
  updateEmailCallback: "/auth/update-email-callback",
  updateEmailInfo: "/auth/update-email-info",
  signIn: "/auth/signin",
  signUpDeprecated: "/auth/signup-deprecated",
  magicSignIn: "/auth/magic-signin",
  magicSignUp: "/auth/magic-signup",
  loginProblem: "/auth/login-problem",
  resetPassword: "/auth/reset-password",
  confirmResetPassword: "/auth/confirm-reset-password",
  socialRedirectHandler: "/auth/social-redirect-handler",
  socialLogoutHandler: "/auth/social-logout-handler",
  sessionCleanup: "/auth/session-cleanup",
  needToMigrateAccountNotice: "/auth/need-to-migrate-account-notice",
  migrateAccount: "/auth/migrate-account",
  verifyMagicLink: "/auth/verify-magic-link",
};
