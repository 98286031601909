import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient();

const invalidateQueriesChannel = new BroadcastChannel("invalidate_queries");
type InvalidateQueriesMessage = Parameters<
  (typeof queryClient)["invalidateQueries"]
>;

const originalInvalidateQueries =
  queryClient.invalidateQueries.bind(queryClient);

queryClient.invalidateQueries = (async (
  ...params: InvalidateQueriesMessage
) => {
  invalidateQueriesChannel.postMessage(params);
  await originalInvalidateQueries(...params);
}) as typeof queryClient.invalidateQueries;

invalidateQueriesChannel.addEventListener(
  "message",
  ({ data }: { data: InvalidateQueriesMessage }) => {
    originalInvalidateQueries(...data);
  },
);

const resetQueriesChannel = new BroadcastChannel("reset_queries");
type ResetQueriesMessage = Parameters<(typeof queryClient)["resetQueries"]>;

const originalResetQueries = queryClient.resetQueries.bind(queryClient);

queryClient.resetQueries = (async (...params: ResetQueriesMessage) => {
  resetQueriesChannel.postMessage(params);
  await originalResetQueries(...params);
}) as typeof queryClient.resetQueries;

resetQueriesChannel.addEventListener(
  "message",
  ({ data }: { data: ResetQueriesMessage }) => {
    originalResetQueries(...data);
  },
);
