"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.languageToBaseLanguageTable = exports.languageToCamelCaseTable = exports.allLanguages = exports.Language = void 0;
const utility_1 = require("../utils/utility");
var Language;
(function (Language) {
    Language["en_US"] = "en-US";
    Language["de_CH"] = "de-CH";
    Language["de_DE"] = "de-DE";
    Language["it_IT"] = "it-IT";
    Language["es_ES"] = "es-ES";
    Language["es_CO"] = "es-CO";
    Language["nl_NL"] = "nl-NL";
    Language["tr_TR"] = "tr-TR";
    Language["fr_FR"] = "fr-FR";
})(Language || (exports.Language = Language = {}));
exports.allLanguages = (0, utility_1.allEnumValues)({
    [Language.en_US]: Language.en_US,
    [Language.de_CH]: Language.de_CH,
    [Language.de_DE]: Language.de_DE,
    [Language.it_IT]: Language.it_IT,
    [Language.es_ES]: Language.es_ES,
    [Language.es_CO]: Language.es_CO,
    [Language.nl_NL]: Language.nl_NL,
    [Language.tr_TR]: Language.tr_TR,
    [Language.fr_FR]: Language.fr_FR,
});
exports.languageToCamelCaseTable = (() => {
    // Warning: rake install:all required if you add languages
    const lookup = {};
    for (const lang of Object.values(Language)) {
        const transformed = lang.replace("-", "_");
        lookup[lang] = transformed;
    }
    return lookup;
})();
exports.languageToBaseLanguageTable = (() => {
    const lookup = {};
    for (const lang of Object.values(Language)) {
        const transformed = lang.split("-")[0];
        lookup[lang] = transformed;
    }
    return lookup;
})();
