/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MedicalProfessionalRating } from './MedicalProfessionalRating';
import {
    MedicalProfessionalRatingFromJSON,
    MedicalProfessionalRatingFromJSONTyped,
    MedicalProfessionalRatingToJSON,
} from './MedicalProfessionalRating';
import type { MedicalProfessionalsOnUsers } from './MedicalProfessionalsOnUsers';
import {
    MedicalProfessionalsOnUsersFromJSON,
    MedicalProfessionalsOnUsersFromJSONTyped,
    MedicalProfessionalsOnUsersToJSON,
} from './MedicalProfessionalsOnUsers';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * Represents a MedicalProfessional record
 * @export
 * @interface MedicalProfessional
 */
export interface MedicalProfessional {
    /**
     * 
     * @type {string}
     * @memberof MedicalProfessional
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalProfessional
     */
    practiceName?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalProfessional
     */
    doctorName?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalProfessional
     */
    generalMedicalSpeciality: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedicalProfessional
     */
    medicalSpecialities: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedicalProfessional
     */
    medicalSpecialitiesFormatted: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MedicalProfessional
     */
    streetAddress: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalProfessional
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalProfessional
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalProfessional
     */
    country: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedicalProfessional
     */
    phones: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedicalProfessional
     */
    websites: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedicalProfessional
     */
    medicalSubAreas: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MedicalProfessional
     */
    careSetting: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof MedicalProfessional
     */
    lat?: number;
    /**
     * 
     * @type {number}
     * @memberof MedicalProfessional
     */
    _long?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicalProfessional
     */
    googleMapsLink?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalProfessional
     */
    datasource?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MedicalProfessional
     */
    entryIsValid?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof MedicalProfessional
     */
    createdAt: Date;
    /**
     * 
     * @type {Array<User>}
     * @memberof MedicalProfessional
     */
    user?: Array<User>;
    /**
     * 
     * @type {Array<MedicalProfessionalsOnUsers>}
     * @memberof MedicalProfessional
     */
    medicalProfessionalsOnUsers?: Array<MedicalProfessionalsOnUsers>;
    /**
     * 
     * @type {Array<MedicalProfessionalRating>}
     * @memberof MedicalProfessional
     */
    medicalProfessionalRating?: Array<MedicalProfessionalRating>;
}

/**
 * Check if a given object implements the MedicalProfessional interface.
 */
export function instanceOfMedicalProfessional(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "generalMedicalSpeciality" in value;
    isInstance = isInstance && "medicalSpecialities" in value;
    isInstance = isInstance && "medicalSpecialitiesFormatted" in value;
    isInstance = isInstance && "streetAddress" in value;
    isInstance = isInstance && "postalCode" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "phones" in value;
    isInstance = isInstance && "websites" in value;
    isInstance = isInstance && "medicalSubAreas" in value;
    isInstance = isInstance && "careSetting" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function MedicalProfessionalFromJSON(json: any): MedicalProfessional {
    return MedicalProfessionalFromJSONTyped(json, false);
}

export function MedicalProfessionalFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalProfessional {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'practiceName': !exists(json, 'practiceName') ? undefined : json['practiceName'],
        'doctorName': !exists(json, 'doctorName') ? undefined : json['doctorName'],
        'generalMedicalSpeciality': json['generalMedicalSpeciality'],
        'medicalSpecialities': json['medicalSpecialities'],
        'medicalSpecialitiesFormatted': json['medicalSpecialitiesFormatted'],
        'streetAddress': json['streetAddress'],
        'postalCode': json['postalCode'],
        'city': json['city'],
        'country': json['country'],
        'phones': json['phones'],
        'websites': json['websites'],
        'medicalSubAreas': json['medicalSubAreas'],
        'careSetting': json['careSetting'],
        'lat': !exists(json, 'lat') ? undefined : json['lat'],
        '_long': !exists(json, 'long') ? undefined : json['long'],
        'googleMapsLink': !exists(json, 'googleMapsLink') ? undefined : json['googleMapsLink'],
        'datasource': !exists(json, 'datasource') ? undefined : json['datasource'],
        'entryIsValid': !exists(json, 'entryIsValid') ? undefined : json['entryIsValid'],
        'createdAt': (new Date(json['createdAt'])),
        'user': !exists(json, 'user') ? undefined : ((json['user'] as Array<any>).map(UserFromJSON)),
        'medicalProfessionalsOnUsers': !exists(json, 'medicalProfessionalsOnUsers') ? undefined : ((json['medicalProfessionalsOnUsers'] as Array<any>).map(MedicalProfessionalsOnUsersFromJSON)),
        'medicalProfessionalRating': !exists(json, 'medicalProfessionalRating') ? undefined : ((json['medicalProfessionalRating'] as Array<any>).map(MedicalProfessionalRatingFromJSON)),
    };
}

export function MedicalProfessionalToJSON(value?: MedicalProfessional | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'practiceName': value.practiceName,
        'doctorName': value.doctorName,
        'generalMedicalSpeciality': value.generalMedicalSpeciality,
        'medicalSpecialities': value.medicalSpecialities,
        'medicalSpecialitiesFormatted': value.medicalSpecialitiesFormatted,
        'streetAddress': value.streetAddress,
        'postalCode': value.postalCode,
        'city': value.city,
        'country': value.country,
        'phones': value.phones,
        'websites': value.websites,
        'medicalSubAreas': value.medicalSubAreas,
        'careSetting': value.careSetting,
        'lat': value.lat,
        'long': value._long,
        'googleMapsLink': value.googleMapsLink,
        'datasource': value.datasource,
        'entryIsValid': value.entryIsValid,
        'createdAt': (value.createdAt.toISOString()),
        'user': value.user === undefined ? undefined : ((value.user as Array<any>).map(UserToJSON)),
        'medicalProfessionalsOnUsers': value.medicalProfessionalsOnUsers === undefined ? undefined : ((value.medicalProfessionalsOnUsers as Array<any>).map(MedicalProfessionalsOnUsersToJSON)),
        'medicalProfessionalRating': value.medicalProfessionalRating === undefined ? undefined : ((value.medicalProfessionalRating as Array<any>).map(MedicalProfessionalRatingToJSON)),
    };
}

