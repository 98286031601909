import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import {
  GetProrationInvoiceRequest,
  StripeProduct,
  SwitchSubscriptionRequest,
} from "../../api/generated/backend";
import {
  toTenantId,
  toTenantIdHeader,
  useApiMutation,
} from "../../api/use-api";
import { useModal } from "../../models/modal-provider";
import {
  USER_OWNED_ACTIVE_SUBSCRIPTIONS_KEY,
  USER_OWNED_SCHEDULED_SUBSCRIPTIONS_KEY,
} from "../../types/query-keys";
import { useTenantId } from "../../util/use-active-tenant-id";
import { PrimaryButton } from "../form/button";

export const SwitchSubscriptionButton: React.FC<{
  priceId: string;
  language: string;
  product: StripeProduct;
}> = ({ priceId, language, product }) => {
  const tenantId = useTenantId();
  const queryClient = useQueryClient();
  const {
    showModal: showModal,
    hideModal: hideModal,
    updateModal: updateModal,
  } = useModal();

  const { mutate: switchSubscription } = useApiMutation(
    "backend",
    (api) => (request: SwitchSubscriptionRequest) =>
      api.switchSubscription(request),
    undefined,
    { successMessage: { tx: "subscriptions.membershipUpdated" } },
    {
      onSuccess: (_, header) => {
        queryClient.invalidateQueries(
          USER_OWNED_ACTIVE_SUBSCRIPTIONS_KEY(toTenantId(header)),
        );
        queryClient.invalidateQueries(
          USER_OWNED_SCHEDULED_SUBSCRIPTIONS_KEY(toTenantId(header)),
        );
      },
      onSettled: () => hideModal(),
    },
  );

  const { mutate: getProrationInvoice } = useApiMutation(
    "backend",
    (api) => (request: GetProrationInvoiceRequest) =>
      api.getProrationInvoice(request),
    undefined,
    undefined,
    {
      onSuccess: (invoice) => {
        updateModal({
          loading: { isLoading: false },
          description: invoice && {
            tx: invoice?.isUpgrading
              ? "subscriptions.upgradeMembershipExplanation"
              : "subscriptions.downgradeMembershipExplanation",
            txData: invoice && {
              newMembershipName: product.name,
              prorationAmount: (invoice.prorationAmount / 100).toLocaleString(
                language,
                {
                  style: "currency",
                  currency: invoice.currency,
                },
              ),
              renewalDate: new Date(
                invoice.periodEnd * 1000,
              ).toLocaleDateString(language),
              membershipName: product.name,
            },
          },
          onConfirm: () => {
            updateModal({
              loading: {
                isLoading: true,
                message: { tx: "general.loading" },
              },
              disabled: {
                isCloseButtonDisabled: true,
                isConfirmButtonDisabled: true,
              },
              description: {},
            });
            switchSubscription({
              switchSubscriptionDto: { priceId },
              ...toTenantIdHeader(tenantId),
            });
          },
        });
      },
      onError: hideModal,
    },
  );

  const openSwitchSubscriptionModal = useCallback(() => {
    showModal({
      title: { tx: "subscriptions.headerTitle" },
      loading: { isLoading: true, message: { tx: "general.loading" } },
    });

    getProrationInvoice({
      getProrationInvoiceDto: { priceId },
      ...toTenantIdHeader(tenantId),
    });
  }, [getProrationInvoice, priceId, showModal, tenantId]);

  return (
    <PrimaryButton
      onClick={openSwitchSubscriptionModal}
      tx="subscriptions.subscribeButton"
    />
  );
};
