import React, { useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { NO_ORGANISATION_RESERVED_NAME } from "shared/model/organisations";

export const NavigateToDisease: React.FC = () => {
  const location = useLocation();
  const targetLocation = useMemo(() => {
    const segments = location.pathname.split("/");
    segments.splice(2, 1, NO_ORGANISATION_RESERVED_NAME);
    return segments.join("/") + location.search + location.hash;
  }, [location]);
  return <Navigate to={targetLocation} replace={true} />;
};

export const AppendEmptyOrganisationToPath: React.FC = () => {
  const location = useLocation();
  const targetLocation = useMemo(() => {
    const segments = location.pathname.split("/");
    segments.splice(2, 0, NO_ORGANISATION_RESERVED_NAME);
    return segments.join("/") + location.search + location.hash;
  }, [location]);
  return <Navigate to={targetLocation} replace={true} />;
};
