import { encodeEMailLinkComponent } from "./encode-email-link-component";

export interface OpenEmailProgram {
  subject: string;
  body: string;
  to: string;
  cc?: string;
}

export const supportEmail = "support@mamahealth.io";

export const openEmailProgram = ({
  to,
  subject,
  body,
  cc,
}: OpenEmailProgram): void => {
  window.location.href = `mailto:${to}?subject=${encodeEMailLinkComponent(
    subject,
  )}&body=${encodeEMailLinkComponent(body)}&cc=${cc}`;
};
