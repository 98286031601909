import { PropsWithChildren, useEffect, useRef, useState } from "react";

import {
  useAuthStateStore,
  useObservedAuthState,
} from "./auth-state-store-context";
import { authStoreToUse } from "./auth-store";
import { AuthStoreContext } from "./auth-store-context";
import { useCognitoConfiguration } from "./implementations/cognito/hooks/use-cognito-configuration";
import { cognitoPaths } from "./implementations/cognito/routes/paths";
import { AuthStateEnum, IAuthStore } from "./implementations/generic/types";
import { useAuthNavigate } from "./implementations/generic/use-auth-navigate";
import { NullReplacer } from "../components/null-replacer";

export const AuthStoreProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const cognitoConfigState = useCognitoConfiguration();
  const [authStore, setAuthStore] = useState<IAuthStore | null>(null);
  const authNavigate = useAuthNavigate();
  const authState = useObservedAuthState();
  const authStateRef = useRef(authState);
  const authStateStore = useAuthStateStore();

  useEffect(() => {
    if (cognitoConfigState !== "ready") {
      return;
    }
    const AuthStoreInializer = authStoreToUse;
    const inializedAuthStore = AuthStoreInializer.init(
      cognitoConfigState,
      authStateStore,
    );
    setAuthStore(inializedAuthStore);
  }, [cognitoConfigState, authStateStore]);

  useEffect(() => {
    if (
      authState === AuthStateEnum.SIGNED_OUT &&
      authStateRef.current !== AuthStateEnum.SIGNED_OUT
    ) {
      authNavigate({
        to: { type: "uri", uri: cognitoPaths.sessionCleanup },
        state: {
          overwriteRedirectUrl:
            location.pathname + location.search + location.hash,
        },
        replace: true,
      });
    }
    authStateRef.current = authState;
  }, [authState, authNavigate]);

  switch (authState) {
    case undefined:
    case AuthStateEnum.SIGNED_OUT:
    case AuthStateEnum.LOADING:
      return <NullReplacer text="AUTH STORE" />;
    case AuthStateEnum.SIGNED_IN:
      return authStore ? (
        <AuthStoreContext.Provider value={authStore}>
          {children}
        </AuthStoreContext.Provider>
      ) : (
        <NullReplacer text="AUTH STORE" />
      );
  }
};
