import { EnabledPage, PageLockType } from "shared/model/pages";

import { useChatCompletenessLock } from "./implementations/chat-completeness-lock";
import { useGroupLock } from "./implementations/group-lock";
import { useLanguageLock } from "./implementations/language-lock";
import { usePaywallLock } from "./implementations/paywall-lock";

export const pageLockImplementations: Record<
  PageLockType,
  (page?: EnabledPage) => boolean
> = {
  [PageLockType.GROUP]: useGroupLock,
  [PageLockType.PAYWALL]: usePaywallLock,
  [PageLockType.LANGUAGE]: useLanguageLock,
  [PageLockType.CHAT_COMPLETENESS]: useChatCompletenessLock,
};
