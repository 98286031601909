import {
  PaginationConfig,
  PropertyFilter,
  PropertyFilterRule,
  SortConfig,
  SortConfigDirection,
} from "./types";
import { sortByKey } from "../../util/sorting";

type PropertyFilterType<K> = Omit<PropertyFilter, "property"> & {
  property: K;
};
type SortType<K> = Omit<SortConfig, "property"> & {
  property: K;
};

export const applyQuery = <T, K extends keyof T>(
  items: Array<T>,
  {
    sortingParams,
    filterParams,
    paginationParams,
  }: {
    sortingParams?: SortConfig;
    filterParams?: PropertyFilter[];
    paginationParams: PaginationConfig;
  },
):
  | {
      page: number;
      pageCount: number;
      results: Array<T>;
    }
  | undefined => {
  if (!items) {
    return undefined;
  }

  let filteredContent = items;

  filterParams?.forEach((propFilter) => {
    filteredContent = filter(
      filteredContent,
      propFilter as PropertyFilterType<K>,
    );
  });

  const sortedContent = sortingParams
    ? sort(filteredContent, sortingParams as SortType<K>)
    : filteredContent;
  const paginatedContent = paginate(sortedContent, paginationParams);

  return paginatedContent;
};

const paginate = <T>(
  items: Array<T>,
  paginationParams: PaginationConfig,
): {
  page: number;
  pageCount: number;
  results: Array<T>;
} => {
  return {
    page: paginationParams.page ?? 1,
    pageCount: paginationParams.resultsPerPage
      ? Math.ceil(items.length / paginationParams.resultsPerPage)
      : 1,
    results: items.slice(
      paginationParams.resultsPerPage
        ? paginationParams.resultsPerPage * ((paginationParams.page ?? 1) - 1)
        : undefined,
      paginationParams.resultsPerPage
        ? paginationParams.resultsPerPage * (paginationParams.page ?? 1)
        : undefined,
    ),
  };
};

const sort = <T, K extends keyof T>(
  items: Array<T>,
  sortingParams: SortType<K>,
): Array<T> => {
  if (items.length === 0) {
    return items;
  }

  if (
    typeof items[0][sortingParams.property] !== "number" &&
    !(items[0][sortingParams.property] instanceof Date)
  ) {
    return items;
  }
  const ascending = sortByKey(items, sortingParams.property);
  if (sortingParams.direction === SortConfigDirection.DESC) {
    return ascending.reverse();
  } else {
    return ascending;
  }
};

const filter = <T, K extends keyof T>(
  items: Array<T>,
  propertyFilter: PropertyFilterType<K>,
): Array<T> => {
  return items.filter((item) => {
    switch (propertyFilter.rule) {
      case PropertyFilterRule.CONTAINS:
        return typeof item[propertyFilter.property] === "string" &&
          typeof propertyFilter.value === "string"
          ? (item[propertyFilter.property] as string)
              .toLowerCase()
              .includes(propertyFilter.value.toLowerCase())
          : true;

      case PropertyFilterRule.IS:
        return typeof item[propertyFilter.property] === "string" &&
          typeof propertyFilter.value === "string"
          ? (item[propertyFilter.property] as string).toLowerCase() ===
              propertyFilter.value.toLowerCase()
          : typeof item[propertyFilter.property] === "number" &&
            typeof propertyFilter.value === "number"
          ? (item[propertyFilter.property] as number) === propertyFilter.value
          : true;

      case PropertyFilterRule.ARE:
        return typeof item[propertyFilter.property] === "string" &&
          (propertyFilter.value as string[]).length > 0
          ? (propertyFilter.value as string[]).includes(
              item[propertyFilter.property] as string,
            )
          : true;

      case PropertyFilterRule.IS_PART_OF:
        return Array.isArray(item[propertyFilter.property]) &&
          typeof item[propertyFilter.property] === "string"
          ? (propertyFilter.value as string[]).includes(
              item[propertyFilter.property] as string,
            )
          : true;

      case PropertyFilterRule.ARE_PART_OF:
        return Array.isArray(item[propertyFilter.property]) &&
          (propertyFilter.value as string[]).length > 0
          ? (item[propertyFilter.property] as string[]).some((value) =>
              (propertyFilter.value as string[]).includes(value),
            )
          : true;

      default:
        return true;
    }
  });
};
