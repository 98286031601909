import { EventDetailRecord } from "../../api/generated/landbot/models/EventDetailRecord";
import { useTenantConfig } from "../../tenant-settings";
import { joinDetails } from "../../util/join-details";
import { EnumIcon } from "../icons/enum-icon";
import { Text } from "../text";

export const EventDetail: React.FC<{ eventDetail: EventDetailRecord }> = ({
  eventDetail,
}) => {
  const tenantConfig = useTenantConfig();

  return (
    <>
      <div className="flex flex-row items-center gap-2">
        <div className="h-8 w-8">
          <EnumIcon
            disease={tenantConfig?.disease || "common"}
            type="detail"
            enumValue={eventDetail.type}
            color="fill-base-content"
          />
        </div>
        <div className="text-light-grey w-[calc(100%-2rem)] hyphens-auto break-words text-sm font-light">
          <Text
            as="b"
            txUnchecked={`enums.common.detail.${eventDetail.type}.title`}
            text={fallbackTitleForEnum(eventDetail.type)}
          />
          {": "}
          {joinDetails(eventDetail)}
        </div>
      </div>
      <div className="ml-3 flex min-h-0 min-w-0 flex-col justify-center gap-2 text-left">
        {eventDetail.details.map((d) => (
          <EventDetail eventDetail={d} key={d.id} />
        ))}
      </div>
    </>
  );
};

const fallbackTitleForEnum = (type: string): string => {
  return type
    .split("_")
    .map((s) => s[0] + s.substring(1).toLowerCase())
    .join(" ");
};
