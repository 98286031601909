/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChatTechnology } from './ChatTechnology';
import {
    ChatTechnologyFromJSON,
    ChatTechnologyFromJSONTyped,
    ChatTechnologyToJSON,
} from './ChatTechnology';
import type { DiseaseSettings } from './DiseaseSettings';
import {
    DiseaseSettingsFromJSON,
    DiseaseSettingsFromJSONTyped,
    DiseaseSettingsToJSON,
} from './DiseaseSettings';
import type { EventDetail } from './EventDetail';
import {
    EventDetailFromJSON,
    EventDetailFromJSONTyped,
    EventDetailToJSON,
} from './EventDetail';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * Represents a Event record
 * @export
 * @interface Event
 */
export interface Event {
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    time?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    duration?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    text?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Event
     */
    dateWasEstimated?: boolean;
    /**
     * 
     * @type {Array<EventDetail>}
     * @memberof Event
     */
    eventDetails?: Array<EventDetail>;
    /**
     * 
     * @type {Date}
     * @memberof Event
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Event
     */
    inputTime: Date;
    /**
     * 
     * @type {User}
     * @memberof Event
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    userId: string;
    /**
     * 
     * @type {DiseaseSettings}
     * @memberof Event
     */
    disease?: DiseaseSettings;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    diseaseId: string;
    /**
     * 
     * @type {ChatTechnology}
     * @memberof Event
     */
    chatTechnology: ChatTechnology;
}

/**
 * Check if a given object implements the Event interface.
 */
export function instanceOfEvent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "inputTime" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "diseaseId" in value;
    isInstance = isInstance && "chatTechnology" in value;

    return isInstance;
}

export function EventFromJSON(json: any): Event {
    return EventFromJSONTyped(json, false);
}

export function EventFromJSONTyped(json: any, ignoreDiscriminator: boolean): Event {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': json['type'],
        'time': !exists(json, 'time') ? undefined : json['time'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'dateWasEstimated': !exists(json, 'dateWasEstimated') ? undefined : json['dateWasEstimated'],
        'eventDetails': !exists(json, 'eventDetails') ? undefined : ((json['eventDetails'] as Array<any>).map(EventDetailFromJSON)),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'inputTime': (new Date(json['inputTime'])),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'userId': json['userId'],
        'disease': !exists(json, 'disease') ? undefined : DiseaseSettingsFromJSON(json['disease']),
        'diseaseId': json['diseaseId'],
        'chatTechnology': ChatTechnologyFromJSON(json['chatTechnology']),
    };
}

export function EventToJSON(value?: Event | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'time': value.time,
        'duration': value.duration,
        'text': value.text,
        'dateWasEstimated': value.dateWasEstimated,
        'eventDetails': value.eventDetails === undefined ? undefined : ((value.eventDetails as Array<any>).map(EventDetailToJSON)),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'inputTime': (value.inputTime.toISOString()),
        'user': UserToJSON(value.user),
        'userId': value.userId,
        'disease': DiseaseSettingsToJSON(value.disease),
        'diseaseId': value.diseaseId,
        'chatTechnology': ChatTechnologyToJSON(value.chatTechnology),
    };
}

