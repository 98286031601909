/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DiseaseSettings } from './DiseaseSettings';
import {
    DiseaseSettingsFromJSON,
    DiseaseSettingsFromJSONTyped,
    DiseaseSettingsToJSON,
} from './DiseaseSettings';
import type { OrganisationSettings } from './OrganisationSettings';
import {
    OrganisationSettingsFromJSON,
    OrganisationSettingsFromJSONTyped,
    OrganisationSettingsToJSON,
} from './OrganisationSettings';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';
import type { UserGroup } from './UserGroup';
import {
    UserGroupFromJSON,
    UserGroupFromJSONTyped,
    UserGroupToJSON,
} from './UserGroup';

/**
 * Represents a GroupsOfUser record
 * @export
 * @interface GroupsOfUser
 */
export interface GroupsOfUser {
    /**
     * 
     * @type {string}
     * @memberof GroupsOfUser
     */
    id: string;
    /**
     * 
     * @type {Array<UserGroup>}
     * @memberof GroupsOfUser
     */
    groups: Array<UserGroup>;
    /**
     * 
     * @type {Date}
     * @memberof GroupsOfUser
     */
    createdAt: Date;
    /**
     * 
     * @type {User}
     * @memberof GroupsOfUser
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof GroupsOfUser
     */
    userId: string;
    /**
     * 
     * @type {DiseaseSettings}
     * @memberof GroupsOfUser
     */
    disease?: DiseaseSettings;
    /**
     * 
     * @type {string}
     * @memberof GroupsOfUser
     */
    diseaseId: string;
    /**
     * 
     * @type {OrganisationSettings}
     * @memberof GroupsOfUser
     */
    organisation?: OrganisationSettings;
    /**
     * 
     * @type {string}
     * @memberof GroupsOfUser
     */
    organisationId?: string;
}

/**
 * Check if a given object implements the GroupsOfUser interface.
 */
export function instanceOfGroupsOfUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "groups" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "diseaseId" in value;

    return isInstance;
}

export function GroupsOfUserFromJSON(json: any): GroupsOfUser {
    return GroupsOfUserFromJSONTyped(json, false);
}

export function GroupsOfUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupsOfUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'groups': ((json['groups'] as Array<any>).map(UserGroupFromJSON)),
        'createdAt': (new Date(json['createdAt'])),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'userId': json['userId'],
        'disease': !exists(json, 'disease') ? undefined : DiseaseSettingsFromJSON(json['disease']),
        'diseaseId': json['diseaseId'],
        'organisation': !exists(json, 'organisation') ? undefined : OrganisationSettingsFromJSON(json['organisation']),
        'organisationId': !exists(json, 'organisationId') ? undefined : json['organisationId'],
    };
}

export function GroupsOfUserToJSON(value?: GroupsOfUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'groups': ((value.groups as Array<any>).map(UserGroupToJSON)),
        'createdAt': (value.createdAt.toISOString()),
        'user': UserToJSON(value.user),
        'userId': value.userId,
        'disease': DiseaseSettingsToJSON(value.disease),
        'diseaseId': value.diseaseId,
        'organisation': OrganisationSettingsToJSON(value.organisation),
        'organisationId': value.organisationId,
    };
}

