import { EventDetailRecord } from "../api/generated/landbot";

export const joinDetails = (detail: EventDetailRecord): string =>
  [
    detail.text,
    detail.number,
    detail.unit,
    detail.select != null ? (detail.select ? "✅" : "❌") : null,
  ]
    .filter((x) => x != null)
    .join(" ");
