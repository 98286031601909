/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserMedia } from './UserMedia';
import {
    UserMediaFromJSON,
    UserMediaFromJSONTyped,
    UserMediaToJSON,
} from './UserMedia';

/**
 * Represents a DocumentExtraction record
 * @export
 * @interface DocumentExtraction
 */
export interface DocumentExtraction {
    /**
     * 
     * @type {string}
     * @memberof DocumentExtraction
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentExtraction
     */
    documentLabel: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentExtraction
     */
    documentQuality: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentExtraction
     */
    documentSummary: string;
    /**
     * 
     * @type {Array<UserMedia>}
     * @memberof DocumentExtraction
     */
    userMedia?: Array<UserMedia>;
}

/**
 * Check if a given object implements the DocumentExtraction interface.
 */
export function instanceOfDocumentExtraction(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "documentLabel" in value;
    isInstance = isInstance && "documentQuality" in value;
    isInstance = isInstance && "documentSummary" in value;

    return isInstance;
}

export function DocumentExtractionFromJSON(json: any): DocumentExtraction {
    return DocumentExtractionFromJSONTyped(json, false);
}

export function DocumentExtractionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentExtraction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'documentLabel': json['documentLabel'],
        'documentQuality': json['documentQuality'],
        'documentSummary': json['documentSummary'],
        'userMedia': !exists(json, 'userMedia') ? undefined : ((json['userMedia'] as Array<any>).map(UserMediaFromJSON)),
    };
}

export function DocumentExtractionToJSON(value?: DocumentExtraction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'documentLabel': value.documentLabel,
        'documentQuality': value.documentQuality,
        'documentSummary': value.documentSummary,
        'userMedia': value.userMedia === undefined ? undefined : ((value.userMedia as Array<any>).map(UserMediaToJSON)),
    };
}

