import { twMerge } from "tailwind-merge";

import { ApplyFilterType } from "./filter/types";
import { Pagination } from "./pagination";

export const PaginatedList: React.FC<{
  children: React.ReactNode;
  page?: number;
  pages?: number;
  hidden?: boolean;
  className?: string;
  onPageChange: (type: ApplyFilterType) => void;
}> = ({ children, page, pages, onPageChange, hidden, className }) => {
  return (
    <div
      className={twMerge(
        "flex w-full flex-col justify-center gap-4",
        className,
      )}
    >
      {children}
      {page !== undefined && pages !== undefined && !hidden && (
        <Pagination page={page} pages={pages} onPageChange={onPageChange} />
      )}
    </div>
  );
};
