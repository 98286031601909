import React from "react";
import { Disease } from "shared/model/diseases";

import { I18nProps, Text, TextProps } from "./text";
import { EnumType, useEnumTitle } from "../i18n/enum-translation";

export const EnumTitle: React.FC<
  Omit<TextProps, keyof I18nProps> & {
    disease: Disease | "common";
    type: EnumType;
    enumValue: string;
  }
> = ({ disease, type, enumValue, ...rest }) => (
  <Text txUnchecked={useEnumTitle(disease, type, enumValue)} {...rest} />
);
