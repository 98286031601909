import { t } from "i18next";

import { Button, ErrorButton } from "../../form/button";
import { Text } from "../../text";

export const DeleteCategoryModal = ({
  deleteCategory,
  hideModal,
  category,
}: {
  deleteCategory: () => void;
  hideModal: () => void;
  category: string;
}): ReturnType<React.FC> => {
  return (
    <div>
      <Text
        className="mx-2 pb-4 text-lg font-semibold"
        as="div"
        text={`${t(
          "socialMedia.categories.areYouSureYouWantToDelete",
        )} "${category}" ?`}
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          deleteCategory();
          hideModal();
        }}
      >
        <div className="flex flex-row justify-end gap-4">
          <Button
            tx="socialMedia.categories.cancel"
            onClick={(e) => {
              e.preventDefault();
              hideModal();
            }}
          />
          <ErrorButton tx="socialMedia.categories.delete" />
        </div>
      </form>
    </div>
  );
};
