import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";
import { twMerge } from "tailwind-merge";

const iconStyle = { round: true, size: 40 };

export const SocialShareButtons: React.FC<{ className?: string }> = ({
  className,
}) => {
  const url = window.location.href;
  return (
    <div className={twMerge("flex gap-4", className)}>
      <FacebookShareButton url={url}>
        <FacebookIcon {...iconStyle} />
      </FacebookShareButton>
      <LinkedinShareButton url={url}>
        <LinkedinIcon {...iconStyle} />
      </LinkedinShareButton>
      <TwitterShareButton url={url}>
        <XIcon {...iconStyle} />
      </TwitterShareButton>
      <WhatsappShareButton url={url}>
        <WhatsappIcon {...iconStyle} />
      </WhatsappShareButton>
    </div>
  );
};
