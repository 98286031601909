import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

import {
  DeleteSocialMediaPostCommentRequest,
  SocialMediaComment,
} from "../../api/generated/backend";
import { useApiQuery } from "../../api/use-api";
import { useModal } from "../../models/modal-provider";
import { USER } from "../../types/query-keys";
import { useTenantId } from "../../util/use-active-tenant-id";
import { Avatar } from "../avatar";
import { SvgIcon } from "../icons/svg-icon";

export const SocialMediaPostComment: React.FC<
  SocialMediaComment & {
    onDeleteComment: (request: DeleteSocialMediaPostCommentRequest) => void;
  }
> = ({
  id,
  body,
  userId,
  createdAt,
  onDeleteComment,
  isCurrentUserComment,
}) => {
  const {
    i18n: { language },
  } = useTranslation();

  const tenantId = useTenantId();

  const { data: user } = useApiQuery(
    "backend",
    (api) =>
      api.getUser({
        getUserDto: { id: userId },
        mamaDisease: tenantId.disease,
        mamaOrganisation: tenantId.organisation,
      }),
    USER(userId),
    undefined,
    { enabled: !!userId },
  );

  const { showModal, hideModal } = useModal();

  const commentedOn = useMemo(
    () =>
      new Date(createdAt).toLocaleString(language, {
        dateStyle: "medium",
        timeStyle: "short",
      }),
    [createdAt, language],
  );

  return (
    <div className="relative flex flex-col">
      <div
        className={twMerge(
          isCurrentUserComment ? "bg-neutral-200" : "bg-neutral-100",
          "w-full gap-4 rounded-lg px-4 py-2 pr-8",
        )}
      >
        <div className="flex gap-3">
          <Avatar
            src={user?.userAttributes?.avatar}
            className="h-12 w-12 self-start"
            alt=""
          />
          <div className="">
            <p className="font-bold">
              {user?.userAttributes?.name ?? "mama health team"}
            </p>
            <p>{body}</p>
          </div>
        </div>
        <p className="mt-2 text-xs text-neutral">{commentedOn}</p>
      </div>

      {isCurrentUserComment && (
        <div
          className="absolute right-0 top-0 cursor-pointer"
          onClick={() => {
            showModal({
              title: { tx: "socialMedia.aboutToDeleteComment" },
              description: { tx: "socialMedia.deleteComment" },
              onConfirm: () => {
                onDeleteComment({
                  id,
                });
                hideModal();
              },
            });
          }}
        >
          <SvgIcon height={40} width={40} icon="garbage" />
        </div>
      )}
    </div>
  );
};
