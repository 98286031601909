import i18next from "i18next";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { PlainError } from "./components/events/plain-error";
import { initI18n } from "./i18n/init-i18n";
import { useStore } from "./models/helpers";

export const LanguageSettings: React.FC<{
  setI18NReady: (value: boolean) => void;
}> = ({ setI18NReady }: { setI18NReady: (value: boolean) => void }) => {
  const store = useStore();
  const [searchParams] = useSearchParams();
  const potentialLanguagePreset = useMemo(
    () => searchParams.get("language"),
    [searchParams],
  );

  const [hasInitialized, setHasInitialized] = useState(false);

  useEffect(() => {
    if (!hasInitialized) {
      setHasInitialized(true);
      initI18n()
        .then(() => {
          if (potentialLanguagePreset) {
            i18next.changeLanguage(potentialLanguagePreset);
          }
          setI18NReady(true);
        })
        .catch((error) => {
          store.addToastEvent(
            new PlainError(
              { text: "Error while initializing language selector" },
              error instanceof Error ? error.stack : undefined,
            ),
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
