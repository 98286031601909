import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Language } from "shared/model/languages";
import { FrontendPageEnum } from "shared/model/pages";

import {
  CreateSocialMediaPostRequest,
  UpdateSocialMediaPostRequest,
} from "../api/generated/backend";
import { toTenantIdHeader, useApiMutation, useApiQuery } from "../api/use-api";
import { PlainError } from "../components/events/plain-error";
import { Button, GhostButton, PrimaryButton } from "../components/form/button";
import { Form } from "../components/form/form";
import { FormImageInput } from "../components/form/form-image-input";
import { FormTextInput } from "../components/form/form-text-input";
import { FormLanguageDropDown } from "../components/form/language/form-language-dropdown-input";
import { MultiColumnSection } from "../components/gallery";
import { CategoryMultiSelectDropdown } from "../components/social-media/categories/categories-multiselect-dropdown";
import { Text } from "../components/text";
import { Tooltip } from "../components/tooltip";
import { DefaultContentContainer } from "../layouts/default-content-container";
import { useStore } from "../models/helpers";
import { SOCIAL_MEDIA_MEDIA, SOCIAL_MEDIA_POST } from "../types/query-keys";
import {
  useNavigateBack,
  useNavigateInsideTenant,
} from "../util/navigation-hooks";
import { useTenantId } from "../util/use-active-tenant-id";

export const SocialMediaEdit: React.FC = () => {
  const { id: paramsId } = useParams();
  const tenantId = useTenantId();
  const navigate = useNavigateInsideTenant();
  const store = useStore();
  const navigateBack = useNavigateBack();

  const [media, setMedia] = useState<string[] | undefined>([]);
  const [newMedia, setNewMedia] = useState<string[] | undefined>([]);
  const [categories, setCategories] = useState<string[]>([]);

  const isNew = useMemo(() => paramsId === "new", [paramsId]);

  const { register, handleSubmit, formState, setValue, control } = useForm<{
    body: string;
    media: string[];
    language: Language;
  }>({
    defaultValues: { body: "", media: [] },
  });

  const { data: mediaUrls } = useApiQuery(
    "backend",
    (api) =>
      api.getSocialMediaPostMedia({
        socialMediaPostIdDto: { postId: paramsId ?? "" },
      }),
    SOCIAL_MEDIA_MEDIA(paramsId),
    undefined,
    { enabled: !!paramsId && !isNew, refetchOnWindowFocus: false },
  );

  const { data: post, isFetching: isFetchingPost } = useApiQuery(
    "backend",
    (api) => api.getSocialMediaPost({ id: paramsId ?? "" }),
    SOCIAL_MEDIA_POST,
    undefined,
    {
      enabled: !!paramsId && !isNew,
      refetchOnWindowFocus: false,
    },
  );

  const { mutate: createSocialMediaPost, isLoading: isCreatingPost } =
    useApiMutation(
      "backend",
      (api) => (request: CreateSocialMediaPostRequest) =>
        api.createSocialMediaPost(request),
      undefined,
      { successMessage: { tx: "socialMedia.postSuccesfullyCreated" } },
      {
        onSuccess: () => {
          navigate(FrontendPageEnum.ADMIN_SOCIAL_MEDIA);
        },
      },
    );

  const { mutate: updateSocialMediaPost, isLoading: isUpdatingPost } =
    useApiMutation(
      "backend",
      (api) => (request: UpdateSocialMediaPostRequest) =>
        api.updateSocialMediaPost(request),
      undefined,
      { successMessage: { tx: "socialMedia.postSuccesfullyUpdated" } },
      {
        onSuccess: () => {
          navigate(FrontendPageEnum.ADMIN_SOCIAL_MEDIA);
        },
      },
    );

  const createPost = useCallback(
    (conf: { body: string; language: string }) => {
      if (!newMedia || !newMedia.length) {
        return store.addToastEvent(
          new PlainError({ tx: "socialMedia.postMustHaveAtLeastOneMedia" }),
        );
      }

      createSocialMediaPost({
        createSocialMediaPostDto: {
          contentBody: conf.body,
          media: newMedia,
          language: conf.language,
          categories: categories,
        },
        ...toTenantIdHeader(tenantId),
      });
    },
    [categories, createSocialMediaPost, newMedia, store, tenantId],
  );

  const updatePost = useCallback(
    (conf: { body: string; language: string }) => {
      if (!paramsId) return;

      updateSocialMediaPost({
        id: paramsId,
        updateSocialMediaPostDto: {
          contentBody: conf.body,
          media: newMedia?.length ? newMedia : undefined,
          language: conf.language,
          categories: categories,
        },
        ...toTenantIdHeader(tenantId),
      });
    },
    [paramsId, updateSocialMediaPost, newMedia, categories, tenantId],
  );

  const handleFormSubmit = handleSubmit(({ body, language }) => {
    if (isNew) {
      createPost({ body, language });
    } else {
      updatePost({ body, language });
    }
  });

  useEffect(() => {
    setValue("body", isNew ? "" : post?.body || "");
  }, [isNew, post?.body, setValue]);

  useEffect(() => {
    setMedia(isNew ? undefined : mediaUrls?.mediaUrls);
  }, [isNew, mediaUrls?.mediaUrls]);

  useEffect(() => {
    setValue("language", post?.language as Language);
  }, [post?.language, setValue]);

  return isFetchingPost ? null : (
    <DefaultContentContainer>
      <GhostButton icon="left" tx="navigateBack" onClick={navigateBack} />

      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleFormSubmit();
        }}
      >
        <FormImageInput
          name="meda"
          multiple
          control={control}
          onCropComplete={setNewMedia}
          error={{ txUnchecked: formState.errors.media?.message }}
        >
          <div className="flex gap-4">
            <Tooltip
              text={{ tx: "socialMedia.uploadExplanation" }}
              className="tooltip-top w-full self-start"
            >
              <Button
                as="p"
                icon="plus"
                isDisabled={isCreatingPost}
                tx={
                  isNew ? "socialMedia.uploadMedia" : "socialMedia.replaceMedia"
                }
              />
            </Tooltip>
            <Tooltip text={{ tx: "socialMedia.languageExplanation" }}>
              <FormLanguageDropDown
                name="language"
                control={control}
                error={{ txUnchecked: formState.errors.language?.message }}
              />
            </Tooltip>
            <CategoryMultiSelectDropdown
              selectedCategories={post?.categories}
              onChange={setCategories}
            />
          </div>
        </FormImageInput>

        <div className="flex justify-between">
          <FormTextInput
            as="textarea"
            className="h-28 max-h-72"
            title={{ tx: "socialMedia.content" }}
            {...register("body")}
            error={{ txUnchecked: formState.errors.body?.message }}
          />
        </div>
        <PrimaryButton
          className="mx-2 w-full max-w-fit self-center"
          tx={isNew ? "socialMedia.publish" : "socialMedia.edit"}
          isDisabled={isCreatingPost || isUpdatingPost}
        />
      </Form>
      <MediaPreview media={newMedia?.length ? newMedia : media} />
    </DefaultContentContainer>
  );
};

const MediaPreview: React.FC<{ media?: string[] }> = ({ media }) => {
  return (
    <div className="mt-8">
      <div className="mb-5">
        <Text tx="socialMedia.mediaPreview" className="text-2xl" />
      </div>

      {media?.length ? (
        <MultiColumnSection>
          {media.map((src) => (
            <div key={src} className="flex h-full cursor-pointer flex-col">
              <img className="rounded-lg" src={src} />
            </div>
          ))}
        </MultiColumnSection>
      ) : (
        <Text
          as="p"
          tx="socialMedia.noMediaSelected"
          className="mt-10 text-center text-xl text-neutral"
        />
      )}
    </div>
  );
};
