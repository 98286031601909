import { useState } from "react";

import { Avatar } from "../../../../../components/avatar";
import { FormImageInput } from "../../../../../components/form/form-image-input";
import { mamaLogo } from "../../../../profile/constants";
import { ProfileCompletenessScore } from "../../../../profile/profile-completeness-score";
import { useProfileCompleteness } from "../../../../profile/use-profile-completeness";
import { FormDataCollectionInput } from "../types";

export const profileImageInput: FormDataCollectionInput = {
  wasCompleted: (user) => !!user.userAttributes?.avatar,
  Component: ({ errors, control, user }) => {
    const [image, setImage] = useState(user.userAttributes?.avatar);
    const { isProfileComplete } = useProfileCompleteness();

    return (
      <div>
        <FormImageInput
          name="avatar"
          control={control}
          error={{ txUnchecked: errors.name?.message }}
          onCropComplete={(avatar) => setImage(avatar?.[0])}
        >
          <Avatar
            className="relative h-96 w-96"
            alt="user-avatar"
            src={image ?? user.userAttributes?.avatar}
          >
            {isProfileComplete && (
              <div className="absolute bottom-5 right-5 h-20 w-20 rounded-full bg-white p-3 outline outline-primary">
                <img src={mamaLogo} alt="mama logo" />
              </div>
            )}
          </Avatar>
        </FormImageInput>
        {!isProfileComplete && <ProfileCompletenessScore />}
      </div>
    );
  },
};
