import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Language } from "shared/model/languages";

import { useTenantConfig } from "../../../tenant-settings";
import { PrimaryButton } from "../../form/button";
import { SupportedLanguagesDropdown } from "../../form/language/supported-languages-dropdown";
import { LoadingIndicator } from "../../loading-spinner";
import { Text } from "../../text";

export const LandbotChatInitialView: React.FC<{
  selectLanguage: (language: string) => Promise<void>;
}> = ({ selectLanguage }) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState<Language>(i18n.language as Language);
  const [loading, setLoading] = useState(false);

  const config = useTenantConfig();

  useEffect(() => {
    if (config?.supportedLanguages.length === 1) {
      selectLanguage(config?.supportedLanguages[0]);
    }
  }, [config, selectLanguage]);

  return (
    <dialog
      className="modal modal-bottom sm:modal-middle"
      open={true}
      onCancel={(e) => e.preventDefault()}
    >
      {loading ? (
        <LoadingIndicator as="spinner" className="h-full w-full" />
      ) : (
        <form method="dialog" className="modal-box overflow-visible">
          <Text
            as="h3"
            className="text-lg font-bold"
            tx="landbot.selectLandbotLanguageHeader"
          />
          <Text
            as="p"
            className="py-4"
            tx="landbot.selectLandbotLanguageText"
          />
          <SupportedLanguagesDropdown
            value={language}
            onChange={(newLanguage) => setLanguage(newLanguage)}
            className="dropdown-top sm:dropdown-bottom "
          />
          <div className="modal-action">
            <PrimaryButton
              onClick={(e) => {
                e.preventDefault();
                selectLanguage(language).catch(() => setLoading(true));
              }}
              tx="i18n.selectLanguage"
            />
          </div>
        </form>
      )}
    </dialog>
  );
};
