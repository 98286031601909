import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ChatDataGuard,
  ChatOptionsType,
} from "shared/model/data-collection/guard/chat-data-guard/chat-data-guard";
import { Language } from "shared/model/languages";

import { Button } from "../../../../../components/form/button";
import { IconType } from "../../../../../components/icons/svg-icon";
import { Text } from "../../../../../components/text";
import { CallCard } from "../cards/call-card";
import { DefaultCard } from "../cards/default-card";

export const ChatGuard: React.FC<{
  config: ChatDataGuard;
  children: React.ReactNode;
}> = ({ config, children }) => {
  const [hasConfirmedSelection, setHasConfirmedSelection] = useState(false);
  const [chatOption, setChatOption] = useState<ChatOptionsType | undefined>(
    undefined,
  );

  const {
    i18n: { language },
  } = useTranslation();

  const options = useMemo(
    () =>
      config.optionsConfig?.filter(
        (option) =>
          !option.supportedLanguages ||
          option.supportedLanguages.includes(language as Language),
      ),
    [config.optionsConfig, language],
  );

  const selectionComponentsMap: Record<ChatOptionsType, React.ReactNode> =
    useMemo(
      () => ({
        [ChatOptionsType.CHAT]: children,
        [ChatOptionsType.CALL]: <CallCard />,
      }),
      [children],
    );

  const hasOptions = useMemo(() => !!options?.length, [options]);

  return (
    <div className="h-full p-5">
      {hasOptions ? (
        hasConfirmedSelection && chatOption ? (
          selectionComponentsMap[chatOption]
        ) : (
          <div className="flex h-full flex-col items-center justify-center gap-2">
            <Text
              className="mb-5 text-center text-xl font-bold"
              tx="dataGuards.chat.cards.chooseHowToTellYourStory"
            />
            {options?.map((option) => (
              <div
                key={option.type}
                className="w-full max-w-[540px] rounded-xl border-2 border-primary"
              >
                <DefaultCard
                  config={option}
                  setChatOption={setChatOption}
                  icon={cardIconMap[option.type]}
                  selected={chatOption === option.type}
                />
              </div>
            ))}
            <Button
              tx="dataGuards.chat.cards.proceed"
              className={`bg-${chatOption ? "primary" : "disabled"}`}
              onClick={() => {
                if (chatOption) {
                  setHasConfirmedSelection(true);
                }
              }}
            />
          </div>
        )
      ) : (
        children
      )}
    </div>
  );
};

const cardIconMap: Record<ChatOptionsType, IconType> = {
  [ChatOptionsType.CHAT]: "chat",
  [ChatOptionsType.CALL]: "telephone",
};
