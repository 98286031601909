import { useCallback } from "react";
import { twMerge } from "tailwind-merge";

import { LogUserEventRequest } from "../../api/generated/backend";
import { UserActivityLogType } from "../../api/generated/multiagent";
import { useApiMutation } from "../../api/use-api";
import { useModal } from "../../models/modal-provider";
import { useUser } from "../../util/use-user";
import { IconColor, SvgIcon } from "../icons/svg-icon";
import { Text } from "../text";

export const UserStoryCard: React.FC<{
  story: { id: number; story: string };
  styles?: { background: string; iconColor: IconColor };
}> = ({ story, styles }) => {
  const user = useUser();

  const { mutate: logUserEvent } = useApiMutation(
    "backend",
    (api) => (logUserActivityRequest: LogUserEventRequest) =>
      api.logUserEvent(logUserActivityRequest),
  );

  const { showModal } = useModal();

  const onOpenStory = useCallback(() => {
    showModal({
      showCloseButton: false,
      closeOnClickOutside: true,
      showsWindowCloseButton: true,
      children: (
        <div
          className="p-5"
          dangerouslySetInnerHTML={{ __html: story.story }}
        />
      ),
      className: "overflow-scroll w-full max-w-4xl",
    });

    logUserEvent({
      userEventDto: {
        type: UserActivityLogType.Event,
        info: {
          type: "USER_STORY",
          userId: user?.id,
          message: `Story id read: ${story.id}`,
        },
      },
    });
  }, [logUserEvent, showModal, story.id, story.story, user?.id]);

  return (
    <div
      onClick={onOpenStory}
      className="flex cursor-pointer flex-col gap-3 transition-all hover:scale-105"
    >
      <div className={twMerge("rounded-xl", styles?.background)}>
        <SvgIcon icon="book" className="h-40" color={styles?.iconColor} />
      </div>

      <div className="relative h-48 overflow-hidden">
        <Text text={story.story} />
        <div className="absolute bottom-0 h-32 w-full bg-gradient-to-t from-white " />
      </div>
    </div>
  );
};
