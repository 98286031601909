import { AuthSession } from "aws-amplify/auth";
import { makeAutoObservable } from "mobx";

import { AuthStateEnum, IAuthStateDelegate } from "../../generic/types";
import { UserData } from "../../generic/types";
import { CognitoConfigurationState, ICognitoAuthGen2 } from "../misc/types";

export class CognitoAuthStore {
  public userData?: UserData;
  public get jwtToken(): string | undefined {
    return this.session?.tokens?.accessToken.toString();
  }

  private session?: AuthSession; // Warning: Don't set this directly, use setTimeoutAndSessionIfRenewed
  private timeoutRef?: NodeJS.Timeout;
  private get delegateState(): AuthStateEnum {
    return this.authStateDelegate.state;
  }

  private constructor(
    cognitoConfigState: CognitoConfigurationState,
    private authStateDelegate: IAuthStateDelegate,
    private auth: ICognitoAuthGen2,
  ) {
    if (cognitoConfigState === "loading") {
      throw new Error("Cognito configuration is still loading");
    }

    makeAutoObservable(this);
    authStateDelegate.setAuthStoreDelegate(this);
  }

  public static async asyncCreate(
    cognitoConfigState: CognitoConfigurationState,
    authStateDelegate: IAuthStateDelegate,
    auth: ICognitoAuthGen2,
  ): Promise<CognitoAuthStore> {
    const authStore = new CognitoAuthStore(
      cognitoConfigState,
      authStateDelegate,
      auth,
    );
    await authStore.setup();
    return authStore;
  }

  public static create(
    cognitoConfigState: CognitoConfigurationState,
    authStateDelegate: IAuthStateDelegate,
    auth: ICognitoAuthGen2,
  ): CognitoAuthStore {
    const authStore = new CognitoAuthStore(
      cognitoConfigState,
      authStateDelegate,
      auth,
    );
    authStore.setup();
    return authStore;
  }

  public static async probeIfUserIsSignedIn(
    auth: ICognitoAuthGen2,
  ): Promise<void> {
    try {
      const session = await auth.fetchAuthSession({ forceRefresh: true });
      if (!session.userSub || !session.tokens?.accessToken) {
        throw new Error("empty session found");
      }
    } catch {
      throw new Error("no session found");
    }
  }

  public setup = async (): Promise<void> => {
    if (this.delegateState === AuthStateEnum.SIGNED_IN) {
      return;
    }

    try {
      const user = await this.auth.getCurrentUser();
      const session = await this.auth.fetchAuthSession();

      if (!session) {
        throw new Error("No session found");
      }
      this.setTimeoutAndSession(session);
      this.userData = {
        sub: user.userId,
        email: (session.tokens?.idToken?.payload.email as string) || "",
      };
      this.setDelegateState(AuthStateEnum.SIGNED_IN);
    } catch {
      this.setDelegateState(AuthStateEnum.SIGNED_OUT);
    }
  };

  public signOut = async (): Promise<void> => {
    if (this.delegateState === AuthStateEnum.SIGNED_OUT) {
      return;
    }
    try {
      await this.auth.signOut();
    } finally {
      this.setDelegateState(AuthStateEnum.SIGNED_OUT);
    }
  };

  public getValidJwtToken = async (): Promise<string | undefined> => {
    try {
      const maybeNewSession = await this.auth.fetchAuthSession();
      const maybeNewToken = maybeNewSession.tokens?.accessToken.toString();

      const oldToken = this.jwtToken;
      if (this.session && oldToken && oldToken === maybeNewToken) {
        return oldToken;
      }

      this.setTimeoutAndSession(maybeNewSession);
      await this.updateUserData();
      return maybeNewToken;
    } catch {
      this.setDelegateState(AuthStateEnum.SIGNED_OUT);
      return undefined;
    }
  };

  private updateUserData = async (): Promise<void> => {
    try {
      const user = await this.auth.getCurrentUser();
      const session = await this.auth.fetchAuthSession();

      this.userData = {
        sub: user.userId,
        email: (session.tokens?.idToken?.payload.email as string) || "",
      };
    } catch (e) {
      this.userData = undefined;
      throw e;
    }
  };

  private setDelegateState(newState: AuthStateEnum): void {
    this.authStateDelegate.setState(newState);
  }

  private setTimeoutAndSession(newSession: AuthSession): void {
    this.session = newSession;
    const token = newSession.tokens?.accessToken;
    this.setTimeoutForTokenRefresh(
      token?.payload.exp ||
        CognitoAuthStore.staticCalculateSecondsToRefresh(60),
    );
  }

  private async forceRenewTokenBeforeExpiration(): Promise<void> {
    try {
      const session = await this.auth.fetchAuthSession({ forceRefresh: true });
      if (!session) {
        this.setDelegateState(AuthStateEnum.SIGNED_OUT);
        return;
      }

      this.setTimeoutAndSession(session);
    } catch (e) {
      console.log("error refreshing token, signing out", e);
      this.setDelegateState(AuthStateEnum.SIGNED_OUT);
    }
  }

  private setTimeoutForTokenRefresh(tokenExpirationInSec: number): void {
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
    }

    const timeoutInMilliseconds =
      CognitoAuthStore.staticCalculateSecondsToRefresh(tokenExpirationInSec) *
      1000;
    this.timeoutRef = setTimeout(() => {
      this.forceRenewTokenBeforeExpiration();
    }, timeoutInMilliseconds);
  }

  private static staticCalculateSecondsToRefresh(exp: number): number {
    return (((exp - new Date().getTime() / 1000) | 0) * 0.8) | 0;
  }
}
