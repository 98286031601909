export const isOlderThan = (config: {
  year: number;
  minimumAge: number;
}): boolean => {
  return new Date().getFullYear() - config.minimumAge >= config.year;
};

export const isYoungerThan = (config: {
  year: number;
  maximumAge: number;
}): boolean => {
  return new Date().getFullYear() - config.year <= config.maximumAge;
};
