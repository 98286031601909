import { Helmet } from "react-helmet";

import { backendApiUrl } from "./util/env-utils";
import { useOptionalTenantId } from "./util/use-active-tenant-id";

export const DynamicHelmet: React.FC = () => {
  const tenantId = useOptionalTenantId();

  return tenantId?.disease ? (
    <Helmet>
      <link
        rel="manifest"
        href={`${backendApiUrl}-public/tenant/${tenantId.disease.toLowerCase()}/manifest.json`}
      />
    </Helmet>
  ) : null;
};
