import { t } from "i18next";
import { useCallback, useMemo, useState } from "react";
import { Language, TranslationPerLanguageType } from "shared/model/languages";
import { twMerge } from "tailwind-merge";

import { DEFAULT_LANGUAGE_KEY, LanguageKey } from "./constants";
import { useTenantConfig } from "../../../tenant-settings";
import { Text } from "../../text";
import { Button } from "../button";
import { TextInput } from "../text-input";

type TranslationsInputFieldsType = React.FC<{
  values?: TranslationPerLanguageType;
  className?: string;
  onChange: (translations: TranslationPerLanguageType) => void;
}>;

export const TranslationsInputFields: TranslationsInputFieldsType = ({
  values,
  className,
  onChange,
}: {
  values?: TranslationPerLanguageType;
  className?: string;
  onChange: (translations: TranslationPerLanguageType) => void;
}) => {
  const tenantConfig = useTenantConfig();

  const supportedLanguages = useMemo(
    () =>
      tenantConfig?.supportedLanguages.filter(
        (lang) => lang !== Language[DEFAULT_LANGUAGE_KEY],
      ),
    [tenantConfig],
  );

  const handleChange = useCallback(
    (translations: TranslationPerLanguageType) => {
      onChange(translations);
    },
    [onChange],
  );

  const possibleTranslations = useMemo(
    () =>
      supportedLanguages?.reduce((acc, lang) => {
        const langKey = Object.keys(Language).find(
          (key) => Language[key as LanguageKey] === lang,
        );

        acc[langKey as LanguageKey] = values
          ? values[langKey as LanguageKey]
          : "";
        return acc;
      }, {} as TranslationPerLanguageType),
    [supportedLanguages, values],
  );

  const [translations, setTranslations] =
    useState<TranslationPerLanguageType>(possibleTranslations);

  return (
    <div className={twMerge("dropdown-right dropdown", className)}>
      <Button
        as="div"
        className="btn m-1"
        tx="i18n.translations"
        tabIndex={0}
        role="button"
      />
      <div className="dropdown-content menu z-[10] w-fit items-center rounded-box bg-base-100 p-2 shadow">
        {Object.keys(possibleTranslations).map((key, idx) => (
          <div
            key={idx}
            className="mb-2 flex w-fit flex-row items-center gap-4 bg-base-100 p-4 "
          >
            <Text
              className="w-20 pr-4 font-normal"
              text={t(`i18n.languages.${Language[key as LanguageKey]}`) + ": "}
            />
            <TextInput
              className="w-40"
              value={translations[key as LanguageKey] ?? ""}
              onChange={(e) => {
                const trans = { ...translations, [key]: e.target.value };
                setTranslations(trans);
                handleChange(trans);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
