import React, {
  createRef,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { twMerge } from "tailwind-merge";

export const Carousel: React.FC<{ images: string[]; className?: string }> = ({
  images,
  className,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselItems = useRef<RefObject<HTMLDivElement>[]>([]);

  useEffect(() => {
    carouselItems.current = images.map(
      (_, index) => carouselItems.current[index] ?? createRef(),
    );
  }, [images]);

  const goToSlide = useCallback(
    (index: number) => {
      const newIndex = index < 0 ? images.length - 1 : index % images.length;
      setCurrentSlide(newIndex);
      carouselItems.current[newIndex].current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    },
    [images.length],
  );

  return (
    <div className=" flex flex-col">
      <div className="relative">
        <div className={twMerge("carousel gap-2 rounded-box", className)}>
          {images.map((image, i) => (
            <div
              className="carousel-item w-full"
              key={image}
              ref={carouselItems.current[i]}
            >
              <img
                src={image}
                className="h-full w-full rounded-md object-cover"
              />
            </div>
          ))}
          <div className="absolute left-5 right-5 top-1/2 flex h-full -translate-y-1/2 transform justify-between sm:h-auto">
            <button
              className={`btn btn-circle m-0 h-full w-[30%]  border-2	border-gray-500 bg-white p-0 opacity-0 sm:h-12 sm:w-12 sm:opacity-100 ${
                currentSlide === 0
                  ? "btn-disabled md:opacity-0"
                  : "md:opacity-100"
              }`}
              onClick={() =>
                currentSlide - 1 >= 0 ? goToSlide(currentSlide - 1) : {}
              }
            >
              &lt;
            </button>
            <button
              className={`btn btn-circle h-full w-[30%] border-2 border-gray-500 bg-white	opacity-0 sm:h-12 sm:w-12 sm:opacity-100 ${
                currentSlide === images.length - 1
                  ? "btn-disabled md:opacity-0"
                  : "md:opacity-100"
              }`}
              onClick={() =>
                currentSlide + 1 < images.length
                  ? goToSlide(currentSlide + 1)
                  : {}
              }
            >
              &gt;
            </button>
          </div>
        </div>
      </div>
      <div className="flex w-full items-center justify-center gap-2 py-4">
        {images.map((_, i) => (
          <div
            key={i}
            className={`${
              currentSlide === i ? "scale-125 " : "scale-100"
            } h-4 w-4 rounded-full bg-gray-300`}
            onClick={() => goToSlide(i)}
          />
        ))}
      </div>
    </div>
  );
};
