import { useTenantId } from "./use-active-tenant-id";
import { User } from "../api/generated/backend";
import { toTenantIdHeader, useApiQuery } from "../api/use-api";
import { ME_KEY } from "../types/query-keys";

export const useUser = (): User | undefined => {
  const tenantId = useTenantId();
  const { data: user } = useApiQuery(
    "backend",
    (api) => api.getMe(toTenantIdHeader(tenantId)),
    ME_KEY(tenantId),
  );
  return user;
};
