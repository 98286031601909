/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EventDetailRecord } from './EventDetailRecord';
import {
    EventDetailRecordFromJSON,
    EventDetailRecordFromJSONTyped,
    EventDetailRecordToJSON,
} from './EventDetailRecord';
import type { EventRecord } from './EventRecord';
import {
    EventRecordFromJSON,
    EventRecordFromJSONTyped,
    EventRecordToJSON,
} from './EventRecord';

/**
 * 
 * @export
 * @interface EventsDTO
 */
export interface EventsDTO {
    /**
     * 
     * @type {Array<EventDetailRecord>}
     * @memberof EventsDTO
     */
    userDetails: Array<EventDetailRecord>;
    /**
     * 
     * @type {Array<EventRecord>}
     * @memberof EventsDTO
     */
    events: Array<EventRecord>;
}

/**
 * Check if a given object implements the EventsDTO interface.
 */
export function instanceOfEventsDTO(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userDetails" in value;
    isInstance = isInstance && "events" in value;

    return isInstance;
}

export function EventsDTOFromJSON(json: any): EventsDTO {
    return EventsDTOFromJSONTyped(json, false);
}

export function EventsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userDetails': ((json['userDetails'] as Array<any>).map(EventDetailRecordFromJSON)),
        'events': ((json['events'] as Array<any>).map(EventRecordFromJSON)),
    };
}

export function EventsDTOToJSON(value?: EventsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userDetails': ((value.userDetails as Array<any>).map(EventDetailRecordToJSON)),
        'events': ((value.events as Array<any>).map(EventRecordToJSON)),
    };
}

