import { useTranslation } from "react-i18next";
import { Disease } from "shared/model/diseases";

import { IconType } from "../components/icons/svg-icon";

export type EnumType = "detail" | "event";
export type EnumContent = "title" | "icon";

const getEnumTx = (
  disease: Disease | "common",
  type: EnumType,
  enumValue: string,
  content: EnumContent,
): string => `enums.${disease}.${type}.${enumValue}.${content}`;

export const useEnumIcon = (
  disease: Disease | "common",
  type: EnumType,
  enumValue: string,
): IconType => {
  const { t } = useTranslation();
  const tx = getEnumTx(disease, type, enumValue, "icon");
  const fallbackTx = getEnumTx("common", type, enumValue, "icon");

  return t([tx, fallbackTx], { defaultValue: "empty" }) as IconType;
};

export const useEnumTitle = (
  disease: Disease | "common",
  type: EnumType,
  enumValue: string,
): string => {
  const {
    t,
    i18n: { exists },
  } = useTranslation();
  const tx = getEnumTx(disease, type, enumValue, "title");
  const fallbackTx = getEnumTx("common", type, enumValue, "title");
  const defaultValue = enumValue
    .split("_")
    .map(
      (upperCase) =>
        `${upperCase.slice(0, 1)}${upperCase.toLowerCase().slice(1)}`,
    )
    .join(" ");

  if (exists(tx, { fallbackLng: "false" })) {
    return t(tx);
  }

  return t(fallbackTx, { defaultValue });
};
