import React, { PropsWithChildren, useEffect, useRef, useState } from "react";

import { useDetailsBlur } from "../../util/hooks";

interface DropdownLayoutProps {
  summaryElement: React.ReactElement;
  className?: string;
  setCloseDropdown?: (closeDropdown: () => () => void) => void;
  closeOnClickOutside: boolean;
  isOpenInitially: boolean;
}

export const MenuDropdown = ({
  summaryElement,
  children,
  className,
  setCloseDropdown,
  closeOnClickOutside,
  isOpenInitially,
}: PropsWithChildren<DropdownLayoutProps>): ReturnType<React.FC> => {
  const dropDownRef = useRef<HTMLDetailsElement>(null);

  useDetailsBlur(dropDownRef, !closeOnClickOutside);

  useEffect(() => {
    setCloseDropdown?.(() => () => {
      if (dropDownRef.current?.open) {
        dropDownRef.current.open = false;
      }
    });
  }, [setCloseDropdown]);

  return (
    <details
      ref={dropDownRef}
      className={className}
      open={useState(isOpenInitially)[0]}
    >
      {summaryElement}

      {children}
    </details>
  );
};
