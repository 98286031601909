import { Button } from "../../../../../components/form/button";
import { LoadingButton } from "../../../../../components/form/loading-button";
import { Tooltip } from "../../../../../components/tooltip";
import { DefaultContentContainer } from "../../../../../layouts/default-content-container";
import { FormDataCollectionContext, FormDataGuardLayout } from "../types";

export const signUpLayout: FormDataGuardLayout = {
  Layout: ({ inputs, loading, goToGuard, currentGuard, ...rest }) => {
    const currentGuardIndex = currentGuard ?? 0;
    return (
      <DefaultContentContainer className="max-w-[1200px]">
        <div className="flex flex-row items-center justify-center gap-4">
          <div className="flex w-full shrink-0 flex-col gap-2 xl:max-w-[600px] 2xl:max-w-[700px]">
            {inputs.map(({ input, reasonWhyShown, prefilledValue }, index) => {
              const component = (
                <input.Component
                  key={index}
                  prefilledValue={prefilledValue}
                  context={FormDataCollectionContext.SIGN_UP}
                  {...rest}
                />
              );
              return (
                <Tooltip
                  key={index}
                  className="tooltip-top w-full self-start"
                  text={reasonWhyShown}
                >
                  {component}
                </Tooltip>
              );
            })}
            <div className="grid grid-flow-col justify-stretch">
              <Button
                className={`mx-4 mt-4 ${
                  currentGuard !== 0
                    ? "opacity-100"
                    : "cursor-default opacity-0"
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  goToGuard?.(Math.max(0, currentGuardIndex - 1));
                }}
                tx="navigateBack"
              />

              <LoadingButton
                loading={loading}
                tx="general.confirm"
                className="mx-4 mt-4"
              />
            </div>
          </div>
        </div>
      </DefaultContentContainer>
    );
  },
};
