import { useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import React, { useMemo, useRef, useState } from "react";

import { useActiveLandbotUrlTx } from "./active-landbot-url-tx";
import { LandbotChatInitialView } from "./landbot-chat-initial-view";
import { LoadedLandbotChat } from "./loaded-landbot-chat";
import {
  loadLocalLandbotState,
  saveLandbotStateLocally,
} from "./session-storage";
import { UpdateSettingsRequest } from "../../../api/generated/backend";
import {
  toTenantIdHeader,
  useApiMutation,
  useApiQuery,
} from "../../../api/use-api";
import { useObservedUserData } from "../../../auth/auth-store-context";
import {
  LANDBOT_CHAT_COMPLETENESS_KEY,
  LANDBOT_CHAT_EVENT_COMPLETENESS_KEY,
  ME_KEY,
} from "../../../types/query-keys";
import { useTenantId } from "../../../util/use-active-tenant-id";

export const LandbotChat: React.FC = () => {
  const userData = useObservedUserData();
  const tenantId = useTenantId();
  const queryClinet = useQueryClient();

  const lastRequest = useRef(Promise.resolve());
  const { data: user } = useApiQuery(
    "backend",
    (api) => api.getMe(toTenantIdHeader(tenantId)),
    ME_KEY(tenantId),
  );

  const storedLanguage = useMemo(
    () => user?.settings?.landbotLanguage,
    [user?.settings?.landbotLanguage],
  );
  const [initialLanguage, setInitialLanguage] = useState<string>();
  const resultingLanguage = useMemo(
    () => storedLanguage ?? initialLanguage,
    [storedLanguage, initialLanguage],
  );

  const activeLandbotUrlTx = useActiveLandbotUrlTx();

  const initialValues = useMemo(
    () =>
      activeLandbotUrlTx && userData?.sub
        ? JSON.parse(
            loadLocalLandbotState({
              landbotUrlTx: activeLandbotUrlTx,
              sub: userData.sub,
            }) ??
              user?.settings?.landbotSettings.find(
                ({ landbotUrlTx }) => landbotUrlTx === activeLandbotUrlTx,
              )?.landbotSettingsJson ??
              "{}",
          )
        : {},
    [user?.settings?.landbotSettings, activeLandbotUrlTx, userData?.sub],
  );

  const landbotUrl = useMemo(
    () =>
      activeLandbotUrlTx && resultingLanguage
        ? t(activeLandbotUrlTx, { lng: resultingLanguage })
        : undefined,
    [activeLandbotUrlTx, resultingLanguage],
  );

  const { mutate: updateSettings } = useApiMutation(
    "backend",
    (api) => (request: UpdateSettingsRequest) => api.updateSettings(request),
  );

  if (!activeLandbotUrlTx || !userData?.sub) {
    return null;
  } else if (landbotUrl) {
    return (
      <LoadedLandbotChat
        configUrl={landbotUrl}
        initialLandbotValues={initialValues}
        updateLandbotValues={(newLandbotValues, persist) =>
          (lastRequest.current = lastRequest.current.finally(() => {
            persist
              ? updateSettings({
                  updateSettingsDto: {
                    landbotSettings: [
                      {
                        landbotUrlTx: activeLandbotUrlTx,
                        landbotSettingsJson: JSON.stringify(newLandbotValues),
                      },
                    ],
                  },
                  ...toTenantIdHeader(tenantId),
                })
              : saveLandbotStateLocally(
                  {
                    landbotUrlTx: activeLandbotUrlTx,
                    sub: userData.sub,
                  },
                  JSON.stringify(newLandbotValues),
                );

            queryClinet.invalidateQueries(
              LANDBOT_CHAT_EVENT_COMPLETENESS_KEY(tenantId.disease),
            );

            queryClinet.invalidateQueries(
              LANDBOT_CHAT_COMPLETENESS_KEY(tenantId.disease),
            );
          }))
        }
      />
    );
  } else {
    return (
      <LandbotChatInitialView
        selectLanguage={(language) =>
          (lastRequest.current = lastRequest.current
            .finally(() =>
              updateSettings({
                updateSettingsDto: { landbotLanguage: language },
                mamaDisease: tenantId.disease,
              }),
            )
            .then(() => setInitialLanguage(language)))
        }
      />
    );
  }
};
