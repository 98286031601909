import { t } from "i18next";

import { I18nProps } from "../components/text";

export const getTextValue = (I18nObj: I18nProps): string =>
  I18nObj.tx
    ? t(I18nObj.tx)
    : I18nObj.txUnchecked
    ? t(I18nObj.txUnchecked)
    : I18nObj.text ?? "";
