"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.legalDataGuard = void 0;
const data_guard_type_1 = require("../../data-guard-type");
const form_data_guard_1 = require("../form-data-guard");
exports.legalDataGuard = (0, data_guard_type_1.createDataGuard)({
    path: "legal",
    type: data_guard_type_1.DataGuardType.FORM_DATA_GUARD,
    title: "dataGuards.legal.title",
    inputs: [
        { type: form_data_guard_1.FormDataCollectionInputType.PRIVACY_POLICY_2023_10_18 },
        { type: form_data_guard_1.FormDataCollectionInputType.TERMS_AND_CONDITIONS_2023_08_30 },
    ],
    layout: form_data_guard_1.FormDataGuardLayoutType.SIGN_UP_LAYOUT,
});
