/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StartPublicConversationResponse
 */
export interface StartPublicConversationResponse {
    /**
     * 
     * @type {string}
     * @memberof StartPublicConversationResponse
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof StartPublicConversationResponse
     */
    conversationId: string;
    /**
     * 
     * @type {string}
     * @memberof StartPublicConversationResponse
     */
    userId: string;
}

/**
 * Check if a given object implements the StartPublicConversationResponse interface.
 */
export function instanceOfStartPublicConversationResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "conversationId" in value;
    isInstance = isInstance && "userId" in value;

    return isInstance;
}

export function StartPublicConversationResponseFromJSON(json: any): StartPublicConversationResponse {
    return StartPublicConversationResponseFromJSONTyped(json, false);
}

export function StartPublicConversationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StartPublicConversationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
        'conversationId': json['conversation_id'],
        'userId': json['user_id'],
    };
}

export function StartPublicConversationResponseToJSON(value?: StartPublicConversationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'conversation_id': value.conversationId,
        'user_id': value.userId,
    };
}

