import React, { useMemo, useState } from "react";
import { twMerge } from "tailwind-merge";

import { GhostButton } from "./button";
import { TextInput } from "./text-input";
import { getTextValue } from "../../util/text";
import { I18nProps, Text } from "../text";

export interface SearchableDropdownProps {
  value?: string;
  onChange?: (value: string) => void | undefined;
  options: {
    value: string;
    label: I18nProps;
    disabled?: { isOptionDisabled: boolean; reasonWhyDisabled?: I18nProps };
  }[];
  className?: string;
  disabled?: boolean;
  overflowVisible?: boolean;
  selectedValueClassName?: string;
  resultsLimit?: number;
  searchResultsOnTop?: boolean;
}

export const SearchableDropdown = ({
  value,
  options,
  onChange,
  className,
  disabled,
  overflowVisible,
  selectedValueClassName,
  resultsLimit,
  searchResultsOnTop,
}: SearchableDropdownProps): ReturnType<React.FC> => {
  const [searchResults, setSearchResults] = useState<typeof options>([]);
  const [searchValue, setSearchValue] = useState(value ?? "");
  const [showResults, setShowResults] = useState(false);

  const limit = useMemo(
    () =>
      resultsLimit
        ? resultsLimit < options.length
          ? resultsLimit
          : options.length - 1
        : options.length - 1,
    [options.length, resultsLimit],
  );

  return (
    <div
      className={twMerge(
        "relative flex",
        className,
        disabled ? "pointer-events-none opacity-30" : "",
        searchResultsOnTop ? "flex-col-reverse" : "",
      )}
    >
      <TextInput
        className={selectedValueClassName}
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
          setSearchResults(
            options
              .filter((option) =>
                getTextValue(option.label)
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase()),
              )
              .slice(0, limit),
          );
          setShowResults(true);
        }}
        onClick={() => {
          setShowResults(!showResults && searchValue.length > 0);
        }}
      />

      {searchValue.length > 1 && showResults && (
        <div
          className={twMerge(
            "absolute z-10 my-2 max-h-96 rounded-box bg-white shadow",
            overflowVisible ? "overflow-visible" : "overflow-scroll",
            searchResultsOnTop ? "bottom-12" : "top-12",
          )}
        >
          {searchResults.map((option, index) => (
            <GhostButton
              className="flex w-full flex-row justify-start rounded-md text-left"
              key={index}
              value={option.value}
              onClick={(e) => {
                const labelText = getTextValue(option.label);
                e.preventDefault();
                setSearchValue(labelText);
                setSearchResults(
                  options.filter((opt) =>
                    getTextValue(opt.label)
                      .toLowerCase()
                      .includes(labelText.toLowerCase()),
                  ),
                );
                onChange?.(option.value);
                setShowResults(false);
              }}
            >
              <Text {...option.label} />
            </GhostButton>
          ))}
        </div>
      )}
    </div>
  );
};
