/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationDto
 */
export interface NotificationDto {
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    type: NotificationDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    frequency: NotificationDtoFrequencyEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    messageTitle: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    message: string;
    /**
     * 
     * @type {Date}
     * @memberof NotificationDto
     */
    sendAt: Date;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    language: string;
}


/**
 * @export
 */
export const NotificationDtoTypeEnum = {
    Recurring: 'RECURRING',
    NonRecurring: 'NON_RECURRING'
} as const;
export type NotificationDtoTypeEnum = typeof NotificationDtoTypeEnum[keyof typeof NotificationDtoTypeEnum];

/**
 * @export
 */
export const NotificationDtoFrequencyEnum = {
    HalfHour: 'EVERY_HALF_HOUR',
    Hour: 'EVERY_HOUR',
    TwoHours: 'EVERY_TWO_HOURS',
    ThreeHours: 'EVERY_THREE_HOURS',
    FourHours: 'EVERY_FOUR_HOURS',
    FiveHours: 'EVERY_FIVE_HOURS',
    SixHours: 'EVERY_SIX_HOURS',
    SevenHours: 'EVERY_SEVEN_HOURS',
    EightHours: 'EVERY_EIGHT_HOURS',
    NineHours: 'EVERY_NINE_HOURS',
    TenHours: 'EVERY_TEN_HOURS',
    ElevenHours: 'EVERY_ELEVEN_HOURS',
    TwelveHours: 'EVERY_TWELVE_HOURS',
    ThirteenHours: 'EVERY_THIRTEEN_HOURS',
    FourteenHours: 'EVERY_FOURTEEN_HOURS',
    FifteenHours: 'EVERY_FIFTEEN_HOURS',
    SixteenHours: 'EVERY_SIXTEEN_HOURS',
    SeventeenHours: 'EVERY_SEVENTEEN_HOURS',
    EighteenHours: 'EVERY_EIGHTEEN_HOURS',
    NineteenHours: 'EVERY_NINETEEN_HOURS',
    TwentyHours: 'EVERY_TWENTY_HOURS',
    TwentyoneHours: 'EVERY_TWENTYONE_HOURS',
    TwentytwoHours: 'EVERY_TWENTYTWO_HOURS',
    TwentythreeHours: 'EVERY_TWENTYTHREE_HOURS',
    TwentyfourHours: 'EVERY_TWENTYFOUR_HOURS',
    ThirtysixHours: 'EVERY_THIRTYSIX_HOURS',
    FourtyeightHours: 'EVERY_FOURTYEIGHT_HOURS',
    SeventytwoHours: 'EVERY_SEVENTYTWO_HOURS'
} as const;
export type NotificationDtoFrequencyEnum = typeof NotificationDtoFrequencyEnum[keyof typeof NotificationDtoFrequencyEnum];


/**
 * Check if a given object implements the NotificationDto interface.
 */
export function instanceOfNotificationDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "frequency" in value;
    isInstance = isInstance && "messageTitle" in value;
    isInstance = isInstance && "message" in value;
    isInstance = isInstance && "sendAt" in value;
    isInstance = isInstance && "language" in value;

    return isInstance;
}

export function NotificationDtoFromJSON(json: any): NotificationDto {
    return NotificationDtoFromJSONTyped(json, false);
}

export function NotificationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'type': json['type'],
        'frequency': json['frequency'],
        'messageTitle': json['messageTitle'],
        'message': json['message'],
        'sendAt': (new Date(json['sendAt'])),
        'language': json['language'],
    };
}

export function NotificationDtoToJSON(value?: NotificationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'type': value.type,
        'frequency': value.frequency,
        'messageTitle': value.messageTitle,
        'message': value.message,
        'sendAt': (value.sendAt.toISOString()),
        'language': value.language,
    };
}

