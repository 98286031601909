import { FormTextInput } from "../../../../../components/form/form-text-input";
import { FormDataCollectionInput } from "../types";

export const conditionVariantInput: FormDataCollectionInput = {
  wasCompleted: (user) => !!user.userDetails?.conditionVariant,
  Component: ({ errors, register }) => (
    <FormTextInput
      labelClassName="font-bold"
      title={{ tx: "profile.conditionVariant" }}
      {...register("conditionVariant")}
      error={{ txUnchecked: errors.conditionVariant?.message }}
    />
  ),
};
