/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Doctor
 */
export interface Doctor {
    /**
     * 
     * @type {string}
     * @memberof Doctor
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Doctor
     */
    doctorName: string;
    /**
     * 
     * @type {string}
     * @memberof Doctor
     */
    practiceName: string;
    /**
     * 
     * @type {string}
     * @memberof Doctor
     */
    streetAddress: string;
    /**
     * 
     * @type {number}
     * @memberof Doctor
     */
    rating?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Doctor
     */
    phones?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Doctor
     */
    lat: number;
    /**
     * 
     * @type {number}
     * @memberof Doctor
     */
    _long: number;
    /**
     * 
     * @type {boolean}
     * @memberof Doctor
     */
    isSaved?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Doctor
     */
    isLinked?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Doctor
     */
    generalMedicalSpeciality: DoctorGeneralMedicalSpecialityEnum;
}


/**
 * @export
 */
export const DoctorGeneralMedicalSpecialityEnum = {
    Neurologie: 'neurologie',
    MsSchwerpunktzentrum: 'MS-Schwerpunktzentrum',
    Allgemeinmedizin: 'allgemeinmedizin',
    Physiotherapeut: 'Physiotherapeut'
} as const;
export type DoctorGeneralMedicalSpecialityEnum = typeof DoctorGeneralMedicalSpecialityEnum[keyof typeof DoctorGeneralMedicalSpecialityEnum];


/**
 * Check if a given object implements the Doctor interface.
 */
export function instanceOfDoctor(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "doctorName" in value;
    isInstance = isInstance && "practiceName" in value;
    isInstance = isInstance && "streetAddress" in value;
    isInstance = isInstance && "lat" in value;
    isInstance = isInstance && "_long" in value;
    isInstance = isInstance && "generalMedicalSpeciality" in value;

    return isInstance;
}

export function DoctorFromJSON(json: any): Doctor {
    return DoctorFromJSONTyped(json, false);
}

export function DoctorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Doctor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'doctorName': json['doctorName'],
        'practiceName': json['practiceName'],
        'streetAddress': json['streetAddress'],
        'rating': !exists(json, 'rating') ? undefined : json['rating'],
        'phones': !exists(json, 'phones') ? undefined : json['phones'],
        'lat': json['lat'],
        '_long': json['long'],
        'isSaved': !exists(json, 'isSaved') ? undefined : json['isSaved'],
        'isLinked': !exists(json, 'isLinked') ? undefined : json['isLinked'],
        'generalMedicalSpeciality': json['generalMedicalSpeciality'],
    };
}

export function DoctorToJSON(value?: Doctor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'doctorName': value.doctorName,
        'practiceName': value.practiceName,
        'streetAddress': value.streetAddress,
        'rating': value.rating,
        'phones': value.phones,
        'lat': value.lat,
        'long': value._long,
        'isSaved': value.isSaved,
        'isLinked': value.isLinked,
        'generalMedicalSpeciality': value.generalMedicalSpeciality,
    };
}

