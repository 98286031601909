import React from "react";
import {
  Control,
  Controller,
  FieldPathByValue,
  PathValue,
  Validate,
} from "react-hook-form";

import { FormInput } from "./form-input";
import { formatDate } from "../../util/date";
import { PlainI18nProps } from "../text";

export type FormDateInputProps<
  Values extends object,
  Name extends FieldPathByValue<Values, string | undefined>,
> = {
  title: PlainI18nProps;
  error: PlainI18nProps;
  disabled?: boolean;
  name: Name;
  control: Control<Values>;
  validate?: Validate<PathValue<Values, Name>, Values>;
  labelClassName?: string;
};

export const FormDateInput = <
  Values extends object,
  Name extends FieldPathByValue<Values, string | undefined>,
>({
  title,
  error,
  disabled,
  control,
  name,
  validate,
  labelClassName,
}: FormDateInputProps<Values, Name>): ReturnType<React.FC> => {
  return (
    <FormInput title={title} error={error} labelClassName={labelClassName}>
      <Controller
        name={name}
        control={control}
        rules={{
          validate,
        }}
        render={({ field: { onChange, value } }) => {
          return (
            <input
              className="input-bordered input w-full p-2"
              type="date"
              value={formatDate(value) ?? ""}
              onChange={(newDate) => {
                onChange(newDate.target.value);
              }}
              disabled={disabled}
              onClick={(e) => e.currentTarget.showPicker()}
            />
          );
        }}
      />
    </FormInput>
  );
};
