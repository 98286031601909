import { confirmUserAttribute } from "aws-amplify/auth";
import { useEffect, useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import { cognitoPaths } from "./paths";
import { LoadingIndicator } from "../../../../components/loading-spinner";
import { AuthMessageLevel } from "../../generic/types";
import { useAuthNavigate } from "../../generic/use-auth-navigate";
import { useCognitoConfiguration } from "../hooks/use-cognito-configuration";
import { CognitoError, handleCognitoError } from "../misc/cognito-errors";

export const CognitoUpdateEmailCallback: React.FC = () => {
  const cognitoConfigurationState = useCognitoConfiguration();

  const [params] = useSearchParams();
  const sub = useMemo(() => params.get("sub"), [params]);
  const confirmationCode = useMemo(
    () => params.get("confirmation_code"),
    [params],
  );
  const email = useMemo(() => params.get("email"), [params]);

  const navigate = useAuthNavigate(email ? () => ({ email }) : undefined);

  const hasSentRequest = useRef(false);

  useEffect(() => {
    if (sub && confirmationCode) {
      if (cognitoConfigurationState === "ready" && !hasSentRequest.current) {
        hasSentRequest.current = true;
        confirmUserAttribute({
          userAttributeKey: "email",
          confirmationCode,
        })
          .then(() => {
            navigate({
              to: { type: "uri", uri: cognitoPaths.updateEmailInfo },
              replace: true,
              state: {
                message: {
                  level: AuthMessageLevel.INFO,
                  tx: "profile.updateEmailSuccessful",
                },
              },
            });
          })
          .catch(async (err: CognitoError) => {
            navigate({
              to: { type: "uri", uri: cognitoPaths.updateEmailInfo },
              replace: true,
              state: {
                message: {
                  level: AuthMessageLevel.ERROR,
                  ...handleCognitoError(err),
                },
              },
            });
          });
      }
    } else {
      navigate({
        to: { type: "uri", uri: cognitoPaths.updateEmailInfo },
        replace: true,
        state: {
          message: {
            level: AuthMessageLevel.ERROR,
            tx: "profile.emailConfirmationLinkError",
          },
        },
      });
    }
  }, [cognitoConfigurationState, confirmationCode, navigate, sub]);

  return (
    <div className="min-h-16 flex flex-row items-center justify-center self-stretch">
      <LoadingIndicator
        as="spinner"
        message={{ tx: "profile.confirmingEmailWaitingMessage" }}
      />
    </div>
  );
};
