import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

import { FormAddressInput } from "../../../../../components/form/form-address-input";
import { FormDataCollectionInput } from "../types";

export const addressInput: FormDataCollectionInput = {
  wasCompleted: (user) => !!user.userAttributes?.postalCode,
  Component: ({ errors, control }) => {
    const formContext = useFormContext();

    const updateFields = useCallback(
      (address?: string) => {
        formContext?.setValue(
          "postalCode",
          address ? JSON.parse(address).POSTAL_CODE : "",
        );
        formContext?.setValue("city", address ? JSON.parse(address).CITY : "");
        formContext?.setValue(
          "country",
          address ? JSON.parse(address).COUNTRY : "",
        );
      },
      [formContext],
    );
    return (
      <FormAddressInput
        isOptional
        name="address"
        labelClassName="font-bold"
        title={{ tx: "userData.Address" }}
        control={control}
        error={{ txUnchecked: errors.address?.message }}
        onSuggestionsClick={updateFields}
      />
    );
  },
};
