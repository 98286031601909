import React, { useCallback } from "react";

import { useT } from "../../../../../../i18n/use-t";
import { useModal } from "../../../../../../models/modal-provider";
import { PrimaryButton, SecondaryButton } from "../../../../../form/button";
import { ChatTutorial } from "../../../../chat-tutorial";
import { ChatOptionComponentProps } from "../lookup";

export const IntroductionModal: React.FC<ChatOptionComponentProps> = ({
  sendResponse,
}) => {
  const t = useT();
  const { showModal, hideModal } = useModal();

  const toggleModal = useCallback(() => {
    showModal({
      onClose: () => {
        sendResponse?.({
          message: t({ tx: "chat.accept" }),
          selection: [],
        });
        hideModal();
      },
      showCloseButton: false,
      showsWindowCloseButton: true,
      windowCloseButtonClassName: "text-bg-blue-900 border-0",
      className: "max-w-xs",
      children: <ChatTutorial />,
    });
  }, [hideModal, sendResponse, showModal, t]);

  return (
    <div className="flex gap-3">
      <SecondaryButton tx="chat.showMore" onClick={toggleModal} />
      <PrimaryButton
        tx="chat.skip"
        onClick={() => {
          sendResponse?.({ message: t({ tx: "chat.skip" }), selection: [] });
        }}
      />
    </div>
  );
};
