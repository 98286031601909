import * as Sentry from "@sentry/react";
import { DeploymentEnvironment } from "shared/config/deployment-environments";

import { appEnv, isNotLocalhost, sentryRelease } from "./util/env-utils";

const sharedUrls = [
  "https://m.stripe.com/*",
  "https://cognito-idp.eu-central-1.amazonaws.com/*",
];

const networkDetailAllowUrls = [
  ...(appEnv === DeploymentEnvironment.PROD
    ? ["https://api.mamahealth.com/*"]
    : ["https://api.dev.mamahealth.com/*"]),
  ...sharedUrls,
];

const networkRequestHeaders = [
  "Cache-Control",
  "Mama-Disease",
  "Mama-Trace-Id",
  "Origin",
  "Referer",
];
const networkResponseHeaders = [
  "Apigw-Requestid",
  "Content-Type",
  "Mama-Trace-Id",
  "Referrer-Policy",
];

export const setupSentry: () => void = () => {
  Sentry.init({
    dsn: "https://d41e47b09fd36cd211361d597de08dcd@o4506790745604096.ingest.sentry.io/4506791476199424",
    environment: appEnv,
    enabled: isNotLocalhost,
    release: sentryRelease,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: true,
        maskAllInputs: true,
        blockAllMedia: true,
        networkDetailAllowUrls,
        networkRequestHeaders,
        networkResponseHeaders,
      }),
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};

const allowHeaders = (
  headers: Headers,
  whitelist: string[],
): Record<string, string> => {
  const allowed = whitelist.map((s) => s.toLowerCase());
  const whitelisted: Record<string, string> = {};

  headers.forEach((value, key) => {
    if (allowed.includes(value.toLowerCase())) {
      whitelisted[value] = key;
    }
  });

  return whitelisted;
};

export const allowRequestHeaders = (headers: Headers): Record<string, string> =>
  allowHeaders(headers, networkRequestHeaders);

export const allowResponseHeaders = (
  headers: Headers,
): Record<string, string> => allowHeaders(headers, networkResponseHeaders);
