import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { twMerge } from "tailwind-merge";

import { useApiMutation } from "../../api/use-api";
import { useAuthStore } from "../../auth/auth-store-context";
import { SuccessEvent } from "../../components/events/success-event";
import { Link } from "../../components/link";
import { Text } from "../../components/text";
import { useStore } from "../../models/helpers";
import { useModal } from "../../models/modal-provider";
import { ME_KEY } from "../../types/query-keys";
import { useTenantId } from "../../util/use-active-tenant-id";

export const DeleteUserButton: FC<JSX.IntrinsicElements["div"]> = ({
  className,
}) => {
  const queryClient = useQueryClient();
  const tenantId = useTenantId();
  const store = useStore();
  const { signOut } = useAuthStore();
  const { showModal, hideModal, updateModal } = useModal();
  const { mutate: deleteUser } = useApiMutation(
    "backend",
    (api) => () => api.deletUserFromCognito(),
    undefined,
    undefined,
    {
      onSuccess: () => {
        queryClient.removeQueries(ME_KEY(tenantId));
        hideModal();
        signOut?.();
        store.addToastEvent(new SuccessEvent({ tx: "profile.accountDeleted" }));
      },
    },
  );
  return (
    <Text
      className={twMerge("cursor-pointer text-error", className)}
      tx="profile.deleteAccount"
      onClick={() => {
        if (!signOut) {
          alert("Error: no auth implementation.");
          return;
        }

        showModal({
          title: { tx: "profile.deleteAccount" },
          description: {
            tx: "profile.deleteAccountDescription",
            txComponents: {
              SupportEmailLink: <Link />,
            },
          },
          onConfirm: () => {
            updateModal({
              loading: { isLoading: true },
              description: { text: "" },
            });

            deleteUser();
          },
        });
      }}
    />
  );
};
