import { getPointsPerProfileCompletionLevel } from "shared/model/data-collection/profile/profile-completeness-score";

export enum DefaultFormUpdateProfileInputType {
  NAME = "NAME",
  EMAIL = "EMAIL",
  COUNTRY = "COUNTRY",
  LANGUAGE = "LANGUAGE",
  POSTAL_CODE = "POSTAL_CODE",
  PHONE_NUMBER = "PHONE_NUMBER",
  PROFILE_IMAGE = "PROFILE_IMAGE",
  AGREED_TO_BE_VISIBLE = "AGREED_TO_BE_VISIBLE",
  IS_MEMBER_OF_PAG = "IS_MEMBER_OF_PAG",
}

export enum OpadeFormUpdateProfileInputType {
  OPADE_PATIENT_ID = "OPADE_PATIENT_ID",
}

export const mamaLogo = "/resources/mama-logo.svg";

export const pointsPerProfileCompletionLevel =
  getPointsPerProfileCompletionLevel();
