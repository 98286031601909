import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import { UpdateDefaultPaymentMethodRequest } from "../../api/generated/backend";
import {
  toTenantId,
  toTenantIdHeader,
  useApiMutation,
} from "../../api/use-api";
import { ModalConfig, useModal } from "../../models/modal-provider";
import {
  PAYMENT_METHODS_KEY,
  STRIPE_CUSTOMER_KEY,
} from "../../types/query-keys";
import { useTenantId } from "../../util/use-active-tenant-id";
import { SecondaryButton } from "../form/button";
import { StripeCardList } from "../stripe/stripe-card-list";
import { StripeCollectCardInformationForm } from "../stripe/stripe-collect-card-information-form";

export const UpdatePaymentMethodButton: React.FC = () => {
  const { hideModal, showModal, updateModal } = useModal();

  return (
    <SecondaryButton
      tx="stripe.changeCardForFuturePayments"
      onClick={() => {
        showModal({
          title: { tx: "stripe.changeCardForFuturePayments" },
          description: { tx: "stripe.updateCardExplanation" },
          children: (
            <PaymentMethodSelector
              hideModal={hideModal}
              updateModal={updateModal}
            />
          ),
        });
      }}
    />
  );
};

const PaymentMethodSelector: React.FC<{
  hideModal: () => void;
  updateModal: (config: Partial<ModalConfig>) => void;
}> = ({ updateModal, hideModal }) => {
  const tenantId = useTenantId();
  const queryClient = useQueryClient();
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const { mutate: updateDefaultPaymentMethod } = useApiMutation(
    "backend",
    (api) => (request: UpdateDefaultPaymentMethodRequest) =>
      api.updateDefaultPaymentMethod(request),
    undefined,
    { successMessage: { tx: "stripe.cardUpdated" } },
    {
      onSettled: () => {
        hideModal();
        setIsButtonLoading(false);
      },
      onSuccess: (_, header) => {
        queryClient.invalidateQueries(STRIPE_CUSTOMER_KEY(toTenantId(header)));
        queryClient.invalidateQueries(PAYMENT_METHODS_KEY(toTenantId(header)));
      },
    },
  );

  return (
    <div className="mt-5 flex flex-col gap-4">
      <StripeCollectCardInformationForm />
      <StripeCardList
        onConfirm={(defaultPaymentMethodId) => {
          setIsButtonLoading(true);
          updateModal({
            disabled: { isCloseButtonDisabled: true },
          });
          updateDefaultPaymentMethod({
            defaultPaymentMethodDto: {
              defaultPaymentMethodId,
            },
            ...toTenantIdHeader(tenantId),
          });
        }}
        confirmButtonControls={{
          isLoading: isButtonLoading,
          isDisabledIfDefaultPaymentSelected: true,
        }}
      />
    </div>
  );
};
