import { observer } from "mobx-react-lite";

import { Event } from "./event";
import { useStore } from "../../models/helpers";

export const Events = observer(() => {
  const store = useStore();
  return (
    <div className="toast fixed z-[1002]">
      {store.events.map((event, idx) => (
        <Event key={idx} {...event} />
      ))}
    </div>
  );
});
