import { useQueryClient } from "@tanstack/react-query";
import { VideoGuard } from "shared/model/data-collection/guard/video-data-guard/video-guard";

import { useApiMutation } from "../../../../api/use-api";
import { Button } from "../../../../components/form/button";
import { useT } from "../../../../i18n/use-t";
import { ME_KEY } from "../../../../types/query-keys";
import { useTenantId } from "../../../../util/use-active-tenant-id";
import { useUser } from "../../../../util/use-user";
import { DataGuardImplementation } from "../types";

export const videoGuardImplementation: DataGuardImplementation = {
  useWasCompleted: () => {
    const user = useUser();
    return !!user?.userAttributes?.watchedOnboardingVideo;
  },
  Component: ({ config }) => {
    return <VideoComponent config={config as VideoGuard} />;
  },
};

const VideoComponent: React.FC<{ config: VideoGuard }> = ({ config }) => {
  const queryClient = useQueryClient();
  const tenantId = useTenantId();

  const t = useT();
  const currentGuardIndex = config.currentGuard ?? 0;
  const videoUrl = config.autoplay
    ? `${t({ tx: config.videoUrl }) + "?autoplay=1"}`
    : t({ tx: config.videoUrl });

  const { mutate: updateUserAttributes } = useApiMutation(
    "backend",
    (api) => () =>
      api.updateUserAttributes({
        updateUserAttributesDto: { watchedOnboardingVideo: true },
      }),
    undefined,
    {
      successMessage: { tx: "profile.updateSuccessful" },
      trackErrors: true,
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(ME_KEY(tenantId));
        config.goToGuard?.(currentGuardIndex + 1);
      },
    },
  );

  return (
    <div className="flex h-full flex-col items-center justify-center gap-4 px-4 py-8 sm:gap-8 md:px-8 lg:px-0">
      <div className="w-full max-w-4xl">
        <iframe
          className="aspect-video w-full"
          src={videoUrl}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      </div>
      <div className="flex flex-wrap gap-2 sm:gap-4">
        <Button
          onClick={(e) => {
            e.preventDefault();
            config.goToGuard?.(currentGuardIndex - 1);
          }}
          className="btn mx-auto px-14"
          tx="general.goBack"
        />

        <Button
          onClick={(e) => {
            e.preventDefault();
            updateUserAttributes();
          }}
          className="btn-primary btn mx-auto px-14"
          tx="general.confirm"
        />
      </div>
    </div>
  );
};
