/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';
import type { UserFeedbackState } from './UserFeedbackState';
import {
    UserFeedbackStateFromJSON,
    UserFeedbackStateFromJSONTyped,
    UserFeedbackStateToJSON,
} from './UserFeedbackState';
import type { UserFeedbackType } from './UserFeedbackType';
import {
    UserFeedbackTypeFromJSON,
    UserFeedbackTypeFromJSONTyped,
    UserFeedbackTypeToJSON,
} from './UserFeedbackType';

/**
 * Represents a UserFeedback record
 * @export
 * @interface UserFeedback
 */
export interface UserFeedback {
    /**
     * 
     * @type {string}
     * @memberof UserFeedback
     */
    feedbackId: string;
    /**
     * 
     * @type {string}
     * @memberof UserFeedback
     */
    title: string;
    /**
     * 
     * @type {UserFeedbackType}
     * @memberof UserFeedback
     */
    type: UserFeedbackType;
    /**
     * 
     * @type {UserFeedbackState}
     * @memberof UserFeedback
     */
    state: UserFeedbackState;
    /**
     * 
     * @type {string}
     * @memberof UserFeedback
     */
    feedback?: string;
    /**
     * 
     * @type {number}
     * @memberof UserFeedback
     */
    activityMinutes: number;
    /**
     * 
     * @type {Date}
     * @memberof UserFeedback
     */
    timestamp: Date;
    /**
     * 
     * @type {User}
     * @memberof UserFeedback
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof UserFeedback
     */
    userId: string;
}

/**
 * Check if a given object implements the UserFeedback interface.
 */
export function instanceOfUserFeedback(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "feedbackId" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "activityMinutes" in value;
    isInstance = isInstance && "timestamp" in value;
    isInstance = isInstance && "userId" in value;

    return isInstance;
}

export function UserFeedbackFromJSON(json: any): UserFeedback {
    return UserFeedbackFromJSONTyped(json, false);
}

export function UserFeedbackFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserFeedback {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'feedbackId': json['feedbackId'],
        'title': json['title'],
        'type': UserFeedbackTypeFromJSON(json['type']),
        'state': UserFeedbackStateFromJSON(json['state']),
        'feedback': !exists(json, 'feedback') ? undefined : json['feedback'],
        'activityMinutes': json['activityMinutes'],
        'timestamp': (new Date(json['timestamp'])),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'userId': json['userId'],
    };
}

export function UserFeedbackToJSON(value?: UserFeedback | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'feedbackId': value.feedbackId,
        'title': value.title,
        'type': UserFeedbackTypeToJSON(value.type),
        'state': UserFeedbackStateToJSON(value.state),
        'feedback': value.feedback,
        'activityMinutes': value.activityMinutes,
        'timestamp': (value.timestamp.toISOString()),
        'user': UserToJSON(value.user),
        'userId': value.userId,
    };
}

