/* tslint:disable */
/* eslint-disable */
/**
 * mama health backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TimelineDto
 */
export interface TimelineDto {
    /**
     * 
     * @type {string}
     * @memberof TimelineDto
     */
    to: string;
    /**
     * 
     * @type {string}
     * @memberof TimelineDto
     */
    language: TimelineDtoLanguageEnum;
}


/**
 * @export
 */
export const TimelineDtoLanguageEnum = {
    EnUs: 'en-US',
    DeCh: 'de-CH',
    DeDe: 'de-DE',
    ItIt: 'it-IT',
    EsEs: 'es-ES',
    EsCo: 'es-CO',
    NlNl: 'nl-NL',
    TrTr: 'tr-TR',
    FrFr: 'fr-FR'
} as const;
export type TimelineDtoLanguageEnum = typeof TimelineDtoLanguageEnum[keyof typeof TimelineDtoLanguageEnum];


/**
 * Check if a given object implements the TimelineDto interface.
 */
export function instanceOfTimelineDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "to" in value;
    isInstance = isInstance && "language" in value;

    return isInstance;
}

export function TimelineDtoFromJSON(json: any): TimelineDto {
    return TimelineDtoFromJSONTyped(json, false);
}

export function TimelineDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimelineDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'to': json['to'],
        'language': json['language'],
    };
}

export function TimelineDtoToJSON(value?: TimelineDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'to': value.to,
        'language': value.language,
    };
}

